import React, { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { isDefined } from "@apl-digital/utils";
import PhoneField from "@base/components/Global/PhoneField";
import TextField from "@base/components/Global/TextField";
import useDebounce from "@base/hooks/useDebounce";
import { isFieldVisible } from "@context/SalesPointProvider/actions";
import { useAppDispatch, useAppSelector } from "@store";
import { setFormContactInfo } from "@store/contactInfo";
import { selectConfiguration } from "@store/salesPoint";
import { selectCanEditShoppingCart } from "@store/shoppingCart";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  fieldsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
    paddingBottom: spacing.x3l,
  },
  title: {
    fontSize: font.size.x2l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.l,
    color: color.defaultText,
    marginBottom: spacing.l,
  },
  helpText: {
    fontSize: font.size.s,
    color: color.contactInfoMessageLabel,
    lineHeight: font.lineHeight.s,
  },
}));

const ContactDetailsForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const configuration = useAppSelector(selectConfiguration);
  const canEdit = useAppSelector(selectCanEditShoppingCart);
  const dispatch = useAppDispatch();
  const { control } = useFormContext();

  const values = useWatch({
    control,
    name: ["name", "phone", "email"],
  });

  const [name, phone, email] = useDebounce(values, 500);

  useEffect(() => {
    const newContactInfo = {
      Name: isDefined(name) ? name : null,
      Phone: isDefined(phone) ? phone : null,
      Email: isDefined(email) ? email : null,
    };

    dispatch(setFormContactInfo(newContactInfo));
  }, [name, phone, email]);

  return (
    <>
      <div className={classes.title}>{t("order_notifications")}:</div>
      <form className={classes.fieldsContainer}>
        {isFieldVisible("name", configuration) && (
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  value={value || ""}
                  label={t("contact_details_name_label")}
                  setValue={onChange}
                  placeholder={t("contact_details_enter_name")}
                  isDisabled={!canEdit}
                  hasError={!!error}
                  errorMessage={error && error.message}
                  id="contact-details-name"
                  onBlur={onBlur}
                />
              </>
            )}
          />
        )}
        {isFieldVisible("phone", configuration) && (
          <Controller
            control={control}
            name="phone"
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => (
              <PhoneField
                value={value || ""}
                setValue={onChange}
                label={t("contact_details_phone_label")}
                setCountryCode={() => {}}
                hasError={!!error}
                errorMessage={error && error.message}
                id="contact-details-phone"
                isDisabled={!canEdit}
                onBlur={onBlur}
              />
            )}
          />
        )}
        {isFieldVisible("email", configuration) && (
          <Controller
            control={control}
            name="email"
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                value={value || ""}
                type="email"
                label={t("contact_details_email_label")}
                setValue={onChange}
                placeholder={t("contact_details_enter_email")}
                isDisabled={!canEdit}
                hasError={!!error}
                errorMessage={error && error.message}
                id="contact-details-email"
                onBlur={onBlur}
              />
            )}
          />
        )}
      </form>
      <div className={classes.helpText}>{t("contact_details_add_msg")}</div>
    </>
  );
};

export default ContactDetailsForm;
