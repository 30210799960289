import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles(({ color, borderRadius, spacing, font }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.x5l,
    marginBottom: 50,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  addCommentMsg: {
    fontWeight: font.weight.l,
  },
  comment: {
    border: `1px solid ${color.productPageSpecialNeedsFieldBorder}`,
    boxSizing: "border-box",
    borderRadius: borderRadius.input,
    width: "100%",
    height: "auto",
    minHeight: 48,
    resize: "none",
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    padding: [spacing.l, spacing.xxl],
    outline: 0,
    background: color.productPageSpecialNeedsFieldBg,
    color: color.fieldText,
    "&::placeholder": {
      color: color.productPageSpecialNeedsComment,
    },
    overflow: "hidden",
  },
  commentDisabled: {
    cursor: "not-allowed",
  },
  infoText: {
    color: color.productPageSpecialNeedsTitle,
    whiteSpace: "pre",
    fontSize: font.size.x2l,
    lineHeight: font.lineHeight.l,
    fontWeight: font.weight.s,
  },
}));

type SpecialNeedsBlockProps = {
  comment: string | undefined;
  setComment: (value: string) => void;
  isDisabled?: boolean;
};

const SpecialNeedsBlock: React.FC<SpecialNeedsBlockProps> = ({
  comment,
  setComment,
  isDisabled = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const endRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (comment && textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = "48px";
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    } else if (
      (!comment || comment === "") &&
      textAreaRef &&
      textAreaRef.current
    ) {
      textAreaRef.current.style.height = "48px";
    }
  }, [comment]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.infoText}>
          {`${t("specialNeeds_question")} `}
        </div>
      </div>
      <div ref={endRef}>
        <textarea
          ref={textAreaRef}
          placeholder={t("specialNeeds_placeholder")}
          className={classNames(
            classes.comment,
            isDisabled && classes.commentDisabled,
          )}
          value={comment || ""}
          maxLength={200}
          rows={1}
          onChange={(e) => setComment(e.target.value)}
          readOnly={isDisabled}
        />
      </div>
    </div>
  );
};

export default SpecialNeedsBlock;
