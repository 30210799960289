import { ITheatreLayout } from "@api/interfaces/theatreLayouts";
import { ResponseFailed } from "@api/types";
import { isPresent } from "@apl-digital/utils";
import { getSessionStorage, SessionStorageKey } from "@constants/storage";
import { createSlice } from "@reduxjs/toolkit";
import {
  setStateFailed,
  setStatePending,
} from "@store/apiRequestStatusMachine";

import { getTheatreResource } from "./actions";
import { TheatreResourceState } from "./types";

const getInitialState = (): TheatreResourceState => {
  const theatreResource = getSessionStorage(SessionStorageKey.THEATER_RESOURCE);

  return {
    theatreResource: isPresent(theatreResource)
      ? { state: theatreResource, status: "stale" }
      : { status: "idle" },
  };
};

export const theatreResourceSlice = createSlice({
  name: "theatreResource",
  initialState: getInitialState(),
  reducers: {
    resetTheatreResource: (state) => {
      state.theatreResource = getInitialState().theatreResource;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTheatreResource.pending, (state) => {
        setStatePending<ITheatreLayout, ResponseFailed>(
          state.theatreResource,
          (nextState) => (state.theatreResource = nextState),
        );
      })
      .addCase(getTheatreResource.fulfilled, (state, { payload }) => {
        state.theatreResource = {
          state: payload,
          status: "succeeded",
        };
      })
      .addCase(getTheatreResource.rejected, (state, { payload }) => {
        setStateFailed<ITheatreLayout, ResponseFailed>(
          state.theatreResource,
          (nextState) =>
            (state.theatreResource = { ...nextState, state: payload! }),
        );
      });
  },
  selectors: {
    selectTheatreResource: (state) => state.theatreResource,
  },
});

export const { resetTheatreResource } = theatreResourceSlice.actions;

export const { selectTheatreResource } = theatreResourceSlice.selectors;

export default theatreResourceSlice.reducer;
