import React, { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { isPresent } from "@apl-digital/utils";
import { useAppDispatch, useAppSelector } from "@store";
import {
  addNewOrder,
  getOrderStatus,
  selectIsOrderRegistered,
  selectOrdersForRefresh,
  setAllOrdersStale,
} from "@store/orders";
import {
  resetShoppingCart,
  selectCurrentBarcodeWithoutChecksum,
} from "@store/shoppingCart";

type OrderStatusPageContextResults = {
  selectedOrderId?: string;
};

const OrderStatusPageContext = createContext<OrderStatusPageContextResults>(
  {} as OrderStatusPageContextResults,
);

export const useOrderStatusPageContext = () =>
  useContext(OrderStatusPageContext);

type OrderStatusPageContextProviderProps = {
  children?: React.ReactNode;
};

export const OrderStatusPageContextProvider: React.FC<
  OrderStatusPageContextProviderProps
> = ({ children }) => {
  const { orderID } = useParams();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const isOrderRegistered = useAppSelector((state) =>
    selectIsOrderRegistered(state, orderID),
  );
  const refreshingOrders = useAppSelector(selectOrdersForRefresh);
  const barcodeWithoutChecksum = useAppSelector(
    selectCurrentBarcodeWithoutChecksum,
  );

  useEffect(() => {
    if (isPresent(orderID) && !isOrderRegistered) {
      dispatch(
        addNewOrder({
          BarcodeWithoutChecksum: orderID,
          dttmCreatedUTC: new Date().toISOString(),
        }),
      );

      if (
        isPresent(barcodeWithoutChecksum) &&
        isPresent(orderID) &&
        barcodeWithoutChecksum === orderID
      ) {
        dispatch(resetShoppingCart());
      }
    }
  }, [orderID, isOrderRegistered, barcodeWithoutChecksum]);

  useEffect(() => {
    if (refreshingOrders.length === 0) return;

    dispatch(
      getOrderStatus({
        barcodeWithoutChecksums: refreshingOrders,
      }),
    );
  }, [refreshingOrders]);

  useEffect(() => {
    dispatch(setAllOrdersStale());
  }, [i18n.language]);

  return (
    <OrderStatusPageContext.Provider
      value={{
        selectedOrderId: orderID,
      }}
    >
      {children}
    </OrderStatusPageContext.Provider>
  );
};
