import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useAppDispatch } from "@store";
import { setIsRootScrollbarVisible } from "@store/layout";
import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { ModalRoot } from "./ModalRoot";

type StyleProps = {
  customZIndex?: number;
  isMdAndUp?: boolean;
  isFullWidth?: boolean;
};

const useStyles = createUseStyles(({ color, zIndex }) => ({
  outerContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "end",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: color.modalOverlay,
    zIndex: ({ customZIndex }: StyleProps) => customZIndex || zIndex.footer,
    overflow: "hidden",
  },
  container: {
    background: color.modalSearchBg,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: ({ isFullWidth }: StyleProps) => (isFullWidth ? "100%" : "unset"),
    bottom: 0,
    overflowY: "hidden",
    position: "relative",
  },
  content: {
    overflowX: ({ isMdAndUp }: StyleProps) => (isMdAndUp ? "hidden" : "scroll"),
    height: "100%",
  },
}));

const outerVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const innerVariants = {
  bottomToTop: {
    open: { top: 0 },
    closed: { top: "100%" },
  },
  rightToLeft: {
    open: { left: 0 },
    closed: { left: "100%" },
  },
};

type TransitionVariant = "bottomToTop" | "rightToLeft";

type GenericModalContentProps = {
  id?: string;
  closeModal: () => void;
  override?: string;
  transitionVariant?: TransitionVariant;
  customZIndex?: number;
  isMdAndUp?: boolean;
  isFullWidth?: boolean;
  children?: React.ReactNode;
  shouldHideRootScrollbar?: boolean;
};

const GenericModalContent: React.FC<GenericModalContentProps> = ({
  id,
  closeModal,
  customZIndex,
  override,
  isMdAndUp,
  transitionVariant = "bottomToTop",
  isFullWidth = false,
  children,
  shouldHideRootScrollbar = false,
}) => {
  const dispatch = useAppDispatch();

  const classes = useStyles({ customZIndex, isMdAndUp, isFullWidth });

  useEffect(() => {
    if (shouldHideRootScrollbar) dispatch(setIsRootScrollbarVisible(false));

    return () => {
      if (shouldHideRootScrollbar) dispatch(setIsRootScrollbarVisible(true));
    };
  }, []);

  return (
    <motion.div
      initial="closed"
      animate="open"
      exit="closed"
      variants={outerVariants}
      className={classnames([classes.outerContainer])}
      onClick={closeModal}
      id={id}
    >
      <motion.div
        initial="closed"
        animate="open"
        exit="closed"
        variants={innerVariants[transitionVariant]}
        className={classnames([classes.container, override])}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.content}>{children}</div>
      </motion.div>
    </motion.div>
  );
};

type GenericModalProps = GenericModalContentProps & {
  title?: string;
  isOpen: boolean;
  showHeader?: boolean;
  children?: React.ReactNode;
};

const GenericModal: React.FC<GenericModalProps> = ({
  isOpen,
  children,
  ...props
}) => {
  return (
    <ModalRoot>
      <AnimatePresence>
        {isOpen && (
          <GenericModalContent {...props}>{children}</GenericModalContent>
        )}
      </AnimatePresence>
    </ModalRoot>
  );
};

export default GenericModal;
