import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "@base/components/Global/Button";
import Icon from "@base/components/Global/Icon";
import TextField from "@base/components/Global/TextField";
import { useAppDispatch, useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import {
  getTheatreResource,
  selectTheatreResource,
} from "@store/theatreResource";
import { getBlobStorage, getBrandTheme } from "@theme";

const useStyles = createUseStyles(
  ({ color, spacing, font, borderRadius, zIndex }) => ({
    container: {
      position: "absolute",
      top: "calc(100vh - 90%)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      background: color.modalQrTableNrBg,
      padding: [44, spacing.x4l, 22, spacing.x4l],
      borderRadius: borderRadius.x3l,
      fontSize: font.size.xs,
      color: color.modalQrText,
      maxWidth: 343,
      gap: spacing.x4l,
    },
    headerText: {
      fontSize: font.size.x3l,
      fontWeight: font.weight.s,
      lineHeight: font.lineHeight.xl,
    },
    overlay: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: color.modalOverlay,
      zIndex: zIndex.actionTopModal,
    },
    body: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    text: {
      fontWeight: font.weight.m,
      fontSize: font.size.m,
      lineHeight: font.lineHeight.s,
      color: color.modalQrHelpText,
    },
    btn: {
      whiteSpace: "nowrap",
    },
    error: {
      color: color.error,
      paddingBottom: spacing.s,
    },
    icon: {
      borderRadius: borderRadius.qrCode,
      display: "flex",
      overflow: "hidden",
      height: "fit-content",
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: spacing.l,
      width: "100%",
    },
    scanBtn: {
      background: color.modalQrButtonBg,
      border: `1px solid ${color.modalQrButtonBorder}`,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  }),
);

type TheatreResourceModalContentProps = {
  onAdded?: (value: string) => void;
  onClose: () => void;
  switchToScanner: () => void;
};

export const TheatreResourceModalContent: React.FC<
  TheatreResourceModalContentProps
> = ({ onAdded, onClose, switchToScanner }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const tableInfo = useAppSelector(selectTheatreResource);
  const isTouchDevice = useAppSelector((state) => state.layout.isTouchDevice);

  const [value, setValue] = useState<string>("");
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);

  useEffect(() => {
    if (hasData(tableInfo)) {
      setValue(tableInfo.state.Barcode);
    }
  }, [tableInfo]);

  const submitAction = () => {
    dispatch(getTheatreResource({ barcodeWithoutChecksum: value }))
      .unwrap()
      .then(() => {
        onClose();
        onAdded?.(value);
      })
      .catch(() => {
        setShouldShowError(true);
      });
  };

  const submitOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && value) {
      submitAction();
    }
  };

  const { scanQrIcon } = getBlobStorage();
  const { color } = getBrandTheme();

  const variant = hasData(tableInfo) ? "enter" : "change";

  return (
    <>
      <div className={classes.headerText}>
        {t(`qr_modal_header_${variant}`)}
      </div>
      <div className={classes.body}>
        {shouldShowError && (
          <div className={classes.error}>{t("qr_modal_errorMsg")}</div>
        )}
        <TextField
          autoComplete="off"
          handleKeyDown={submitOnEnter}
          setValue={setValue}
          value={value}
          hidePrefixIconOnValue={false}
          showClearButton={false}
          hasError={shouldShowError}
          placeholder={t("qr_tableNr")}
          autoFocus
          id="qr-modal-input"
        />
      </div>
      <div className={classes.text}>
        {t(`qr_modal_helpMsg_${variant}`)}
        <span>{t("qr_modal_helpSaveMsg")}</span>
      </div>
      <div className={classes.actions}>
        {isTouchDevice && (
          <Button
            action={switchToScanner}
            className={classes.scanBtn}
            id="rescan-qr-btn"
          >
            <div className={classes.icon}>
              <Icon
                height={20}
                width={20}
                url={scanQrIcon}
                stroke={color.modalQrButtonIcon}
                wrapper="svg"
              />
            </div>
          </Button>
        )}
        <Button
          id="save-qr-btn"
          action={submitAction}
          className={classes.btn}
          isDisabled={!value}
        >
          {t("qr_modal_saveBtn")}
        </Button>
      </div>
    </>
  );
};
