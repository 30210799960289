import React, {
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import DropdownBlock from "@base/components/Global/PhoneField/DropdownBlock";
import DropdownButton from "@base/components/Global/PhoneField/DropdownButton";
import { getCountryCodes } from "@base/components/Global/PhoneField/helpers";
import { CountryCode } from "@base/components/Global/PhoneField/types";
import classnames from "classnames";

export const DROPDOWN_BUTTON_ID = "dropdown-button";

const useStyles = createUseStyles(
  ({ color, spacing, font, borderRadius, opacity }) => ({
    container: {
      paddingTop: 5,
      position: "relative",
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      background: color.fieldBg,
      width: "100%",
      height: 48,
      lineHeight: font.lineHeight.s,
      padding: [spacing.s, spacing.l],
      border: `1px solid ${color.fieldBorder}`,
      borderRadius: borderRadius.input,
    },
    input: {
      backgroundColor: color.fieldBg,
      color: color.fieldText,
      width: "100%",
      outline: 0,
      border: 0,
      padding: 0,
      fontSize: font.size.m,
      lineHeight: font.lineHeight.s,
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    inputDisabled: {
      cursor: "not-allowed",
    },
    fieldError: {
      border: `1px solid ${color.fieldErrorBorder}`,
    },
    label: {
      zIndex: 1,
      position: "absolute",
      top: -5,
      left: 18,
      background: color.fieldBg,
      color: color.fieldLabelText,
      padding: [0, 5],
    },
    labelError: {
      color: color.error,
    },
    errorMessage: {
      color: color.error,
      padding: [0, spacing.xxl],
      fontSize: font.size.xxs,
      lineHeight: "1.3em",
    },
    disabled: {
      opacity: opacity.disabled,
      cursor: "not-allowed !important",
    },
  }),
);

type PhoneFieldProps = {
  setValue: (value: string | null) => void;
  setCountryCode: (countryCode: string | null) => void;
  value: string;
  hasError?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  handleKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  id?: string;
  isDisabled: boolean;
  label?: string;
  errorMessage?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const PhoneField: React.FC<PhoneFieldProps> = ({
  setValue,
  setCountryCode,
  value,
  hasError = false,
  errorMessage,
  inputRef,
  label,
  handleKeyDown,
  id = "tel",
  isDisabled,
  onBlur,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [country, updateCountry] = useState<CountryCode | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const countryCodes = getCountryCodes(t);
  const setCountry = (newCountry: CountryCode) => {
    updateCountry(newCountry);
  };

  useEffect(() => {
    if (!phone) {
      setValue(null);
      setCountryCode(null);
    } else if (country && phone) {
      setCountryCode(country.code);
      setValue(`${country.code}${phone.replace(/\s+/g, "")}`);
    }
  }, [country, phone]);

  useEffect(() => {
    if (countryCodes && countryCodes.length > 0 && !country) {
      setCountry(countryCodes[0]);
    }
  }, [countryCodes]);

  const removeCountryCode = (): void => {
    if (phone && phone.length > 0 && phone.slice(0, 1) === "+") {
      const newPhone = `+${phone.replace(/\D+/g, "")}`;
      const n = countryCodes
        .filter((c) => newPhone.startsWith(c.code))
        .sort((a, b) => b.code.length - a.code.length);
      if (n[0]) {
        setCountry(n[0]);
        setPhone(newPhone.replace(n[0].code, ""));
      }
    } else if (country && phone) {
      const fullPhone = `${country.code}${phone.replace(/\D+/g, "")}`;
      const n = countryCodes
        .filter((c) => fullPhone.startsWith(c.code))
        .sort((a, b) => b.code.length - a.code.length);
      if (n[0] && n[0].code !== country.code) {
        setCountry(n[0]);
        setPhone(fullPhone.replace(n[0].code, ""));
      }
    }
  };

  useEffect(() => {
    if (value && value.length > 0 && phone === null) {
      setPhone(value);
    }
  }, [value]);

  useEffect(removeCountryCode, [phone]);

  return (
    <div className={classes.container}>
      {label ? (
        <span
          className={classnames(classes.label, hasError && classes.labelError)}
        >
          {label}
        </span>
      ) : null}
      <div
        className={classnames([
          classes.field,
          hasError && classes.fieldError,
          isDisabled && classes.disabled,
        ])}
      >
        <DropdownButton
          country={country}
          isOpen={isOpen}
          setOpen={setOpen}
          isDisabled={isDisabled}
        />
        <input
          ref={inputRef}
          className={classnames(
            classes.input,
            isDisabled && classes.inputDisabled,
          )}
          type="text"
          id={id}
          inputMode="numeric"
          pattern="[0-9]"
          placeholder="123 4567"
          name="tel"
          onKeyDown={handleKeyDown}
          value={phone || ""}
          onBlur={onBlur}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          disabled={isDisabled}
        />
        <DropdownBlock
          countryCodes={countryCodes}
          setCountry={setCountry}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </div>
      {errorMessage && (
        <div className={classes.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default PhoneField;
