import React from "react";
import { Modal } from "@base/components/Global/Modal";
import LocationModalContent from "@pages/HomePage/components/LocationModalContent";

const LocationModalWrapper: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => (
  <Modal isOpen={true} onClose={onClose}>
    <LocationModalContent />
  </Modal>
);
export default LocationModalWrapper;
