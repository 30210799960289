import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IDiningOptionEnum } from "@api/interfaces/configurationLayouts";
import Loader from "@base/components/Global/Loader";
import { NavigationPath } from "@constants/navigation";
import { useAppDispatch, useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import { resetShoppingCart, selectShoppingCart } from "@store/shoppingCart";
import { initializeDiningCart } from "@store/shoppingCart/actions";
import { getTheatreResource } from "@store/theatreResource";
import { selectUserId } from "@store/user";

const QRLandingPage = () => {
  const navigate = useNavigate();
  const { qrCode } = useParams();

  const shoppingCart = useAppSelector(selectShoppingCart);
  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (hasData(shoppingCart)) {
      // TODO: important - we should also send a clearance request. So that carts are not hoarded.
      // We also need to have the navbar button state reflect that the cart is cleared.
      dispatch(resetShoppingCart());
    }

    if (!qrCode) {
      navigate(`${NavigationPath.Home}?source=qr`);
      return;
    }

    dispatch(getTheatreResource({ barcodeWithoutChecksum: qrCode }))
      .unwrap()
      .then((theatreResource) => {
        dispatch(
          initializeDiningCart({
            theatreResourceId: theatreResource.ID,
            dttmRequestedDelivery: null,
            diningOptionId: IDiningOptionEnum.DINE_IN,
            customerPersonId: userId,
          }),
        );
      })
      .catch((error) => {
        if (!("name" in error) || error.name !== "ConditionError") {
          console.error(`Could not find QR code "${qrCode}"`, error);
        }
      })
      .finally(() => {
        navigate(`${NavigationPath.Home}?source=qr`);
      });
  }, []);

  return <Loader isFullScreen />;
};

export default QRLandingPage;
