import React from "react";
import { createUseStyles } from "react-jss";
import { getBlobStorage } from "@theme";
import classNames from "classnames";

import Icon from "./Icon";

const useStyles = createUseStyles(({}) => ({
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  spinner: {
    animation: "$spin 1s infinite linear",
  },
}));

const SpinnerSmall: React.FC<{ className?: string }> = ({ className }) => {
  const classes = useStyles();
  const { spinnerIconSmall } = getBlobStorage();

  return (
    <Icon
      className={classNames(classes.spinner, className)}
      url={spinnerIconSmall}
      height={24}
      width={24}
    />
  );
};

export default SpinnerSmall;
