import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

type StyleProps = {
  styleVariant?: "default" | "rounded";
};

const useStyles = createUseStyles(() => ({
  parallax: {
    borderRadius: ({ styleVariant }: StyleProps) =>
      styleVariant === "rounded" ? "1.8rem" : undefined,
    height: "100%",
    overflow: "hidden",
  },
  parallaxLayer: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  parallaxImage: {
    maxWidth: "none",
    flex: "0 0 calc(115% + (1rem * 2))",
    objectFit: "cover",
  },
}));

type ChildrenProps = {
  parallaxImageClassName?: string;
};

type ImageParallaxWrapperProps = StyleProps & {
  isEnabled: boolean;
  children: ({ parallaxImageClassName }: ChildrenProps) => React.ReactNode;
};

export const ImageParallaxWrapper: React.FC<ImageParallaxWrapperProps> = ({
  children,
  styleVariant,
  isEnabled = false,
}) => {
  const classes = useStyles({ styleVariant });

  if (!isEnabled) {
    return <>{children({})}</>;
  }

  return (
    <div className={classNames(classes.parallax)}>
      <div id="parallax_layer" className={classNames(classes.parallaxLayer)}>
        {children({ parallaxImageClassName: classes.parallaxImage })}
      </div>
    </div>
  );
};
