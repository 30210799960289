import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useDebounce } from "react-use";
import { isPresent } from "@apl-digital/utils";
import Icon, { IconProps } from "@base/components/Global/Icon";
import { getBlobStorage, getBrandTheme } from "@theme";
import classnames from "classnames";

const useStyles = createUseStyles(({ spacing, color, borderRadius, font }) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",

    border: `1px solid  ${color.amountBtnBorder}`,
    backgroundColor: color.amountBtnBg,
    borderRadius: borderRadius.button,

    width: 48,
    minWidth: 48,
    height: 48,
    alignItems: "center",
    padding: [0, spacing.s],
  },
  disabledContainer: {
    borderColor: color.qttInactiveIcon,
  },
  expandedContainer: {
    width: "fit-content",
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 40,
    height: 40,
    cursor: "pointer",
    color: color.qttText,
    "&:disabled": {
      cursor: "none",
    },
    userSelect: "none",
  },
  disabledBox: {
    cursor: "default !important",
  },
  amount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: font.weight.s,
    fontSize: font.size.xl,
    lineHeight: font.lineHeight.m,
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  reversed: {
    color: color.amountBtnReversedText,
  },
  reversedContainer: {
    backgroundColor: color.amountBtnReversedBg,

    color: color.amountBtnReversedText,
  },
  expandedAmount: {
    minWidth: 48,
    padding: 0,
  },
  shadow: {
    boxShadow: color.productRowAmountBoxShadow,
  },
}));

type GroupButtonProps = Pick<IconProps, "url"> & {
  id: string;
  isDisabled?: boolean;
  isReversed?: boolean;
  shouldFillIcon?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

const GroupButton: React.FC<GroupButtonProps> = ({
  id,
  isDisabled = false,
  isReversed = false,
  shouldFillIcon = false,
  onClick,
  url,
}) => {
  const classes = useStyles();
  const { color } = getBrandTheme();

  const iconColorMap = {
    reversed: {
      active: color.qttActiveReversedIcon,
      inactive: color.qttInactiveReversedIcon,
    },
    normal: {
      active: color.qttActiveIcon,
      inactive: color.qttInactiveIcon,
    },
  };

  const colorVariant = isReversed ? iconColorMap.reversed : iconColorMap.normal;
  const currentColor = isDisabled ? colorVariant.inactive : colorVariant.active;

  return (
    <div
      className={classnames([classes.box, isDisabled && classes.disabledBox])}
      onClick={(e) => {
        e.stopPropagation();
        if (!isDisabled) onClick(e);
      }}
      id={id}
    >
      <div className={classes.icon}>
        <Icon
          url={url}
          stroke={currentColor}
          fill={shouldFillIcon ? currentColor : undefined}
          height={24}
          width={24}
        />
      </div>
    </div>
  );
};

type AmountButtonProps = {
  currentAmount: number;
  canIncrement: (increase: number) => boolean;
  canDecrement: (amount: number) => boolean;
  onAmountChange: (newQtt: number) => void;
  onDebounceStateChange?: (isReady: boolean) => void;
  onTrashAction?: () => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isOpen?: boolean;
  isDisabled?: boolean;
  isReversed?: boolean;
  isDebounceDisabled?: boolean;
  hasShadow?: boolean;
  shouldIncrementOnInitialClick?: boolean;
};

const AmountButton: React.FC<AmountButtonProps> = ({
  currentAmount,
  canIncrement,
  canDecrement,
  onAmountChange,
  onDebounceStateChange,
  onTrashAction,
  onClick,
  isOpen = false,
  isDisabled = false,
  isReversed = false,
  isDebounceDisabled = false,
  hasShadow = false,
  shouldIncrementOnInitialClick = false,
}) => {
  const classes = useStyles();

  const [amount, setAmount] = React.useState(currentAmount);

  const { amountMinusActiveIcon, amountPlusActiveIcon, trashIcon, plusIcon } =
    getBlobStorage();

  useEffect(() => {
    setAmount(currentAmount);
  }, [currentAmount]);

  const [isReady] = useDebounce(
    () => {
      onAmountChange(amount);
    },
    isDebounceDisabled ? undefined : 750,
    [amount],
  );

  useEffect(() => {
    if (isPresent(onDebounceStateChange)) {
      const isDebounceReady = isReady();

      onDebounceStateChange(
        isPresent(isDebounceReady) ? isDebounceReady : false,
      );
    }
  }, [isReady]);

  const isExpanded = isOpen && !isDisabled;

  return (
    <div
      className={classnames([
        classes.container,
        isOpen && classes.expandedContainer,
        isReversed && classes.reversedContainer,
        isDisabled && classes.disabledContainer,
        hasShadow && classes.shadow,
      ])}
    >
      {isExpanded && (
        <>
          {isPresent(onTrashAction) && amount <= 1 ? (
            <GroupButton
              id="amount-btn-trash"
              onClick={() => {
                onTrashAction();
              }}
              isDisabled={!canDecrement(amount)}
              isReversed={isReversed}
              url={trashIcon}
            />
          ) : (
            <GroupButton
              id="amount-btn-minus"
              onClick={() => {
                setAmount((currentValue) => currentValue - 1);
              }}
              isDisabled={!canDecrement(amount)}
              isReversed={isReversed}
              shouldFillIcon
              url={amountMinusActiveIcon}
            />
          )}
        </>
      )}
      <div
        id="amount-btn-total"
        className={classnames(classes.box, isDisabled && classes.disabledBox)}
        onClick={(e) => {
          if (!isDisabled) onClick?.(e);
        }}
      >
        <div
          className={classnames([
            classes.amount,
            isReversed && classes.reversed,
          ])}
        >
          {amount === 0 && !isExpanded ? (
            <GroupButton
              id="amount-btn-plus"
              onClick={(e) => {
                onClick?.(e);

                if (shouldIncrementOnInitialClick) {
                  setAmount((currentValue) => currentValue + 1);
                }
              }}
              isDisabled={!canIncrement(amount - currentAmount)}
              isReversed={isReversed}
              shouldFillIcon
              url={plusIcon}
            />
          ) : (
            amount
          )}
        </div>
      </div>
      {isExpanded && (
        <GroupButton
          id="amount-btn-plus"
          onClick={() => {
            setAmount((currentValue) => currentValue + 1);
          }}
          isDisabled={!canIncrement(amount - currentAmount)}
          isReversed={isReversed}
          shouldFillIcon
          url={amountPlusActiveIcon}
        />
      )}
    </div>
  );
};

export default AmountButton;
