import { commonDarkTheme } from "@theme/themes/common.dark";
import { DefaultTheme } from "react-jss";

export const cityExpressDarkTheme: DefaultTheme = {
  ...commonDarkTheme,
  blobStorage: {
    ...commonDarkTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/dark/logo/logo-city-express.svg",
  },
  config: {
    ...commonDarkTheme.config,
    brandLogoWidth: 117,
    brandLogoHeight: 45,
    sectionsColors: "#79431A;#9B5823;#964000;#772A00;#A6510F;#562D0D",
  },
  color: {
    ...commonDarkTheme.color,
    productRowFeaturedBg: "#ED6F1D",
    productRowFeaturedText: "#FFFFFF",
  },
} as const;
