import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import useInterval from "@base/hooks/useInterval";

type StyleProps = {
  size?: string;
};

const useStyles = createUseStyles(({ color }) => ({
  "@keyframes spin": {
    "100%": { transform: "rotate(1turn)" },
  },
  loader: {
    width: ({ size }: StyleProps) => size,
    height: ({ size }: StyleProps) => size,

    aspectRatio: 1,
    display: "grid",
    "-webkit-mask": "conic-gradient(from 15deg,#0000,#000)",
    animation: "$spin 1s infinite steps(12)",

    "&, &::before, &::after": {
      background: `radial-gradient(closest-side at 50% 12.5%, ${color.loaderSpinnerColor} 90%, #0000) 50% 0/20% 80% repeat-y, radial-gradient(closest-side at 12.5% 50%, ${color.loaderSpinnerColor} 90%, #0000) 0 50%/80% 20% repeat-x`,
    },

    "&::before, &::after": {
      content: "''",
      gridArea: "1/1",
      transform: "rotate(30deg)",
    },

    "&::after": {
      transform: "rotate(60deg)",
    },
  },

  outerContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: 48,
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: color.loadingScreenBg,
    zIndex: 100,
    overflowY: "hidden",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflowY: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflowY: "hidden",
  },
}));

export type LoaderProps = StyleProps & {
  wait?: boolean;
  isFullScreen?: boolean;
  isRaw?: boolean;
};

const Loader: React.FC<LoaderProps> = ({
  wait = true,
  isFullScreen = false,
  isRaw = false,
  size = "100px",
}) => {
  const classes = useStyles({ size });
  const [shouldShowLoader, setShouldShowLoader] = useState(false);

  const intervalTime = wait ? 1000 : 0;

  useInterval(() => setShouldShowLoader(true), intervalTime);

  if (!shouldShowLoader) return null;

  if (isRaw) {
    return <div className={classes.loader}></div>;
  }

  if (!isFullScreen) {
    return (
      <div className={classes.centerContainer}>
        <div className={classes.container}>
          <div className={classes.loader}></div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.loader}></div>
      </div>
    </div>
  );
};

export default Loader;
