import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles(({ spacing, mediaQuery }) => ({
  columns: {
    display: "flex",
  },
  column: {
    paddingLeft: spacing.xxl,
    paddingRight: spacing.xxl,

    "&:first-child": {
      paddingLeft: 0,
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  leftColumn: {
    flex: 1,
    minHeight: 400,
    minWidth: 0,
  },
  rightColumn: {
    flex: [0, 0, "290px"],
    [mediaQuery.lg]: {
      flex: [0, 0, "372px"],
    },
    [mediaQuery.xl]: {
      flex: [0, 0, "33.33333%"],
    },
  },
}));

const SidebarWrapper: React.FC<{
  renderMain: () => JSX.Element;
  renderSidebar: () => JSX.Element;
}> = ({ renderMain, renderSidebar }) => {
  const classes = useStyles();

  return (
    <div className={classes.columns}>
      <div className={classNames(classes.column, classes.leftColumn)}>
        {renderMain()}
      </div>

      <div className={classNames(classes.column, classes.rightColumn)}>
        {renderSidebar()}
      </div>
    </div>
  );
};

export default SidebarWrapper;
