import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "@base/components/Global/Button";
import { useAppDispatch } from "@store";
import { removeOrder } from "@store/orders";
import classNames from "classnames";

import { OrderCardCompletedBlock } from "./OrderCardCompletedBlock";

const useStyles = createUseStyles(({ font, color, spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxl,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  orderIdentifier: {
    fontSize: font.size.m,
    lineHeight: font.lineHeight.s,
    fontWeight: font.weight.m,
    color: color.orderStatusCardOrderCardCompletedBlockTitleTextColor,
  },
  centerButton: {
    alignSelf: "center",
  },
}));

type OrderCardNotFoundProps = {
  variant: "desktop" | "mobile";
  isSelected: boolean;
  barcodeWithoutChecksum: string;
};

export const OrderCardNotFound: React.FC<OrderCardNotFoundProps> = ({
  variant,
  barcodeWithoutChecksum,
  isSelected,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("orderStatusPage");
  const dispatch = useAppDispatch();

  return (
    <div className={classes.container}>
      <div className={classes.infoContainer}>
        <OrderCardCompletedBlock
          variant="notFound"
          lastStatusChangeDateTime={null}
        />

        <div className={classes.orderIdentifier}>
          {t("OrderCardNotFound.orderIdentifier", {
            barcodeWithoutChecksum,
          })}
        </div>
      </div>

      {!isSelected || variant === "mobile" ? (
        <Button
          action={() => dispatch(removeOrder(barcodeWithoutChecksum))}
          fitContent
          className={classNames(variant === "mobile" && classes.centerButton)}
        >
          {t("OrderCardNotFound.removeOrderButtonText")}
        </Button>
      ) : null}
    </div>
  );
};
