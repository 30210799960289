import React, { createContext, useContext, useState } from "react";

type NavbarContextResults = {
  customTitle?: string;
  setCustomTitle: (title?: string) => void;
};

const NavbarContext = createContext<NavbarContextResults>(
  {} as NavbarContextResults,
);

export const useNavbarContext = () => useContext(NavbarContext);

type NavbarContextProviderProps = {
  children?: React.ReactNode;
};

export const NavbarContextProvider: React.FC<NavbarContextProviderProps> = ({
  children,
}) => {
  const [customTitle, setCustomTitle] = useState<string>();

  return (
    <NavbarContext.Provider
      value={{
        customTitle,
        setCustomTitle: setCustomTitle,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};
