import {
  AssetCategories,
  ISalesPointConfiguration,
} from "@api/interfaces/configurationLayouts";
import { ResponseFailed } from "@api/types";
import config from "@constants/config";
import { createSlice } from "@reduxjs/toolkit";
import {
  hasData,
  setStateFailed,
  setStatePending,
} from "@store/apiRequestStatusMachine";
import i18n from "@translation";

import { loadSalesPointConfiguration } from "./actions";
import { SalesPointState } from "./types";

const getInitialState = (): SalesPointState => {
  return {
    configuration: { status: "idle" },
    shouldShowScanner: false,
    isPhoneRequired: false,
    isEmailRequired: false,
    isNameRequired: false,
    isLoginAllowed: true,
  };
};

export const salesPointSlice = createSlice({
  name: "salesPoint",
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadSalesPointConfiguration.pending, (state) => {
        setStatePending<ISalesPointConfiguration, ResponseFailed | undefined>(
          state.configuration,
          (nextState) => {
            state.configuration = nextState;
          },
        );
      })
      .addCase(loadSalesPointConfiguration.fulfilled, (state, { payload }) => {
        state.configuration = {
          status: "succeeded",
          state: {
            ...payload,
          },
        };
      })
      .addCase(loadSalesPointConfiguration.rejected, (state, { payload }) => {
        setStateFailed<ISalesPointConfiguration, ResponseFailed | undefined>(
          state.configuration,
          (nextState) => {
            state.configuration = { ...nextState, state: payload };
          },
        );
      });
  },
  selectors: {
    selectConfiguration: (state) => state.configuration,
    selectDefaultTableBarcode: (state) =>
      hasData(state.configuration)
        ? state.configuration.state.DefaultTableBarcode
        : undefined,
  },
});

export const { selectConfiguration, selectDefaultTableBarcode } =
  salesPointSlice.selectors;

export default salesPointSlice.reducer;
