import React from "react";
import Icon from "@base/components/Global/Icon";
import { getBlobStorage, getBrandTheme } from "@theme";

export const getBrandLogo = (): React.ReactElement => {
  const { brandLogo } = getBlobStorage();
  const { config } = getBrandTheme();
  return (
    <Icon
      width={config.brandLogoWidth}
      height={config.brandLogoHeight}
      url={brandLogo}
      wrapper="svg"
    />
  );
};
