import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import useWindowSize from "@base/hooks/useWindowSize";
import { NavigationPath } from "@constants/navigation";
import AuthLandingPage from "@pages/Auth/AuthLandingPage";
import CartPage from "@pages/CartPage/CartPage";
import { ErrorPage } from "@pages/ErrorPage";
import HomePageWrapper from "@pages/HomePage/components/HomePageWrapper";
import LocationModalWrapper from "@pages/HomePage/components/LocationModalWrapper";
import LocationPage from "@pages/LocationPage/LocationPage";
import OrderStatusPageWrapper from "@pages/OrderStatusPage";
import PaymentLandingPage from "@pages/PaymentPage/PaymentLandingPage";
import ProductPage from "@pages/ProductPage";
import ProductModalWrapper from "@pages/ProductPage/ProductModalWrapper";
import QRLandingPage from "@pages/QRLandingPage/QRLandingPage";
import * as Sentry from "@sentry/react";

import { Layout } from "../Layout";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter: React.FC = () => {
  const { isMdAndUp } = useWindowSize();
  const navigate = useNavigate();

  return (
    <SentryRoutes>
      <Route element={<Layout />}>
        <Route path="/" element={<HomePageWrapper />}>
          {isMdAndUp && (
            <Route
              path={NavigationPath.Product}
              element={<ProductModalWrapper onClose={() => navigate("..")} />}
            />
          )}
          {isMdAndUp && (
            <Route
              path={NavigationPath.Location}
              element={<LocationModalWrapper onClose={() => navigate("..")} />}
            />
          )}
        </Route>
        <Route path={NavigationPath.Product} element={<ProductPage />} />
        <Route
          path={NavigationPath.OrderStatus}
          element={<OrderStatusPageWrapper />}
        />
        <Route path={NavigationPath.Cart} element={<CartPage />} />
        <Route path={NavigationPath.Location} element={<LocationPage />} />
      </Route>

      <Route path={NavigationPath.AuthCallback} element={<AuthLandingPage />} />
      <Route
        path={NavigationPath.OrderPaymentCallback}
        element={<PaymentLandingPage />}
      />
      <Route path={NavigationPath.QR} element={<QRLandingPage />} />
      <Route
        path="*"
        element={<ErrorPage error={{ type: "navigation", httpCode: 404 }} />}
      />
    </SentryRoutes>
  );
};

export default AppRouter;
