import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { createUseStyles } from "react-jss";
import { generatePath, useNavigate } from "react-router-dom";
import { ICheckoutOption } from "@api/interfaces/cartLayouts";
import { isPresent } from "@apl-digital/utils";
import { TheatreResourceModal } from "@base/components/Modals/TheatreResourceModal/TheatreResourceModal";
import {
  ECustomFieldType,
  getCustomFieldValue,
} from "@base/utils/customFieldUtils";
import { NavigationPath, OrderID } from "@constants/navigation";
import { useAppSelector } from "@store";
import { selectCurrentBarcodeWithoutChecksum } from "@store/shoppingCart";
import classNames from "classnames";

import { usePayLaterCheckoutFlow } from "../hooks";

import { PayLaterConfirmModal } from "./PayLaterConfirmModal";

const useStyles = createUseStyles(({ spacing, font, color }) => ({
  buttonContainerMobile: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  underlineButton: {
    color: color.defaultText,
    cursor: "pointer",
    textDecorationLine: "underline",
    textAlign: "center",
    fontSize: font.size.s,
    fontWeight: font.weight.m,
    lineHeight: font.lineHeight.s,
  },
  termsAndConditionsBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
  },
  warning: {
    display: "flex",
    gap: spacing.s,
    justifyContent: "center",
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    fontWeight: font.weight.m,
  },
  verified: {
    color: color.black,
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
  confirmText: {
    color: color.cartPageConfirmBlockText,
  },
}));

export type PayLaterCheckoutOptionProps = {
  isDisabled: boolean;
  variant: "mobile" | "desktop";
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
};

export const PayLaterCheckoutOption: React.FC<
  ICheckoutOption & PayLaterCheckoutOptionProps
> = ({ isDisabled, variant, scrollContainerRef, ...checkoutOptions }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const barcodeWithoutChecksum = useAppSelector(
    selectCurrentBarcodeWithoutChecksum,
  );

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { trigger, handleSubmit } = useFormContext();

  const { startFlow, resetFlow, setFlowState, currentFlowState, isComplete } =
    usePayLaterCheckoutFlow({});

  useEffect(() => {
    if (isComplete) {
      if (isPresent(barcodeWithoutChecksum)) {
        navigate(
          generatePath(NavigationPath.OrderStatus, {
            [OrderID]: barcodeWithoutChecksum,
          }),
        );
      }
    }
  }, [isComplete]);

  const disabledAction = () => {
    const scrollDiv = scrollContainerRef?.current;

    if (scrollDiv) {
      window.scrollTo({
        behavior: "smooth",
        top: scrollDiv.scrollHeight,
      });
    }

    trigger();
  };

  const submit = handleSubmit(startFlow);

  return (
    <>
      <TheatreResourceModal
        isOpen={currentFlowState === "theatreResourceNeeded"}
        onClose={resetFlow}
        onAdded={() => setFlowState("theatreResourceCheckStarted")}
      />

      <PayLaterConfirmModal
        {...checkoutOptions}
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={submit}
        variant={variant}
      />

      <div
        className={classNames(
          variant === "mobile" && classes.buttonContainerMobile,
        )}
      >
        <div
          className={classes.underlineButton}
          onClick={() => {
            if (isDisabled) {
              disabledAction();
            } else {
              setIsConfirmationModalOpen(true);
            }
          }}
        >
          {getCustomFieldValue(
            checkoutOptions.CustomFieldsAndValues,
            ECustomFieldType.PayLaterRuleSecondaryCTA,
          )}
        </div>
      </div>
    </>
  );
};
