import { commonDarkTheme } from "@theme/themes/common.dark";
import { DefaultTheme } from "react-jss";

export const mySushiDarkTheme: DefaultTheme = {
  ...commonDarkTheme,
  blobStorage: {
    ...commonDarkTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/dark/logo/logo-mysushi.svg",
    burgerMenuLinks: [
      "https://assets.markus.live/00-generic-app/icons/logo/ApolloClub24.svg",
    ],
  },
  config: {
    ...commonDarkTheme.config,
    brandLogoWidth: 104,
    brandLogoHeight: 40,
    sectionsColors: "#8A510D;#223137;#052A30;#896231;#1A3924",
  },
  color: {
    ...commonDarkTheme.color,
    productRowFeaturedBg: "#004F50",
  },
} as const;
