import { TheatreResource } from "@api/controller/theatreResource";
import {
  createAsyncStatusMachineThunk,
  getCancelWhenAlreadyLoadingCondition,
} from "@store/apiRequestStatusMachine";

import { selectTheatreResource } from "./slice";

export const getTheatreResource = createAsyncStatusMachineThunk(
  "theatreResource/getTheatreResource",
  TheatreResource.getByBarcode,
  selectTheatreResource,
  [getCancelWhenAlreadyLoadingCondition()],
);
