import React from "react";
import { createUseStyles } from "react-jss";
import {
  UnifiedCartLine,
  UnifiedCartLineModifierChoice,
  UnifiedCartLineModifierExtra,
} from "@store/shoppingCart";

import { CartItemModifierBlock } from "./CartItemModifierBlock";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  modifiers: {
    color: color.cartPageRowPrice,
    display: "flex",
    flexDirection: "column",
    gap: spacing.xs,
    fontSize: font.size.xxs,
    lineHeight: font.lineHeight.xxs,
    margin: 0,
  },
}));

type CartItemModifiersBlockProps = {
  item: UnifiedCartLine;
};

export const CartItemModifiersBlock: React.FC<CartItemModifiersBlockProps> = ({
  item,
}) => {
  const classes = useStyles();

  if (item.modifiers.length === 0) {
    return null;
  }

  return (
    <ul className={classes.modifiers}>
      {item.modifiers
        .filter(
          (modifier): modifier is UnifiedCartLineModifierChoice =>
            modifier.type === "choice",
        )
        .map((modifier) => (
          <CartItemModifierBlock
            key={modifier.uniqueKey}
            name={modifier.name.trim()}
            description={modifier.description.trim()}
            amount={modifier.amount}
            grossSum={modifier.grossSum}
          />
        ))}

      {item.modifiers
        .filter(
          (modifier): modifier is UnifiedCartLineModifierExtra =>
            modifier.type === "extra",
        )
        .map((modifier) => (
          <CartItemModifierBlock
            key={modifier.uniqueKey}
            name={modifier.name.trim()}
            amount={modifier.amount}
            grossSum={modifier.grossSum}
          />
        ))}
    </ul>
  );
};
