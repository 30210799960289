import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Icon from "@base/components/Global/Icon";
import DeleteItemsModal from "@pages/CartPage/components/DeleteItemsModal";
import { useAppSelector } from "@store";
import {
  selectShoppingCartItemCount,
  selectShoppingCartUnifiedProducts,
} from "@store/shoppingCart";
import { getBlobStorage } from "@theme";
import classNames from "classnames";

import CartItemBlock from "./CartItemBlock";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: [0, spacing.xxl],

    "&$sidebarVariant": {
      padding: 0,
    },
  },
  sidebarVariant: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: [spacing.x4l, 0],
    alignItems: "center",

    "$sidebarVariant &": {
      padding: 0,
      marginBottom: spacing.m,
    },
  },
  title: {
    fontSize: font.size.x3l,
    lineHeight: font.lineHeight.xl,
    fontWeight: font.weight.s,
    color: color.defaultText,

    "$sidebarVariant &": {
      fontSize: font.size.x2l,
    },
  },
  lastItem: {
    borderBottom: `1px solid ${color.cartPageItemBlockBorder}`,
  },
}));

enum CartItemsBlockVariant {
  PAGE = "PAGE",
  SIDEBAR = "SIDEBAR",
}

type CartItemsBlockProps = {
  variant: CartItemsBlockVariant;
};

const CartItemsBlock: React.FC<CartItemsBlockProps> = ({ variant }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const shoppingCartProducts = useAppSelector(
    selectShoppingCartUnifiedProducts,
  );
  const shoppingCartItemCount = useAppSelector(selectShoppingCartItemCount);

  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
  const { trashIcon } = getBlobStorage();

  return (
    shoppingCartProducts && (
      <div
        className={classNames(classes.itemsContainer, {
          [classes.sidebarVariant]: variant === CartItemsBlockVariant.SIDEBAR,
        })}
      >
        <DeleteItemsModal
          isOpen={shouldShowDeleteModal}
          header={t("cart_modal_delete_all_header")}
          message={t("cart_modal_delete_all_msg")}
          showModal={setShouldShowDeleteModal}
        />
        <div className={classes.header}>
          <div className={classes.title}>{t("order_your_oder_msg")}</div>
          <div
            onClick={() =>
              shoppingCartItemCount !== 0 && setShouldShowDeleteModal(true)
            }
            id="delete-cart-btn"
          >
            <Icon url={trashIcon} width={24} height={24} />
          </div>
        </div>
        {shoppingCartProducts.map((item, idx, arr) => (
          <div
            key={item.uniqueKey}
            className={classNames([idx === arr.length - 1 && classes.lastItem])}
          >
            <CartItemBlock item={item} />
          </div>
        ))}
      </div>
    )
  );
};

export default CartItemsBlock;
export { CartItemsBlockVariant };
