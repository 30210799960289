import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useAppSelector } from "@store";
import { selectOrders } from "@store/orders";

import { MenuItem } from "./BurgerMenuItem";
import { BurgerMenuMyOrderLine } from "./BurgerMenuMyOrderLine";

const useStyles = createUseStyles(({ font, color }) => ({
  extraInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: color.white,
    fontWeight: font.weight.l,
    width: 30,
    height: 30,
    borderRadius: 24,
    background: color.greyCoal,
  },
}));

type BurgerMenuMyOrderProps = {
  closeBurgerMenu: () => void;
};

export const BurgerMenuMyOrder: React.FC<BurgerMenuMyOrderProps> = ({
  closeBurgerMenu,
}) => {
  const classes = useStyles();
  const orders = useAppSelector(selectOrders);

  const { t } = useTranslation();

  return (
    <MenuItem
      extraInfo={
        orders.length > 0 && (
          <div className={classes.extraInfo} id="my-order-count">
            <strong>{orders.length}</strong>
          </div>
        )
      }
      label={t("burgermenu_main_orders")}
      showSubMenu={orders.length > 0}
    >
      {orders.map((order) => (
        <BurgerMenuMyOrderLine
          order={order}
          key={order.state.BarcodeWithoutChecksum}
          closeBurgerMenu={closeBurgerMenu}
        />
      ))}
    </MenuItem>
  );
};
