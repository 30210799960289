import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import HorizontalScrollable from "@base/components/Global/HorizontalScrollable";
import useDebounce from "@base/hooks/useDebounce";
import useWindowSize from "@base/hooks/useWindowSize";
import { getStorage, StorageKey } from "@constants/storage";
import {
  ProductListItem,
  ProductListItemType,
} from "@store/ProductsProvider/types";
import { useScrollContext } from "@store/ScrollProvider";
import { getBrandTheme } from "@theme";
import classnames from "classnames";

import CategoriesList from "./CategoriesList";
import ItemList from "./ItemList";
import {
  CATEGORIES_SELECTOR_HEIGHT,
  ITEM_MARGIN_BOTTOM,
  SECTION_TITLE_HEIGHT,
  SECTIONS_SELECTOR_HEIGHT,
} from "./ProductList";

const useStyles = createUseStyles(
  ({ color, borderRadius, spacing, font, mediaQuery }) => ({
    section: {
      paddingTop: spacing.x4l,
      paddingBottom: spacing.xxl,
      paddingLeft: spacing.xxl,
      fontSize: font.size.x4l,
      fontWeight: font.weight.s,
      lineHeight: font.lineHeight.xxl,
      color: color.topCategoriesHeadingText,
    },
    sectionItem: {
      display: "inline-block",
      fontSize: font.size.xs,
      fontWeight: font.weight.l,
      lineHeight: font.lineHeight.xxs,
      padding: [spacing.l, spacing.xxl],
      color: color.subCategoriesText,
      position: "relative",
      textDecoration: "none",
      borderRadius: borderRadius.button,
    },
    sectionItemActive: {
      color: color.subCategoriesTextActive,
    },
    subItemsBar: {
      position: "sticky",
      zIndex: 1,
      transition: "300ms top ease",
      margin: "0 -16px",
      padding: "0 16px",
      backgroundColor: color.subCategoriesBg,
    },
    subItems: {
      width: "inherit",
      overflowX: "auto",
      overflowY: "hidden",
      whiteSpace: "nowrap",
      overflow: "-moz-scrollbars-none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        width: "0 !important",
        height: "0 !important",
      },
      display: "flex",
      gap: spacing.s,
      justifyContent: "start",
      alignItems: "center",
      paddingTop: spacing.s,
      paddingBottom: spacing.s,
    },
    fixedSectionContainer: {
      display: "flex",
      flexDirection: "column",
    },
    fixedSectionEmptyContainer: {
      paddingTop: SECTIONS_SELECTOR_HEIGHT,
    },
    productListContainer: {
      marginBottom: 0,
      display: "flex",
      flexWrap: "wrap",
      gap: spacing.xxl,

      [mediaQuery.lg]: {
        marginRight: -spacing.xxl,
      },
    },
    featuredItemWrapper: {
      display: "flex",
      flexDirection: "row",
      gap: "0.5rem",
    },
  }),
);

type ItemProps = {
  item: ProductListItem;
  setActiveSection?: (item: ProductListItem) => void;
  isSectionActive: boolean;
  activeSectionBg?: string | undefined;
  showShadows: boolean;
};

const SectionItem = ({
  item,
  setActiveSection,
  isSectionActive,
  showShadows = false,
}: ItemProps) => {
  const [isFixed, setIsFixed] = useState(false);
  const sectionContainer = useRef<HTMLDivElement>(null);
  const categoriesContainer = useRef<HTMLDivElement>(null);
  const [activeCategory, _setActiveCategory] = useState<ProductListItem | null>(
    null,
  );
  const activeCategoryRef = React.useRef(activeCategory);
  const setActiveCategory = (newActiveCategory: ProductListItem | null) => {
    activeCategoryRef.current = newActiveCategory;
    _setActiveCategory(newActiveCategory);
  };
  const debouncedActiveCategoryRef = useDebounce(activeCategoryRef, 50);
  const [{ values }] = useScrollContext();

  const [left, setLeft] = useState<number>(0);
  const debouncedLeft = useDebounce(left, 50);
  const theme = getBrandTheme();

  const [isScrollActive, _setIsScrollActive] = useState(true);
  const isScrollActiveRef = React.useRef(isScrollActive);
  const setIsScrollActive = (newValue: boolean) => {
    isScrollActiveRef.current = newValue;
    _setIsScrollActive(newValue);
  };
  const { isMdAndUp } = useWindowSize();

  const valuesRef = React.useRef(values);

  useEffect(() => {
    // This is necessary to access state values in event listeners (which are closed over initialState)
    valuesRef.current = values;
  }, [values]);

  const classes = useStyles();

  const hasCategories =
    (item.items || []).filter((i) => i.type === ProductListItemType.CATEGORY)
      .length > 0;

  useEffect(() => {
    if (activeCategory === null && item?.items && item.items.length > 0) {
      setActiveCategory(item.items[0]);
    }
  }, [item]);

  useEffect(() => {
    const onScroll = () => {
      // Too expensive of a function to be called _per_ section for _each_ scroll event.

      const isModalOpen = getStorage(StorageKey.IS_MODAL_OPEN);
      if (sectionContainer.current && !isModalOpen) {
        const sectionContainerRect =
          sectionContainer.current.getBoundingClientRect();

        // TODO: the name of this variable is lying. It stays true if you scroll way below the section.
        const isSectionVisible =
          sectionContainerRect.top <= SECTIONS_SELECTOR_HEIGHT + 50;

        // TODO: the name of this variable is lying. It stays true if you scroll way below the category.
        const isCategoryVisible =
          sectionContainerRect.top <=
          SECTIONS_SELECTOR_HEIGHT -
            SECTION_TITLE_HEIGHT +
            (valuesRef.current.shouldShowNavbar ? 48 : 0);

        const hasLeftSection =
          -sectionContainerRect.top >=
          sectionContainerRect.height -
            SECTIONS_SELECTOR_HEIGHT +
            ITEM_MARGIN_BOTTOM;

        const isBelowCategory =
          -sectionContainerRect.top >=
          sectionContainerRect.height -
            SECTIONS_SELECTOR_HEIGHT -
            CATEGORIES_SELECTOR_HEIGHT;

        const isItemVisible = isSectionVisible && !hasLeftSection;

        if (isItemVisible && setActiveSection) {
          setActiveSection(item);
        }

        if (hasCategories) {
          if (!isCategoryVisible && !isBelowCategory) {
            setIsFixed(false);
          } else if (isCategoryVisible && !isBelowCategory) {
            setIsFixed(true);
          } else if (isCategoryVisible && isBelowCategory) {
            setIsFixed(false);
          }
        }
      }
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const isModalOpen = getStorage(StorageKey.IS_MODAL_OPEN);
    if (categoriesContainer.current && !isModalOpen) {
      categoriesContainer.current.scrollTo({
        left: debouncedLeft,
        behavior: "smooth",
      });
    }
  }, [debouncedLeft]);

  useEffect(() => {
    if (activeCategory && isSectionActive && categoriesContainer.current) {
      const activeCategoryChildren = categoriesContainer.current.children;
      let newLeft = 0;
      if (activeCategoryChildren) {
        for (let i = 0; i < activeCategoryChildren.length; i++) {
          const element = activeCategoryChildren[i];
          const firstClass = element.classList[0];
          const secondClass = element.classList[1];
          if (secondClass && secondClass.includes("sectionItemActive")) {
            const { width } = element.getBoundingClientRect() || {};
            if (width) {
              newLeft += width * 0.5 - 32;
            }
            break;
          }
          if (firstClass && firstClass.includes("sectionItem")) {
            const { width } = element.getBoundingClientRect() || {};
            if (width) {
              newLeft += width + 8; // width + paddingLeft
            }
          }
        }

        const listElement = document.getElementById("section-selectors-list");
        if (listElement) {
          const halfListWidth = listElement.getBoundingClientRect().width / 2;
          newLeft -= halfListWidth;
        }
      }
      setLeft(newLeft);
    }
  }, [activeCategory]);

  return (
    <div
      className={classnames(
        isFixed &&
          (hasCategories
            ? classes.fixedSectionContainer
            : classes.fixedSectionEmptyContainer),
      )}
      ref={sectionContainer}
    >
      <div id={`section-${item.id}`} />
      <div className={classes.section}>{item.title || "-"}</div>

      {hasCategories ? (
        <>
          <div
            className={classes.subItemsBar}
            style={{ top: `${values.fixedSubItemTop}px` }}
          >
            <HorizontalScrollable
              scrollContainerRef={categoriesContainer}
              buttonHeight={40}
              shouldShowArrows={isMdAndUp}
            >
              <div
                className={classnames(classes.subItems, "sub-sections")}
                ref={categoriesContainer}
              >
                {item.items?.map((category) => (
                  <a
                    style={{
                      backgroundColor: item.bgColor,
                    }}
                    key={`category-link-${item.id}-${category.id}`}
                    className={classnames(
                      classes.sectionItem,
                      category.id === activeCategory?.id &&
                        classes.sectionItemActive,
                    )}
                    onClick={async (e) => {
                      setIsScrollActive(false);
                      e.preventDefault();
                      const wrapper = document.getElementById(
                        `category-${item.id}-${category.id}`,
                      );
                      if (wrapper) {
                        const bodyRect = document.body.getBoundingClientRect();
                        const wrapperRect = wrapper.getBoundingClientRect();
                        const offset = wrapperRect.top - bodyRect.top;
                        const top = isMdAndUp
                          ? Number(offset) -
                            SECTIONS_SELECTOR_HEIGHT -
                            CATEGORIES_SELECTOR_HEIGHT -
                            (wrapperRect.top < 0
                              ? theme.config.desktopNavbarHeight
                              : 0) +
                            54
                          : Number(offset) -
                            SECTIONS_SELECTOR_HEIGHT -
                            CATEGORIES_SELECTOR_HEIGHT -
                            (wrapperRect.top < 0
                              ? theme.config.mobileNavbarHeight
                              : 0) +
                            8;

                        await window.scrollTo({
                          top,
                          behavior: "smooth",
                        });
                        setActiveCategory(category);
                      }
                      setTimeout(() => {
                        setIsScrollActive(true);
                      }, 1000);
                    }}
                    href={`#category-${item.id}-${category.id}`}
                  >
                    {category.title}
                  </a>
                ))}
              </div>
            </HorizontalScrollable>
          </div>
          <CategoriesList
            sectionId={item.id}
            categories={item.items}
            activeCategoryRef={debouncedActiveCategoryRef}
            isScrollActiveRef={isScrollActiveRef}
            setActiveCategory={setActiveCategory}
            showShadows={showShadows}
          />
        </>
      ) : (
        <div className={classes.productListContainer} ref={categoriesContainer}>
          <ItemList
            items={item.items}
            keyGenerator={(itemId, i) =>
              `product-item-category-${itemId}-${item.uniqueId}-${i}`
            }
            showShadows={showShadows}
          />
        </div>
      )}
    </div>
  );
};

type CategoriesListProps = {
  sections: ProductListItem[];
  setActiveSection?: (item: ProductListItem) => void;
  activeSection?: ProductListItem | null;
  activeSectionBg?: string | undefined;
  showShadows?: boolean;
};

const SectionsList: React.FC<CategoriesListProps> = ({
  sections,
  setActiveSection,
  activeSection,
  showShadows = false,
}) => {
  return (
    <>
      {sections.map((section) => (
        <SectionItem
          item={section}
          key={`section-block-item-${section.id}`}
          setActiveSection={setActiveSection}
          isSectionActive={
            activeSection ? activeSection.id === section.id : false
          }
          showShadows={showShadows}
        />
      ))}
    </>
  );
};

export default SectionsList;
