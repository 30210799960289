import { windowBreakpoints } from "@constants/breakpoints";
import { asPartialTheme } from "@theme/types";

export const commonTheme = asPartialTheme({
  config: {
    mobileNavbarHeight: 48,
    desktopNavbarHeight: 54,
    buttonHeight: 54,
    footerHeight: 90,
    boxGap: 24,
    sectionsColors: "#FFEFDC;#E8F4F9;#F6F0FF;#FFECEC;#F2F4E0",
  },
  opacity: {
    enabled: 1,
    disabled: 0.5,
  },
  borderRadius: {
    s: 4,
    m: 8,
    l: 16,
    xl: 20,
    x2l: 30,
    x3l: 32,
    round: 50,
    qrCode: 2,
    button: 32,
    input: 30,
  },
  font: {
    faceFaces: [
      {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "300",
        fontDisplay: "swap",
        src: "url('../assets/fonts/Lato-Light.eot')",
        fallbacks: [
          {
            src: "url('../assets/fonts/Lato-Light.eot?#iefix') format('embedded-opentype')",
          },
          { src: "url('../assets/fonts/Lato-Light.woff2') format('woff2')" },
          { src: "url('../assets/fonts/Lato-Light.woff') format('woff')" },
          { src: "url('../assets/fonts/Lato-Light.ttf') format('truetype')" },
        ],
      },
      {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        fontDisplay: "swap",
        src: "url('../assets/fonts/Lato-Regular.eot')",
        fallbacks: [
          {
            src: "url('../assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype')",
          },
          { src: "url('../assets/fonts/Lato-Regular.woff2') format('woff2')" },
          { src: "url('../assets/fonts/Lato-Regular.woff') format('woff')" },
          { src: "url('../assets/fonts/Lato-Regular.ttf') format('truetype')" },
        ],
      },
      {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "500",
        fontDisplay: "swap",
        src: "url('../assets/fonts/Lato-Medium.eot')",
        fallbacks: [
          {
            src: "url('../assets/fonts/Lato-Medium.eot?#iefix') format('embedded-opentype')",
          },
          { src: "url('../assets/fonts/Lato-Medium.woff2') format('woff2')" },
          { src: "url('../assets/fonts/Lato-Medium.woff') format('woff')" },
          { src: "url('../assets/fonts/Lato-Medium.ttf') format('truetype')" },
        ],
      },
    ],
    defaultFontStyles: {
      fontFamily: "'Lato', sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
    },
    size: {
      x4s: 9,
      x3s: 10,
      xxs: 12,
      xs: 14,
      s: 16,
      m: 18,
      l: 20,
      xl: 22,
      x2l: 28,
      x3l: 36,
      x4l: 46,
      x5l: 54,
    },
    lineHeight: {
      x4s: "12px",
      x3s: "14px",
      xxs: "16px",
      xs: "20px",
      s: "22px",
      m: "24px",
      l: "30px",
      xl: "38px",
      xxl: "48px",
      x3l: "54px",
    },
    weight: {
      s: 300,
      m: 400,
      l: 500,
    },
  },
  spacing: {
    xs: 4,
    s: 8,
    m: 10,
    l: 12,
    xl: 14,
    xxl: 16,
    x2l: 18,
    x3l: 20,
    x4l: 24,
    x5l: 32,
  },
  zIndex: {
    actionTopModal: 99,
    onTopModal: 7,
    modal: 300,
    burgerMenu: 100,
    burgerMenuOverlay: 95,
    bottomMenu: 3,
    footer: 89,
    navbar: 400,
  },
  sizes: {
    smallWidth: "@media screen and (max-width: 374px)", // Is this necessary?
  },
  mediaQuery: {
    xs: `@media screen and (min-width: ${windowBreakpoints.xsMinWidth}px)`,
    sm: `@media screen and (min-width: ${windowBreakpoints.smMinWidth}px)`,
    md: `@media screen and (min-width: ${windowBreakpoints.mdMinWidth}px)`,
    lg: `@media screen and (min-width: ${windowBreakpoints.lgMinWidth}px)`,
    xl: `@media screen and (min-width: ${windowBreakpoints.xlMinWidth}px)`,
    xxl: `@media screen and (min-width: ${windowBreakpoints.xxlMinWidth}px)`,
  },
  scrollbar: {
    height: "8px",
    width: "8px",
    borderRadius: "4px",
    backgroundColor: "#e6e8ec",
    thumb: {
      borderRadius: "4px",
      backgroundColor: "#c3c4c6",
      hover: {
        backgroundColor: "#75787a",
      },
    },
  },
} as const);
