import { ECheckoutOptionType } from "@api/interfaces/cartLayouts";
import { getZodStorage, ZodLocalStorageKey } from "@constants/storage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isStatus } from "@store/utils";

import { initializePayment } from "./actions";
import {
  PaymentCallbackBaseData,
  PaymentCallbackData,
  PaymentState,
} from "./types";

export const getInitialState = (loadFromStorage: boolean): PaymentState => {
  const storePaymentCallback = getZodStorage(
    ZodLocalStorageKey.PAYMENT_CALLBACK,
  );

  return {
    paymentSession: { status: "idle" },
    paymentCallback: loadFromStorage
      ? storePaymentCallback ?? { status: "idle" }
      : { status: "idle" },
  };
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState: getInitialState(true),
  reducers: {
    paymentSessionUsed: (
      state,
      { payload }: PayloadAction<ECheckoutOptionType>,
    ) => {
      if (!isStatus(state.paymentSession, "succeeded")) {
        console.warn("Payment session not in succeeded state");
        return;
      }

      state.paymentCallback = {
        status: "pending",
        state: {
          barcodeWithoutChecksum: state.paymentSession.barcodeWithoutChecksum,
          checkoutOptionType: payload,
        },
      };

      state.paymentSession = { status: "idle" };
    },
    resetPayment: () => {
      return getInitialState(false);
    },
    paymentCallbackPending: (
      state,
      { payload }: PayloadAction<PaymentCallbackBaseData>,
    ) => {
      state.paymentCallback = {
        status: "pending",
        state: payload,
      };
    },
    paymentCallbackCompleted: (
      state,
      { payload }: PayloadAction<PaymentCallbackData>,
    ) => {
      if (!isStatus(state.paymentCallback, "pending")) {
        console.warn("Payment callback not in pending state");
        return;
      }

      state.paymentCallback = {
        status: "completed",
        state: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializePayment.pending, (state, { meta }) => {
        state.paymentSession = {
          status: "pending",
          barcodeWithoutChecksum: meta.arg.barcodeWithoutChecksum,
        };
      })
      .addCase(initializePayment.fulfilled, (state, { payload, meta }) => {
        state.paymentSession = {
          status: "succeeded",
          state: payload,
          barcodeWithoutChecksum: meta.arg.barcodeWithoutChecksum,
        };
      })
      .addCase(initializePayment.rejected, (state, { meta }) => {
        state.paymentSession = {
          status: "failed",
          barcodeWithoutChecksum: meta.arg.barcodeWithoutChecksum,
        };
      });
  },
  selectors: {
    selectPaymentSession: (state) => state.paymentSession,
    selectPaymentCallback: (state) => state.paymentCallback,
  },
});

export const {
  paymentSessionUsed,
  resetPayment,
  paymentCallbackPending,
  paymentCallbackCompleted,
} = paymentSlice.actions;

export const { selectPaymentSession, selectPaymentCallback } =
  paymentSlice.selectors;

export default paymentSlice.reducer;
