import React from "react";
import { createUseStyles } from "react-jss";
import config from "@constants/config";

import { StepperUpperGroup } from "./StepperUpperGroup";
import { UpperGroup } from "./types";

const useStyles = createUseStyles(({ spacing }) => ({
  statusBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
  },
  statusProgressBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxl,
    marginTop: spacing.xxl,
    width: "100%",
  },
  statusProgressBlockKDS: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

type StepperProps = {
  groups: UpperGroup[];
};

export const Stepper: React.FC<StepperProps> = ({ groups }) => {
  const classes = useStyles();

  return (
    <div className={classes.statusBlock}>
      <div
        className={
          config.kdsConfig.isEnabled
            ? classes.statusProgressBlockKDS
            : classes.statusProgressBlock
        }
      >
        {groups.map((group, index, array) => (
          <StepperUpperGroup
            key={group.id}
            group={group}
            isFirstGroup={index === 0}
            isMiddleGroup={
              array.length > 1 && index !== 0 && index !== array.length - 1
            }
            isLastGroup={index === array.length - 1}
          />
        ))}
      </div>
    </div>
  );
};
