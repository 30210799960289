import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "react-jss";
import { Provider } from "react-redux";
import Loader from "@base/components/Global/Loader";
import Polyfills from "@base/utils/Polyfills";
import { store } from "@store";
import { getBrandTheme } from "@theme";
import { GlobalStyleProvider } from "@theme/GlobalStyleProvider";

import App from "./App";

import "normalize.css";

import "./sentry";
import "./translation";

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={getBrandTheme() || {}}>
        <GlobalStyleProvider>
          <Polyfills />
          <Suspense fallback={<Loader isFullScreen />}>
            <App />
          </Suspense>
        </GlobalStyleProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);
