import React from "react";
import { createUseStyles } from "react-jss";
import { Outlet } from "react-router-dom";
import PageWrapper from "@base/components/Global/PageWrapper";
import SidebarWrapper from "@base/components/Global/SidebarWrapper";
import LocationBox from "@pages/HomePage/components/LocationBox";
import { useAppDispatch } from "@store";
import { showProductSearchModal } from "@store/layout";

import CartSection from "./components/CartSection";
import { MenuBlockDesktopWrapper } from "./components/MenuBlock";
import ProductList from "./components/ProductList";

const useStyles = createUseStyles(({ spacing, borderRadius }) => ({
  desktopLandingImagePlaceholder: {
    height: 452,
    borderRadius: borderRadius.x3l,
    marginTop: 54,
    background: "linear-gradient(93deg, #F5F5F5, #F8F8F8)",
  },
  menuBlockContainer: {
    margin: [spacing.x4l, 0],
  },
}));

const DesktopHomePage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  return (
    <>
      <PageWrapper>
        <Outlet />
        <LocationBox />
        <div className={classes.menuBlockContainer}>
          <MenuBlockDesktopWrapper
            onOpenSearch={() => dispatch(showProductSearchModal())}
          />
        </div>
        <SidebarWrapper
          renderMain={() => <ProductList showShadows />}
          renderSidebar={() => <CartSection />}
        />
      </PageWrapper>
    </>
  );
};

export default DesktopHomePage;
