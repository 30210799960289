import React from "react";
import { createUseStyles } from "react-jss";
import { CountryCode } from "@base/components/Global/PhoneField/types";

const useStyles = createUseStyles(({ color, spacing, font }) => ({
  container: {
    position: "relative",
    display: "flex",
    height: 36,
    padding: spacing.s,
    cursor: "pointer",
  },
  icon: {
    display: "flex",
  },
  svg: {
    height: "100%",
    width: 30,
    border: `1px solid ${color.mediumGray}`,
    marginRight: spacing.xs,
  },
  code: {
    color: color.fieldCountryListText,
    fontWeight: font.weight.l,
    display: "flex",
    minWidth: 50,
    marginLeft: "auto",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  title: {
    color: color.fieldCountryListText,
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
    fontWeight: font.weight.s,
  },
}));

type DropdownItemProps = {
  countryCode: CountryCode;
  setCountry: (code: CountryCode) => void;
};

const DropdownItem: React.FC<DropdownItemProps> = ({
  countryCode,
  setCountry,
}) => {
  const classes = useStyles();
  const { flagUrl, code, title } = countryCode;

  return (
    <div className={classes.container} onClick={() => setCountry(countryCode)}>
      <div className={classes.icon}>
        {flagUrl && <img src={flagUrl} className={classes.svg} />}
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.code}>{code}</div>
    </div>
  );
};

export default DropdownItem;
