import React, { useState } from "react";
import { createContext, useContext } from "react";

export type Values = {
  sectionContainerTop?: number;
  sectionLineTop?: number;
  fixedSubItemTop?: number;
  transition?: boolean;
  shouldShowNavbar?: boolean;
};

interface IScrollState {
  values: Values;
  isScrolledAboveFold: boolean;
}

interface IScrollActions {
  setIsScrolledAboveFold: (v: boolean) => void;
  setValues: React.Dispatch<React.SetStateAction<Values>>;
}

const DEFAULT_VALUES_OBJECT = {
  sectionContainerTop: 0,
  sectionLineTop: 0,
  fixedSubItemTop: 0,
  transition: false,
  shouldShowNavbar: true,
};

const DEFAULT_STATE: IScrollState = {
  isScrolledAboveFold: true,
  values: DEFAULT_VALUES_OBJECT,
};

const DEFAULT_ACTIONS: IScrollActions = {
  setIsScrolledAboveFold: () => undefined,
  setValues: () => undefined,
};

const ScrollContext = createContext<[IScrollState, IScrollActions]>([
  DEFAULT_STATE,
  DEFAULT_ACTIONS,
]);

const useScrollContext = () => useContext(ScrollContext);

type ScrollProviderProps = {
  children: React.ReactNode;
};

const ScrollProvider: React.FC<ScrollProviderProps> = ({ children }) => {
  const [isScrolledAboveFold, setIsScrolledAboveFold] = useState(true);
  const [values, setValues] = useState<Values>(DEFAULT_VALUES_OBJECT);

  return (
    <ScrollContext.Provider
      value={[
        { isScrolledAboveFold, values },
        { setIsScrolledAboveFold, setValues },
      ]}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
export { useScrollContext };
