import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { ECheckoutOptionType } from "@api/interfaces/cartLayouts";
import Button from "@base/components/Global/Button";
import { TheatreResourceModal } from "@base/components/Modals/TheatreResourceModal/TheatreResourceModal";
import { NavigationPath } from "@constants/navigation";
import { usePayNowCheckoutFlow } from "@pages/CartPage/hooks/usePayNowCheckoutFlow";
import { useAppDispatch, useAppSelector } from "@store";
import { paymentSessionUsed } from "@store/payment";
import { selectShouldShowPaymentErrorForCurrentCart } from "@store/payment/selectors";
import { resetShoppingCart } from "@store/shoppingCart";
import { isStatus } from "@store/utils";
import classNames from "classnames";

import { PaymentFailedBlock } from "./PaymentFailedBlock";

const useStyles = createUseStyles({
  textNoWrap: {
    whiteSpace: "pre",
  },
  checkout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 60,
    width: "100%",
  },
});

export type PayNowCheckoutOptionProps = {
  isDisabled: boolean;
  totalSum: number;
  variant: "mobile" | "desktop";
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
};

export const PayNowCheckoutOption: React.FC<PayNowCheckoutOptionProps> = ({
  isDisabled,
  totalSum,
  variant,
  scrollContainerRef,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("cartPage");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const shouldShowPaymentError = useAppSelector((state) =>
    selectShouldShowPaymentErrorForCurrentCart(
      state,
      ECheckoutOptionType.PayNow,
    ),
  );

  const { trigger, handleSubmit } = useFormContext();

  const {
    startFlow,
    resetFlow,
    setFlowState,
    currentFlowState,
    isComplete,
    paymentSession,
  } = usePayNowCheckoutFlow({});

  useEffect(() => {
    if (isComplete && isStatus(paymentSession, "succeeded")) {
      dispatch(paymentSessionUsed(ECheckoutOptionType.PayNow));

      window.open(paymentSession.state.directUrl, "_self");
    }
  }, [isComplete, paymentSession]);

  const disabledAction = () => {
    const scrollDiv = scrollContainerRef?.current;

    if (scrollDiv) {
      window.scrollTo({
        behavior: "smooth",
        top: scrollDiv.scrollHeight,
      });
    }

    trigger();
  };

  const onCancel = async () => {
    dispatch(resetShoppingCart());

    if (variant === "mobile") {
      const body = document.querySelector("body");
      if (body) {
        body.style.overflow = "auto";
      }
      navigate(NavigationPath.Home);
    }
  };

  const submit = handleSubmit(startFlow);

  return (
    <>
      <TheatreResourceModal
        isOpen={currentFlowState === "theatreResourceNeeded"}
        onClose={resetFlow}
        onAdded={() => setFlowState("theatreResourceCheckStarted")}
      />

      {shouldShowPaymentError ? (
        <PaymentFailedBlock
          onTryAgain={submit}
          onCancel={onCancel}
          isDisabled={isDisabled}
        />
      ) : (
        <div className={classNames(variant === "mobile" && classes.checkout)}>
          <Button
            action={submit}
            isDisabled={isDisabled}
            disabledAction={disabledAction}
            id="cart-confirm-btn"
          >
            {variant === "desktop" && totalSum > 0 && (
              <span className={classes.textNoWrap}>
                {t("PayNowCheckoutOption.confirmButton.price", {
                  totalSum,
                  formatParams: {
                    totalSum: { currency: "EUR" },
                  },
                })}
              </span>
            )}

            <span className={classes.textNoWrap}>
              {t("PayNowCheckoutOption.confirmButton.text")}
            </span>
          </Button>
        </div>
      )}
    </>
  );
};
