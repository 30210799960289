import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Outlet, useLocation } from "react-router-dom";
import useInterval from "@base/hooks/useInterval";
import config from "@constants/config";
import { ModalContainer } from "@context/ModalContainer";
import { UserProvider } from "@context/UserProvider";
import { useAppDispatch, useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import { selectIsRootScrollbarVisible } from "@store/layout";
import { setAllOrdersStale } from "@store/orders";
import ProductsProvider from "@store/ProductsProvider";
import ScrollProvider from "@store/ScrollProvider";
import {
  isShoppingCartExpired,
  refreshCart,
  resetShoppingCart,
  selectShoppingCart,
  shoppingCartLanguageChanged,
} from "@store/shoppingCart";
import { isStatus } from "@store/utils";
import classNames from "classnames";

import Navbar from "../Navbar/Navbar";
import { NavbarContextProvider } from "../Navbar/NavbarContext";

const useStyles = createUseStyles({
  container: {
    margin: "0px",
    fontSize: "14px",
    height: "100dvh",
    minHeight: "calc(100vh - 55px)",
  },
  hideOverflow: {
    overflow: "hidden",
  },
});

export const Layout = () => {
  const { hash, key } = useLocation();
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const shoppingCart = useAppSelector(selectShoppingCart);
  const isRootScrollbarVisible = useAppSelector(selectIsRootScrollbarVisible);

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, [hash, key]);

  // Shopping cart related logic. These would ideally be inside of an shopping cart context if we had one in the location inside this project.

  useEffect(() => {
    if (isStatus(shoppingCart, "stale")) {
      dispatch(refreshCart({ transactionId: shoppingCart.state.ID }));
    }
  }, [shoppingCart]);

  // Verify if cart expired every 5 seconds
  useInterval(() => {
    if (hasData(shoppingCart) && isShoppingCartExpired(shoppingCart.state)) {
      dispatch(resetShoppingCart());
    }
  }, 5000);

  // Refresh order status every 30 seconds
  useInterval(
    () => {
      dispatch(setAllOrdersStale());
    },
    30000,
    config.kdsConfig.isEnabled,
  );

  // Update shopping cart language when i18n language changes
  useEffect(() => {
    dispatch(shoppingCartLanguageChanged(i18n.language));
  }, [i18n.language]);

  return (
    <div
      className={classNames(
        classes.container,
        !isRootScrollbarVisible && classes.hideOverflow,
      )}
    >
      <UserProvider>
        <ScrollProvider>
          <ProductsProvider>
            <ModalContainer>
              <NavbarContextProvider>
                <Navbar />
                <Outlet />
              </NavbarContextProvider>
            </ModalContainer>
          </ProductsProvider>
        </ScrollProvider>
      </UserProvider>
    </div>
  );
};
