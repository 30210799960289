import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { useCopyToClipboard, useTimeoutFn } from "react-use";
import { isPresent } from "@apl-digital/utils";
import { getBlobStorage, getBrandTheme } from "@theme";
import { motion } from "framer-motion";

import Icon, { IconProps } from "../../Icon";

const useStyles = createUseStyles(({ spacing, borderRadius }) => ({
  container: {
    display: "inline-block",

    border: "1px solid transparent",

    borderRadius: borderRadius.input,

    cursor: "pointer",
  },
  text: {
    display: "inline-block",
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,

    borderTopLeftRadius: borderRadius.input,
    borderBottomLeftRadius: borderRadius.input,
  },
  button: {
    display: "inline-flex",
    padding: "0px",
    width: "24px",
    height: "24px",
    border: "none",

    cursor: "pointer",

    verticalAlign: "middle",

    borderTopRightRadius: borderRadius.input,
    borderBottomRightRadius: borderRadius.input,

    justifyContent: "center",

    backgroundColor: "transparent",
  },
}));

type CopyableFieldProps = {
  children?: string[] | string;
};

export const CopyableField: React.FC<CopyableFieldProps> = ({ children }) => {
  const classes = useStyles();
  const [isCopied, setIsCopied] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();
  const [, , reset] = useTimeoutFn(() => {
    setIsCopied(false);
  }, 1000);

  const { copyIcon, checkerWithBorderIcon, warningIcon } = getBlobStorage();
  const { color } = getBrandTheme();

  if (!isPresent(children)) {
    return null;
  }

  const copyText = (text: string) => {
    copyToClipboard(text);
    setIsCopied(true);
    reset();
  };

  const text: string = useMemo(() => {
    if (Array.isArray(children)) {
      return children.join(" ");
    }

    return children;
  }, [children]);

  const statusIcon: IconProps = useMemo(() => {
    if (state.error) {
      return {
        url: warningIcon,
        height: "22px",
        width: "22px",
        stroke: color.fieldIcon,
      };
    }

    return isCopied
      ? {
          url: checkerWithBorderIcon,
          height: "20px",
          width: "20px",
          stroke: color.fieldIcon,
        }
      : {
          url: copyIcon,
          height: "24px",
          width: "24px",
          stroke: color.fieldIcon,
        };
  }, [state.error, isCopied]);

  return (
    <motion.div
      whileHover={{
        borderColor: color.fieldBorderFocused,
        border: `1px solid ${color.fieldBorder}`,
      }}
      className={classes.container}
      onClick={() => copyText(text)}
    >
      <div className={classes.text}>{text}</div>
      <button type="button" className={classes.button}>
        <Icon {...statusIcon} />
      </button>
    </motion.div>
  );
};
