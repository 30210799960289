import { request } from "@api";
import {
  GetByBarcodeRequest,
  ITheatreLayout,
} from "@api/interfaces/theatreLayouts";
import { ApiPath } from "@api/paths";
import { ApiRequest, RequestResponse } from "@api/types";

const getByBarcode: ApiRequest<
  RequestResponse<ITheatreLayout>,
  GetByBarcodeRequest
> = async ({ barcodeWithoutChecksum, ...options }) =>
  request({
    ...options,
    api: ApiPath.TheatreResource.getByBarcode(barcodeWithoutChecksum),
    canFail: true,
  });

const TheatreResource = {
  getByBarcode,
};

export { TheatreResource };
