import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { DROPDOWN_BUTTON_ID } from "@base/components/Global/PhoneField";
import DropdownItem from "@base/components/Global/PhoneField/DropdownItem";
import { CountryCode } from "@base/components/Global/PhoneField/types";
import useOnClickOutside from "@base/hooks/useOnClickOutside";
import { getCountrySearchResult } from "@base/utils/countryCodeHelper";
import classnames from "classnames";

import TextField from "../TextField";

const useStyles = createUseStyles(({ color, zIndex }) => ({
  container: {
    display: "flex",
    position: "relative",
  },
  countryContainer: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    width: 50,
  },
  svg: {
    height: "100%",
    border: `1px solid ${color.fieldBorder}`,
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
  },
  arrowUp: {
    borderBottom: `5px solid ${color.fieldIcon}`,
  },
  arrowDown: {
    borderTop: `5px solid ${color.fieldIcon}`,
  },
  dropdown: {
    display: "none",
    zIndex: zIndex.actionTopModal,
    position: "absolute",
    top: "calc(100% + 2px)",
    width: "calc(100% + 2px)",
    left: -1,
    height: 300,
    maxHeight: "25vh",
    backgroundColor: color.fieldBg,
    borderTop: "none",
    overflow: "scroll",
  },
  open: {
    display: "block",
  },
  searchInput: {
    border: "none",
    "&:focus": {
      border: "none",
    },
  },
}));

type DropdownBlockProps = {
  countryCodes: CountryCode[];
  setCountry: (country: CountryCode) => void;
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
};

const DropdownBlock: React.FC<DropdownBlockProps> = ({
  countryCodes,
  setCountry,
  setOpen,
  isOpen,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const inputRef = useRef<HTMLInputElement>(null);
  useOnClickOutside(dropdownRef, (event: MouseEvent | TouchEvent) => {
    let shouldSkip = false;
    const pathElements = event.composedPath() as HTMLElement[];
    pathElements?.forEach((p: HTMLElement) => {
      if (p.id === DROPDOWN_BUTTON_ID) shouldSkip = true;
    });
    if (!shouldSkip) {
      setOpen(false);
    }
  });

  const [search, setSearch] = useState("");
  const [currentCountryCodes, setCurrentCountryCodes] =
    useState<CountryCode[]>(countryCodes);

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    }
    inputRef.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    const filteredCountryCodes = getCountrySearchResult(search, countryCodes);
    if (filteredCountryCodes.length === 1) {
      setCountry(filteredCountryCodes[0]);
      setOpen(false);
    } else {
      setCurrentCountryCodes(filteredCountryCodes);
    }
  }, [search]);

  if (!isOpen) return null;

  return (
    <div
      className={classnames([classes.dropdown, isOpen && classes.open])}
      ref={dropdownRef}
    >
      <TextField
        setValue={setSearch}
        inputRef={inputRef}
        type="text"
        value={search}
        showClearButton
        placeholder={t("field_country_code_search")}
        override={classes.searchInput}
      />
      {currentCountryCodes.map((countryCode: CountryCode) => (
        <DropdownItem
          key={countryCode.title}
          countryCode={countryCode}
          setCountry={(c: CountryCode) => {
            setCountry(c);
            setOpen(false);
          }}
        />
      ))}
    </div>
  );
};

export default DropdownBlock;
