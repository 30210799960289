import { ICartContactInfo } from "@api/interfaces/cartLayouts";
import { getStorage, StorageKey } from "@constants/storage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ContactInfoState } from "./types";

const getInitialState = (loadFromStorage: boolean): ContactInfoState => {
  const storedContactInfo = getStorage(StorageKey.CONTACT_INFO);

  return {
    formContactInfo: loadFromStorage ? storedContactInfo : null,
  };
};

export const contactInfoSlice = createSlice({
  name: "contactInfo",
  initialState: getInitialState(true),
  reducers: {
    setFormContactInfo: (
      state,
      { payload }: PayloadAction<ICartContactInfo>,
    ) => {
      state.formContactInfo = payload;
    },
    clearFormContactInfo: (state) => {
      state.formContactInfo = null;
    },
  },
  selectors: {
    selectFormContactInfo: (state) => state.formContactInfo,
  },
});

export const { setFormContactInfo, clearFormContactInfo } =
  contactInfoSlice.actions;

export const { selectFormContactInfo } = contactInfoSlice.selectors;

export default contactInfoSlice.reducer;
