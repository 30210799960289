import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { isPresent } from "@apl-digital/utils";
import Button from "@base/components/Global/Button";
import { Modal } from "@base/components/Global/Modal";
import { useAppDispatch, useAppSelector } from "@store";
import {
  removeAllProductsFromCart,
  removeLinesFromCart,
  resetShoppingCart,
  selectCanEditShoppingCart,
  selectCurrentTransactionId,
} from "@store/shoppingCart";

const useStyles = createUseStyles(({ color, font, borderRadius, spacing }) => ({
  container: {
    background: color.modalDeleteBg,
    color: color.modalDeleteText,
    borderRadius: borderRadius.x3l,
    display: "flex",
    flexDirection: "column",
    gap: spacing.x4l,
    padding: [44, spacing.x5l],
    margin: [0, spacing.xxl],
    maxWidth: 500,
  },
  header: {
    fontWeight: font.weight.s,
    fontSize: font.size.x3l,
    lineHeight: font.lineHeight.xl,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  body: {
    fontSize: font.size.m,
    lineHeight: font.lineHeight.s,
    fontWeight: font.weight.m,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.l,
  },
}));

type DeleteItemsModalProps = {
  header: string;
  message: string;
  isOpen: boolean;
  showModal: (v: boolean) => void;
  cartLineIds?: number[];
  onConfirm?: () => void;
};

const DeleteItemsModal: React.FC<DeleteItemsModalProps> = ({
  header,
  message,
  showModal,
  cartLineIds,
  onConfirm,
  isOpen,
}) => {
  const classes = useStyles();
  const transactionId = useAppSelector(selectCurrentTransactionId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const canEdit = useAppSelector(selectCanEditShoppingCart);

  const removeProducts = () => {
    showModal(false);

    if (!isPresent(transactionId)) {
      return;
    }

    if (!canEdit) {
      dispatch(resetShoppingCart());
      return;
    }

    if (isPresent(cartLineIds) && cartLineIds.length > 0) {
      dispatch(removeLinesFromCart({ transactionId, cartLineIds }));
    } else {
      dispatch(removeAllProductsFromCart({ transactionId }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => showModal(false)}
      shouldHideRootScrollbar={false}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <div>{header}</div>
        </div>
        <div className={classes.body}>{message}</div>
        <div className={classes.actions}>
          <Button
            action={() => showModal(false)}
            id="delete-items-modal-cancel-btn"
            isTransparent
          >
            {t("global_btn_cancel")}
          </Button>
          <Button
            action={() => {
              removeProducts();
              onConfirm?.();
            }}
            id="delete-items-modal-confirm-btn"
          >
            {t("global_btn_delete")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteItemsModal;
