export enum IPaymentStatus {
  SUCCESS = "success",
  ERROR = "error",
  CANCELLED = "cancelled",
}

export interface IInitializePaymentBody {
  transactionBarcode: string;
  options: {
    customerGUID?: string;
    confirmationEmail?: string;
    returnURLSuccess: string;
    returnURLError: string;
    returnURLCancelled: string;
    returnActionLabel: string;
    statusMonitorURL: string;
  };
}

export type InitializeRequest = {
  barcodeWithoutChecksum: string;
  body: IInitializePaymentBody;
};

export interface IInitializePaymentResponse {
  remoteShoppingCartGuid: string;
  directUrl: string;
  shortCode: string;
  shortCodeEntryDisplayUrl: string;
  config: {
    hasSMSGateway: boolean;
  };
}
