import {
  ICartContactInfoSchema,
  OrderStorageSchema,
} from "@api/interfaces/cartLayouts";
import { TheatreLayout } from "@api/interfaces/theatreLayouts";
import { UserLayoutSchema } from "@api/interfaces/userLayouts";
import { YupStorage } from "@apl-digital/yup-storage";
import { ZodStorage } from "@apl-digital/zod-storage";
import { OrdersSchema } from "@store/orders/types";
import { PaymentCallbackSchema } from "@store/payment/types";
import { array, boolean, string } from "yup";

const logger = process.env.NODE_ENV === "development" ? console : undefined;

export enum StorageKey {
  CART = "CART",
  LANGUAGE = "LANGUAGE",
  ORDERS = "ORDERS",
  USER = "USER",
  LAST_ORDER_TIMESTAMP = "LAST_ORDER_TIMESTAMP",
  PENDING_ORDER = "PENDING_ORDER",
  IS_MODAL_OPEN = "IS_MODAL_OPEN",
  CONTACT_INFO = "CONTACT_INFO",
  PRODUCTS_SCROLL = "PRODUCTS_SCROLL",
  AUTH_PREV_PAGE = "AUTH_PREV_PAGE",
  IS_AUTH_IN_PROGRESS = "IS_AUTH_IN_PROGRESS",
  TOKEN_HINT = "TOKEN_HINT",
  THEME = "THEME",
  LAST_LANDING = "LAST_LANDING",
  SHOPPING_CART_LANGUAGE = "SHOPPING_CART_LANGUAGE",
}

// TODO: Replace string().required() with correct types.
const StorageSchemas = {
  [StorageKey.CART]: string().required(), // Feel free to convert this to Yup schema, but only if you are brave enough
  [StorageKey.LANGUAGE]: string().required(), // Correct type
  [StorageKey.ORDERS]: array(OrderStorageSchema).required(),
  [StorageKey.USER]: UserLayoutSchema.required(), // Correct type
  [StorageKey.LAST_ORDER_TIMESTAMP]: string().required(),
  [StorageKey.PENDING_ORDER]: string().required(), // Correct type
  [StorageKey.IS_MODAL_OPEN]: string().required(),
  [StorageKey.CONTACT_INFO]: ICartContactInfoSchema.required(), // Correct type
  [StorageKey.PRODUCTS_SCROLL]: string().required(),
  [StorageKey.AUTH_PREV_PAGE]: string().required(), // Correct type
  [StorageKey.IS_AUTH_IN_PROGRESS]: boolean().required(), // Correct type
  [StorageKey.TOKEN_HINT]: string().required(), // Correct type
  [StorageKey.THEME]: string().required(),
  [StorageKey.LAST_LANDING]: string().required(),
  [StorageKey.SHOPPING_CART_LANGUAGE]: string().required(),
} as const;

export const { clearStorage, getStorage, setStorage } = new YupStorage(
  StorageSchemas,
  { name: "YupLocalStorage", storage: window.localStorage },
  logger,
);

export enum SessionStorageKey {
  THEATER_RESOURCE = "THEATER_RESOURCE",
  SENTRY_REPLAY_SESSION = "sentryReplaySession", // Managed by sentry
}

const SessionStorageSchemas = {
  [SessionStorageKey.THEATER_RESOURCE]: TheatreLayout.required(),
} as const;

export const {
  clearStorage: clearSessionStorage,
  getStorage: getSessionStorage,
  setStorage: setSessionStorage,
} = new YupStorage(
  SessionStorageSchemas,
  { name: "YupSessionStorage", storage: window.sessionStorage },
  logger,
);

// ----------------------------------------------
// ZOD SCHEMAS
// ----------------------------------------------

export enum ZodLocalStorageKey {
  ORDERS = "ORDERS",
  PAYMENT_CALLBACK = "PAYMENT_CALLBACK",
}

const ZodStorageSchemas = {
  [ZodLocalStorageKey.ORDERS]: OrdersSchema,
  [ZodLocalStorageKey.PAYMENT_CALLBACK]: PaymentCallbackSchema,
};

export const {
  clearStorage: clearZodStorage,
  getStorage: getZodStorage,
  setStorage: setZodStorage,
} = new ZodStorage(
  ZodStorageSchemas,
  { name: "ZodLocalStorage", storage: window.localStorage },
  logger,
);
