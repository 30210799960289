import { compareDesc } from "date-fns";

import { InitialOrdersState } from "./types";

export const sortOrdersByCreatedDateOrSelected = (
  a: InitialOrdersState,
  b: InitialOrdersState,
  selectedOrderId: string | undefined,
) => {
  if (a.BarcodeWithoutChecksum === selectedOrderId) {
    return -1;
  } else if (b.BarcodeWithoutChecksum === selectedOrderId) {
    return 1;
  } else {
    return compareDesc(a.dttmCreatedUTC, b.dttmCreatedUTC);
  }
};
