import React from "react";
import { createUseStyles } from "react-jss";
import ProductItem from "@pages/HomePage/components/ProductItem";
import { ProductListItem } from "@store/ProductsProvider/types";

const useStyles = createUseStyles(({ mediaQuery, spacing }) => ({
  featuredItemWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.xxl,
    [mediaQuery.lg]: {
      marginRight: spacing.xxl,
    },
  },
  productListItemContainer: {
    flex: [0, 0, "100%"],
    [mediaQuery.lg]: {
      flex: [0, 0, `calc(50% - ${spacing.xxl}px)`],
    },
  },
}));

type ItemListProps = {
  items: ProductListItem[] | undefined;
  keyGenerator: (itemId: number, i: number) => string;
  showShadows?: boolean;
};

const ItemList: React.FC<ItemListProps> = ({
  items,
  keyGenerator,
  showShadows = false,
}) => {
  const classes = useStyles();

  return (
    <>
      {items
        ? items.map((item, idx) => (
            <div className={classes.productListItemContainer} key={idx}>
              <ProductItem
                showShadow={showShadows}
                key={`${keyGenerator(item.id, idx)}`}
                item={item}
              />
            </div>
          ))
        : null}
    </>
  );
};

export default ItemList;
