import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles(
  ({ spacing, zIndex, borderRadius, color }) => ({
    footer: {
      position: "fixed",
      width: "100vw",
      left: 0,
      bottom: 0,
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      padding: [spacing.xxl, spacing.xxl, spacing.x3l],
      zIndex: zIndex.footer,
      flexWrap: "wrap",
      backgroundColor: color.footerBg,
      borderTopLeftRadius: borderRadius.x3l,
      borderTopRightRadius: borderRadius.x3l,
    },
    error: {
      backgroundColor: color.warningBg,
    },
    extraMsg: {
      boxShadow: color.footerBoxShadow,
      gap: spacing.l,
    },
  }),
);

type FooterProps = {
  override?: string;
  isError?: boolean;
  hasExtraHeight?: boolean;
  children: React.ReactNode;
};

const Footer = forwardRef<HTMLDivElement, FooterProps>(
  ({ override, isError = false, hasExtraHeight = false, children }, ref) => {
    const classes = useStyles();

    const innerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => innerRef.current!);

    // eslint-disable-next-line
    const cancelEvent = (e: any) => {
      e?.preventDefault();
    };

    useEffect(() => {
      if (innerRef.current) {
        innerRef.current.addEventListener("wheel", cancelEvent, {
          passive: false,
        });
        innerRef.current.addEventListener("touchmove", cancelEvent, {
          passive: false,
        });
        innerRef.current.addEventListener("scroll", cancelEvent, {
          passive: false,
        });
        innerRef.current.addEventListener("pointerover", cancelEvent, {
          passive: false,
        });
      }

      return () => {
        if (innerRef.current) {
          innerRef.current.removeEventListener("touchmove", cancelEvent);
          innerRef.current.removeEventListener("wheel", cancelEvent);
          innerRef.current.removeEventListener("scroll", cancelEvent);
          innerRef.current.removeEventListener("pointerover", cancelEvent);
        }
      };
    }, []);

    return (
      <div
        ref={innerRef}
        className={classnames([
          classes.footer,
          isError && classes.error,
          hasExtraHeight && classes.extraMsg,
          override,
        ])}
      >
        {children}
      </div>
    );
  },
);

export default Footer;
