import { ICartLayout } from "@api/interfaces/cartLayouts";
import { IUserLayout } from "@api/interfaces/userLayouts";
import { PayloadAction, UnknownAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import { setFormContactInfo } from "./contactInfo";
import { initializePayment } from "./payment";
import {
  bindContactInfo,
  bundleShoppingCart,
  initializeDiningCart,
  refreshCart,
  removeCustomerFromCart,
  unbundleShoppingCart,
  updateCustomerCart,
  updateTheatreResource,
} from "./shoppingCart";
import { RootState } from "./slices";
import { logInWithID, logInWithUsernameAndPassword, setUser } from "./user";

const REDACTED_VALUE = "< REDACTED />";

const redactCartLayoutPayload = (
  action: PayloadAction<ICartLayout>,
): PayloadAction<ICartLayout> => {
  return {
    ...action,
    payload: {
      ...action.payload,
      ContactInfo: {
        ...action.payload.ContactInfo,
        Name: REDACTED_VALUE,
        Email: REDACTED_VALUE,
        Phone: REDACTED_VALUE,
      },
      CustomerPerson: {
        ...action.payload.CustomerPerson,
        FirstName: REDACTED_VALUE,
        LastName: REDACTED_VALUE,
        Email: REDACTED_VALUE,
        Mobile: REDACTED_VALUE,
      },
      PurchaseConfirmation: {
        ...action.payload.PurchaseConfirmation,
        Email: REDACTED_VALUE,
        Address: REDACTED_VALUE,
      },
    },
  };
};

const redactUserLayoutPayload = (
  action: PayloadAction<IUserLayout>,
): PayloadAction<IUserLayout> => {
  return {
    ...action,
    payload: {
      ...action.payload,
      FirstName: REDACTED_VALUE,
      LastName: REDACTED_VALUE,
      Email: REDACTED_VALUE,
      Phone: REDACTED_VALUE,
    },
  };
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: RootState) => ({
    ...state,
    user: {
      ...state.user,
      userData: {
        ...state.user.userData,
        FirstName: REDACTED_VALUE,
        LastName: REDACTED_VALUE,
        Email: REDACTED_VALUE,
        Phone: REDACTED_VALUE,
        GUID: REDACTED_VALUE,
      },
      setIdTokenHint: REDACTED_VALUE,
    },
    shoppingCart: {
      ...state.shoppingCart,
      shoppingCart: {
        ...state.shoppingCart.shoppingCart,
        ContactInfo: {
          ...state.shoppingCart.shoppingCart?.state?.ContactInfo,
          Name: REDACTED_VALUE,
          Email: REDACTED_VALUE,
          Phone: REDACTED_VALUE,
        },
        CustomerPerson: {
          ...state.shoppingCart.shoppingCart?.state?.CustomerPerson,
          FirstName: REDACTED_VALUE,
          LastName: REDACTED_VALUE,
          Email: REDACTED_VALUE,
          Mobile: REDACTED_VALUE,
        },
        PurchaseConfirmation: {
          ...state.shoppingCart.shoppingCart?.state?.PurchaseConfirmation,
          Email: REDACTED_VALUE,
          Address: REDACTED_VALUE,
        },
      },
    },
    contactInfo: {
      ...state.contactInfo,
      formContactInfo: {
        ...state.contactInfo.formContactInfo,
        Name: REDACTED_VALUE,
        Email: REDACTED_VALUE,
        Phone: REDACTED_VALUE,
      },
    },
  }),
  actionTransformer: (action: UnknownAction) => {
    if (setUser.match(action)) {
      return {
        ...action,
        payload: {
          ...action.payload,
          FirstName: REDACTED_VALUE,
          LastName: REDACTED_VALUE,
          Email: REDACTED_VALUE,
          Phone: REDACTED_VALUE,
          GUID: REDACTED_VALUE,
        },
      };
    }

    if (
      logInWithID.fulfilled.match(action) ||
      logInWithUsernameAndPassword.fulfilled.match(action)
    ) {
      return redactUserLayoutPayload(action);
    }

    if (setFormContactInfo.match(action)) {
      return {
        ...action,
        payload: {
          ...action.payload,
          Name: REDACTED_VALUE,
          Email: REDACTED_VALUE,
          Phone: REDACTED_VALUE,
        },
      };
    }

    if (bindContactInfo.pending.match(action)) {
      return {
        ...action,
        meta: {
          ...action.meta,
          arg: {
            ...action.meta.arg,
            Name: REDACTED_VALUE,
            Email: REDACTED_VALUE,
            Phone: REDACTED_VALUE,
          },
        },
      };
    }

    if (bindContactInfo.fulfilled.match(action)) {
      return {
        ...action,
        meta: {
          ...action.meta,
          arg: {
            ...action.meta.arg,
            Name: REDACTED_VALUE,
            Email: REDACTED_VALUE,
            Phone: REDACTED_VALUE,
          },
        },
      };
    }

    if (bindContactInfo.rejected.match(action)) {
      return {
        ...action,
        meta: {
          ...action.meta,
          arg: {
            ...action.meta.arg,
            Name: REDACTED_VALUE,
            Email: REDACTED_VALUE,
            Phone: REDACTED_VALUE,
          },
        },
      };
    }

    if (initializePayment.pending.match(action)) {
      return {
        ...action,
        meta: {
          ...action.meta,
          arg: {
            ...action.meta.arg,
            confirmationEmail: REDACTED_VALUE,
          },
        },
      };
    }

    if (initializePayment.fulfilled.match(action)) {
      return {
        ...action,
        meta: {
          ...action.meta,
          arg: {
            ...action.meta.arg,
            confirmationEmail: REDACTED_VALUE,
            customerGUID: REDACTED_VALUE,
          },
        },
      };
    }

    if (
      refreshCart.fulfilled.match(action) ||
      updateCustomerCart.fulfilled.match(action) ||
      removeCustomerFromCart.fulfilled.match(action) ||
      updateTheatreResource.fulfilled.match(action) ||
      initializeDiningCart.fulfilled.match(action) ||
      bundleShoppingCart.fulfilled.match(action) ||
      unbundleShoppingCart.fulfilled.match(action)
    ) {
      return redactCartLayoutPayload(action);
    }

    return action;
  },
  configureScopeWithState: (scope, state: RootState) => {
    scope.setUser({
      id: state.user.userData?.ID,
    });
  },
});
