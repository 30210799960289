import { ICartContactInfo } from "@api/interfaces/cartLayouts";
import { isDefined, isPresent } from "@apl-digital/utils";
import { createSelector } from "@reduxjs/toolkit";
import { selectShoppingCartContactInfo } from "@store/shoppingCart";
import { RootState } from "@store/slices";
import { selectUserContactInfo } from "@store/user";

import { selectFormContactInfo } from "./slice";

export const selectContactInfo = createSelector(
  [selectUserContactInfo, selectShoppingCartContactInfo, selectFormContactInfo],
  (
    userContactInfo,
    shoppingCartContactInfo,
    formContactInfo,
  ): ICartContactInfo => {
    let contactInfo: ICartContactInfo = {
      Name: null,
      Email: null,
      Phone: null,
    };

    if (isPresent(shoppingCartContactInfo)) {
      contactInfo = {
        Name: isDefined(shoppingCartContactInfo.Name)
          ? shoppingCartContactInfo.Name
          : contactInfo.Name,
        Email: isDefined(shoppingCartContactInfo.Email)
          ? shoppingCartContactInfo.Email
          : contactInfo.Email,
        Phone: isDefined(shoppingCartContactInfo.Phone)
          ? shoppingCartContactInfo.Phone
          : contactInfo.Phone,
      };
    }

    if (isPresent(userContactInfo)) {
      contactInfo = {
        Name: isDefined(userContactInfo.Name)
          ? userContactInfo.Name
          : contactInfo.Name,
        Email: isDefined(userContactInfo.Email)
          ? userContactInfo.Email
          : contactInfo.Email,
        Phone: isDefined(userContactInfo.Phone)
          ? userContactInfo.Phone
          : contactInfo.Phone,
      };
    }

    if (isPresent(formContactInfo)) {
      contactInfo = {
        Name: isDefined(formContactInfo.Name)
          ? formContactInfo.Name
          : contactInfo.Name,
        Email: isDefined(formContactInfo.Email)
          ? formContactInfo.Email
          : contactInfo.Email,
        Phone: isDefined(formContactInfo.Phone)
          ? formContactInfo.Phone
          : contactInfo.Phone,
      };
    }

    return contactInfo;
  },
);

export const selectHasContactInfo = (state: RootState): boolean => {
  const salesPoint = state.salesPoint;
  const contactInfo = selectContactInfo(state);

  const hasEmail = salesPoint.isEmailRequired
    ? isPresent(contactInfo?.Email)
    : true;
  const hasPhone = salesPoint.isPhoneRequired
    ? isPresent(contactInfo?.Phone)
    : true;
  const hasName = salesPoint.isNameRequired
    ? isPresent(contactInfo?.Name)
    : true;

  return hasEmail && hasPhone && hasName;
};

export const selectIsContactInfoBound = (state: RootState): boolean => {
  const shoppingCartContactInfo = selectShoppingCartContactInfo(state);
  const expectedContactInfo = selectContactInfo(state);

  return (
    shoppingCartContactInfo?.Name === expectedContactInfo.Name &&
    shoppingCartContactInfo?.Email === expectedContactInfo.Email &&
    shoppingCartContactInfo?.Phone === expectedContactInfo.Phone
  );
};
