import UserAuth from "@api/controller/userAuth";
import { createAsyncFetchThunk } from "@store/utils";

export const logInWithID = createAsyncFetchThunk(
  "user/logInWithID",
  UserAuth.personData,
);

export const logInWithUsernameAndPassword = createAsyncFetchThunk(
  "user/logInWithUsernameAndPassword",
  UserAuth.personData,
);
