import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Box from "@base/components/Box/Box";
import BoxList from "@base/components/Box/BoxList";
import Button from "@base/components/Global/Button";
import Footer from "@base/components/Global/Footer";
import { getBlobStorage } from "@theme";

import { reloadWebsite } from "./ErrorPage";
import { AppError } from "./types";
import { useError } from "./useError";

const useStyles = createUseStyles(({ font, spacing, color }) => ({
  container: {
    height: "100vh",
  },
  bgImage: {
    width: "100%",
    height: "100vw",
    maxHeight: 500,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  header: {
    fontSize: font.size.x4l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.xxl,
    color: color.errorPageTitle,
  },
  errorMsg: {
    paddingTop: spacing.x4l,
    fontWeight: font.weight.m,
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    color: color.errorPageText,
    textAlign: "center",
  },
  boxContainer: {
    height: "100%",
    margin: [0, spacing.xxl],
  },
  btn: {
    marginTop: "auto",
  },
  footnote: {
    fontSize: font.size.xs,
    textAlign: "center",
    margin: [spacing.x4l, 0],
    color: color.errorPageText,
    opacity: 0.5,
  },
}));

type ErrorPageMobileProps = {
  error?: AppError;
};

const ErrorPageMobile: React.FC<ErrorPageMobileProps> = ({ error }) => {
  const classes = useStyles();
  const { t } = useTranslation("errorPage");
  const { errorPageBg } = getBlobStorage();
  const { title, message, footnote } = useError(error);

  return (
    <>
      <div
        className={classes.bgImage}
        style={{
          backgroundImage: `url(${encodeURI(errorPageBg)})`,
        }}
      />
      <BoxList hasFooter>
        <Box>
          <div className={classes.header}>{title}</div>
          <div className={classes.errorMsg}>{message}</div>

          {footnote && <p className={classes.footnote}>{footnote}</p>}
        </Box>

        <Footer>
          <Button action={reloadWebsite} className={classes.btn}>
            {t("ErrorPageMobile.goBackButton")}
          </Button>
        </Footer>
      </BoxList>
    </>
  );
};

export default ErrorPageMobile;
