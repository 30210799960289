import config from "@constants/config";
import { useAppSelector } from "@store";
import { useProducts } from "@store/ProductsProvider";
import {
  getHighestMinRequiredAge,
  selectMinRequiredAge,
  selectShoppingCartProducts,
} from "@store/shoppingCart";
import { useMemo } from "react";

export const useHighestMinRequiredAge = () => {
  if (config.featureFlags.useOldShoppingCartHighestMinRequiredAgeLogic) {
    const [, { getRawProductsByIds }] = useProducts();
    const shoppingCartProducts = useAppSelector(selectShoppingCartProducts);

    const shoppingCartProductIds = useMemo(
      () => shoppingCartProducts.map((cartProduct) => cartProduct.Product.ID),
      [shoppingCartProducts],
    );

    const products = useMemo(
      () => getRawProductsByIds(shoppingCartProductIds),
      [shoppingCartProductIds],
    );

    return getHighestMinRequiredAge(products);
  } else {
    return useAppSelector(selectMinRequiredAge);
  }
};
