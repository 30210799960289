import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LayoutContextState } from "./types";

const initialState: LayoutContextState = {
  isProductSearchModalVisible: false,
  isDeliveryMethodModalVisible: false,
  isTouchDevice: false,
  isLandscape: false,
  isLoginModalVisible: false,
  isRootScrollbarVisible: true,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    showProductSearchModal: (state) => {
      state.isProductSearchModalVisible = true;
    },
    hideProductSearchModal: (state) => {
      state.isProductSearchModalVisible = false;
    },
    showDeliveryMethodModal: (state) => {
      state.isDeliveryMethodModalVisible = true;
    },
    hideDeliveryMethodModal: (state) => {
      state.isDeliveryMethodModalVisible = false;
    },
    showLoginModal: (state) => {
      state.isLoginModalVisible = true;
    },
    hideLoginModal: (state) => {
      state.isLoginModalVisible = false;
    },
    setIsTouchDevice: (state, { payload }: PayloadAction<boolean>) => {
      state.isTouchDevice = payload;
    },
    setIsLandscape: (state, { payload }: PayloadAction<boolean>) => {
      state.isLandscape = payload;
    },
    setIsRootScrollbarVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isRootScrollbarVisible = payload;
    },
  },
  selectors: {
    selectIsRootScrollbarVisible: (state) => state.isRootScrollbarVisible,
  },
});

export const {
  showProductSearchModal,
  hideProductSearchModal,
  showDeliveryMethodModal,
  hideDeliveryMethodModal,
  showLoginModal,
  hideLoginModal,
  setIsTouchDevice,
  setIsLandscape,
  setIsRootScrollbarVisible,
} = layoutSlice.actions;

export const { selectIsRootScrollbarVisible } = layoutSlice.selectors;

export default layoutSlice.reducer;
