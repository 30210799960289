import {
  GetOrdersStatusSummaryLineSchema,
  OrdersStatusSummary,
  OrdersStatusSummarySchema,
  ReadyForDeliveryAtChannelSchema,
} from "@api/interfaces/orderLayouts";
import {
  FailedPendingState,
  FailedState,
  IdleState,
  PendingState,
  StalePendingState,
  StaleState,
  State,
  SucceededState,
} from "@store/types";
import { z } from "zod";

type OrderMovedStatus = "orderMoved";
type NotFoundStatus = "notFound";

export type InitialOrdersState = Pick<
  OrdersStatusSummary,
  "BarcodeWithoutChecksum" | "dttmCreatedUTC"
>;

const InitialOrdersStateSchema: z.ZodSchema<InitialOrdersState> = z.object({
  BarcodeWithoutChecksum: z.string(),
  dttmCreatedUTC: z.string(),
});

export type Order =
  | IdleState<InitialOrdersState>
  | StaleState<OrdersStatusSummary>
  | PendingState<InitialOrdersState>
  | StalePendingState<OrdersStatusSummary>
  | SucceededState<OrdersStatusSummary>
  | State<OrderMovedStatus, OrdersStatusSummary>
  | State<NotFoundStatus, InitialOrdersState>
  | FailedPendingState<InitialOrdersState>
  | FailedState<InitialOrdersState>;

export type OrdersState = {
  orders: Array<Order>;
};

export const OrderDataLineSchema = GetOrdersStatusSummaryLineSchema.extend({
  isPending: z.boolean(),
  isInProgress: z.boolean(),
  isReady: z.boolean(),
  isDelivered: z.boolean(),
});
export type OrderDataLine = z.infer<typeof OrderDataLineSchema>;

export const OrderDataSchema = OrdersStatusSummarySchema.omit({
  Lines: true,
}).extend({
  lines: z.array(OrderDataLineSchema),
  isPending: z.boolean(),
  isComplete: z.boolean(),
  isPaid: z.boolean(),
});
export type OrderData = z.infer<typeof OrderDataSchema>;

const OrderIdleStateSchema = z.object({
  status: z.literal("idle"),
  state: InitialOrdersStateSchema,
});

const OrderStaleStateSchema = z.object({
  status: z.literal("stale"),
  state: OrdersStatusSummarySchema,
});

const OrderPendingStateSchema = z.object({
  status: z.literal("pending"),
  state: InitialOrdersStateSchema,
});

const OrderStalePendingStateSchema = z.object({
  status: z.literal("stalePending"),
  state: OrdersStatusSummarySchema,
});

const OrderSucceededStateSchema = z.object({
  status: z.literal("succeeded"),
  state: OrdersStatusSummarySchema,
});

const OrderMovedStateSchema = z.object({
  status: z.literal("orderMoved"),
  state: OrdersStatusSummarySchema,
});

const OrderNotFoundStateSchema = z.object({
  status: z.literal("notFound"),
  state: InitialOrdersStateSchema,
});

const OrderFailedPendingStateSchema = z.object({
  status: z.literal("failedPending"),
  state: InitialOrdersStateSchema,
  retryCount: z.number(),
});

const OrderFailedStateSchema = z.object({
  status: z.literal("failed"),
  state: InitialOrdersStateSchema,
  retryCount: z.number(),
});

const OrderSchema: z.ZodSchema<Order> = z.discriminatedUnion("status", [
  OrderIdleStateSchema,
  OrderStaleStateSchema,
  OrderPendingStateSchema,
  OrderStalePendingStateSchema,
  OrderSucceededStateSchema,
  OrderMovedStateSchema,
  OrderNotFoundStateSchema,
  OrderFailedPendingStateSchema,
  OrderFailedStateSchema,
]);

export const OrdersSchema: z.ZodType<OrdersState["orders"]> =
  z.array(OrderSchema);

const OrderDataGroupLineSchema = OrderDataLineSchema.omit({
  ReadyForDeliveryAtChannel: true,
});
export type OrderDataGroupLine = z.infer<typeof OrderDataGroupLineSchema>;

const OrderDataGroupSchema = z.object({
  ReadyForDeliveryAtChannel: ReadyForDeliveryAtChannelSchema.nullable(),
  lines: z.array(OrderDataGroupLineSchema),
});
export type OrderDataGroup = z.infer<typeof OrderDataGroupSchema>;

const OrderLineGroupSchema = z.object({
  pending: z.array(OrderDataGroupSchema),
  inProgress: z.array(OrderDataGroupSchema),
  ready: z.array(OrderDataGroupSchema),
  delivered: z.array(OrderDataGroupSchema),
  all: z.array(OrderDataGroupSchema),
});
export type OrderLineGroup = z.infer<typeof OrderLineGroupSchema>;

export const OrderDataWithGroupingSchema = OrderDataSchema.omit({
  lines: true,
}).extend({
  groups: OrderLineGroupSchema,
});
export type OrderDataWithGrouping = z.infer<typeof OrderDataWithGroupingSchema>;

export type OrderDataWithGroupingStatusMachine =
  | IdleState<InitialOrdersState>
  | StaleState<OrderDataWithGrouping>
  | PendingState<InitialOrdersState>
  | StalePendingState<OrderDataWithGrouping>
  | SucceededState<OrderDataWithGrouping>
  | State<OrderMovedStatus, OrderDataWithGrouping>
  | State<NotFoundStatus, InitialOrdersState>
  | FailedPendingState<InitialOrdersState>
  | FailedState<InitialOrdersState>;
