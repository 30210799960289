import { CountryCode } from "@base/components/Global/PhoneField/types";
import config from "@constants/config";
import { CountryProperty, customList } from "country-codes-list";
import { TFunction } from "i18next";

const DEFAULT_COUNTRY = `global_phone_field_default_country_${config.appConfig.defaultLanguage}`;

// (AN - Netherlands Antilles) - Disabled because we don't have a flag for it
const EXCLUDED_COUNTRY_CODES = ["AN"];

const sortCountryCodes = (codes: string[], t: TFunction): string[] => {
  // @ts-ignore
  const defaultCountry = t(DEFAULT_COUNTRY);
  const hasDefaultCountry = defaultCountry !== DEFAULT_COUNTRY;

  // @ts-ignore
  const marketCountries: string[] = t(
    // @ts-ignore
    `global_phone_field_market_countries_${config.appConfig.defaultLanguage}`,
  ).split(" ");
  const newCodes = hasDefaultCountry ? [defaultCountry] : [];

  marketCountries.forEach((marketCountryCode) => {
    if (
      !newCodes.includes(marketCountryCode) &&
      codes.includes(marketCountryCode)
    ) {
      newCodes.push(marketCountryCode);
    }
  });

  codes.forEach((code) => {
    if (!newCodes.includes(code)) {
      newCodes.push(code);
    }
  });

  // @ts-ignore
  return newCodes;
};

const getCountryCodes = (t: TFunction): CountryCode[] => {
  const countryNameEn: Record<string, string> = customList(
    "countryCode" as CountryProperty,
    "{countryNameEn}",
  );
  const countryNameLocal: Record<string, string> = customList(
    "countryCode" as CountryProperty,
    "{countryNameLocal}",
  );
  const countryCallingCode: Record<string, string> = customList(
    "countryCode" as CountryProperty,
    "{countryCallingCode}",
  );
  const sortedCountryCodes: string[] = sortCountryCodes(
    Object.keys(countryNameEn),
    t,
  ).filter((code) => !EXCLUDED_COUNTRY_CODES.includes(code));

  return sortedCountryCodes.map((countryCode: string) => {
    const flagUrl = countryCode ? `/assets/flags/${countryCode}.svg` : null;

    const title =
      countryNameLocal[countryCode] === countryNameEn[countryCode]
        ? countryNameEn[countryCode]
        : `${countryNameEn[countryCode]} (${countryNameLocal[countryCode]})`;

    const code = `+${countryCallingCode[countryCode].replace(/\D+/g, "")}`;

    return {
      code,
      flagUrl,
      title,
    } as CountryCode;
  });
};

export { getCountryCodes, sortCountryCodes };
