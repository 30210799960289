import {
  IAddLineToCartBody,
  ICartAddModifierChoice,
  ILineAdd,
} from "@api/interfaces/cartLayouts";
import { ICartStatus } from "@api/interfaces/orderLayouts";

export type AddItemToCart = {
  productId: number;
  amount: number;
  modifiers?: ICartAddModifierChoice[];
  noteToKitchen?: string;
  variableProductID?: number;
};

const CartService = {
  buildAddItemRequest: (args: AddItemToCart): IAddLineToCartBody => {
    const products: ILineAdd[] = [];

    for (let i = 0; i < args.amount; i++) {
      const modifiers: ICartAddModifierChoice[] =
        args.modifiers?.map((m) => {
          return {
            ProductModifierID: m.ProductModifierID,
            ProductModifierChoiceID: m.ProductModifierChoiceID,
            Amount: 1,
            ProductId: m.ProductId,
          };
        }) || [];

      const addRequestProducts: ILineAdd = {
        ProductID: args.productId,
        Amount: 1,
        ModifierChoices: modifiers,
        CustomModifiers: args.noteToKitchen,
        VariableProductID: args.variableProductID,
      };

      products.push(addRequestProducts);
    }

    return {
      Operation: 0,
      Products: products,
    };
  },
  getOrderByID: (id: string, statuses: ICartStatus[]): ICartStatus | null => {
    const order: ICartStatus | undefined = statuses.find(
      (status) => status.TransactionBarcodeWithoutChecksum == id,
    );
    if (!order) {
      return null;
    }
    return order;
  },
};

export default CartService;
