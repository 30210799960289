import { dismissiblePaths, NavigationPath } from "@constants/navigation";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useDismissiblePath = () => {
  const pathname = useLocation().pathname;
  const [isDismissible, setDismissible] = useState<boolean>(false);
  useEffect(() => {
    if (pathname) {
      if (pathname !== NavigationPath.Home) {
        dismissiblePaths.every((dismissiblePath: string) => {
          if (pathname.includes(dismissiblePath)) {
            setDismissible(true);
            return false;
          }
          return true;
        });
      } else {
        setDismissible(false);
      }
    }
  }, [pathname]);

  return isDismissible;
};

export default useDismissiblePath;
