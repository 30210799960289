import "ts-replace-all";

import { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";

const Polyfills = () => {
  useEffect(() => {
    smoothscroll.polyfill();
  });
  return null;
};

export default Polyfills;
