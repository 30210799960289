import { ApiRequest, RequestResponse, ResponseFailed } from "@api/types";
import { AsyncThunkOptions, createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "@translation";

import { AppAsyncThunkConfig, State } from "./types";

type FilterIn<T, U> = T extends U ? T : never;

export function isStatus<
  TState extends object | undefined,
  TExtraState extends object,
  TMachine extends State<string, TState, TExtraState>,
  TStatus extends TMachine["status"],
>(
  state: TMachine,
  status: TStatus,
): state is FilterIn<TMachine, State<TStatus, TState, TExtraState>>;
export function isStatus<
  TState extends object | undefined,
  TExtraState extends object,
  TMachine extends State<string, TState, TExtraState>,
  TStatus extends TMachine["status"],
>(
  state: TMachine,
  status: ReadonlyArray<TStatus>,
): state is FilterIn<TMachine, State<TStatus, TState, TExtraState>>;
export function isStatus<
  TState extends object | undefined,
  TExtraState extends object,
  TMachine extends State<string, TState, TExtraState>,
  TStatus extends TMachine["status"],
>(
  state: TMachine,
  status: TStatus | ReadonlyArray<TStatus>,
): state is FilterIn<TMachine, State<TStatus, TState, TExtraState>> {
  if (Array.isArray(status)) {
    return status.includes(state.status as TStatus);
  }

  return state.status === status;
}

export const createAsyncFetchThunk = <
  TResponseSuccess,
  TResponseError,
  TParameters,
>(
  type: string,
  fetchFunction: ApiRequest<
    RequestResponse<TResponseSuccess, TResponseError>,
    TParameters
  >,
  options?: AsyncThunkOptions<
    TParameters,
    AppAsyncThunkConfig<ResponseFailed<TResponseError>>
  >,
) => {
  return createAsyncThunk<
    TResponseSuccess,
    TParameters,
    AppAsyncThunkConfig<ResponseFailed<TResponseError>>
  >(
    type,
    async (parameters, { rejectWithValue, signal }) => {
      const language = i18n.language;

      const response = await fetchFunction({
        signal,
        language,
        ...parameters,
      });

      if (response && response.isResponseOk) {
        return response.response;
      }

      return rejectWithValue(response);
    },
    options,
  );
};

// Please return this array for selectors instead of creating a new array each time. This will help with memoization.
export const EMPTY_ARRAY = [];
