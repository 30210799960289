import React from "react";
import { createUseStyles } from "react-jss";
import { DROPDOWN_BUTTON_ID } from "@base/components/Global/PhoneField";
import { CountryCode } from "@base/components/Global/PhoneField/types";
import classnames from "classnames";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  container: {
    display: "flex",
    position: "relative",
    cursor: "pointer",
  },
  countryContainer: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    width: 115,
  },
  icon: {
    height: "100%",
    width: 32,
    border: `1px solid ${color.fieldBorder}`,
    marginRight: spacing.xs,
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
  },
  arrowUp: {
    borderBottom: `5px solid ${color.fieldIcon}`,
  },
  arrowDown: {
    borderTop: `5px solid ${color.fieldIcon}`,
  },
  countryCode: {
    paddingLeft: 8,
    paddingRight: 8,
    marginLeft: "auto",
    display: "flex",
    color: color.fieldText,
    fontSize: font.size.m,
  },
}));

type DropdownProps = {
  country: CountryCode | null;
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  isDisabled: boolean;
};

const DropdownButton: React.FC<DropdownProps> = ({
  country = null,
  setOpen,
  isOpen,
  isDisabled,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      id={DROPDOWN_BUTTON_ID}
      onClick={() => !isDisabled && setOpen(!isOpen)}
    >
      <div className={classes.countryContainer}>
        {country?.flagUrl && (
          <img src={country.flagUrl} className={classes.icon} />
        )}
        <span
          className={classnames([
            classes.arrow,
            isOpen ? classes.arrowUp : classes.arrowDown,
          ])}
        />
        {country?.code && (
          <div className={classes.countryCode}>{country.code}</div>
        )}
      </div>
    </div>
  );
};

export default DropdownButton;
