import { useTranslation } from "react-i18next";
import { isPresent } from "@apl-digital/utils";

import { AppError } from "./types";

type UseErrorResult = {
  title: string;
  message: string;
  footnote?: string;
};

export const useError = (error?: AppError): UseErrorResult => {
  const { t } = useTranslation("errorPage");

  if (!isPresent(error)) {
    return {
      title: t("errorCode.unspecific.header"),
      message: t("errorCode.unspecific.msg"),
    };
  }

  switch (error.type) {
    case "fetch":
      return {
        // @ts-ignore
        title: t([
          `errorCode.${error.httpResponseCode}.header`,
          "errorCode.unspecific.header",
        ]),
        // @ts-ignore
        message: t([
          `errorCode.${error.httpResponseCode}.msg`,
          "errorCode.unspecific.msg",
        ]),
      };
    case "simple":
      return {
        title: t("errorCode.unspecific.header"),
        message: error.message,
      };

    case "navigation":
      return {
        // @ts-ignore
        title: t([
          `errorCode.${error.httpCode}.header`,
          "errorCode.unspecific.header",
        ]),
        // @ts-ignore
        message: t([
          `errorCode.${error.httpCode}.msg`,
          "errorCode.unspecific.msg",
        ]),
      };

    case "admin": {
      if (typeof error.messageForAdmin === "function") {
        error.messageForAdmin();
      } else {
        console.error(error.messageForAdmin);
      }

      return {
        title: t("errorCode.unspecific.header"),
        message: t("errorCode.unspecific.msg"),
        footnote:
          process.env.NODE_ENV === "development"
            ? t("errorCode.unspecific.footnote")
            : undefined,
      };
    }

    default:
      return {
        title: t("errorCode.unspecific.header"),
        message: t("errorCode.unspecific.msg"),
      };
  }
};
