import { DefaultTheme } from "react-jss";
import { DeepPartial } from "ts-essentials";
import * as z from "zod";

import { BaseTheme } from "./baseTheme";

export const ThemeKeySchema = z.enum([
  "VAPIANO",
  "OLEARYS",
  "BLENDER",
  "MYSUSHI",
  "APOLLO_CINEMA_EE",
  "APOLLO_CINEMA_LT",
  "FORUM_CINEMAS_LT",
  "KFC",
  "FINNKINO",
  "URBAN_KITCHEN",
  "CITY_EXPRESS",
  "APOLLO_FOOD_TRUCK",
]);
export type ThemeKey = z.infer<typeof ThemeKeySchema>;

export const ThemeVariantSchema = z.enum(["LIGHT", "DARK"]);
export type ThemeVariant = z.infer<typeof ThemeVariantSchema>;

export const ThemeCountrySchema = z.enum(["EE", "LT"]);
export type ThemeCountry = z.infer<typeof ThemeCountrySchema>;

export type ThemeVariantMap<T = DefaultTheme> = {
  [key in ThemeVariant]?: T;
};

export type ThemeCountryMap<T = DefaultTheme> = {
  [key in ThemeCountry]?: T;
};

export const asPartialTheme = <T extends DeepPartial<BaseTheme>>(
  partialTheme: T,
) => partialTheme;
