import { isPresent } from "@apl-digital/utils";
import { getUnixDateFromUTC } from "@base/utils/dateHelpers";
import { clearStorage, setStorage, StorageKey } from "@constants/storage";
import { isAnyOf } from "@reduxjs/toolkit";
import { hasData } from "@store/apiRequestStatusMachine";
import { AppStartListening } from "@store/listenerMiddleware";
import { deepEqual } from "fast-equals";

import {
  addProductToCart,
  refreshCart,
  removeAllProductsFromCart,
  removeCustomerFromCart,
  removeLinesFromCart,
  removeProductFromCart,
  updateCustomerCart,
  updateShoppingCartLanguage,
} from "./actions";
import { isShoppingCartExpired } from "./helper";
import {
  resetShoppingCart,
  selectCurrentTransactionId,
  selectShoppingCart,
} from "./slice";

export const addShoppingCartListeneres = (
  startListening: AppStartListening,
) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      !deepEqual(
        previousState.shoppingCart.shoppingCart,
        currentState.shoppingCart.shoppingCart,
      ),
    effect: (_action, { getState }) => {
      const shoppingCart = selectShoppingCart(getState());

      if (hasData(shoppingCart) && !isShoppingCartExpired(shoppingCart.state)) {
        setStorage(StorageKey.CART, JSON.stringify(shoppingCart.state), {
          type: "expiringRecord",
          expiresAt: getUnixDateFromUTC(shoppingCart.state.dttmExpiresUTC),
        });
      } else {
        clearStorage(StorageKey.CART);
      }
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      !deepEqual(previousState.user.userData, currentState.user.userData) &&
      isPresent(currentState.shoppingCart.shoppingCart),
    effect: (_action, { getState, dispatch }) => {
      const transactionId = selectCurrentTransactionId(getState());
      const userData = getState().user.userData;

      if (isPresent(userData) && isPresent(transactionId)) {
        dispatch(
          updateCustomerCart({
            transactionId,
            body: {
              CustomerPersonID: userData.ID,
              LoyaltyProgramMembershipID: 0,
            },
          }),
        );
      } else {
        const shoppingCart = getState().shoppingCart.shoppingCart;

        // The predicate above ensures that shoppingCart is present.
        if (!hasData(shoppingCart)) {
          return;
        }

        dispatch(
          removeCustomerFromCart({
            transactionId: shoppingCart.state.ID,
          }),
        );
      }
    },
  });

  startListening({
    matcher: isAnyOf(
      addProductToCart.fulfilled,
      addProductToCart.rejected,
      removeLinesFromCart.fulfilled,
      removeProductFromCart.fulfilled,
      removeAllProductsFromCart.fulfilled,
      updateShoppingCartLanguage.fulfilled,
    ),
    effect: (_action, { dispatch, getState }) => {
      const transactionId = selectCurrentTransactionId(getState());

      if (isPresent(transactionId)) {
        dispatch(refreshCart({ transactionId }));
      }
    },
  });

  startListening({
    matcher: isAnyOf(
      removeAllProductsFromCart.rejected,
      removeCustomerFromCart.rejected,
      refreshCart.rejected,
    ),
    effect: (_action, { dispatch }) => {
      dispatch(resetShoppingCart());
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      previousState.shoppingCart.shoppingCartLanguage !==
      currentState.shoppingCart.shoppingCartLanguage,
    effect: (_action, { getState, dispatch }) => {
      const shoppingCartLanguage = getState().shoppingCart.shoppingCartLanguage;

      if (isPresent(shoppingCartLanguage)) {
        const transactionId = selectCurrentTransactionId(getState());

        setStorage(StorageKey.SHOPPING_CART_LANGUAGE, shoppingCartLanguage);

        if (!isPresent(transactionId)) {
          return;
        }

        dispatch(
          updateShoppingCartLanguage({
            transactionId,
            body: {
              language: shoppingCartLanguage,
            },
          }),
        );
      } else {
        clearStorage(StorageKey.SHOPPING_CART_LANGUAGE);
      }
    },
  });
};
