import {
  ExternalIdentifier,
  IProduct,
  IProductLayoutResponse,
  ISection,
} from "@api/interfaces/productLayouts";
import { notNull } from "@base/utils/arrayHelpers";
import { SECTIONS_SELECTOR_HEIGHT } from "@pages/HomePage/components/ProductList";
import {
  ProductListItem,
  ProductListItemType,
  ProductType,
} from "@store/ProductsProvider/types";
import { getProductMinRequiredAge } from "@store/shoppingCart/helper";

const getUniqueSectionId = (section: ISection): string => {
  let uniqueId = "section-unique-id";

  if (section.ID) uniqueId += `-${section.ID}`;
  if (section.ProductLayoutID) uniqueId += `-${section.ProductLayoutID}`;
  if (section.RowCount) uniqueId += `-${section.RowCount}`;
  if (section.SortOrder) uniqueId += `-${section.SortOrder}`;
  if (section?.Items?.length) uniqueId += `-${section.Items.length}`;

  return uniqueId;
};

const getProductListSection = (
  section: ISection,
  items: ProductListItem[] = [],
): ProductListItem =>
  ({
    id: section.ID,
    uniqueId: getUniqueSectionId(section),
    type: ProductListItemType.SECTION,
    title: section.LocalName,
    items,
    picture: section.MediaResources.map((media) => media.Location)[0],
    thumbnail: section.MediaResources.map(
      (media) => media.ThumbnailLocation,
    )[0],
  }) as ProductListItem;

const getProductListCategory = (
  category: IProduct,
  items: ProductListItem[] = [],
): ProductListItem =>
  ({
    id: category.ID,
    uniqueId: `categoory-id-${category.ID}-${items.length}`,
    type: ProductListItemType.CATEGORY,
    title: category.LocalName,
    items,
  }) as ProductListItem;

const getProductListItem = (
  rawProduct: IProduct | undefined,
): ProductListItem | undefined => {
  let product: ProductListItem | undefined = undefined;

  if (rawProduct) {
    product = {
      id: rawProduct.ID,
      type: ProductListItemType.ITEM,
      title: rawProduct.LocalName,
      shortTitle: rawProduct.VariationDisplayName,
      description: rawProduct.PublicDescription,
      category: rawProduct?.Category?.ID,
      minAge: rawProduct?.AlcoholContent?.MinRequiredAge,
      picture: rawProduct.MediaResources.map((media) => media.Location)[0],
      thumbnail: rawProduct.MediaResources.map(
        (media) => media.ThumbnailLocation,
      )[0],
      tax: rawProduct.Tax,
      netPrice: rawProduct.NetUnitPrice,
      grossPrice: rawProduct.GrossUnitPrice,
      modifiers: rawProduct.ModifierSet,
      subItems: rawProduct.SubItemProducts,
      isAgeRestricted: getProductMinRequiredAge(rawProduct) > 0,
      isFeatured:
        rawProduct.Keywords?.filter(
          (el) => el.ExternalIdentifier === ExternalIdentifier.FEATURED,
        ).length !== 0,
      isDisabled:
        rawProduct.Keywords?.filter(
          (el) => el.ExternalIdentifier === ExternalIdentifier.DISABLED,
        ).length !== 0,
      isCrossSells:
        rawProduct.Keywords?.filter(
          (el) => el.ExternalIdentifier === ExternalIdentifier.CROSS_SELLING,
        ).length !== 0,
    } as ProductListItem;
  }

  if (rawProduct?.Type === ProductType.VARIABLE) {
    let mainProductId: number | null = null;

    if (rawProduct?.SubItemProducts?.length) {
      const defaultItem = rawProduct.SubItemProducts.find((i) => i.IsDefault);
      if (defaultItem) {
        mainProductId = defaultItem.ID;
      } else {
        mainProductId = rawProduct.SubItemProducts[0].ID;
      }
    }

    if (mainProductId) {
      product = {
        ...product,
        type: ProductListItemType.VARIABLE,
        mainProductId,
      } as ProductListItem;
    }
  }

  return product;
};

const getBgColorByIdx = (
  colLen: number,
  colors: string[],
  idx: number,
): string | undefined => {
  if (colLen > idx) return colors[idx];
  let tempIdx = idx;
  while (tempIdx >= colLen) {
    tempIdx = tempIdx - colLen;
  }
  return colors[tempIdx];
};

const getMappedSections = (
  rawData: IProductLayoutResponse,
  colors: string[],
): ProductListItem[] => {
  if (rawData) {
    const mappedSections = rawData.Sections.map((section) => {
      let newItems = [] as IProduct[];
      let newCategories = [] as IProduct[];

      section.Items.map((item) => {
        const foundProduct = rawData.Products.find(
          (product) => product.ID === item.ProductID,
        );

        if (foundProduct) {
          if (
            foundProduct?.Type === ProductType.REGULAR ||
            foundProduct?.Type === ProductType.VARIABLE
          ) {
            newItems = [
              ...newItems,
              {
                ...foundProduct,
                Column: item.Column,
              },
            ];
          } else if (foundProduct?.Type === ProductType.SEPARATOR) {
            newCategories = [
              ...newCategories,
              {
                ...foundProduct,
                Column: item.Column,
              },
            ];
          }
        }
      });

      if (newItems.length > 0) {
        if (newCategories.length === 0) {
          const returnItems = newItems
            .map(getProductListItem)
            .filter(notNull) as ProductListItem[];
          return getProductListSection(section, returnItems);
        } else {
          const returnedCategories: ProductListItem[] = newCategories.map(
            (category) => {
              const itemsInCategory = newItems
                .filter((i) => i.Column === category.Column)
                .map(getProductListItem)
                .filter(notNull) as ProductListItem[];
              return getProductListCategory(category, itemsInCategory);
            },
          );
          return getProductListSection(section, returnedCategories);
        }
      }
      return null;
    })
      .map((section) => {
        // Checks if section, category is not empty (if section is empty, will not display the category)
        if (section?.type === ProductListItemType.SECTION) {
          // Checks if category has sections (if no sections, will display list of items in category)
          if (section.items) {
            const categories = section.items.filter((category) => {
              // Checks if section has items (if no items in section, will not display section)
              if (category.type === ProductListItemType.CATEGORY) {
                return category.items?.length !== 0;
              }
              return section;
            });
            return categories.length === 0
              ? null
              : {
                  ...section,
                  items: categories,
                };
          }
        }
        return null;
      })
      .filter(notNull);

    return (mappedSections as ProductListItem[]).map((s, idx) => ({
      ...s,
      bgColor: getBgColorByIdx(colors.length, colors, idx),
    }));
  }

  return [];
};

export const getSections = (
  rawData: IProductLayoutResponse | null,
  colors: string[],
): ProductListItem[] => (rawData ? getMappedSections(rawData, colors) : []);

export const getProductListItems = (
  rawData: IProductLayoutResponse | null,
): ProductListItem[] =>
  (rawData?.Products?.map((product) => getProductListItem(product)).filter(
    notNull,
  ) as ProductListItem[]) || [];

export const scrollToSection = (
  sectionId: number,
  compensation?: number,
): void => {
  const wrapper = document.getElementById(`section-${sectionId}`);

  if (wrapper) {
    const bodyRect = document.body.getBoundingClientRect();
    const wrapperRect = wrapper.getBoundingClientRect();
    const offset = wrapperRect.top - bodyRect.top;
    const isTargetAboveCurrentScroll = window.scrollY > offset;
    const directionCompensation = isTargetAboveCurrentScroll
      ? SECTIONS_SELECTOR_HEIGHT + 48
      : SECTIONS_SELECTOR_HEIGHT;

    window.scrollTo({
      top: Number(offset) - directionCompensation + (compensation || 0),
      behavior: "smooth",
    });
  }
};
