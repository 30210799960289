import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: spacing.xxl,
  },
  infoLabel: {
    fontWeight: font.weight.m,
    fontSize: font.size.m,
    lineHeight: font.lineHeight.x3s,
    color: color.orderStatusCardInfoLabelTextColor,
    paddingRight: "56px",
  },
  infoValue: {
    fontWeight: font.weight.m,
    fontSize: font.size.m,
    lineHeight: font.lineHeight.m,
    color: color.orderStatusCardInfoValueTextColor,
    textWrap: "nowrap",
  },
  infoValueBig: {
    fontSize: font.size.xl,
    lineHeight: font.lineHeight.m,
  },
  table: {
    width: "fit-content",
    minWidth: "0",
    maxWidth: "100%",
  },
  infoRow: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
}));

type OrderCardExtraInfoBlockProps = {
  transactionId: number;
  totalGrossSum: number;
  variant: "column" | "row";
};

export const OrderCardExtraInfoBlock: React.FC<
  OrderCardExtraInfoBlockProps
> = ({ transactionId, totalGrossSum, variant }) => {
  const classes = useStyles();
  const { t } = useTranslation("orderStatusPage");

  if (variant === "column") {
    return (
      <div className={classes.infoContainer}>
        <div className={classes.infoRow}>
          <span className={classes.infoLabel}>
            {t("OrderCardExtraInfoBlock.orderNumberLabel")}
          </span>
          <span className={classes.infoValue}>
            {t("OrderCardExtraInfoBlock.orderNumberValue", {
              transactionId,
            })}
          </span>
        </div>

        <div className={classes.infoRow}>
          <span className={classes.infoLabel}>
            {t("OrderCardExtraInfoBlock.totalGrossSumLabel")}
          </span>
          <span className={classNames(classes.infoValue, classes.infoValueBig)}>
            {t("OrderCardExtraInfoBlock.totalGrossSumValue", {
              totalGrossSum,
              formatParams: {
                totalGrossSum: { currency: "EUR" },
              },
            })}
          </span>
        </div>
      </div>
    );
  }

  return (
    <table className={classes.table}>
      <tbody>
        <tr>
          <td className={classes.infoLabel}>
            {t("OrderCardExtraInfoBlock.orderNumberLabel")}
          </td>
          <td className={classes.infoValue}>
            {t("OrderCardExtraInfoBlock.orderNumberValue", {
              transactionId,
            })}
          </td>
        </tr>
        <tr>
          <td className={classes.infoLabel}>
            {t("OrderCardExtraInfoBlock.totalGrossSumLabel")}
          </td>
          <td className={classNames(classes.infoValue, classes.infoValueBig)}>
            {t("OrderCardExtraInfoBlock.totalGrossSumValue", {
              totalGrossSum,
              formatParams: {
                totalGrossSum: { currency: "EUR" },
              },
            })}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
