import { ApiRequest, RequestResponse } from "@api/types";

import { RootState } from "./slices";
import {
  FailedPendingState,
  FailedState,
  IdleState,
  PendingState,
  StalePendingState,
  StaleState,
  SucceededState,
} from "./types";
import { createAsyncFetchThunk, isStatus } from "./utils";

export type ApiRequestStatusMachine<
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
> =
  | IdleState
  | StaleState<TSuccess>
  | StalePendingState<TSuccess>
  | PendingState
  | SucceededState<TSuccess>
  | FailedState<TFailed>
  | FailedPendingState<TFailed>;

export const hasData = <
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
>(
  value: ApiRequestStatusMachine<TSuccess, TFailed>,
): value is
  | StaleState<TSuccess>
  | StalePendingState<TSuccess>
  | SucceededState<TSuccess> =>
  isStatus(value, ["stale", "stalePending", "succeeded"]);

export const hasFailedData = <
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
>(
  value: ApiRequestStatusMachine<TSuccess, TFailed>,
): value is FailedPendingState<TFailed> | FailedState<TFailed> =>
  isStatus(value, ["failedPending", "failed"]);

export const isLoading = <
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
>(
  value: ApiRequestStatusMachine<TSuccess, TFailed>,
): value is
  | PendingState
  | StalePendingState<TSuccess>
  | FailedPendingState<TFailed> =>
  isStatus(value, ["pending", "stalePending", "failedPending"]);

export const shouldLoad = <
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
>(
  value: ApiRequestStatusMachine<TSuccess, TFailed>,
): value is IdleState | StaleState<TSuccess> | FailedState<TFailed> =>
  isStatus(value, ["idle", "stale"]) ||
  (value.status === "failed" && value.retryCount < 3);

export const setStateInitial = <
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
>(
  currentState: ApiRequestStatusMachine<TSuccess, TFailed>,
  applyNextState: (nextState: StaleState<TSuccess> | IdleState) => void,
  useStale = true,
): void => {
  if (useStale && hasData(currentState)) {
    applyNextState({
      ...currentState,
      status: "stale",
    });
  } else {
    applyNextState({
      status: "idle",
    });
  }
};

export const setStatePending = <
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
>(
  currentState: ApiRequestStatusMachine<TSuccess, TFailed>,
  applyNextState: (
    nextState:
      | FailedPendingState<TFailed>
      | StalePendingState<TSuccess>
      | PendingState,
  ) => void,
  useStale = true,
): void => {
  if (useStale && hasFailedData(currentState)) {
    if (currentState.status === "failed") {
      applyNextState({
        ...currentState,
        status: "failedPending",
      });
    }
  } else if (useStale && hasData(currentState)) {
    applyNextState({
      ...currentState,
      status: "stalePending",
    });
  } else {
    applyNextState({
      status: "pending",
    });
  }
};

export const setStateFailed = <
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
>(
  currentState: ApiRequestStatusMachine<TSuccess, TFailed>,
  applyNextState: (nextState: FailedState<TFailed> | FailedState) => void,
): void => {
  if (hasFailedData(currentState) && isLoading(currentState)) {
    applyNextState({
      ...currentState,
      status: "failed",
      retryCount: currentState.retryCount + 1,
    });
  } else {
    applyNextState({
      status: "failed",
      retryCount: 0,
    });
  }
};

export type onCancelProps<TStateMachine> = {
  type: string;
  state: TStateMachine;
};

export type CancelCondition<TStateMachine> = {
  predicate: (state: TStateMachine) => boolean;
  onCancel: ({ type, state }: onCancelProps<TStateMachine>) => void;
};

export const createAsyncStatusMachineThunk = <
  TResponseSuccess,
  TResponseError,
  TParameters,
  TStateMachine,
>(
  type: string,
  fetchFunction: ApiRequest<
    RequestResponse<TResponseSuccess, TResponseError>,
    TParameters
  >,
  selectFunction?: (state: RootState) => TStateMachine,
  cancelConditions?: CancelCondition<TStateMachine>[],
) =>
  createAsyncFetchThunk<TResponseSuccess, TResponseError, TParameters>(
    type,
    fetchFunction,
    {
      condition: (_, { getState }) => {
        if (!selectFunction) {
          return;
        }

        const state = selectFunction(getState());

        for (const condition of cancelConditions ?? []) {
          if (condition.predicate(state)) {
            condition.onCancel({ type, state });
            return false;
          }
        }

        return;
      },
    },
  );

export const createCancelCondition = <TStateMachine>(
  cancelCondition: CancelCondition<TStateMachine>,
): CancelCondition<TStateMachine> => cancelCondition;

export const getCancelWhenAlreadyLoadingCondition = <
  TSuccess extends object | undefined = undefined,
  TFailed extends object | undefined = undefined,
>() =>
  createCancelCondition({
    predicate: isLoading<TSuccess, TFailed>,
    onCancel: ({ type, state }) => {
      if (process.env.NODE_ENV === "development") {
        console.log(
          "%cCancelled action %s because it was already loading.",
          "color:red",
          type,
          state,
        );
      }
    },
  });
