import { commonLightTheme } from "@theme/themes/common.light";
import { DefaultTheme } from "react-jss";

export const blenderLightTheme: DefaultTheme = {
  ...commonLightTheme,
  blobStorage: {
    ...commonLightTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/light/logo/logo-blender.svg",
    burgerMenuLinks: [
      "https://assets.markus.live/00-generic-app/icons/logo/ApolloClub24.svg",
    ],
  },
  config: {
    ...commonLightTheme.config,
    brandLogoWidth: 78,
    brandLogoHeight: 40,
    sectionsColors: "#FFF2DA;#FFD0A5;#FFE2C7;#FFE3AE;#FFEDCB;#FFD6B0",
  },
} as const;
