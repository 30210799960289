import { loadCurrentConfig, validateConfigs } from "@apl-digital/tenant-config";
import { ThemeKeySchema, ThemeVariantSchema } from "@theme/types";
import { z } from "zod";

import rawConfig from "../../config.yaml";

const CarouselConfigSchema = z
  .object({
    mode: z.enum(["fade", "slide", "parallax"]),
    autoplayInterval: z.number().positive().int().min(1000),
  })
  .strict();

const AppConfigSchema = z
  .object({
    cartItemMaxQuantity: z.number().positive().int(),
    singleItemMaxQuantity: z.number().positive().int(),
    availableLanguages: z.array(z.string()),
    defaultLanguage: z.string(),
    carousel: z.object({
      homePageDesktopBanner: CarouselConfigSchema,
      landingImageBanner: CarouselConfigSchema,
      locationPageBusinessImage: CarouselConfigSchema,
    }),
  })
  .strict();

const TenantConfigSchema = z
  .object({
    key: z.string(),
    location: z.string(),
  })
  .strict();

const KDSConfigSchema = z
  .object({
    isEnabled: z.boolean(),
    shouldShowDeliveredItems: z.boolean(),
  })
  .strict();

const ThemeConfigSchema = z
  .object({
    key: ThemeKeySchema,
    defaultVariant: ThemeVariantSchema,
  })
  .strict();

const SSOConfigSchema = z
  .object({
    authorityUrl: z.string().url(),
    clientId: z.string().nullable(),
  })
  .strict();

const AssetConfigSchema = z
  .object({
    translations: z
      .object({
        url: z.string().url(),
      })
      .strict(),
    images: z
      .object({
        resizerServiceUrl: z.string().url(),
      })
      .strict(),
    assets: z
      .object({
        faviconUrl: z.string(),
        manifestUrl: z.string(),
      })
      .strict(),
  })
  .strict();

const ApiConfigSchema = z
  .object({
    orderAppProxy: z
      .object({
        url: z.string().url(),
        timeout: z.number().positive().int(),
      })
      .strict(),
    google: z
      .object({
        tagManager: z
          .object({
            code: z.string(),
          })
          .strict()
          .nullable(),
      })
      .strict(),
  })
  .strict();

const FeatureFlagsSchema = z
  .object({
    showWIP: z.boolean(),
    shouldHideMenuBlock: z.boolean(),
    isLoginEnabled: z.boolean(),
    showReadyForPickupStatus: z.boolean(),
    showOrderPickupLocation: z.boolean(),
    useOldShoppingCartHighestMinRequiredAgeLogic: z.boolean(),
  })
  .strict();

export const SiteConfigSchema = z
  .object({
    appConfig: AppConfigSchema,
    tenantConfig: TenantConfigSchema,
    kdsConfig: KDSConfigSchema,
    themeConfig: ThemeConfigSchema,
    ssoConfig: SSOConfigSchema,
    assetConfig: AssetConfigSchema,
    apiConfig: ApiConfigSchema,
    featureFlags: FeatureFlagsSchema,
  })
  .strict();
export type SiteConfig = z.infer<typeof SiteConfigSchema>;

const tenantKey = window.location.hostname.split(".").reverse();

if (process.env.NODE_ENV === "development") {
  validateConfigs(SiteConfigSchema, rawConfig, tenantKey);
}

const config = loadCurrentConfig(SiteConfigSchema, rawConfig, tenantKey);
export default config;
