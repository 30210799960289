import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ICheckoutOption } from "@api/interfaces/cartLayouts";
import Button from "@base/components/Global/Button";
import Icon from "@base/components/Global/Icon";
import { Modal } from "@base/components/Global/Modal";
import {
  ECustomFieldType,
  getCustomFieldValue,
} from "@base/utils/customFieldUtils";
import { getBlobStorage } from "@theme";
import classnames from "classnames";

const useStyles = createUseStyles(({ spacing, font, color }) => ({
  underlineButton: {
    color: color.defaultText,
    textDecoration: "underline",
    cursor: "pointer",
  },
  termsAndConditionsBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
  },
  warning: {
    display: "flex",
    gap: spacing.s,
    justifyContent: "center",
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    fontWeight: font.weight.m,
  },
  verified: {
    color: color.black,
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
  confirmText: {
    color: color.cartPageConfirmBlockText,
  },
  container: {
    padding: [50, spacing.x5l, spacing.x5l, spacing.x5l],
    maxHeight: "calc(100vh - 32px)",
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
    alignItems: "start",
  },
  title: {
    fontSize: font.size.x2l,
    fontWeight: font.weight.s,
    color: color.defaultText,
    lineHeight: font.lineHeight.l,
  },
  bodyText: {
    margin: [spacing.x5l, 0],
    fontSize: font.size.m,
    color: color.defaultText,
    lineHeight: font.lineHeight.m,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
  },
  scrollWindow: {
    overflowY: "auto",
    minHeight: "0px",
    maxHeight: "calc(100vh - 50%);",
    color: color.defaultText,
  },
}));

type PayLaterConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  variant: "mobile" | "desktop";
};

export const PayLaterConfirmModal: React.FC<
  PayLaterConfirmModalProps & ICheckoutOption
> = ({ isOpen, onClose, onConfirm, variant, ...checkoutOptions }) => {
  const classes = useStyles();
  const { t } = useTranslation("cartPage");

  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState(false);

  const { warningUnchecked, warningChecked } = getBlobStorage();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      forceToForeground
      allowCloseOnOutsideClick
      width={variant === "desktop" ? "60%" : "100%"}
    >
      <div className={classes.container}>
        <div className={classes.title}>
          {getCustomFieldValue(
            checkoutOptions.CustomFieldsAndValues,
            ECustomFieldType.PayLaterRuleConfirmationTitle,
          )}
        </div>

        <div className={classes.scrollWindow}>
          <p>
            {getCustomFieldValue(
              checkoutOptions.CustomFieldsAndValues,
              ECustomFieldType.PayLaterRuleConfirmationMessage,
            )}
          </p>
        </div>
        <a
          className={classes.confirmText}
          href={getCustomFieldValue(
            checkoutOptions.CustomFieldsAndValues,
            ECustomFieldType.PayLaterRuleTermsLink,
          )}
        >
          {getCustomFieldValue(
            checkoutOptions.CustomFieldsAndValues,
            ECustomFieldType.PayLaterRuleTermsLinkLabel,
          )}
        </a>
        <div
          className={classnames([
            classes.warning,
            isTermsAndConditionsAccepted && classes.verified,
          ])}
          id="confirm-age-bar"
          onClick={() => setIsTermsAndConditionsAccepted((v) => !v)}
        >
          <div className={classes.icon} id="confirm-age-btn">
            {isTermsAndConditionsAccepted ? (
              <Icon url={warningChecked} height={24} width={24} />
            ) : (
              <Icon url={warningUnchecked} height={24} width={24} />
            )}
          </div>
          <a className={classes.confirmText}>
            {getCustomFieldValue(
              checkoutOptions.CustomFieldsAndValues,
              ECustomFieldType.PayLaterRuleConfirmationAcknowledgment,
            )}
          </a>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            action={() => {
              onClose();
              onConfirm();
            }}
            isDisabled={!isTermsAndConditionsAccepted}
          >
            {t("PayLaterConfirmModal.confirmButtonText")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
