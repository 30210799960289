import { isPresent } from "@apl-digital/utils";
import { hasData } from "@store/apiRequestStatusMachine";
import { selectShoppingCart } from "@store/shoppingCart";
import { RootState } from "@store/slices";

import { selectTheatreResource } from "./slice";

export const selectIsTheatreResourceBound = (state: RootState) => {
  const shoppingCart = selectShoppingCart(state);
  const expectedTheatreResource = selectTheatreResource(state);

  if (
    !hasData(expectedTheatreResource) ||
    !hasData(shoppingCart) ||
    !isPresent(shoppingCart.state.Lines) ||
    shoppingCart.state.Lines.length === 0
  ) {
    return false;
  }

  return shoppingCart.state.Lines.every(
    (line) => line.TheatreResource.ID === expectedTheatreResource.state.ID,
  );
};
