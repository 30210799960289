import {
  GetOrdersStatusSummaryLine,
  OrderLineStatus,
  OrdersStatusSummary,
  OrderStatus,
  ShoppingCartOnHoldReason,
} from "@api/interfaces/orderLayouts";

import {
  OrderData,
  OrderDataGroup,
  OrderDataLine,
  OrderDataWithGrouping,
} from "./types";

const mapToOrderDataLine = (
  line: GetOrdersStatusSummaryLine,
  orderStatus: OrderStatus,
): OrderDataLine => {
  return {
    ...line,
    isPending:
      orderStatus === OrderStatus.OnHold &&
      line.Status === OrderLineStatus.Unknown,
    isInProgress:
      line.Status === OrderLineStatus.Accepted ||
      line.Status === OrderLineStatus.Prepared ||
      line.Status === OrderLineStatus.New,
    isReady: line.Status === OrderLineStatus.ReadyForDelivery,
    isDelivered: line.Status === OrderLineStatus.Delivered,
  };
};

export const mapOrderStatusResponseToOrderData = (
  ordersStatusSummary: OrdersStatusSummary,
): OrderData => {
  const orderLines = ordersStatusSummary.Lines.map((line) =>
    mapToOrderDataLine(line, ordersStatusSummary.Status),
  );

  const isOrderPending =
    (ordersStatusSummary.Status === OrderStatus.OnHold &&
      ordersStatusSummary.ShoppingCartOnHoldReason ===
        ShoppingCartOnHoldReason.None &&
      ordersStatusSummary.Lines.length !== 0) ||
    (ordersStatusSummary.Status === OrderStatus.OnHold &&
      ordersStatusSummary.ShoppingCartOnHoldReason ===
        ShoppingCartOnHoldReason.Draft);

  const isOrderComplete =
    [OrderStatus.Cancelled, OrderStatus.Refunded].includes(
      ordersStatusSummary.Status,
    ) ||
    (ordersStatusSummary.Status === OrderStatus.Completed &&
      orderLines.every((line) => line.isDelivered));

  const isOrderPaid =
    ordersStatusSummary.Status === OrderStatus.Completed &&
    (ordersStatusSummary.ShoppingCartOnHoldReason ===
      ShoppingCartOnHoldReason.None ||
      ordersStatusSummary.ShoppingCartOnHoldReason ===
        ShoppingCartOnHoldReason.Unknown);

  return {
    ...ordersStatusSummary,
    lines: orderLines,
    isPending: isOrderPending,
    isComplete: isOrderComplete,
    isPaid: isOrderPaid,
  };
};

const mapToOrderDataGroups = (lines: OrderDataLine[]): OrderDataGroup[] => {
  return lines.reduce<Array<OrderDataGroup>>((acc, line) => {
    const existingGroup = acc.find(
      (group) =>
        group.ReadyForDeliveryAtChannel?.ID ===
        line.ReadyForDeliveryAtChannel?.ID,
    );

    if (existingGroup) {
      existingGroup.lines.push(line);
    } else {
      acc.push({
        ReadyForDeliveryAtChannel: line.ReadyForDeliveryAtChannel,
        lines: [line],
      });
    }

    return acc;
  }, []);
};

export const mapOrderDataToOrderDataWithGrouping = (
  orderData: OrderData,
): OrderDataWithGrouping => {
  const pendingGroup = mapToOrderDataGroups(
    orderData.lines.filter((line) => line.isPending),
  );

  const inProgressGroup = mapToOrderDataGroups(
    orderData.lines.filter((line) => line.isInProgress),
  );

  const readyGroup = mapToOrderDataGroups(
    orderData.lines.filter((line) => line.isReady),
  );

  const deliveredGroup = mapToOrderDataGroups(
    orderData.lines.filter((line) => line.isDelivered),
  );

  const allGroup = mapToOrderDataGroups(orderData.lines);

  return {
    ...orderData,
    groups: {
      pending: pendingGroup,
      inProgress: inProgressGroup,
      ready: readyGroup,
      delivered: deliveredGroup,
      all: allGroup,
    },
  };
};
