import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import Box from "@base/components/Box/Box";
import BoxList from "@base/components/Box/BoxList";
import Button from "@base/components/Global/Button";
import Footer from "@base/components/Global/Footer";
import Icon from "@base/components/Global/Icon";
import { RichListTranslation } from "@base/components/Global/RichTextTranslation";
import { useRichTextTranslation } from "@base/components/Global/RichTextTranslation/useRichTextTranslation";
import LandingImage from "@base/components/LandingImage/LandingImage";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { useWhiteLabelImage } from "@base/hooks/useWhiteLabelImage";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { NavigationPath } from "@constants/navigation";
import { useAppSelector } from "@store";
import { selectAssetCategoryUrls } from "@store/salesPoint";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(({ color, spacing, font }) => ({
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.x4l,
  },
  header: {
    fontSize: font.size.x3l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.xl,
    color: color.locationInfoHeader,
  },
  location: {
    fontWeight: font.weight.m,
    lineHeight: font.lineHeight.m,
    fontSize: font.size.s,
    display: "flex",
    marginRight: spacing.s,
    color: color.locationText,
  },
  iconWrapper: {
    alignSelf: "flex-start",
    marginRight: spacing.s,
    alignItems: "center",
  },
  description: {
    fontSize: font.size.s,
    lineHeight: font.lineHeight.m,
    color: color.locationDescription,
  },
  contactsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.x4l,
  },
  map: {
    height: 227,
    borderRadius: 24,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginLeft: -spacing.xxl,
    marginRight: -spacing.xxl,
  },
}));

const LocationPage = () => {
  const classes = useStyles();
  const { t } = useTranslation(["salespoint", "translations"]);
  const getWhiteLabeledImage = useWhiteLabelImage();
  const navigate = useNavigate();
  const { resolveKey } = useSalesPointTranslationKey();
  const transProps = useRichTextTranslation();
  const images = useAppSelector((state) =>
    selectAssetCategoryUrls(state, "location_page_business_image"),
  );

  const { locationIcon, locationMap } = getBlobStorage();

  const mapImage = getWhiteLabeledImage(locationMap);

  return (
    <>
      <LandingImage
        hasBottomGradient
        shouldCompensateBottom
        imageUrls={images.map((image) => ({
          img: getImageFromAPI(image, 1290, 1290),
          placeholderImg: getImageFromAPI(image, 129, 129),
        }))}
      />
      <BoxList hasFooter>
        <Box>
          <div className={classes.boxContainer}>
            <div className={classes.header}>{t(resolveKey("name"))}</div>
            <div className={classes.location}>
              <div className={classes.iconWrapper}>
                <Icon url={locationIcon} height={24} width={24} />
              </div>
              <div className={classes.contactsWrapper}>
                <div>
                  <RichListTranslation
                    getTranslation={() =>
                      t(resolveKey("addressLines", { returnObjects: true }), {
                        returnObjects: true,
                      })
                    }
                  />
                </div>
                <div>
                  <RichListTranslation
                    getTranslation={() =>
                      t(resolveKey("contacts", { returnObjects: true }), {
                        returnObjects: true,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className={classes.description}>
              <Trans
                {...transProps}
                i18nKey={resolveKey("description")}
                ns="salespoint"
              />
            </div>
            <a
              className={classes.map}
              style={{
                backgroundImage: `url(${getImageFromAPI(
                  mapImage || "",
                  1001,
                  554,
                )})`,
              }}
              href={t(resolveKey("mapUrl"))}
              target="_blank"
            />
          </div>
        </Box>
        <Footer>
          <Button action={() => navigate(NavigationPath.Home)}>
            {t("translations:error_page_go_back_btn")}
          </Button>
        </Footer>
      </BoxList>
    </>
  );
};

export default LocationPage;
