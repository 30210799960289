import { request } from "@api";
import {
  GetOrdersStatusSummaryResponse,
  GetShoppingCartStatusRequest,
} from "@api/interfaces/orderLayouts";
import { ApiPath } from "@api/paths";
import { ApiRequest, RequestResponse } from "@api/types";

const getOrderStatus: ApiRequest<
  RequestResponse<GetOrdersStatusSummaryResponse>,
  GetShoppingCartStatusRequest
> = async ({ barcodeWithoutChecksums, ...options }) =>
  request({
    ...options,
    api: ApiPath.Order.status(barcodeWithoutChecksums),
    canFail: true,
  });

export const Order = {
  getOrderStatus,
};
