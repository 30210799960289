import { combineSlices } from "@reduxjs/toolkit";

import contactInfoSlice from "./contactInfo/slice";
import layoutSlice from "./layout/slice";
import ordersSlice from "./orders/slice";
import paymentSlice from "./payment/slice";
import salesPointSlice from "./salesPoint/slice";
import shoppingCartSlice from "./shoppingCart/slice";
import theatreResourceSlice from "./theatreResource/slice";
import userSlice from "./user/slice";

export const rootReducer = combineSlices({
  salesPoint: salesPointSlice,
  user: userSlice,
  layout: layoutSlice,
  shoppingCart: shoppingCartSlice,
  contactInfo: contactInfoSlice,
  theatreResource: theatreResourceSlice,
  payment: paymentSlice,
  orders: ordersSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
