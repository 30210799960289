import { ECheckoutOptionType } from "@api/interfaces/cartLayouts";
import { IInitializePaymentResponse } from "@api/interfaces/paymentLayouts";
import {
  FailedStatus,
  IdleState,
  PendingStatus,
  State,
  SucceededStatus,
} from "@store/types";
import { z } from "zod";

export type PaymentData = {
  barcodeWithoutChecksum: string;
};

type PaymentSession =
  | IdleState
  | State<PendingStatus, undefined, PaymentData>
  | State<SucceededStatus, IInitializePaymentResponse, PaymentData>
  | State<FailedStatus, undefined, PaymentData>;

export type PaymentCallbackBaseData = PaymentData & {
  checkoutOptionType: ECheckoutOptionType;
};

type PaymentCallbackSuccessData = PaymentCallbackBaseData & {
  type: "success";
};

type PaymentCallbackErrorData = PaymentCallbackBaseData & {
  type: "error";
};

type PaymentCallbackCancelledData = PaymentCallbackBaseData & {
  type: "cancelled";
};

export type PaymentCallbackData =
  | PaymentCallbackSuccessData
  | PaymentCallbackErrorData
  | PaymentCallbackCancelledData;

type PaymentCallback =
  | IdleState
  | State<"pending", PaymentCallbackBaseData>
  | State<"completed", PaymentCallbackData>;

const PaymentDataSchema = z.object({
  barcodeWithoutChecksum: z.string(),
});

const PaymentCallbackBaseDataSchema = PaymentDataSchema.extend({
  checkoutOptionType: z.nativeEnum(ECheckoutOptionType),
});

const PaymentCallbackSuccessDataSchema = PaymentCallbackBaseDataSchema.extend({
  type: z.literal("success"),
});

const PaymentCallbackErrorDataSchema = PaymentCallbackBaseDataSchema.extend({
  type: z.literal("error"),
});

const PaymentCallbackCancelledDataSchema = PaymentCallbackBaseDataSchema.extend(
  {
    type: z.literal("cancelled"),
  },
);

const PaymentCallbackDataSchema = z.union([
  PaymentCallbackSuccessDataSchema,
  PaymentCallbackErrorDataSchema,
  PaymentCallbackCancelledDataSchema,
]);

export const PaymentCallbackSchema: z.ZodSchema<PaymentCallback> = z.union([
  z.object({
    status: z.literal("idle"),
  }),
  z.object({
    status: z.literal("pending"),
    state: PaymentCallbackBaseDataSchema,
  }),
  z.object({
    status: z.literal("completed"),
    state: PaymentCallbackDataSchema,
  }),
]);

export type PaymentState = {
  paymentSession: PaymentSession;
  paymentCallback: PaymentCallback;
};
