import { createUseStyles } from "react-jss";

type UseSharedStylesOptions = {
  linkLineColor: string;
};

export const useSharedStyles = createUseStyles({
  linkLineBottom: {
    "&:after": {
      content: '""', // eslint-disable-line
      display: "block",
      position: "absolute",
      backgroundColor: ({ linkLineColor }: UseSharedStylesOptions) =>
        linkLineColor,
      width: 1,
      height: "50%",
      top: "50%",
      left: 0,
    },
  },
  linkLineTop: {
    "&:after": {
      content: '""', // eslint-disable-line
      display: "block",
      position: "absolute",
      backgroundColor: ({ linkLineColor }: UseSharedStylesOptions) =>
        linkLineColor,
      width: 1,
      height: "50%",
      bottom: "50%",
      left: 0,
    },
  },
  linkLineBoth: {
    "&:after": {
      content: '""', // eslint-disable-line
      display: "block",
      position: "absolute",
      backgroundColor: ({ linkLineColor }: UseSharedStylesOptions) =>
        linkLineColor,
      width: 1,
      height: "100%",
      left: 0,
    },
  },
});
