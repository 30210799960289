import React from "react";
import { createUseStyles } from "react-jss";
import { ReactSVG } from "react-svg";
import classnames from "classnames";

type StyleProps = {
  url?: string;
  flex?: boolean;
  width?: number | string;
  height?: number | string;
  stroke?: string;
};

const useStyles = createUseStyles({
  svgIcon: {
    "& svg": {
      stroke: ({ stroke }: StyleProps) => (stroke?.length ? stroke : undefined),
    },
    "& svg path": {
      stroke: ({ stroke }: StyleProps) => (stroke?.length ? stroke : undefined),
    },
  },
  iconContainer: {
    display: ({ flex }: StyleProps) => (flex ? "flex" : "inline-block"),
    width: ({ width = "unset" }: StyleProps) => width,
    height: ({ height = "unset" }: StyleProps) => height,
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
  },
});

const applyStylesToElement = (
  element: Element,
  attributeName: string,
  value: string,
) => {
  let child = element.firstElementChild;
  while (child != null) {
    child.setAttribute(attributeName, value);
    if (child.hasChildNodes()) {
      applyStylesToElement(child, attributeName, value);
    }
    child = child.nextElementSibling;
  }
};

const applyStylesToSVG = (
  svg: SVGSVGElement,
  attributeName: string,
  value: string,
) => {
  svg.setAttribute(attributeName, value);
  let child = svg.firstElementChild;
  while (child != null) {
    child.setAttribute(attributeName, value);
    if (child.hasChildNodes()) {
      applyStylesToElement(child, attributeName, value);
    }
    child = child.nextElementSibling;
  }
};

export type IconProps = StyleProps & {
  className?: string;
  icon?: React.ReactNode;
  fill?: string;
  wrapper?: "div" | "span" | "svg";
};

const Icon: React.FC<IconProps> = ({
  url,
  icon,
  stroke,
  fill,
  width,
  height,
  className,
  wrapper = "div",
}) => {
  const classes = useStyles({ url, stroke, width, height });

  if (icon) {
    return (
      <span
        className={classnames([
          classes.iconContainer,
          classes.svgIcon,
          className,
        ])}
      >
        {icon}
      </span>
    );
  }

  if (url) {
    return (
      <ReactSVG
        beforeInjection={(svg) => {
          if (stroke) applyStylesToSVG(svg, "stroke", stroke);
          if (fill) applyStylesToSVG(svg, "fill", fill);
          if (width) svg.setAttribute("width", `${width}`);
          if (height) svg.setAttribute("height", `${height}`);
        }}
        className={classnames([classes.iconContainer, className])}
        evalScripts="always"
        httpRequestWithCredentials={false}
        renumerateIRIElements={false}
        src={url as string}
        useRequestCache={true}
        wrapper={wrapper}
      />
    );
  }

  return null;
};

export default Icon;
