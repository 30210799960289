import { Values } from "@store/ScrollProvider";
import { useEffect } from "react";

const useUpdateSectionStyles = (values: Values) => {
  useEffect(() => {
    const sectionContainer = document.getElementById("section-selectors-bar");
    const sectionLine = document.getElementById("section-line");

    // TODO
    // We shouldn't be straight DOM-referencing nephew components here
    // Things like this should have controllers that deal with this logic in a common ancestor.
    // Components which need style changes should _react_ to these values. Setting them imperatively is ill-advised
    // and defeats the purpose of React.
    if (values) {
      if (values.sectionContainerTop != null && sectionContainer) {
        sectionContainer.style.top = `${values.sectionContainerTop}px`;
      }

      if (values.sectionLineTop != null && sectionLine) {
        sectionLine.style.top = `${values.sectionLineTop}px`;
      }

      if (sectionContainer) {
        sectionContainer.style.transition = values.transition
          ? "300ms top ease"
          : "none";
      }

      if (sectionLine) {
        sectionLine.style.transition = values.transition
          ? "300ms top ease"
          : "none";
      }
    }
  }, [values]);
};

export default useUpdateSectionStyles;
