import { z } from "zod";

import { IDiningOptionEnum } from "./configurationLayouts";

export type GetShoppingCartStatusRequest = {
  barcodeWithoutChecksums: string[];
};

export interface ICartStatus {
  ID: number;
  TransactionBarcodeWithoutChecksum: string;
  dttmCreated: string;
  dttmRequestedDelivery: string;
  Lines: IStatusLineItem[];
  Status: OrderLineStatus;
  TotalGrossSum: number;
}

export interface IReadyForDeliveryAtChannel {
  ID: number;
  Name: string;
  LocalName: string;
}

export interface IStatusLineItem {
  Name: string;
  Status: OrderLineStatus;
  GrossSum: number;
  DiningOption: DiningOption;
  Product: {
    ID: number;
    Name: string;
  };
  ProductModifierChoices?: [
    {
      ProductModifierChoice?: {
        LocalName: string;
        ProductID: number;
      };
    },
  ];
  RelatedTransactionLineID: number;
  ReadyForDeliveryAtChannel: IReadyForDeliveryAtChannel;
}

export enum ShoppingCartOnHoldReason {
  Unknown = 0,
  None = 1,
  Draft = 2,
  Locked = 3,
  OpenInvoice = 4,
}

export const ShoppingCartOnHoldReasonSchema = z.nativeEnum(
  ShoppingCartOnHoldReason,
);

export enum OrderStatus {
  Unknkown = 0,
  Open = 1,
  OnHold = 2,
  Cancelled = 3,
  Completed = 4,
  Committing = 5,
  Refunded = 10,
}

export const OrderStatusSchema = z.nativeEnum(OrderStatus);

export enum OrderLineStatus {
  Unknown = 0,
  New = 1,
  Accepted = 2,
  Rejected = 3,
  Cancelled = 4,
  Prepared = 5,
  ReadyForDelivery = 6,
  Delivered = 7,
  Refunded = 8,
}
export const OrderLineStatusSchema = z.nativeEnum(OrderLineStatus);

export type GetOrdersStatusRequest = {
  barcodeWithoutChecksums: string[];
};

export const ReadyForDeliveryAtChannelSchema = z.object({
  ID: z.number(),
  Name: z.string(),
  LocalName: z.string(),
});
export type ReadyForDeliveryAtChannel = z.infer<
  typeof ReadyForDeliveryAtChannelSchema
>;

export const GetOrdersStatusSummaryLineSchema = z.object({
  ID: z.number(),
  KitchenOrderID: z.number().nullable(),
  Name: z.string(),
  Status: OrderLineStatusSchema,
  ReadyForDeliveryAtChannel: ReadyForDeliveryAtChannelSchema.nullable(),
  dttmAccepted: z.string().nullable(),
  dttmLastStatusChange: z.string().nullable(),
  dttmReadyForDelivery: z.string().nullable(),
});

export type GetOrdersStatusSummaryLine = z.infer<
  typeof GetOrdersStatusSummaryLineSchema
>;

export const DiningOptionSchema = z.object({
  ID: z.nativeEnum(IDiningOptionEnum),
  Name: z.string(),
  LocalName: z.string(),
});

export type DiningOption = z.infer<typeof DiningOptionSchema>;

export const OrdersStatusSummarySchema = z.object({
  TransactionID: z.number(),
  Status: OrderStatusSchema,
  ShoppingCartOnHoldReason: ShoppingCartOnHoldReasonSchema,
  BarcodeWithoutChecksum: z.string(),
  QueueNumber: z.string(),
  TotalGrossSum: z.number(),
  DiningOption: DiningOptionSchema,
  dttmRequestedDelivery: z.string().nullable(),
  dttmCreated: z.string(),
  dttmCreatedUTC: z.string(),
  dttmReadyForDelivery: z.string().nullable(),
  dttmLastStatusChange: z.string().nullable(),
  Lines: z.array(GetOrdersStatusSummaryLineSchema),
});

export type OrdersStatusSummary = z.infer<typeof OrdersStatusSummarySchema>;

const GetOrdersStatusSummaryResponseSchema = z.array(OrdersStatusSummarySchema);

export type GetOrdersStatusSummaryResponse = z.infer<
  typeof GetOrdersStatusSummaryResponseSchema
>;
