import React from "react";
import GenericModal from "@base/components/Global/GenericModal";
import MenuSearchMobile from "@base/components/Modals/MenuSearch/MenuSearchMobile";
import { scrollToSection } from "@store/ProductsProvider/helper";

const SearchModalMobileWrapper: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const handleClose = (id?: number) => {
    onClose();

    if (id) {
      setTimeout(() => scrollToSection(id), 100);
    }
  };

  return (
    <GenericModal
      isOpen={isOpen}
      closeModal={handleClose}
      isFullWidth
      customZIndex={80}
    >
      <MenuSearchMobile closeModal={handleClose} />
    </GenericModal>
  );
};

export default SearchModalMobileWrapper;
