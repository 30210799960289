import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { TheatreResource } from "@api/controller/theatreResource";
import { isPresent } from "@apl-digital/utils";
import GenericModal from "@base/components/Global/GenericModal";
import { useAppDispatch } from "@store";
import { getTheatreResource } from "@store/theatreResource";

import QRCodeScannerModalContent, {
  QRCodeScannerModalContentProps,
} from "./QRCodeScannerModalContent";

const useStyles = createUseStyles(({ color }) => ({
  outerContainer: {
    background: color.modalQrScannerOuterContainerBg,
  },
}));

type TheatreResourceScannerModalProps = {
  isOpen: boolean;
  onAdded?: (value: string) => void;
  onClose: () => void;
  switchToManual?: () => void;
};

export const TheatreResourceScannerModal: React.FC<
  TheatreResourceScannerModalProps
> = ({ isOpen, onAdded, onClose, switchToManual }) => {
  const classes = useStyles();
  const { t } = useTranslation("global");
  const dispatch = useAppDispatch();

  const validateQrCode: QRCodeScannerModalContentProps["onValidate"] = async (
    value: string,
  ) => {
    let scanData = value;

    while (scanData && scanData.length !== 0 && !scanData.startsWith("http")) {
      scanData = scanData.slice(1);
    }

    const url: URL = new URL(scanData);
    const currentHost = window.location.host;

    if (currentHost != url.host) {
      return {
        isValid: false,
        message: t("TheatreResourceScannerModal.errorMessages.invalidUrl"),
      } as const;
    }

    const tableCode = url.pathname.split("/").at(2);

    if (!isPresent(tableCode)) {
      console.error("Failed to read table code from URL", url);

      return {
        isValid: false,
        message: t(
          "TheatreResourceScannerModal.errorMessages.failedToParseTheatreResourceCode",
        ),
      } as const;
    }

    return TheatreResource.getByBarcode({
      barcodeWithoutChecksum: tableCode,
    })
      .then((response) => {
        if (response.isResponseOk) {
          return {
            isValid: true,
            value: tableCode,
          } as const;
        }

        return {
          isValid: false,
          message: t(
            "TheatreResourceScannerModal.errorMessages.theatreResourceNotFound",
          ),
        } as const;
      })
      .catch(() => {
        return {
          isValid: false,
          message: t(
            "TheatreResourceScannerModal.errorMessages.theatreResourceNotFound",
          ),
        } as const;
      });
  };

  const onScanned: QRCodeScannerModalContentProps["onScanned"] = (value) => {
    dispatch(getTheatreResource({ barcodeWithoutChecksum: value })).then(() => {
      onAdded?.(value);
    });
  };

  return (
    <GenericModal
      customZIndex={98}
      isOpen={isOpen}
      closeModal={onClose}
      isFullWidth
      override={classes.outerContainer}
    >
      <QRCodeScannerModalContent
        onValidate={validateQrCode}
        onScanned={onScanned}
        onClose={onClose}
        switchToManual={switchToManual}
      />
    </GenericModal>
  );
};
