import React from "react";
import { createUseStyles } from "react-jss";
import PageWrapper from "@base/components/Global/PageWrapper";
import SidebarWrapper from "@base/components/Global/SidebarWrapper";
import CartSection from "@pages/HomePage/components/CartSection";
import { useAppSelector } from "@store";
import {
  selectActiveOrdersWithGrouping,
  selectInactiveOrdersWithGrouping,
} from "@store/orders";

import OngoingOrdersBanner from "./components/OngoingOrdersBanner";
import { OrderCardDesktop } from "./components/OrderCardDesktop";
import PreviousOrdersBanner from "./components/PreviousOrdersBanner";
import { useOrderStatusPageContext } from "./OrderStatusPageContext";

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    display: "flex",
    margin: [spacing.x5l, 0],
    flexWrap: "wrap",
    gap: spacing.xxl,
  },
}));

const DesktopOrderStatusPage = () => {
  const classes = useStyles();

  const { selectedOrderId } = useOrderStatusPageContext();

  const activeOrders = useAppSelector((state) =>
    selectActiveOrdersWithGrouping(state, selectedOrderId),
  );
  const inactiveOrders = useAppSelector((state) =>
    selectInactiveOrdersWithGrouping(state, selectedOrderId),
  );

  return (
    <PageWrapper>
      <div style={{ marginTop: 54 }}>
        <SidebarWrapper
          renderMain={() => (
            <>
              {activeOrders.length > 0 && (
                <>
                  <OngoingOrdersBanner />
                  <div className={classes.container}>
                    {activeOrders.map((order, index) => (
                      <OrderCardDesktop
                        key={order.state.BarcodeWithoutChecksum}
                        order={order}
                        preferredVariant={index === 0 ? "row" : "column"}
                        isSelected={
                          order.state.BarcodeWithoutChecksum === selectedOrderId
                        }
                      />
                    ))}
                  </div>
                </>
              )}
              {inactiveOrders.length > 0 && (
                <>
                  <PreviousOrdersBanner />
                  <div className={classes.container}>
                    {inactiveOrders.map((order, index) => (
                      <OrderCardDesktop
                        key={order.state.BarcodeWithoutChecksum}
                        order={order}
                        preferredVariant={index === 0 ? "row" : "column"}
                        isSelected={
                          order.state.BarcodeWithoutChecksum === selectedOrderId
                        }
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
          renderSidebar={() => (
            <>
              <CartSection />
            </>
          )}
        />
      </div>
    </PageWrapper>
  );
};

export default DesktopOrderStatusPage;
