import React from "react";
import { Modal } from "@base/components/Global/Modal";
import { useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import { selectShoppingCart } from "@store/shoppingCart";

import { DeliveryMethodModalContent } from "./DeliveryMethodModalContent";

const DeliveryMethodModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const shoppingCart = useAppSelector(selectShoppingCart);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={hasData(shoppingCart)}
    >
      <DeliveryMethodModalContent onCreateCart={onClose} />
    </Modal>
  );
};

export default DeliveryMethodModal;
