import { commonDarkTheme } from "@theme/themes/common.dark";
import { DefaultTheme } from "react-jss";

export const apolloCinemaLTDarkTheme: DefaultTheme = {
  ...commonDarkTheme,
  blobStorage: {
    ...commonDarkTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/dark/logo/logo-apollokinas.svg",
    markusLogo:
      "https://assets.markus.live/00-generic-app/logo/markus_footer_lightgray-color_130x46.svg",
    burgerIcon:
      "https://assets.markus.live/03-order-app/dark/icons/topbar/TopBar_Hamburger_default.svg",
    burgerActiveIcon:
      "https://assets.markus.live/03-order-app/dark/icons/topbar/TopBar_Hamburger_active.svg",
    amountMinusActiveIcon:
      "https://assets.markus.live/03-order-app/dark/media/amount-minus-active.svg",
    amountPlusActiveIcon:
      "https://assets.markus.live/03-order-app/dark/media/amount-plus-active.svg",
    closeModalIcon:
      "https://assets.markus.live/03-order-app/dark/media/close.svg",
    closeIcon:
      "https://assets.markus.live//03-order-app/dark/icons/generic-icons/Close.svg",
    cartIcon:
      "https://assets.markus.live//03-order-app/dark/icons/generic-icons/Shopping_Cart.svg",
    qrIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/QR.svg",
    scanQrIcon:
      "https://assets.markus.live//03-order-app/dark/icons/generic-icons/Scan_QR.svg",
    validFieldIcon:
      "https://assets.markus.live/03-order-app/dark/media/valid_field_green.svg",
    extraChoiceIcon:
      "https://assets.markus.live/03-order-app/dark/media/extra_choice.svg",
    failIcon: "https://assets.markus.live/03-order-app/dark/media/fail.svg",
    commentIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Edit_Comment.svg",
    emptyRadioSmIcon:
      "https://assets.markus.live/03-order-app/dark/media/empty_radio_sm.svg",
    locationIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Location.svg",
    triangleIconActive:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Caution_Small_active.svg",
    languageIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Language_Change_2.svg",
    dropMenuIcon:
      "https://assets.markus.live//03-order-app/dark/icons/generic-icons/Expand.svg",
    magnifyingGlassIcon:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Search_Small.svg",
    showIcon:
      "https://assets.markus.live//03-order-app/dark/icons/generic-icons/Show_Preview.svg",
    hideIcon:
      "https://assets.markus.live//03-order-app/dark/icons/generic-icons/Hide_Preview.svgg",
    trashIcon:
      "https://assets.markus.live//03-order-app/dark/icons/generic-icons/Delete.svg",
    logoutIcon:
      "https://assets.markus.live//03-order-app/dark/icons/generic-icons/Sign_Out.svg",
    smallLoyaltyClubCardIcon:
      "https://assets.markus.live/00-generic-app/media/ApolloClubCardSmall_74x48.svg",
    burgerMenuLinks: [
      "https://assets.markus.live/00-generic-app/icons/logo/ApolloClub24.svg",
    ],
  },
  config: {
    ...commonDarkTheme.config,
    brandLogoWidth: 104,
    brandLogoHeight: 40,
    sectionsColors: "#ff8200;#3f5b56;#ff78b6;#e52530;#4b3f47",
  },
  color: {
    ...commonDarkTheme.color,
    productRowFeaturedBg: "#FF8200",
  },
} as const;
