import React from "react";
import useWindowSize from "@base/hooks/useWindowSize";

import DesktopOrderStatusPage from "./DesktopOrderStatusPage";
import MobileOrderStatusPage from "./MobileOrderStatusPage";
import { OrderStatusPageContextProvider } from "./OrderStatusPageContext";

const OrderStatusPageWrapper = () => {
  const { isMdAndUp } = useWindowSize();

  return (
    <OrderStatusPageContextProvider>
      {isMdAndUp ? <DesktopOrderStatusPage /> : <MobileOrderStatusPage />}
    </OrderStatusPageContextProvider>
  );
};

export default OrderStatusPageWrapper;
