import { isPresent } from "@apl-digital/utils";
import { clearStorage, setStorage, StorageKey } from "@constants/storage";
import { AppStartListening } from "@store/listenerMiddleware";
import { add } from "date-fns";
import { deepEqual } from "fast-equals";

import { clearFormContactInfo, selectFormContactInfo } from "./slice";

export const addContactInfoListeneres = (startListening: AppStartListening) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      !deepEqual(
        selectFormContactInfo(previousState),
        selectFormContactInfo(currentState),
      ),
    effect: (_action, { getState }) => {
      const contactInfo = selectFormContactInfo(getState());

      if (isPresent(contactInfo)) {
        setStorage(StorageKey.CONTACT_INFO, contactInfo, {
          type: "expiringRecord",
          expiresAt: add(Date.now(), { days: 182 }).getTime(),
        });
      } else {
        clearStorage(StorageKey.CONTACT_INFO);
      }
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      isPresent(previousState.user.userData) &&
      !isPresent(currentState.user.userData),
    effect: (_action, { dispatch }) => {
      dispatch(clearFormContactInfo());
    },
  });
};
