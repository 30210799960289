import { request } from "@api";
import {
  GetProductLayoutsRequest,
  IProductLayoutResponse,
} from "@api/interfaces/productLayouts";
import { ApiPath } from "@api/paths";
import { ApiRequest, RequestResponse } from "@api/types";

const getProductLayouts: ApiRequest<
  RequestResponse<IProductLayoutResponse>,
  GetProductLayoutsRequest
> = async ({ productLayoutID, customerPersonId, diningOptionId, ...options }) =>
  request({
    ...options,
    api: ApiPath.Product.getProductLayouts({
      productLayoutID,
      customerPersonId,
      diningOptionId,
    }),
  });

const ProductLayouts = {
  getProductLayouts,
};

export { ProductLayouts };
