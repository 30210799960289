import React from "react";
import { createUseStyles } from "react-jss";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { useProducts } from "@store/ProductsProvider";
import { ProductListItemType } from "@store/ProductsProvider/types";
import classNames from "classnames";

const useStyles = createUseStyles(({ color, font, borderRadius, spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: spacing.s,
  },
  wrapContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: spacing.xxl,
  },
  section: {
    padding: [spacing.l, spacing.xxl],
    width: "100%",
    height: 90,
    borderRadius: borderRadius.l,
    position: "relative",
    cursor: "pointer",

    "@media (hover: hover)": {
      "&:hover": {
        transform: "scale(1.03)",
        boxShadow: color.boxShadowGlobal,
      },
    },

    transitionProperty: ["box-shadow", "transform"],
    transitionDuration: "150ms",
    transitionTimingFunction: "ease-out",
  },
  wrapSection: {
    width: `calc(50% - ${spacing.xxl / 2}px)`,
  },
  title: {
    fontWeight: font.weight.m,
    fontSize: font.size.xl,
    lineHeight: font.lineHeight.m,
  },
  picture: {
    bottom: 0,
    right: 0,
    position: "absolute",
    width: 84,
    height: 84,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderTopLeftRadius: borderRadius.l,
    borderBottomRightRadius: borderRadius.l,
  },
  categories: {
    fontSize: font.size.xxs,
    fontWeight: font.weight.m,
    maxWidth: "80%",
    height: "100%",
    display: "block",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    overflow: "hidden",
    maxHeight: "3.6em",
    lineHeight: "1.4em",
    marginTop: 6,
    paddingRight: spacing.x4l,
    "& span": {
      display: "inline-flex",
    },
  },
  dot: {
    padding: [0, spacing.xs],
    color: color.searchMenuDot,
  },
  titleContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: color.searchCategoriesText,
  },
}));

type Props = {
  closeModal: (id: number) => void;
  wrap?: boolean;
};

const SearchCategoriesList: React.FC<Props> = ({
  closeModal,
  wrap = false,
}) => {
  const classes = useStyles();
  const [{ sections }] = useProducts();

  if (!sections) return null;

  return (
    <div
      className={classNames(classes.container, {
        [classes.wrapContainer]: wrap,
      })}
    >
      {sections.map((section, idx) => (
        <div
          className={classNames(classes.section, {
            [classes.wrapSection]: wrap,
          })}
          key={idx}
          style={{
            backgroundColor: section.bgColor,
          }}
          onClick={() => closeModal(section.id)}
        >
          <div className={classes.titleContainer}>
            <div className={classes.title}>{section.title}</div>
            <div className={classes.categories}>
              {section.items
                ?.filter((item) => item.type === ProductListItemType.CATEGORY)
                .map((item, catIdx, arr) => (
                  <span key={catIdx}>
                    {item.title.charAt(0).toUpperCase() +
                      item.title.slice(1).toLowerCase()}
                    {catIdx !== arr.length - 1 && (
                      <span className={classes.dot}>{"\u2022"}</span>
                    )}
                  </span>
                ))}
            </div>
          </div>
          <div
            className={classes.picture}
            style={{
              backgroundImage: `url(${getImageFromAPI(section.picture)})`,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default SearchCategoriesList;
