import config from "@constants/config";

export const getImageFromAPI = (
  uri?: string,
  width = 500,
  height = 500,
  format?: string,
): string => {
  if (!uri) return "";

  const imageAPI = config.assetConfig.images.resizerServiceUrl;

  const newUrl = new URL(`${imageAPI}/${uri.replace("https://", "")}`);

  newUrl.searchParams.append("width", width.toString());
  newUrl.searchParams.append("height", height.toString());

  if (format) {
    newUrl.searchParams.append("format", format);
  }

  return newUrl.toString();
};
