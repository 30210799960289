import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from "@reduxjs/toolkit";

import { addContactInfoListeneres } from "./contactInfo/listeners";
import { addOrdersListeneres } from "./orders";
import { addPaymentListeners } from "./payment/listeners";
import { addShoppingCartListeneres } from "./shoppingCart";
import { RootState } from "./slices";
import { AppDispatch } from "./store";
import { addTheatreResourceListeneres } from "./theatreResource";
import { addUserListeneres } from "./user";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

export default listenerMiddleware.middleware;

addUserListeneres(startAppListening);
addShoppingCartListeneres(startAppListening);
addContactInfoListeneres(startAppListening);
addTheatreResourceListeneres(startAppListening);
addOrdersListeneres(startAppListening);
addPaymentListeners(startAppListening);
