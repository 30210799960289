import React from "react";
import { createUseStyles } from "react-jss";

import Button from "./Button";

const useStyles = createUseStyles(({ color }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  hint: {
    color: color.retryHintTextColor,
  },
}));

type RetryProps = {
  hintText: string;
  buttonText: string;
  onRetry: () => void;
};

export const Retry: React.FC<RetryProps> = ({
  hintText,
  buttonText,
  onRetry,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p className={classes.hint}>{hintText}</p>
      <Button action={onRetry} fitContent>
        {buttonText}
      </Button>
    </div>
  );
};
