import { deleteRequest, post, put, request } from "@api";
import {
  AddProductToCartRequest,
  BindContactInfoRequest,
  BundleRequest,
  GetShoppingCartByBarcodeRequest,
  ICartLayout,
  ICartLineItem,
  InitializeDiningCartRequest,
  PutOnHoldRequest,
  RemoveAllProductsFromCartRequest,
  RemoveCustomerFromCartRequest,
  RemoveLineFromCartRequest,
  RemoveProductFromCartRequest,
  UnbundleRequest,
  UpdateCustomerCartRequest,
  UpdateLanguageRequest,
  UpdateTheatreResourceRequest,
} from "@api/interfaces/cartLayouts";
import { ApiPath } from "@api/paths";
import { ApiRequest, RequestResponse } from "@api/types";

const getShoppingCartByBarcode: ApiRequest<
  RequestResponse<ICartLayout>,
  GetShoppingCartByBarcodeRequest
> = async ({ transactionId, ...options }) =>
  request({
    ...options,
    api: ApiPath.Cart.getShoppingCart(transactionId),
    canFail: true,
  });

const addProductToCart: ApiRequest<
  RequestResponse<ICartLineItem[]>,
  AddProductToCartRequest
> = async ({ transactionId, ...options }) =>
  post({
    ...options,
    api: ApiPath.Cart.addToCart(transactionId),
    canFail: true,
  });

const removeProductFromCart: ApiRequest<
  RequestResponse<ICartLineItem>,
  RemoveProductFromCartRequest
> = async ({ productId, transactionId, ...options }) =>
  deleteRequest({
    ...options,
    api: ApiPath.Cart.removeProductFromCart(productId, transactionId),
  });

const removeAllProductsFromCart: ApiRequest<
  RequestResponse<void>,
  RemoveAllProductsFromCartRequest
> = async ({ transactionId, ...options }) =>
  deleteRequest({
    ...options,
    api: ApiPath.Cart.removeAllProductsFromCart(transactionId),
    shouldParseResponse: false,
  });

const removeLinesFromCart: ApiRequest<
  RequestResponse<void>,
  RemoveLineFromCartRequest
> = async ({ cartLineIds, transactionId, ...options }) =>
  deleteRequest({
    ...options,
    api: ApiPath.Cart.removeLinesFromCart(cartLineIds, transactionId),
    shouldParseResponse: false,
  });

const bundle: ApiRequest<RequestResponse<ICartLayout>, BundleRequest> = async ({
  transactionId,
  ...options
}) =>
  put({
    ...options,
    api: ApiPath.Cart.bundle(transactionId),
  });

const updateCustomerCart: ApiRequest<
  RequestResponse<ICartLayout>,
  UpdateCustomerCartRequest
> = async ({ body, transactionId, ...options }) =>
  put({
    ...options,
    api: ApiPath.Cart.updateCustomerCart(transactionId),
    body,
  });

const unbundle: ApiRequest<
  RequestResponse<ICartLayout>,
  UnbundleRequest
> = async ({ transactionId, ...options }) =>
  put({
    ...options,
    api: ApiPath.Cart.unbundle(transactionId),
  });

const updateTheatreResource: ApiRequest<
  RequestResponse<ICartLayout>,
  UpdateTheatreResourceRequest
> = async ({ theatreResourceId, transactionId, ...options }) =>
  put({
    ...options,
    api: ApiPath.Cart.theatreResource(theatreResourceId, transactionId),
  });

const removeCustomerFromCart: ApiRequest<
  RequestResponse<ICartLayout>,
  RemoveCustomerFromCartRequest
> = async ({ transactionId, ...options }) =>
  deleteRequest({
    ...options,
    api: ApiPath.Cart.removeCustomerFromCart(transactionId),
  });

const bindContactInfo: ApiRequest<
  RequestResponse<void>,
  BindContactInfoRequest
> = async ({ transactionId, ...options }) =>
  put({
    ...options,
    api: ApiPath.Cart.bindContactInfo(transactionId),
  });

const initializeDiningCart: ApiRequest<
  RequestResponse<ICartLayout>,
  InitializeDiningCartRequest
> = async ({
  theatreResourceId,
  dttmRequestedDelivery,
  diningOptionId,
  customerPersonId,
  ...options
}) =>
  post({
    ...options,
    api: ApiPath.Cart.initializeDiningCart({
      theatreResourceId,
      dttmRequestedDelivery,
      diningOptionId,
      customerPersonId,
    }),
  });

const updateLanguage: ApiRequest<
  RequestResponse<void>,
  UpdateLanguageRequest
> = async ({ transactionId, ...options }) =>
  put({
    ...options,
    api: ApiPath.Cart.updateLanguage(transactionId),
  });

const putOnHold: ApiRequest<RequestResponse<void>, PutOnHoldRequest> = async ({
  transactionId,
  ...options
}) =>
  post({
    ...options,
    api: ApiPath.Cart.putOnHold(transactionId),
  });

const ShoppingCart = {
  getShoppingCartByBarcode,
  addProductToCart,
  removeProductFromCart,
  removeAllProductsFromCart,
  removeLinesFromCart,
  bundle,
  updateCustomerCart,
  unbundle,
  updateTheatreResource,
  removeCustomerFromCart,
  bindContactInfo,
  initializeDiningCart,
  updateLanguage,
  putOnHold,
};

export { ShoppingCart };
