import { ECheckoutOptionType } from "@api/interfaces/cartLayouts";
import { isPresent } from "@apl-digital/utils";
import { selectCurrentBarcodeWithoutChecksum } from "@store/shoppingCart";
import { RootState } from "@store/slices";
import { isStatus } from "@store/utils";

import { selectPaymentCallback } from "./slice";

export const selectShouldShowPaymentError = (
  state: RootState,
  barcodeWithoutChecksum: string,
  checkoutOptionType?: ECheckoutOptionType,
) => {
  const paymentCallback = selectPaymentCallback(state);

  if (!isStatus(paymentCallback, "completed")) {
    return false;
  }

  if (paymentCallback.state.barcodeWithoutChecksum !== barcodeWithoutChecksum) {
    return false;
  }

  if (
    isPresent(checkoutOptionType) &&
    paymentCallback.state.checkoutOptionType !== checkoutOptionType
  ) {
    return false;
  }

  return (
    paymentCallback.state.type === "error" ||
    paymentCallback.state.type === "cancelled"
  );
};

export const selectShouldShowPaymentErrorForCurrentCart = (
  state: RootState,
  checkoutOptionType?: ECheckoutOptionType,
) => {
  const barcodeWithoutChecksum = selectCurrentBarcodeWithoutChecksum(state);

  if (!barcodeWithoutChecksum) {
    return false;
  }

  return selectShouldShowPaymentError(
    state,
    barcodeWithoutChecksum,
    checkoutOptionType,
  );
};
