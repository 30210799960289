import React, { createContext, useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { ProductLayouts } from "@api/controller/productLayouts";
import { IProduct } from "@api/interfaces/productLayouts";
import { isPresent } from "@apl-digital/utils";
import useEffectAsync from "@base/hooks/useEffectAsync";
import { useSalesPointContext } from "@context/SalesPointProvider";
import { useAppSelector } from "@store/hooks";
import {
  ProductListItem,
  ProductListItemType,
  ProductsContextProps,
  ProductsProviderProps,
} from "@store/ProductsProvider/types";
import { selectCurrentDiningOption } from "@store/shoppingCart";
import { selectUserData } from "@store/user";
import { getBrandTheme } from "@theme";

import { getProductListItems, getSections } from "./helper";
import {
  DEFAULT_ACTIONS,
  DEFAULT_STATE,
  ProductsAction,
  productsReducer,
} from "./ProductsReducer";

const ProductsContext = createContext<ProductsContextProps>([
  DEFAULT_STATE,
  DEFAULT_ACTIONS,
]);

/**
 * @deprecated New functionality shouldn't use this hook. Please convert this to a store instead.
 */
const useProducts = () => useContext(ProductsContext);

/**
 * @deprecated New functionality shouldn't use this provider.
 */
const ProductsProvider: React.FC<ProductsProviderProps> = ({ children }) => {
  const userData = useAppSelector(selectUserData);
  const { isLoading } = useAuth();
  const diningOption = useAppSelector(selectCurrentDiningOption);
  const [state, dispatch] = useReducer(productsReducer, {
    ...DEFAULT_STATE,
  });
  const [{ configuration: salesPointConfiguration }] = useSalesPointContext();
  const { i18n } = useTranslation();
  const { config } = getBrandTheme();

  const layoutID = salesPointConfiguration.ProductSalesLayoutId;

  const updateDataLayout = async () => {
    if (!isPresent(diningOption) || isLoading) {
      if (process.env.NODE_ENV === "development")
        console.warn("Unable to update product layout", {
          diningOption: diningOption?.ID,
          isLoading,
        });

      return;
    }

    const productLayoutResponse = await ProductLayouts.getProductLayouts({
      productLayoutID: layoutID,
      customerPersonId: userData?.ID,
      diningOptionId: diningOption.ID,
    });
    if (productLayoutResponse.isResponseOk) {
      const rawData = {
        ...productLayoutResponse.response,
        Products: productLayoutResponse.response.Products.filter(
          (product) => product.IsProductEnabledForSelectedDiningOption === true,
        ),
      };

      const sections = getSections(rawData, config.sectionsColors.split(";"));
      const products = getProductListItems(rawData);

      dispatch(
        ProductsAction.setRawData({
          ...rawData,
          Products: rawData.Products.filter(
            (product) =>
              product.IsProductEnabledForSelectedDiningOption === true,
          ),
        }),
      );
      dispatch(
        ProductsAction.setRawProducts(productLayoutResponse.response.Products),
      );
      dispatch(ProductsAction.setSections(sections));
      dispatch(ProductsAction.setProducts(products));
    }
  };

  useEffectAsync(async () => {
    const isUserEmpty = Boolean(!state.rawData && !userData);

    const isNewUserIsEmpty = Boolean(
      state.rawData &&
        userData?.ID === undefined &&
        state.rawData?.userId !== 0,
    );

    const isNewUserIsDifferent = Boolean(
      userData && userData?.ID && state.rawData?.userId !== userData?.ID,
    );

    const hasValidProps = Boolean(layoutID && i18n.language);

    if (
      (isUserEmpty || isNewUserIsEmpty || isNewUserIsDifferent) &&
      hasValidProps
    ) {
      await updateDataLayout();
    }
  }, [layoutID, userData, diningOption, i18n.language, isLoading]);

  const getRawProductById = (id: number): IProduct | undefined =>
    state?.rawProducts?.find((p: IProduct) => p.ID === id);

  const getRawProductsByIds = (ids: number[]): IProduct[] | undefined =>
    state?.rawProducts?.filter((p: IProduct) => ids.includes(p.ID));

  const getProductById = (id: number): ProductListItem | undefined =>
    state?.products?.find((p: ProductListItem) => p.id === id);

  const getProductsByIds = (ids: number[]): ProductListItem[] | undefined =>
    state?.products?.filter((c: ProductListItem) => ids.includes(c.id));

  const getCrossSellProducts = (): ProductListItem[] | undefined =>
    state?.products?.filter((p: ProductListItem) => p.isCrossSells);

  const getAllProductsInSections = (): ProductListItem[] | undefined => {
    const productIdsSet = new Set<number>([]);

    if (state?.sections) {
      const addItem = (product: ProductListItem) => {
        if (
          product.type === ProductListItemType.ITEM ||
          product.type === ProductListItemType.VARIABLE
        ) {
          productIdsSet.add(product.id);
        } else if (product?.items && product.items.length > 0) {
          product.items.forEach(addItem);
        }
      };

      state.sections.forEach((section) => {
        if (section?.items && section.items.length > 0) {
          section.items.forEach(addItem);
        }
      });
    }

    const productIds = Array.from(productIdsSet);

    if (productIds.length > 0) {
      return getProductsByIds(productIds);
    }

    return undefined;
  };

  return (
    <ProductsContext.Provider
      value={[
        state,
        {
          getAllProductsInSections,
          getRawProductById,
          getRawProductsByIds,
          getProductById,
          getProductsByIds,
          getCrossSellProducts,
        },
      ]}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;
export { useProducts };
