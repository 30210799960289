import {
  clearZodStorage,
  setZodStorage,
  ZodLocalStorageKey,
} from "@constants/storage";
import { AppStartListening } from "@store/listenerMiddleware";
import { deepEqual } from "fast-equals";

import { selectNoneExpiredOrders } from "./selectors";
import { selectOrders } from "./slice";

export const addOrdersListeneres = (startListening: AppStartListening) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      !deepEqual(selectOrders(previousState), selectOrders(currentState)),
    effect: (_action, { getState }) => {
      const orders = selectNoneExpiredOrders(getState());

      if (orders.length > 0) {
        setZodStorage(ZodLocalStorageKey.ORDERS, orders);
      } else {
        clearZodStorage(ZodLocalStorageKey.ORDERS);
      }
    },
  });
};
