import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "@base/components/Global/Icon";
import config from "@constants/config";
import { getBlobStorage } from "@theme";
import { setLanguage } from "@translation";

import { MenuItem } from "./BurgerMenuItem";
import { useSharedStyles } from "./shared.style";

export const BurgerMenuLanguage: React.FC = () => {
  const sharedClasses = useSharedStyles();
  const { t, i18n } = useTranslation();

  const { emptyRadioSmIcon, extraChoiceIcon, languageIcon } = getBlobStorage();

  return (
    <MenuItem
      label={t("burgermenu_main_settings")}
      showSubMenu={true}
      icon={languageIcon}
    >
      {config.appConfig.availableLanguages.map((language) => (
        <div
          className={sharedClasses.menuItemSubMenu}
          onClick={async () => {
            await setLanguage(language);
          }}
          key={language}
          id={`language-${language}`}
        >
          {language === i18n.language ? (
            <Icon url={extraChoiceIcon} height={24} width={24} />
          ) : (
            <Icon url={emptyRadioSmIcon} height={24} width={24} />
          )}
          {/* @ts-ignore */}
          {t(`global_language_${language}`)}
        </div>
      ))}
    </MenuItem>
  );
};
