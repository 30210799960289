import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Icon from "@base/components/Global/Icon";
import useOnClickOutside from "@base/hooks/useOnClickOutside";
import useWindowSize from "@base/hooks/useWindowSize";
import config from "@constants/config";
import { getBlobStorage } from "@theme";
import { setLanguage } from "@translation";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

const languageDropdownStyles = createUseStyles(
  ({
    config: themeConfig,
    borderRadius,
    color,
    spacing,
    font,
    mediaQuery,
  }) => ({
    language: {
      display: "flex",
      color: color.greyCoal,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      width: 48,
      minHeight: themeConfig.mobileNavbarHeight,
      cursor: "pointer",

      [mediaQuery.md]: {
        minHeight: themeConfig.desktopNavbarHeight,
      },
    },
    languageDefaultPositioning: {
      position: "relative",
    },
    languageArrow: {
      display: "flex",
      width: 38,
      height: 38,
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      color: color.languageDropdownButtonText,
      borderRadius: borderRadius.x3l,

      [mediaQuery.md]: {
        width: 60,
      },
    },
    currentLanguage: {
      fontWeight: font.weight.m,
      marginLeft: spacing.xs,
      color: color.languageDropdownSelectedButtonText,
    },
    languageSelection: {
      display: "flex",
      listStyleType: "none",
      color: color.languageDropdownButtonText,
      backgroundColor: color.languageDropdownButtonBg,
      justifyContent: "center",
      alignItems: "center",
      "-webkit-align-items": "center",
      borderRadius: borderRadius.x3l,
      width: 34,
      height: 34,
      transition: "300ms ease",
      "&:hover": {
        backgroundColor: color.languageDropdownButtonHoverBg,
        color: color.languageDropdownButtonHoverText,
      },
    },
    selectedLanguage: {
      backgroundColor: color.languageDropdownSelectedLanguageBg,
      color: color.languageDropdownSelectedButtonText,
    },
    languageSelectionContainer: {
      paddingLeft: 0,
      "& > li": {
        marginBottom: spacing.s,
      },
      "& > li:last-child": {
        marginBottom: 0,
      },
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    languageDropdownContainer: {
      position: "absolute",
      left: 0,
      top: themeConfig.mobileNavbarHeight,

      [mediaQuery.md]: {
        top: themeConfig.desktopNavbarHeight,
      },
    },
    languageDropdown: {
      display: "flex",
      minWidth: 48,
      borderBottomRightRadius: borderRadius.x2l,
      borderBottomLeftRadius: borderRadius.x2l,
      justifyContent: "center",
      paddingBottom: spacing.s,
    },
    languageDropdownWithBackground: {
      backgroundColor: color.languageDropdownBg,
      width: 50,
    },
    languageWithBackground: {
      borderTopRightRadius: borderRadius.x2l,
      borderTopLeftRadius: borderRadius.x2l,
      width: 50,

      "&$open": {
        backgroundColor: color.languageDropdownBg,
      },

      [mediaQuery.md]: {
        backgroundColor: "transparent",
      },
    },
    open: {},
  }),
);

type Props = {
  showCurrentLanguage?: boolean;
  shouldOpenFirst?: boolean;
  contrastMode?: boolean;
  className?: string;
};

const LanguageDropdown: React.FC<Props> = ({
  showCurrentLanguage,
  className,
  shouldOpenFirst,
  contrastMode,
}) => {
  const classes = languageDropdownStyles();
  const { i18n } = useTranslation();
  const { languageIcon } = getBlobStorage();
  const { isMdAndUp } = useWindowSize();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(shouldOpenFirst);
  const [shouldClose, setShouldClose] = useState(shouldOpenFirst);
  const languageDropdownRef = useRef<HTMLDivElement>(null);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: -20,
      overflow: "hidden",
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const openLanguageDropdown = () => {
    setIsDropdownOpen(true);
  };

  const closeLanguageDropdown = () => {
    setIsDropdownOpen(false);
  };

  const toggleLanguageDropdown = () => {
    if (isDropdownOpen) {
      closeLanguageDropdown();
    } else {
      openLanguageDropdown();
    }
  };

  useEffect(() => {
    let closeDropdownTimer: NodeJS.Timeout | undefined;
    if (shouldOpenFirst && shouldClose) {
      closeDropdownTimer = setTimeout(() => {
        closeLanguageDropdown();
      }, 2000);
      setShouldClose(false);
    }

    if (!isDropdownOpen && closeDropdownTimer) {
      clearTimeout(closeDropdownTimer);
    }

    return () => clearTimeout(closeDropdownTimer);
  }, [isDropdownOpen]);

  useOnClickOutside(languageDropdownRef, closeLanguageDropdown);

  return (
    <div
      className={classNames(
        classes.language,
        className || classes.languageDefaultPositioning,
        contrastMode && classes.languageWithBackground,
        isDropdownOpen && classes.open,
      )}
      onClick={toggleLanguageDropdown}
      id="open-language-change-dropdown-btn"
    >
      <div className={classes.languageArrow}>
        <Icon height={24} width={24} url={languageIcon} />
        {isMdAndUp && showCurrentLanguage && (
          <span className={classes.currentLanguage}>
            {/* @ts-ignore */}
            {t(`globalLanguage.${i18n.language}.ISO639-2`)}
          </span>
        )}
      </div>

      <AnimatePresence>
        {isDropdownOpen && (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={isDropdownOpen ? "visible" : "hidden"}
            className={classes.languageDropdownContainer}
            ref={languageDropdownRef}
            exit="hidden"
          >
            <div
              className={classNames([
                classes.languageDropdown,
                contrastMode && classes.languageDropdownWithBackground,
              ])}
            >
              <ul className={classes.languageSelectionContainer}>
                {config.appConfig.availableLanguages.map((language) => (
                  <li
                    key={`language-${language}`}
                    className={classNames([
                      classes.languageSelection,
                      language === i18n.language && classes.selectedLanguage,
                    ])}
                    onClick={() => setLanguage(language)}
                  >
                    {/* @ts-ignore */}
                    {t(`globalLanguage.${language}.ISO639-2`)}
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LanguageDropdown;
