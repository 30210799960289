import { post } from "@api";
import {
  IInitializePaymentResponse,
  InitializeRequest,
} from "@api/interfaces/paymentLayouts";
import { PaymentPath } from "@api/paths";
import { ApiRequest, RequestResponse } from "@api/types";

const initialize: ApiRequest<
  RequestResponse<IInitializePaymentResponse>,
  InitializeRequest
> = async ({ barcodeWithoutChecksum, ...options }) =>
  post({
    ...options,
    api: PaymentPath.initialize(barcodeWithoutChecksum),
  });

const Payment = {
  initialize,
};

export default Payment;
