import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ spacing, mediaQuery }) => ({
  pageWrapper: {
    maxWidth: 1464,
    margin: "0 auto",
    paddingLeft: spacing.xxl,
    paddingRight: spacing.xxl,

    [mediaQuery.lg]: {
      paddingLeft: spacing.x5l,
      paddingRight: spacing.x5l,
    },
  },
}));

type PageWrapperProps = {
  children?: React.ReactNode;
};

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.pageWrapper}>{children}</div>;
};

export default PageWrapper;
