import { Configuration } from "@api/controller/configuration";
import {
  IAvailableDiningDate,
  IAvailableDiningTime,
  IDiningOptionEnum,
} from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import { hasData } from "@store/apiRequestStatusMachine";
import { SalesPointState } from "@store/salesPoint";

// Get all available dates for defined diningOption. Returns empty array if none found or error,
export const getAvailableDates = async (
  diningOption: IDiningOptionEnum,
): Promise<IAvailableDiningDate[]> => {
  const { isResponseOk, response } = await Configuration.getAvailableDates({
    diningOption,
  });
  if (isResponseOk && response) {
    return response;
  }
  return [];
};

// Get all available times for defined diningOption for defined date. Returns empty array if none found or error.
export const getAvailableTimes = async (
  diningOption: IDiningOptionEnum,
  diningDate: IAvailableDiningDate,
): Promise<IAvailableDiningTime[]> => {
  const { isResponseOk, response } = await Configuration.getAvailableTimes({
    diningOption,
    date: diningDate.dtDate,
  });
  if (isResponseOk && response) {
    return response;
  }
  return [];
};

export const isFieldVisible = (
  field: string,
  configuration: SalesPointState["configuration"],
): boolean => {
  if (!hasData(configuration)) return false;
  if (!isPresent(configuration.state.VisibleFormFields)) return false;

  return configuration.state.VisibleFormFields.includes(field);
};

export const isFieldMandatory = (
  field: string,
  configuration: SalesPointState["configuration"],
): boolean => {
  if (!hasData(configuration)) return false;
  if (!isPresent(configuration.state.RequiredFormFields)) return false;

  return configuration.state.RequiredFormFields.includes(field);
};
