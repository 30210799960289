import {
  IModifierSet,
  IProduct,
  IProductLayoutResponse,
  IProductSubItemProduct,
  IProductTax,
} from "@api/interfaces/productLayouts";

// TODO: remove raw data entries, this is only for debugging
type ProductsContextState = {
  rawData: IProductLayoutResponse | null;
  rawProducts: IProduct[] | null;
  sections: ProductListItem[] | null;
  products: ProductListItem[] | null;
};

type ProductsContextActions = {
  getAllProductsInSections: () => ProductListItem[] | undefined;
  getRawProductById: (id: number) => IProduct | undefined;
  getRawProductsByIds: (ids: number[]) => IProduct[] | undefined;
  getProductById: (id: number) => ProductListItem | undefined;
  getProductsByIds: (ids: number[]) => ProductListItem[] | undefined;
  getCrossSellProducts: () => ProductListItem[] | undefined;
};

type ProductsContextProps = [ProductsContextState, ProductsContextActions];

type ProductsProviderProps = {
  children?: React.ReactNode;
};

export enum ProductListItemType {
  SECTION = "SECTION",
  CATEGORY = "CATEGORY",
  ITEM = "ITEM",
  VARIABLE = "VARIABLE",
}

type ProductListItem = {
  id: number;
  uniqueId?: string;
  title: string;
  type: ProductListItemType;
  mainProductId?: number;
  shortTitle?: string;
  description?: string;
  categoryLevel?: number;
  picture?: string;
  thumbnail?: string;
  items?: ProductListItem[];
  category?: number;
  tax: IProductTax;
  netPrice: number;
  grossPrice?: number;
  modifiers?: IModifierSet;
  subItems?: IProductSubItemProduct[];
  isAgeRestricted: boolean;
  isFeatured: boolean;
  isDisabled: boolean;
  isCrossSells: boolean;
  bgColor?: string;
  inSequence?: boolean;
};

export enum ProductType {
  UNKNOWN = 0,
  REGULAR = 1,
  WEIGHTED = 2,
  SEASON_TICKET = 3,
  CONTAINER = 4,
  SEPARATOR = 5,
  TIME_BASED = 6,
  BUNDLE = 7,
  VARIABLE = 8,
}

export type {
  ProductListItem,
  ProductsContextActions,
  ProductsContextProps,
  ProductsContextState,
  ProductsProviderProps,
};
