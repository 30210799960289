import React from "react";
import { createUseStyles } from "react-jss";
import Button from "@base/components/Global/Button";
import { t } from "i18next";

const useStyles = createUseStyles(({ spacing, font, color }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  paymentFailedBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  paymentFailedHeader: {
    fontSize: font.size.x3l,
    lineHeight: font.lineHeight.xl,
    fontWeight: font.weight.s,
    color: color.paymentFailedText,
  },
  paymentFailedMsg: {
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    color: color.paymentFailedText,
  },
  checkoutFailed: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: spacing.l,
    width: "100%",
  },
  cancelBtn: {
    flex: 0.7,
    whiteSpace: "nowrap",
    backgroundColor: color.checkoutCancelBtnBg,
    color: color.checkoutCancelBtn,
  },
  tryAgainBtn: {
    flex: 1.3,
    whiteSpace: "nowrap",
  },
}));

type PaymentFailedBlockProps = {
  onCancel: () => void;
  onTryAgain: () => void;
  isDisabled: boolean;
};

export const PaymentFailedBlock: React.FC<PaymentFailedBlockProps> = ({
  onCancel,
  onTryAgain,
  isDisabled,
}) => {
  const classes = useStyles();

  return (
    <div id="payment-failed-msg" className={classes.container}>
      <div className={classes.paymentFailedBlock}>
        <div className={classes.paymentFailedHeader}>
          {t("cart_header_paymentFailed")}
        </div>
        <div className={classes.paymentFailedMsg}>
          {t("cart_payment_failed")}
        </div>
      </div>
      <div className={classes.checkoutFailed} id="payment-proceeding">
        <Button
          id="decline-further-payment"
          action={onCancel}
          className={classes.cancelBtn}
          isTransparent
        >
          {t("global_btn_cancel")}
        </Button>
        <Button
          id="retry-payment"
          action={onTryAgain}
          className={classes.tryAgainBtn}
          isDisabled={isDisabled}
        >
          {t("cart_checkout_tryAgainBtn")}
        </Button>
      </div>
    </div>
  );
};
