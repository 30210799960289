const formatNumberToTime = (input: number): string => {
  if (input < 10) return "0".concat("", input.toString());
  return input.toString();
};

const formatTime = (input: Date): string => {
  const date = new Date(input);
  const hours = formatNumberToTime(date.getHours());
  const minutes = formatNumberToTime(date.getMinutes());
  return `${hours}:${minutes}`;
};

export { formatTime };
