import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "@api/interfaces/orderLayouts";
import { isPresent } from "@apl-digital/utils";
import Button from "@base/components/Global/Button";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { NavigationPath } from "@constants/navigation";
import { useAppDispatch } from "@store";
import { removeOrder } from "@store/orders";
import { OrderDataWithGrouping } from "@store/orders/types";

import { OrderCardCompletedBlock } from "./OrderCardCompletedBlock";
import { OrderCardExtraInfoBlock } from "./OrderCardExtraInfoBlock";
import { OrderCardPreOrderBlock } from "./OrderCardPreOrderBlock";
import { OrderCardQueueNumberBlock } from "./OrderCardQueueNumberBlock";
import StatusBlock from "./StatusBlock";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  container: {
    display: "flex",
    justifyContent: "start",
    height: "100%",
    flexDirection: "row",
    gap: "64px",
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  statusBlockContainer: {
    margin: [spacing.xxl, 0],
  },
  estimatedTimeTitle: {
    color: color.defaultText,
  },
  estimatedTime: {
    color: color.defaultText,
    padding: [spacing.m, 0],
    fontSize: font.size.x4l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.xxl,
    flexBasis: "66.6%",
    display: "flex",
    flexDirection: "column",
    gap: spacing.xs,
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.x5l,
    justifyContent: "space-between",
    flexBasis: "33.3%",
  },
  centerColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: spacing.x4l,
  },
  endColumn: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
  },
  infoBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.x5l,
    alignItems: "start",
  },
  upperInfoBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxl,
    width: "100%",
  },
  statusHeader: {
    fontSize: font.size.m,
    lineHeight: "1.4em",
    margin: [spacing.l, 0],
    color: color.infoTextColor,
  },
}));

type OrderCardContentRowProps = {
  order: OrderDataWithGrouping;
  isMovedOrder: boolean;
  isSelected: boolean;
};

export const OrderCardContentRow: React.FC<OrderCardContentRowProps> = ({
  order,
  isMovedOrder,
  isSelected,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("orderStatusPage");
  const { resolveKey } = useSalesPointTranslationKey();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  return (
    <div className={classes.container} ref={containerRef}>
      <div className={classes.columnContainer}>
        <div className={classes.infoBlock}>
          <div className={classes.upperInfoBlock}>
            {isMovedOrder ? (
              <OrderCardCompletedBlock
                variant="orderMoved"
                lastStatusChangeDateTime={order.dttmLastStatusChange}
                showSubtitle
              />
            ) : order.isComplete &&
              (order.Status === OrderStatus.Completed ||
                order.Status === OrderStatus.Refunded ||
                order.Status === OrderStatus.Cancelled) ? (
              <OrderCardCompletedBlock
                variant={order.Status}
                lastStatusChangeDateTime={order.dttmLastStatusChange}
              />
            ) : (
              <>
                <OrderCardQueueNumberBlock
                  orderQueueNumber={order.QueueNumber}
                  variant="column"
                />

                {isPresent(order.dttmRequestedDelivery) ? (
                  <OrderCardPreOrderBlock
                    requestedDeliveryDateTime={order.dttmRequestedDelivery}
                    diningOptionId={order.DiningOption.ID}
                    variant="column"
                  />
                ) : (
                  <div className={classes.statusHeader} id="order-table-number">
                    {t(resolveKey("orderHelpMessage"), { ns: "salespoint" })}
                  </div>
                )}
              </>
            )}
          </div>

          <OrderCardExtraInfoBlock
            transactionId={order.TransactionID}
            totalGrossSum={order.TotalGrossSum}
            variant="column"
          />
        </div>

        <div className={classes.endColumn}>
          {isMovedOrder && !isSelected ? (
            <Button
              action={() => dispatch(removeOrder(order.BarcodeWithoutChecksum))}
              fitContent
            >
              {t("OrderCardContentColumn.removeOrderButtonText")}
            </Button>
          ) : (
            <Button
              action={() => navigate(NavigationPath.Home)}
              fitContent
              id="order-more-btn"
            >
              {t("OrderCardContentRow.orderMoreButton")}
            </Button>
          )}
        </div>
      </div>

      <StatusBlock order={order} />
    </div>
  );
};
