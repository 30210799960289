import { useEffect, useState } from "react";
import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

type StyleProps = {
  styleVariant?: "default" | "rounded";
};

const useStyles = createUseStyles(({ borderRadius }) => ({
  container: {
    position: "relative",
    overflow: "hidden",
    borderRadius: ({ styleVariant }: StyleProps) =>
      styleVariant === "rounded" ? borderRadius.x3l : undefined,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "opacity 0.5s ease-in-out",
  },
  lowResImage: {
    filter: "blur(20px)",
  },
  highResImage: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 1,
  },
  hiddenImage: {
    opacity: 0,
  },
}));

type ProgressiveImageProps = StyleProps & {
  lowResSrc: string;
  highResSrc: string;
  alt?: string;
  className?: string;
};

export const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  lowResSrc,
  highResSrc,
  alt,
  className,
  styleVariant,
}) => {
  const classes = useStyles({ styleVariant });
  const [isHighResLoaded, setIsHighResLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = highResSrc;
    img.onload = () => {
      setIsHighResLoaded(true);
    };
  }, [highResSrc]);

  return (
    <>
      <div className={classNames(classes.container, className)}>
        <img
          className={classNames(
            classes.image,
            classes.lowResImage,
            isHighResLoaded && classes.hiddenImage,
          )}
          src={lowResSrc}
          alt={alt}
        />
        <img
          className={classNames(
            classes.image,
            classes.highResImage,
            !isHighResLoaded && classes.hiddenImage,
          )}
          src={highResSrc}
          alt={alt}
        />
      </div>
    </>
  );
};
