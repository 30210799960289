import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { Carousel } from "@apl-digital/common-ui";
import { isPresent } from "@apl-digital/utils";
import Box from "@base/components/Box/Box";
import BoxList from "@base/components/Box/BoxList";
import Button from "@base/components/Global/Button";
import Footer from "@base/components/Global/Footer";
import IconWithText from "@base/components/Global/IconWithText";
import { Modal } from "@base/components/Global/Modal";
import { RichListTranslation } from "@base/components/Global/RichTextTranslation";
import LandingImage from "@base/components/LandingImage/LandingImage";
import ProductsCarousel from "@base/components/ProductsCarousel";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { useWhiteLabelImage } from "@base/hooks/useWhiteLabelImage";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { NavigationPath } from "@constants/navigation";
import LocationModalContent from "@pages/HomePage/components/LocationModalContent";
import { useAppSelector } from "@store";
import { selectActiveAndSelectedOrdersWithGrouping } from "@store/orders";
import { useProducts } from "@store/ProductsProvider";
import { selectAssetCategoryUrls } from "@store/salesPoint";
import { getBlobStorage } from "@theme";
import classNames from "classnames";

import { OrderCardMobile } from "./components/OrderCardMobile";
import { useOrderStatusPageContext } from "./OrderStatusPageContext";

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  container: {
    overflow: "hidden",
    width: "100vw",
  },
  preorderInformationBlock: {
    color: color.defaultText,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: spacing.m,
  },
  statusHeader: {
    fontSize: font.size.m,
    lineHeight: "1.4em",
    margin: [spacing.l, 0],
    color: color.infoTextColor,
  },
  orderNumber: {
    fontWeight: font.weight.m,
    fontSize: font.size.s,
    lineHeight: font.lineHeight.x3s,
    color: color.orderStatusPageOrderNr,
  },
  orderInfoBlock: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
  },
  orderEmailInfo: {
    fontWeight: font.weight.m,
    fontSize: font.size.xs,
    lineHeight: font.lineHeight.xxs,
    color: color.orderStatusPageEmail,
  },
  extraSalesHeading: {
    fontWeight: font.weight.s,
    color: color.orderStatusPageExtraSalesTitle,
    paddingTop: spacing.x5l,
  },
  statusBlockContainer: {
    marginTop: spacing.xxl,
  },
  title: {
    color: color.defaultText,
    fontSize: font.size.x3l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.l,
    marginTop: 0,
  },
  subTitle: {
    color: color.defaultText,
    fontSize: font.size.m,
    fontWeight: font.weight.m,
    margin: 0,
  },
  time: {
    fontSize: font.size.x3l,
    fontWeight: font.weight.s,
    lineHeight: "1em",
    margin: [spacing.xxl, 0],
  },
  bigTime: {
    fontSize: font.size.x5l,
  },
  ordersBox: {
    paddingBottom: spacing.xxl,
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  boxListBackground: {
    backgroundColor: color.orderStatusPageBg,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.x3l,
    padding: [spacing.x5l, spacing.x5l],
  },
}));

const MobileOrderStatusPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["salespoint", "orderStatusPage"]);
  const { resolveKey } = useSalesPointTranslationKey();
  const navigate = useNavigate();

  const footerRef = useRef<HTMLDivElement>(null);

  const [, { getCrossSellProducts }] = useProducts();

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const { selectedOrderId } = useOrderStatusPageContext();

  const currentOrders = useAppSelector((state) =>
    selectActiveAndSelectedOrdersWithGrouping(state, selectedOrderId),
  );
  const images = useAppSelector((state) =>
    selectAssetCategoryUrls(state, "status_page_mobile_banner"),
  );

  const crossSellProducts = getCrossSellProducts();
  const { locationIcon } = getBlobStorage();

  const hasCrossSellProducts =
    crossSellProducts && crossSellProducts?.length > 0;

  const footerHeight = isPresent(footerRef.current?.clientHeight)
    ? `${footerRef.current.clientHeight + 8}px`
    : undefined;

  return (
    <>
      <Modal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      >
        <LocationModalContent />
      </Modal>

      <div className={classes.container}>
        <div style={{ paddingBottom: footerHeight }}>
          <LandingImage
            hasBottomGradient
            shouldCompensateBottom
            imageUrls={images.map((image) => ({
              img: getImageFromAPI(image, 1290, 1290),
              placeholderImg: getImageFromAPI(image, 129, 129),
            }))}
          />
          <BoxList hasFooter={false} className={classes.boxListBackground}>
            <div className={classes.headerContainer}>
              <div>
                <h1 className={classes.title}>
                  {t("orderStatusPage:MobileOrderStatusPage.header.title")}
                </h1>
                <p className={classes.subTitle}>
                  {t("orderStatusPage:MobileOrderStatusPage.header.subtitle")}
                </p>
              </div>
              <IconWithText
                iconUrl={locationIcon}
                onClick={() => setIsContactModalOpen(true)}
                interactiveElementId="homepage-contact-modal-open-btn"
              >
                <RichListTranslation
                  getTranslation={() =>
                    t(resolveKey("addressLines", { returnObjects: true }), {
                      returnObjects: true,
                    })
                  }
                />
              </IconWithText>
            </div>

            <div
              className={classNames(
                classes.ordersBox,
                currentOrders.length === 1 && classes.center,
              )}
            >
              <Carousel
                items={currentOrders}
                allowOverflow
                active={currentOrders.length > 1}
                scrollTo={{
                  index: 0,
                }}
              >
                {({ item }) => (
                  <OrderCardMobile
                    key={item.state.BarcodeWithoutChecksum}
                    order={item}
                    isSelected={
                      item.state.BarcodeWithoutChecksum === selectedOrderId
                    }
                  />
                )}
              </Carousel>
            </div>

            {hasCrossSellProducts && (
              <Box>
                <>
                  <h1 className={classes.extraSalesHeading}>
                    {t(
                      "orderStatusPage:MobileOrderStatusPage.crossSellSection.title",
                    )}
                  </h1>
                  <ProductsCarousel products={crossSellProducts} />
                </>
              </Box>
            )}
          </BoxList>
        </div>
        <Footer ref={footerRef}>
          <Button
            action={() => navigate(NavigationPath.Home)}
            id="order-more-btn"
          >
            {t("orderStatusPage:MobileOrderStatusPage.footer.orderMoreButton")}
          </Button>
        </Footer>
      </div>
    </>
  );
};

export default MobileOrderStatusPage;
