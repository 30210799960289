import { commonDarkTheme } from "@theme/themes/common.dark";
import { DefaultTheme } from "react-jss";

export const olearysDarkTheme: DefaultTheme = {
  ...commonDarkTheme,
  blobStorage: {
    ...commonDarkTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/light/logo/logo-olearys.svg",
  },
  config: {
    ...commonDarkTheme.config,
    brandLogoWidth: 104,
    brandLogoHeight: 40,
    sectionsColors: "#71420A;#223137;#332548;#560909;#3A3F0F",
  },
} as const;
