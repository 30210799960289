import { ICustomFieldsAndValues } from "@api/interfaces/cartLayouts";
import { isPresent } from "@apl-digital/utils";

// https://github.com/APL-Digital/Markus.MCS/blob/041ceb2e0b16bd1ca35d266f49c4c738a257a5a4/src/MARKUS.MCS.Globals/Enumerations.cs#L716
export enum ECustomFieldType {
  PayLaterRuleTitle = 428,
  PayLaterRuleDescription = 429,
  PayLaterRuleTerms = 430,
  PayLaterRuleTermsLink = 431,
  PayLaterRuleInstructions = 432,
  PayLaterRulePrimaryCTA = 433,
  PayLaterRuleSecondaryCTA = 434,
  PayLaterRuleConfirmationTitle = 435,
  PayLaterRuleConfirmationMessage = 436,
  PayLaterRuleConfirmationAcknowledgment = 437,
  PayLaterRuleTermsLinkLabel = 438,
}

export const findCustomField = (
  customFields: ICustomFieldsAndValues[],
  CustomFieldId: ECustomFieldType,
) => {
  return customFields.find(
    (customField) => customField.CustomFieldId === CustomFieldId,
  );
};

export const getCustomFieldValue = (
  customFields: ICustomFieldsAndValues[] | null,
  CustomFieldId: ECustomFieldType,
) => {
  if (!isPresent(customFields) || customFields.length === 0) {
    return `Missing custom field: ${ECustomFieldType[CustomFieldId]}`;
  }

  const customField = findCustomField(customFields, CustomFieldId);

  return (
    customField?.TextValue ??
    `Missing custom field: ${ECustomFieldType[CustomFieldId]}`
  );
};
