import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Footer from "@base/components/Global/Footer";
import FooterMenuButton from "@base/components/Global/FooterMenuButton";

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  footerButtons: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.xxl,
    width: "100%",
    justifyContent: "space-between",
  },
  kitchenClosedMsg: {
    color: color.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: font.size.s,
    lineHeight: font.lineHeight.xxs,
    fontWeight: font.weight.m,
  },
}));

type Props = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const KitchenClosedFooterContent: React.FC<Props> = ({
  isOpen,
  open,
  close,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Footer isError>
      <span className={classes.footerButtons}>
        <div className={classes.kitchenClosedMsg}>
          <div>{t("kitchen_closed_header")}</div>
          <div>{t("kitchen_closed_open_hours")}</div>
        </div>
        <FooterMenuButton isOpen={isOpen} open={open} close={close} />
      </span>
    </Footer>
  );
};

export default KitchenClosedFooterContent;
