import React, { ReactElement, useRef } from "react";
import { createUseStyles } from "react-jss";
import Icon from "@base/components/Global/Icon";
import { getBlobStorage, getBrandTheme } from "@theme";
import classNames from "classnames";

const useStyles = createUseStyles(({ font, spacing, color }) => ({
  title: {
    fontSize: font.size.x2l,
    lineHeight: font.lineHeight.xl,
    fontWeight: font.weight.s,
    color: color.cartPageItemsTitle,
  },
  titleContainer: {
    display: "flex",
    alignitems: "center",
    justifyContent: "space-between",
  },
  expandableTitleContainer: {
    userSelect: "none",
    cursor: "pointer",
  },
  headerPayloadContainer: {
    padding: [spacing.s, 0],
  },
  payloadHeightContainer: {
    overflow: "hidden",
    transition: "max-height 150ms ease-out",
  },
}));

type ExpandableProps = {
  title: string;
  isExpandable?: boolean;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  renderHeaderPayload?: () => ReactElement;
  children?: React.ReactNode;
};

const Expandable: React.FC<ExpandableProps> = ({
  title,
  isExpanded,
  setIsExpanded,
  children,
  renderHeaderPayload,
  isExpandable = true,
}) => {
  const classes = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);
  const { color } = getBrandTheme();

  const { caretDownThinIcon, caretUpThinIcon } = getBlobStorage();

  return (
    <>
      <div
        className={classNames(
          classes.titleContainer,
          isExpandable && classes.expandableTitleContainer,
        )}
        onClick={() => {
          if (isExpandable) setIsExpanded((prevState) => !prevState);
        }}
      >
        <div className={classes.title}>{title}</div>

        {isExpandable && (
          <Icon
            url={isExpanded ? caretUpThinIcon : caretDownThinIcon}
            width={29}
            height={24}
            stroke={color.defaultText}
          />
        )}
      </div>

      {renderHeaderPayload && (
        <div className={classes.headerPayloadContainer}>
          {renderHeaderPayload()}
        </div>
      )}

      <div
        className={classNames(classes.payloadHeightContainer)}
        style={{ maxHeight: isExpanded ? contentRef.current?.clientHeight : 0 }}
      >
        {isExpandable && <div ref={contentRef}>{children}</div>}
      </div>
    </>
  );
};

export default Expandable;
