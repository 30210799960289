import { commonDarkTheme } from "@theme/themes/common.dark";
import { DefaultTheme } from "react-jss";

export const vapianoDarkTheme: DefaultTheme = {
  ...commonDarkTheme,
  blobStorage: {
    ...commonDarkTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/dark/logo/logo-vapiano.svg",
  },
  config: {
    ...commonDarkTheme.config,
    brandLogoWidth: 104,
    brandLogoHeight: 40,
    sectionsColors: "#71420A;#223137;#332548;#560909;#3A3F0F",
  },
  color: {
    ...commonDarkTheme.color,
    productRowFeaturedBg: "#3CCC65",
  },
} as const;
