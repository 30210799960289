import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useTimeoutFn } from "react-use";

import Loader, { LoaderProps } from "./Loader";
import { Retry } from "./Retry";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

type RetryLoaderProps = LoaderProps & {
  onRetry: () => void;
};

export const RetryLoader: React.FC<RetryLoaderProps> = ({
  onRetry,
  ...loaderProps
}) => {
  const classes = useStyles();
  const { t } = useTranslation("global");

  const [canRetry, setCanRetry] = React.useState(false);

  const [reset] = useTimeoutFn(() => setCanRetry(true), 10000);

  return (
    <div className={classes.container}>
      <Loader {...loaderProps} />
      {canRetry && (
        <Retry
          hintText={t("RetryLoader.hint")}
          buttonText={t("RetryLoader.buttonText")}
          onRetry={() => {
            onRetry();
            reset();
            setCanRetry(false);
          }}
        />
      )}
    </div>
  );
};
