import { CountryCode } from "@base/components/Global/PhoneField/types";

const getCountrySearchResult = (
  search: string,
  countryCodes: CountryCode[],
): CountryCode[] => {
  if (search && search !== "") {
    const searchArray = search
      .replaceAll(/[.,-/+()_=*&^`~%$#@!?;:'"\\|{}[\]]/gm, " ")
      .split(" ")
      .filter((v) => v.length);
    if (searchArray.length > 0) {
      let countryCodeMatchArray: CountryCode[] = [];
      const startsWithMatchArray: CountryCode[] = [];
      const includesMatchArray: CountryCode[] = [];
      countryCodes.forEach((countryCode) => {
        for (const searchValue of searchArray) {
          const isCodeMatched = countryCode.code
            .slice(1)
            .startsWith(searchValue);
          if (isCodeMatched) {
            countryCodeMatchArray.push(countryCode);
            break;
          }
          const isTitleStarts = countryCode.title
            .toLowerCase()
            .startsWith(searchValue.toLowerCase());
          if (isTitleStarts) {
            startsWithMatchArray.push(countryCode);
            break;
          }
          const isTitleMatched = countryCode.title
            .toLowerCase()
            .includes(searchValue.toLowerCase());
          if (isTitleMatched) {
            includesMatchArray.push(countryCode);
            break;
          }
        }
      });

      countryCodeMatchArray = countryCodeMatchArray
        .sort((a, b) => a.code.length - b.code.length)
        .sort((a, b) => Number(a.code.slice(1)) - Number(b.code.slice(1)));

      const filteredCountryCodes = [
        ...countryCodeMatchArray,
        ...startsWithMatchArray,
        ...includesMatchArray,
      ];

      if (filteredCountryCodes.length > 0) {
        return filteredCountryCodes;
      }
    }
  }

  return countryCodes;
};

export { getCountrySearchResult };
