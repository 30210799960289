import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import BurgerMenu from "@base/components/BurgerMenu/BurgerMenu";
import Button from "@base/components/Global/Button";
import Icon from "@base/components/Global/Icon";
import LanguageDropdown from "@base/components/LanguageDropdown/LanguageDropdown";
import useNavbarStyles from "@base/components/Navbar/hooks/useNavbarStyles";
import useUpdateSectionStyles from "@base/components/Navbar/hooks/useUpdateSectionStyles";
import useWindowScroll from "@base/components/Navbar/hooks/useWindowScroll";
import { getBrandLogo } from "@base/utils/getBrandLogo";
import config from "@constants/config";
import { ABOVE_FOLD_HEIGHT } from "@constants/misc";
import { NavigationPath } from "@constants/navigation";
import { isSSOEnabled, useUser } from "@context/UserProvider";
import { useAppSelector } from "@store/hooks";
import { selectActiveOrdersCount } from "@store/orders";
import { selectUserData } from "@store/user";
import { getBlobStorage } from "@theme";
import classnames from "classnames";

import DiningOptionButton from "./DiningOptionButton";

const desktopNavbarStyles = createUseStyles(
  ({
    spacing,
    font,
    color,
    zIndex,
    config: themeConfig,
    borderRadius,
    mediaQuery,
  }) => ({
    navbarDesktop: {
      height: themeConfig.desktopNavbarHeight,
      display: "flex",
      width: "100%",
      maxWidth: 1464,
      margin: "0 auto",
      paddingLeft: spacing.xxl,
      paddingRight: spacing.xxl,

      [mediaQuery.lg]: {
        paddingLeft: spacing.x5l,
        paddingRight: spacing.x5l,
      },
    },
    outer: {
      transition: "300ms ease",
      top: -themeConfig.desktopNavbarHeight,
      right: 0,
      left: 0,
      position: "fixed",
      zIndex: zIndex.bottomMenu,
    },
    button: {
      marginLeft: "auto",
      color: color.navbarLoginButtonText,
      fontSize: font.size.s,
      lineHeight: font.lineHeight.s,
      height: 38,
      width: "auto",
      padding: [spacing.xxl, spacing.xxl],
      background: color.navbarLoginButtonBg,
      borderRadius: borderRadius.button,
      textAlign: "center",
      display: "flex",
      alignSelf: "start",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "clip",
      alignItems: "center",
      gap: spacing.s,
      fontStyle: "normal",
      fontWeight: font.weight.m,
    },
    buttons: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
      gap: spacing.xxl,
    },
    user: {
      display: "flex",
      borderRadius: borderRadius.round,
      border: `1px solid ${color.greyCoal}`,
      width: 32,
      height: 32,
      justifyContent: "center",
      alignContent: "center",
      flexWrap: "wrap",
      color: color.navbarUser,
    },
    flexRowCenter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    loyaltyClub: {
      marginRight: spacing.s,
      fontSize: font.size.s,
      fontWeight: font.weight.m,
      color: color.navbarLoyaltyClub,
    },
  }),
);

const NavbarDesktop: React.FC = () => {
  const classes = desktopNavbarStyles();
  const sharedClasses = useNavbarStyles();
  const { login } = useUser();
  const userData = useAppSelector(selectUserData);
  const { t } = useTranslation();
  const { burgerIcon, burgerActiveIcon } = getBlobStorage();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading } = useAuth();
  const activeOrderCount = useAppSelector(selectActiveOrdersCount);

  const navigateHome = () => {
    navigate(NavigationPath.Home);
  };

  const [hasSidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!hasSidebar);
  const pathname = location.pathname || null;
  const scrollPositionRef = React.useRef(0);

  const isProductPage = pathname?.includes(NavigationPath.ProductBase);
  const isHomePage = pathname === NavigationPath.Home;
  const isCartPage = pathname === NavigationPath.Cart;

  const getTopLen = () => {
    if (isHomePage) return -ABOVE_FOLD_HEIGHT;
    const calculatedScreenWidth = screen.width - (30 * screen.width) / 100;
    return -calculatedScreenWidth;
  };

  const setRefScrollPosition = (value: number) => {
    scrollPositionRef.current = value;
  };

  const values = useWindowScroll(
    location,
    scrollPositionRef,
    isProductPage,
    getTopLen,
    setRefScrollPosition,
  );
  useUpdateSectionStyles(values);

  const shouldShowLoginButton =
    config.featureFlags.isLoginEnabled && !userData && isSSOEnabled();

  return (
    <div
      className={classnames([
        classes.outer,
        values.shouldShowNavbar && sharedClasses.showNavbar,
        isCartPage && sharedClasses.cartPageNavbar,
        sharedClasses.navbarBackground,
      ])}
    >
      <div className={classnames([classes.navbarDesktop])}>
        <div
          onClick={() =>
            isHomePage
              ? window.scrollTo({
                  behavior: "smooth",
                  top: 0,
                })
              : navigateHome()
          }
          style={{ cursor: "pointer", display: "flex" }}
          id="navbar-logo-btn"
        >
          {getBrandLogo()}
        </div>
        <div className={classes.buttons}>
          <div className={classes.flexRowCenter}>
            {shouldShowLoginButton && (
              <>
                <span className={classes.loyaltyClub}>
                  {t("loyalty_club_name")}
                </span>
                <Button
                  className={classes.button}
                  action={login}
                  id="header-login-btn"
                  isLoading={isLoading}
                >
                  {t("burgermenu_main_login")}
                </Button>
              </>
            )}
            <DiningOptionButton />
          </div>

          {userData && (
            <div className={classes.flexRowCenter}>
              <span className={classes.loyaltyClub}>
                {t("loyalty_club_name")}
              </span>
              <div className={classes.user} data-sentry-mask={true}>
                {userData.FirstName.substring(0, 1)}
              </div>
            </div>
          )}
          <LanguageDropdown showCurrentLanguage contrastMode />
          <span
            role="button"
            className={sharedClasses.icon}
            id="burger-menu-open-btn"
            onClick={showSidebar}
          >
            {activeOrderCount > 0 ? (
              <Icon height={32} width={32} url={burgerActiveIcon} />
            ) : (
              <Icon height={32} width={32} url={burgerIcon} />
            )}
          </span>
        </div>
        <BurgerMenu showSidebar={showSidebar} hasSidebar={hasSidebar} />
      </div>
    </div>
  );
};

export default NavbarDesktop;
