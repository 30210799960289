import { getZodStorage, ZodLocalStorageKey } from "@constants/storage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isStatus } from "@store/utils";

import { getOrderStatus } from "./actions";
import { InitialOrdersState, Order, OrdersState } from "./types";

const getInitialState = (loadFromStorage: boolean): OrdersState => {
  const storedOrders = getZodStorage(ZodLocalStorageKey.ORDERS, []);

  return {
    orders: loadFromStorage
      ? storedOrders.map((order) => {
          if (isStatus(order, ["succeeded", "stalePending"])) {
            return { ...order, status: "stale" };
          } else if (isStatus(order, ["pending"])) {
            return { ...order, status: "idle" };
          } else if (isStatus(order, ["failedPending"])) {
            return { ...order, status: "failed" };
          } else {
            return order;
          }
        })
      : [],
  };
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState: getInitialState(true),
  reducers: {
    resetOrders: () => {
      return getInitialState(false);
    },
    addNewOrder: (state, { payload }: PayloadAction<InitialOrdersState>) => {
      if (
        state.orders.some(
          (order) =>
            order.state?.BarcodeWithoutChecksum ===
            payload.BarcodeWithoutChecksum,
        )
      ) {
        return;
      }

      state.orders = [...state.orders, { status: "idle", state: payload }];
    },
    removeOrder: (state, { payload }: PayloadAction<string>) => {
      state.orders = state.orders.filter(
        (order) => order.state?.BarcodeWithoutChecksum !== payload,
      );
    },
    setAllOrdersStale: (state) => {
      state.orders = state.orders.map((order) => {
        if (isStatus(order, ["succeeded"])) {
          return {
            ...order,
            status: "stale",
          };
        } else {
          return order;
        }
      });
    },
    resetOrderStatus: (state, { payload }: PayloadAction<string>) => {
      state.orders = state.orders.map((order) => {
        if (order.state.BarcodeWithoutChecksum === payload) {
          return {
            ...order,
            status: "idle",
          };
        } else {
          return order;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderStatus.pending, (state, { meta }) => {
        state.orders = state.orders.map((order): Order => {
          if (
            meta.arg.barcodeWithoutChecksums.includes(
              order.state.BarcodeWithoutChecksum,
            )
          ) {
            if (isStatus(order, ["stale", "succeeded"])) {
              return {
                ...order,
                status: "stalePending",
              };
            } else if (isStatus(order, "idle")) {
              return {
                ...order,
                status: "pending",
              };
            } else if (isStatus(order, "failed")) {
              return {
                ...order,
                status: "failedPending",
              };
            } else {
              return order;
            }
          } else {
            return order;
          }
        });
      })
      .addCase(getOrderStatus.fulfilled, (state, { payload, meta }) => {
        meta.arg.barcodeWithoutChecksums.forEach((barcodeWithoutChecksum) => {
          const stateOrderIndex = state.orders.findIndex(
            (order) =>
              order.state.BarcodeWithoutChecksum === barcodeWithoutChecksum,
          );

          const payloadOrderIndex = payload.findIndex(
            (order) => order.BarcodeWithoutChecksum === barcodeWithoutChecksum,
          );

          if (stateOrderIndex !== -1) {
            if (payloadOrderIndex !== -1) {
              state.orders[stateOrderIndex] = {
                status: "succeeded",
                state: payload[payloadOrderIndex],
              };
            } else {
              const stateOrder = state.orders[stateOrderIndex];

              if (
                isStatus(stateOrder, ["stalePending", "stale", "succeeded"])
              ) {
                state.orders[stateOrderIndex] = {
                  status: "orderMoved",
                  state: stateOrder.state,
                };
              } else {
                state.orders[stateOrderIndex] = {
                  status: "notFound",
                  state: {
                    BarcodeWithoutChecksum: barcodeWithoutChecksum,
                    dttmCreatedUTC: new Date().toISOString(),
                  },
                };
              }
            }
          } else {
            if (payloadOrderIndex !== -1) {
              state.orders = [
                ...state.orders,
                {
                  status: "succeeded",
                  state: payload[payloadOrderIndex],
                },
              ];
            } else {
              const stateOrder = state.orders[stateOrderIndex];

              if (
                isStatus(stateOrder, ["stalePending", "stale", "succeeded"])
              ) {
                state.orders[stateOrderIndex] = {
                  status: "orderMoved",
                  state: stateOrder.state,
                };
              } else {
                state.orders[stateOrderIndex] = {
                  status: "notFound",
                  state: {
                    BarcodeWithoutChecksum: barcodeWithoutChecksum,
                    dttmCreatedUTC: new Date().toISOString(),
                  },
                };
              }
            }
          }
        });
      })
      .addCase(getOrderStatus.rejected, (state, { meta }) => {
        state.orders = state.orders.map((order) => {
          if (
            meta.arg.barcodeWithoutChecksums.includes(
              order.state.BarcodeWithoutChecksum,
            )
          ) {
            if (isStatus(order, "failedPending")) {
              return {
                ...order,
                status: "failed",
                retryCount: order.retryCount + 1,
              };
            } else {
              return {
                ...order,
                status: "failedPending",
                retryCount: 0,
              };
            }
          } else {
            return order;
          }
        });
      });
  },
  selectors: {
    selectOrders: (state) => state.orders,
  },
});

export const {
  resetOrders,
  addNewOrder,
  removeOrder,
  setAllOrdersStale,
  resetOrderStatus,
} = ordersSlice.actions;

export const { selectOrders } = ordersSlice.selectors;

export default ordersSlice.reducer;
