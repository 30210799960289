import { useEffect } from "react";

const useEffectAsync = <T>(effect: () => void, deps?: T[]) => {
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      effect();
    }
    return () => {
      isSubscribed = false;
    };
  }, deps);
};

export default useEffectAsync;
