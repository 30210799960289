import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { IDiningOptionEnum } from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import { useFormatDistance } from "@base/utils/formatDistance";
import { useSalesPointContext } from "@context/SalesPointProvider";
import classNames from "classnames";
import {
  add,
  format,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isToday,
} from "date-fns";

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  container: {
    color: color.defaultText,
    display: "flex",
    alignItems: "start",
    gap: spacing.m,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    flexDirection: "column",
  },
  label: {
    fontSize: font.size.m,
    fontWeight: font.weight.m,
    flexBasis: "50%",
  },
  date: {
    color: color.orderStatusCardPreOrderTitleTextColor,
    fontSize: font.size.x2l,
    fontWeight: font.weight.s,
    lineHeight: "1em",
    textWrap: "nowrap",
  },
  bigTime: {
    fontSize: font.size.x2l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.l,
    display: "flex",
    justifyContent: "center",
  },
  "bigTime-row": {
    textAlign: "end",
  },
  "bigTime-column": {
    textAlign: "start",
  },
  timeBox: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  "timeBox-row": {
    alignItems: "end",
    flexBasis: "50%",
  },
  "timeBox-column": {
    alignItems: "start",
  },
}));

type OrderCardMobileContentProps = {
  requestedDeliveryDateTime: string;
  diningOptionId: IDiningOptionEnum;
  variant: "column" | "row";
};

export const OrderCardPreOrderBlock: React.FC<OrderCardMobileContentProps> = ({
  requestedDeliveryDateTime,
  diningOptionId,
  variant,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("orderStatusPage");

  const [{ configuration }] = useSalesPointContext();
  const { formatDistance } = useFormatDistance();

  const requestedDeliveryObject = new Date(requestedDeliveryDateTime);

  const orderStatusEstimatedTimeTextMap: Partial<
    Record<IDiningOptionEnum, string>
  > = {
    [IDiningOptionEnum.DINE_IN]: t(
      "OrderCardPreOrderBlock.estimatedDeliveryTimeLabel.dineIn",
    ),
    [IDiningOptionEnum.TAKE_OUT]: t(
      "OrderCardPreOrderBlock.estimatedDeliveryTimeLabel.takeOut",
    ),
  };

  let time = format(requestedDeliveryObject, "HH:mm");

  if (isPresent(configuration.KitchenOrderMonitorListWindowInMinutes)) {
    const upperDateLimit = add(new Date(), {
      minutes: configuration.KitchenOrderMonitorListWindowInMinutes,
    });
    const lowerDateLimit = add(new Date(), {
      minutes: -configuration.KitchenOrderMonitorListWindowInMinutes,
    });

    if (
      isBefore(requestedDeliveryObject, upperDateLimit) &&
      isAfter(requestedDeliveryObject, lowerDateLimit)
    ) {
      time = formatDistanceToNow(requestedDeliveryObject, {
        addSuffix: true,
        locale: { formatDistance },
      });
    }
  }

  let date = format(requestedDeliveryObject, "dd.MM.yy");

  if (isToday(requestedDeliveryObject)) {
    date = t("OrderCardPreOrderBlock.preOrderDate.today");
  }

  return (
    <div className={classNames(classes.container, classes[variant])}>
      <div className={classes.label}>
        {orderStatusEstimatedTimeTextMap[diningOptionId]}
      </div>
      <div
        className={classNames(classes.timeBox, classes[`timeBox-${variant}`])}
      >
        <div
          className={classNames(classes.bigTime, classes[`bigTime-${variant}`])}
        >
          {time}
        </div>

        <div className={classes.date}>{date}</div>
      </div>
    </div>
  );
};
