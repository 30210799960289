import { isPresent } from "@apl-digital/utils";
import { ProductParams } from "@constants/navigation";
import { useSearchParams } from "react-router-dom";

type UseProductParamsResults = {
  isEdit: boolean;
  amount?: number;
  comment?: string;
  variableId?: number;
  modifierChoiceIds: number[];
  shoppingCartLineIds: number[];

  removeAllParams: () => void;
};

export const useProductParams = (): UseProductParamsResults => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isEdit = searchParams.get(ProductParams.EDIT);
  const amount = searchParams.get(ProductParams.AMOUNT);
  const comment = searchParams.get(ProductParams.COMMENT);
  const variableId = searchParams.get(ProductParams.VARIABLE_ID);
  const urlLineNumbers = searchParams.get(ProductParams.LINE_ID);
  const modifierChoiceIds = searchParams.get(ProductParams.MODIFIERS);

  return {
    isEdit: isEdit === "true",
    amount: isPresent(amount) ? Number(amount) : undefined,
    comment: comment || undefined,
    variableId: isPresent(variableId) ? Number(variableId) : undefined,
    modifierChoiceIds: isPresent(modifierChoiceIds)
      ? modifierChoiceIds.split(",").map(Number).sort()
      : [],
    shoppingCartLineIds: isPresent(urlLineNumbers)
      ? urlLineNumbers.split(",").map(Number).sort()
      : [],

    removeAllParams: () => {
      Object.values(ProductParams).forEach((param) => {
        searchParams.delete(param);
        setSearchParams(searchParams, { replace: true });
      });
    },
  };
};
