import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { isPresent } from "@apl-digital/utils";
import Loader from "@base/components/Global/Loader";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { NavigationPath } from "@constants/navigation";
import * as Sentry from "@sentry/react";
import { isStatus } from "@store/utils";
import classNames from "classnames";

import ProductDetailSection from "../ProductDetailSection";
import { useProductPageContext } from "../ProductPageContext";

const useStyles = createUseStyles(({ spacing, mediaQuery, borderRadius }) => ({
  container: {
    maxHeight: 520,
    width: 736,
    [mediaQuery.lg]: {
      width: 1000,
    },
    "&$noPicture": {
      width: 520,
    },
    display: "flex",
    minHeight: 0,
  },
  noPicture: {},
  imageContainer: {
    flexBasis: "40%",
    [mediaQuery.lg]: {
      flexBasis: "70%",
    },
    borderRadius: borderRadius.xl,
    overflow: "hidden",
    margin: [spacing.x4l, spacing.l, spacing.x4l, spacing.x4l],
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  sideContainer: {
    flexBasis: "50%",
    "$noPicture &": {
      flexBasis: "100%",
    },
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: [spacing.x4l, spacing.x4l, spacing.x4l, spacing.l],
  },
  loader: {
    width: 736,
    [mediaQuery.lg]: {
      width: 1000,
    },
    height: 520,
    padding: spacing.x4l,
  },
}));

const ProductModalContent: React.FC = () => {
  const classes = useStyles();
  const { currentProduct } = useProductPageContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isStatus(currentProduct, "notFound")) {
      Sentry.captureException(
        new Error("Product not found", {
          cause: {
            productId: currentProduct.state.productId,
          },
        }),
      );
      navigate(NavigationPath.Home);
    }
  }, [currentProduct]);

  if (isStatus(currentProduct, "idle")) {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  }

  if (isStatus(currentProduct, "notFound")) {
    return null;
  }

  const hasPicture = isPresent(currentProduct.state.picture);

  return (
    <div
      className={classNames(classes.container, {
        [classes.noPicture]: !hasPicture,
      })}
    >
      {hasPicture && (
        <div
          className={classes.imageContainer}
          style={{ width: 0, overflow: "hidden" }}
        >
          <img
            className={classes.image}
            src={getImageFromAPI(currentProduct.state.picture)}
          />
        </div>
      )}

      <div className={classes.sideContainer}>
        <ProductDetailSection currentProduct={currentProduct.state} />
      </div>
    </div>
  );
};

export default ProductModalContent;
