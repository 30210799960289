import React, { useEffect } from "react";
import { Modal } from "@base/components/Global/Modal";

import ProductModalContent from "./components/ProductModalContent";
import { ProductPageContextProvider } from "./ProductPageContext";

const ProductModalWrapper: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  useEffect(() => {
    if (!isOpen) onClose();
  }, [isOpen, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      shouldHideRootScrollbar={false}
    >
      <ProductPageContextProvider>
        <ProductModalContent />
      </ProductPageContextProvider>
    </Modal>
  );
};

export default ProductModalWrapper;
