import {
  IProduct,
  IProductLayoutResponse,
} from "@api/interfaces/productLayouts";
import { createAction, createReducer } from "@reduxjs/toolkit";

import {
  ProductListItem,
  ProductsContextActions,
  ProductsContextState,
} from "./types";

const DEFAULT_STATE: ProductsContextState = {
  rawData: null,
  rawProducts: null,
  sections: null,
  products: null,
};

const DEFAULT_ACTIONS: ProductsContextActions = {
  getAllProductsInSections: () => undefined,
  getRawProductById: () => undefined,
  getRawProductsByIds: () => undefined,
  getProductById: () => undefined,
  getProductsByIds: () => undefined,
  getCrossSellProducts: () => undefined,
};

const ProductsAction = {
  setRawData: createAction<IProductLayoutResponse>("SET_RAW_DATA"),
  setRawProducts: createAction<IProduct[] | null>("SET_RAW_PRODUCTS"),
  setSections: createAction<ProductListItem[]>("SET_SECTIONS"),
  setProducts: createAction<ProductListItem[]>("SET_PRODUCTS"),
};

const productsReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(ProductsAction.setRawData, (state, { payload }) => {
      state.rawData = payload;
    })
    .addCase(ProductsAction.setRawProducts, (state, { payload }) => {
      state.rawProducts = payload;
    })
    .addCase(ProductsAction.setSections, (state, { payload }) => {
      state.sections = payload;
    })
    .addCase(ProductsAction.setProducts, (state, { payload }) => {
      state.products = payload;
    });
});

export { DEFAULT_ACTIONS, DEFAULT_STATE, ProductsAction, productsReducer };
