import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Outlet } from "react-router-dom";
import PageWrapper from "@base/components/Global/PageWrapper";
import LandingImage from "@base/components/LandingImage/LandingImage";
import SignInBlock from "@base/components/LoyaltyClub/SignInBlock";
import { useWhiteLabelImage } from "@base/hooks/useWhiteLabelImage";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { clearStorage, getStorage, StorageKey } from "@constants/storage";
import { useUser } from "@context/UserProvider";
import HomePageFooter from "@pages/HomePage/components/HomePageFooter";
import { MenuBlockMobileWrapper } from "@pages/HomePage/components/MenuBlock";
import { useAppDispatch, useAppSelector } from "@store";
import { hideProductSearchModal, showProductSearchModal } from "@store/layout";
import { selectAssetCategoryUrls } from "@store/salesPoint";

import ProductList from "./components/ProductList";

const useStyles = createUseStyles(
  ({ color, config: themeConfig, spacing, borderRadius }) => ({
    container: {
      position: "relative",
      paddingBottom: `${themeConfig.footerHeight + spacing.x3l}px`,
      backgroundColor: color.boxListBg,
      borderTopRightRadius: borderRadius.x3l,
      borderTopLeftRadius: borderRadius.x3l,
      marginTop: -50,
      background: color.boxListBg,
      minHeight: "100vh",
    },
    signInBlockContainer: {
      padding: spacing.xxl,
    },
  }),
);

const MobileHomePage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isProductSearchModalVisible = useAppSelector(
    (state) => state.layout.isProductSearchModalVisible,
  );
  const images = useAppSelector((state) =>
    selectAssetCategoryUrls(state, "home_page_mobile_banner"),
  );
  const { shouldShowSignIn } = useUser();

  useEffect(() => {
    const productsScroll = getStorage(StorageKey.PRODUCTS_SCROLL);
    if (productsScroll && Number(productsScroll) > 0) {
      window.scrollTo(0, Number(productsScroll));
      clearStorage(StorageKey.PRODUCTS_SCROLL);
    }
  }, []);

  return (
    <>
      <LandingImage
        shouldDelayContraction
        hasTitle
        hasTopGradient
        hasBottomGradient
        imageUrls={images.map((image) => ({
          img: getImageFromAPI(image, 1290, 1290),
          placeholderImg: getImageFromAPI(image, 129, 129),
        }))}
      />
      <PageWrapper>
        <div className={classes.container} id="home-content">
          {shouldShowSignIn && (
            <div className={classes.signInBlockContainer}>
              <SignInBlock buttonId="sign-in-homepage" />
            </div>
          )}

          <MenuBlockMobileWrapper />
          <Outlet />
          <ProductList />
          <HomePageFooter
            openModal={() => dispatch(showProductSearchModal())}
            closeModal={() => dispatch(hideProductSearchModal())}
            isOpen={isProductSearchModalVisible}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default MobileHomePage;
