import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import LazyLoad, { forceVisible } from "react-lazyload";
import { generatePath, useNavigate } from "react-router-dom";
import { useTimeoutFn } from "react-use";
import CartService, { AddItemToCart } from "@api/service/cartService";
import { isPresent } from "@apl-digital/utils";
import AmountButton from "@base/components/Cart/AmountBtn";
import Icon from "@base/components/Global/Icon";
import { useTemporaryState } from "@base/hooks/useTemporaryState";
import { getImageFromAPI } from "@base/utils/imageHelper";
import config from "@constants/config";
import { NavigationPath, ProductID } from "@constants/navigation";
import { setStorage, StorageKey } from "@constants/storage";
import DeleteItemsModal from "@pages/CartPage/components/DeleteItemsModal";
import { useAppDispatch, useAppSelector } from "@store";
import { useProducts } from "@store/ProductsProvider";
import {
  ProductListItem,
  ProductListItemType,
} from "@store/ProductsProvider/types";
import {
  addProductToCart,
  removeLinesFromCart,
  selectCanEditShoppingCart,
  selectCurrentTransactionId,
  selectShoppingCartItemCount,
  selectShoppingCartUnifiedProducts,
  UnifiedCartLineModifierChoice,
} from "@store/shoppingCart";
import { getBlobStorage, getBrandTheme } from "@theme";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

const useStyles = createUseStyles(
  ({ spacing, color, font, borderRadius, opacity }) => ({
    itemContainer: {
      padding: [spacing.l, spacing.xxl],
      display: "flex",
      cursor: "pointer",
      width: "100%",
      gap: spacing.s,
      backgroundColor: color.productRowBg,
      borderRadius: borderRadius.l,

      "@media (hover: hover)": {
        "&:hover": {
          transform: "scale(1.03)",
        },
      },

      transition: ["transform", "150ms", "ease-out"],
    },
    shadow: {
      boxShadow: color.boxShadowGlobal,
    },
    containerHolder: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      flex: 1,
    },
    itemTextContainer: {
      width: "100%",
      height: 126,
      flexBasis: "calc(100% - 88px)",
      paddingRight: spacing.s,
      display: "flex",
      flexDirection: "column",
      gap: spacing.s,
      justifyContent: "center",
    },
    itemTitle: {
      fontSize: font.size.l,
      fontWeight: font.weight.m,
      lineHeight: font.lineHeight.m,
      color: color.productRowTitle,
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
    },
    itemPrice: {
      fontSize: font.size.l,
      fontWeight: font.weight.m,
      lineHeight: font.lineHeight.s,
      color: color.productRowPrice,
    },
    itemDescription: {
      fontSize: font.size.xs,
      lineHeight: font.lineHeight.xxs,
      fontWeight: font.weight.m,
      color: color.productRowDescription,
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
    },
    itemPicture: {
      width: 126,
      minWidth: 126,
      height: 126,
      borderRadius: "50%",
      marginLeft: "auto",
      flexShrink: 0,
      alignSelf: "center",
      position: "relative",
    },
    itemsInCart: {
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    featuredContainer: {
      backgroundColor: color.productRowFeaturedBg,
      borderRadius: borderRadius.l,
    },
    featuredText: {
      color: color.productRowFeaturedText,
    },
    disabled: {
      opacity: opacity.disabled,
    },
    disabledText: {
      color: color.warningBg,
      fontSize: font.size.l,
      display: "flex",
      alignItems: "center",
      position: "absolute",
      transform: "translateY(calc(-50% + 75px))",
    },
    productItemCarouselMode: {
      flexDirection: "column-reverse",
      height: 276,
      textAlign: "center",
    },
    productItemCarouselModePicture: {
      margin: 0,
    },
    carouselPrice: {
      marginTop: "auto",
    },
  }),
);

type Props = {
  item: ProductListItem;
  carouselMode?: boolean;
  shouldForceLazyLoad?: boolean;
  onClick?: () => void;
  showShadow?: boolean;
};

const ProductItem: React.FC<Props> = ({
  item,
  carouselMode,
  shouldForceLazyLoad,
  onClick,
  showShadow = false,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("homePage");
  const [, { getProductById }] = useProducts();

  const itemIds =
    item.type === ProductListItemType.VARIABLE
      ? item.subItems?.map((i) => i.ID) || []
      : [item.id];

  const productsInCart = useAppSelector((state) =>
    selectShoppingCartUnifiedProducts(state, itemIds),
  );
  const canEdit = useAppSelector(selectCanEditShoppingCart);
  const transactionId = useAppSelector(selectCurrentTransactionId);
  const shoppingCartTotalItemCount = useAppSelector(
    selectShoppingCartItemCount,
  );

  const productAmountInCart = productsInCart.reduce(
    (previousValue, currentValue) => previousValue + currentValue.amount,
    0,
  );

  const [currentProductAmount, setCurrentProductAmount] =
    useState<number>(productAmountInCart);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [shouldShowWarning, setShouldShowWarning] = useTemporaryState(
    false,
    3000,
  );
  const [isAmountButtonActive, setIsAmountButtonActive] = useState(false);
  const [_, __, reset] = useTimeoutFn(() => {
    setIsAmountButtonActive(false);
  }, 4000);

  const { warningIcon } = getBlobStorage();
  const { color } = getBrandTheme();

  useEffect(() => {
    setCurrentProductAmount(productAmountInCart);
  }, [productAmountInCart]);

  useEffect(() => {
    // Pictures don't appear in search because no scroll or other effect is triggered.
    // This forces the pictures to load.
    if (shouldForceLazyLoad) {
      forceVisible();
    }
  }, [shouldForceLazyLoad]);

  const currentProduct =
    productsInCart.length === 1 ? productsInCart[0] : undefined;

  let renderItem: ProductListItem | undefined;

  if (item.type === ProductListItemType.VARIABLE && item.mainProductId) {
    const mainProductObject = getProductById(item.mainProductId);
    const defaultSubItem = item.subItems?.find((el) => el.IsDefault);
    let subItem;
    if (defaultSubItem) {
      subItem = getProductById(defaultSubItem.ID);
    }
    renderItem = {
      ...mainProductObject,
      id: item.id,
      type: item.type,
      mainProductId: item.mainProductId,
      title: item.title,
      description: item.description,
      thumbnail: mainProductObject?.picture,
      grossPrice: subItem?.grossPrice || 0,
    } as ProductListItem;
  } else {
    renderItem = item;
  }

  if (!renderItem) {
    return null;
  }

  const navigateToItem = () => {
    if (item.isDisabled) setShouldShowWarning(true);
    else {
      onClick?.();
      setStorage(StorageKey.PRODUCTS_SCROLL, String(window.scrollY));
      setTimeout(
        () =>
          navigate(
            generatePath(NavigationPath.Product, {
              [ProductID]: String(item.id),
            }),
          ),
        0,
      );
    }
  };

  const shouldNavigateToItem =
    productsInCart.length > 1 ||
    (productAmountInCart === 0 && item.modifiers?.Modifiers) ||
    (item.subItems && item.subItems.length > 0);

  const onAddProductClicked = () => {
    if (shouldNavigateToItem) {
      navigateToItem();
    } else {
      setIsAmountButtonActive(true);
      reset();
    }
  };

  const addToCart = (amount: number) => {
    if (!isPresent(transactionId) || amount < 0) {
      return;
    }

    let itemToAdd: AddItemToCart | undefined;

    if (isPresent(currentProduct)) {
      itemToAdd = {
        productId: currentProduct.productId,
        amount: amount,
        modifiers: currentProduct.modifiers
          .filter(
            (modifier): modifier is UnifiedCartLineModifierChoice =>
              modifier.type === "choice",
          )
          .map((modifier) => ({
            ProductModifierID: modifier.modifierId,
            ProductModifierChoiceID: modifier.modifierChoiceId,
            Amount: modifier.amount,
            ProductId: currentProduct.productId,
          })),
        noteToKitchen: currentProduct.noteToKitchen,
        variableProductID: item.mainProductId,
      };
    } else {
      itemToAdd = {
        productId: item.id,
        amount: amount,
      };
    }

    dispatch(
      addProductToCart({
        transactionId,
        body: CartService.buildAddItemRequest(itemToAdd),
      }),
    );
  };

  const removeFromCart = (amount: number) => {
    if (!isPresent(transactionId) || !isPresent(currentProduct) || amount < 0) {
      return;
    }

    const cartLineIdsToRemove = currentProduct.mergedCartLineIds.slice(
      currentProduct.mergedCartLineIds.length - amount,
    );

    dispatch(
      removeLinesFromCart({
        transactionId,
        cartLineIds: cartLineIdsToRemove,
      }),
    );
  };

  useEffect(() => {
    if (currentProductAmount > productAmountInCart) {
      addToCart(currentProductAmount - productAmountInCart);
    } else if (currentProductAmount < productAmountInCart) {
      removeFromCart(productAmountInCart - currentProductAmount);
    }
  }, [currentProductAmount]);

  const onDeleteItemConfirmed = () => {
    setCurrentProductAmount(0);
  };

  return (
    <>
      <DeleteItemsModal
        isOpen={shouldShowModal}
        header={t("ProductItem.deleteItemsModal.title")}
        message={t("ProductItem.deleteItemsModal.message")}
        showModal={setShouldShowModal}
        cartLineIds={
          isPresent(currentProduct)
            ? [
                ...currentProduct.mergedCartLineIds,
                ...currentProduct.relatedChildCartLineIds,
              ]
            : []
        }
        onConfirm={onDeleteItemConfirmed}
      />

      <div className={classes.containerHolder}>
        <AnimatePresence>
          <motion.div
            key={item.id}
            className={classNames([
              classes.itemContainer,
              showShadow && classes.shadow,
              item.isFeatured && classes.featuredContainer,
              item.isDisabled && classes.disabled,
              carouselMode && classes.productItemCarouselMode,
            ])}
            onClick={navigateToItem}
            id={item.title}
            variants={{
              blurred: { filter: "blur(4px)" },
              unblurred: { filter: "blur(0px)" },
            }}
            animate={
              item.isDisabled && shouldShowWarning ? "blurred" : "unblurred"
            }
            transition={{ duration: 0.3 }}
          >
            <div className={classes.itemTextContainer}>
              <div
                className={classNames([
                  classes.itemTitle,
                  item.isFeatured && classes.featuredText,
                ])}
                id={`title${item.id}`}
              >
                {renderItem.title}
              </div>
              {renderItem?.description && (
                <div
                  className={classNames([
                    classes.itemDescription,
                    item.isFeatured && classes.featuredText,
                  ])}
                >
                  {renderItem.description}
                </div>
              )}
              <div
                className={classNames([
                  classes.itemPrice,
                  item.isFeatured && classes.featuredText,
                  carouselMode && classes.carouselPrice,
                ])}
              >
                {t("ProductItem.itemPrice", {
                  grossPrice: renderItem.grossPrice || 0,
                  formatParams: {
                    grossPrice: { currency: "EUR" },
                  },
                })}
              </div>
            </div>
            <div
              className={classNames([
                classes.itemPicture,
                carouselMode && classes.productItemCarouselModePicture,
              ])}
            >
              <LazyLoad offset={200} once>
                <div
                  className={classes.itemPicture}
                  style={
                    Boolean(renderItem?.thumbnail) &&
                    typeof renderItem.thumbnail === "string"
                      ? {
                          backgroundImage: `url(${getImageFromAPI(
                            renderItem.thumbnail,
                          )})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                        }
                      : undefined
                  }
                >
                  {!item.isDisabled && canEdit && (
                    <div className={classes.itemsInCart}>
                      <AmountButton
                        currentAmount={currentProductAmount}
                        canIncrement={(increase) =>
                          config.appConfig.singleItemMaxQuantity >
                            productAmountInCart + increase &&
                          config.appConfig.cartItemMaxQuantity >
                            shoppingCartTotalItemCount + increase
                        }
                        canDecrement={(amount) => amount > 0}
                        onAmountChange={setCurrentProductAmount}
                        onTrashAction={() => setShouldShowModal(true)}
                        onClick={(e) => {
                          e.stopPropagation();
                          onAddProductClicked();
                        }}
                        isOpen={isAmountButtonActive}
                        isDisabled={
                          currentProductAmount === 0 &&
                          (config.appConfig.singleItemMaxQuantity <=
                            productAmountInCart ||
                            config.appConfig.cartItemMaxQuantity <=
                              shoppingCartTotalItemCount)
                        }
                        shouldIncrementOnInitialClick={!shouldNavigateToItem}
                        isReversed
                        hasShadow
                      />
                    </div>
                  )}
                </div>
              </LazyLoad>
            </div>
          </motion.div>

          {item.isDisabled && shouldShowWarning && (
            <motion.div
              key="warning"
              className={classes.disabledText}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Icon
                url={warningIcon}
                stroke={color.warningBg}
                height={24}
                width={24}
              />
              <span>{t("ProductItem.productNotAvailableWarningText")}</span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default ProductItem;
