import { InferType, number, object, string } from "yup";

export const TheatreLayout = object({
  ID: number().required(),
  Name: string().required(),
  Description: string().optional(),
  Barcode: string().required(),
  created: number().optional(), // local value
});

export type ITheatreLayout = InferType<typeof TheatreLayout>;

export type GetByBarcodeRequest = {
  barcodeWithoutChecksum: string;
};
