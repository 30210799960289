import React, {
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import useDebounce from "@base/hooks/useDebounce";
import useWindowSize from "@base/hooks/useWindowSize";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { getStorage, StorageKey } from "@constants/storage";
import { SECTIONS_SELECTOR_HEIGHT } from "@pages/HomePage/components/ProductList";
import { useAppDispatch } from "@store";
import { showProductSearchModal } from "@store/layout";
import { scrollToSection } from "@store/ProductsProvider/helper";
import { ProductListItem } from "@store/ProductsProvider/types";
import { getBlobStorage, getBrandTheme } from "@theme";
import classNames from "classnames";

import HorizontalScrollable from "../Global/HorizontalScrollable";
import Icon from "../Global/Icon";

const SECTION_P_PADDING = 22;
const SECTION_HEIGHT = 48;

const useStyles = createUseStyles(
  ({ spacing, color, font, borderRadius, mediaQuery }) => ({
    container: {
      position: "fixed",
      top: 0,
      zIndex: 2,
      left: 0,
      right: 0,
      background: color.topCategoriesBg,
      padding: [0, spacing.xl],
      [mediaQuery.lg]: {
        padding: [0, spacing.x5l],
      },
      display: "none",
    },
    show: {
      display: "block",
    },
    sectionsContainer: {
      display: "flex",
      gap: spacing.xl,
      [mediaQuery.lg]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    sectionsContainerStick: {
      maxWidth: 1464 - 2 * spacing.x5l,
      margin: "0 auto",
    },
    sectionItem: {
      height: SECTION_HEIGHT,
      fontSize: font.size.l,
      lineHeight: font.lineHeight.m,
      color: color.subCategoriesText,
      textDecoration: "none",
      position: "relative",
      borderRadius: borderRadius.button,
      display: "flex",
      flexDirection: "row",
      "&:not(:last-child)": {
        marginRight: spacing.xxl,
      },
      "& p": {
        paddingLeft: SECTION_P_PADDING,
        paddingRight: SECTION_P_PADDING,
        alignSelf: "center",
      },
      "& p:has(img)": {
        paddingRight: spacing.xxl,
      },
    },
    sectionItemImage: {
      right: 0,
      bottom: 0,
      borderTopRightRadius: borderRadius.x3l,
      borderBottomRightRadius: borderRadius.x3l,
      objectFit: "cover",
    },
    sectionItemActive: {
      color: color.topCategoriesActiveText,
      position: "relative",
      "&:after": {
        content: "''",
        position: "absolute",
        top: "100%",
        left: "50%",
        marginLeft: "-10px",
        width: 0,
        height: 0,
        borderTop: "solid 10px",
        borderTopColor: "var(--sectionItemActiveArrowColor)",
        borderLeft: "solid 10px transparent",
        borderRight: "solid 10px transparent",
      },
    },
    sectionSelectorsList: {
      width: "100%",
      overflowX: "auto",
      whiteSpace: "nowrap",
      display: "flex",
      overflow: "-moz-scrollbars-none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        width: "0 !important",
        height: "0 !important",
      },
      scrollbarWidth: "none",
      padding: [spacing.xxl, 0],
    },
    menuButtonContainer: {
      padding: [spacing.xxl, 0],
    },
    menuButton: {
      borderRadius: borderRadius.button,
      height: SECTION_HEIGHT,
      backgroundColor: color.topCategoriesMenuButtonBg,
      color: color.topCategoriesMenuButtonText,
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      padding: [0, spacing.l],
      gap: spacing.xs,
      cursor: "pointer",

      "& span": {
        textTransform: "uppercase",
        fontSize: font.size.m,
        whiteSpace: "nowrap",
      },
    },
  }),
);

const FixedSectionScroller: React.FC<{
  activeSection: ProductListItem | null;
  sectionListRef: RefObject<HTMLDivElement>;
  sections: ProductListItem[];
}> = ({ activeSection, sectionListRef, sections }) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const theme = getBrandTheme();
  const [left, setLeft] = useState<number>(0);
  const debouncedLeft = useDebounce(left, 50);
  const [activeSectionBg, setActiveSectionBg] = useState<string | undefined>();
  const { isMdAndUp } = useWindowSize();
  const { magnifyingGlassIcon } = getBlobStorage();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    const onScroll = () => {
      const isModalOpen = getStorage(StorageKey.IS_MODAL_OPEN);
      if (sectionListRef.current && !isModalOpen) {
        const elementBounding = sectionListRef.current.getBoundingClientRect();
        setIsVisible(
          elementBounding.top <=
            SECTIONS_SELECTOR_HEIGHT + theme.config.mobileNavbarHeight,
        );
      }
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const sectionContainer = document.getElementById("section-selectors-list");
    const isModalOpen = getStorage(StorageKey.IS_MODAL_OPEN);
    if (sectionContainer && !isModalOpen) {
      sectionContainer.scrollTo({
        left: debouncedLeft,
        behavior: "smooth",
      });
    }
  }, [debouncedLeft]);

  useLayoutEffect(() => {
    const isModalOpen = getStorage(StorageKey.IS_MODAL_OPEN);
    if (activeSection && !isModalOpen) {
      const sectionContainer = document.getElementById(
        "section-selectors-list",
      );
      const sectionContainerChildren = sectionContainer?.children;
      let newLeft = 0;
      if (sectionContainerChildren) {
        for (let i = 0; i < sectionContainerChildren.length; i++) {
          const element = sectionContainerChildren[i];
          const firstClass = element.classList[0];
          const secondClass = element.classList[1];
          if (secondClass && secondClass.includes("sectionItemActive")) {
            const { width } = element.getBoundingClientRect() || {};
            if (width) {
              newLeft += width * 0.5 + 96;
            }
            break;
          }
          if (firstClass && firstClass.includes("sectionItem")) {
            const { width } = element.getBoundingClientRect() || {};
            if (width) {
              newLeft += width;
            }
          }
        }

        const listElement = document.getElementById("section-selectors-list");
        if (listElement) {
          const halfListWidth = listElement.getBoundingClientRect().width / 2;
          newLeft -= halfListWidth;
        }
        setLeft(newLeft);
      }
    }
    setActiveSectionBg(activeSection?.bgColor);
  }, [activeSection]);

  useEffect(() => {
    if (isVisible && activeSectionBg) {
      const parentSection: HTMLElement | null = document.getElementById(
        `section-link-${activeSection?.id}`,
      );
      parentSection?.style.setProperty(
        "--sectionItemActiveArrowColor",
        activeSectionBg,
      );
    }
  }, [activeSectionBg, isVisible]);

  const openSearchModal = () => dispatch(showProductSearchModal());

  return (
    <div
      className={classNames(classes.container, isVisible && classes.show)}
      id="section-selectors-bar"
    >
      <div
        className={classNames(
          classes.sectionsContainer,
          classes.sectionsContainerStick,
        )}
      >
        {isMdAndUp && (
          <div className={classes.menuButtonContainer} id="header-menu-btn">
            <div
              role="button"
              tabIndex={0}
              className={classes.menuButton}
              onClick={openSearchModal}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  openSearchModal();
                }
              }}
            >
              <Icon
                url={magnifyingGlassIcon}
                stroke={theme.color.menuBlockSearchIcon}
                height={24}
                width={24}
              />
              <span>{t("menu_title")}</span>
            </div>
          </div>
        )}
        <HorizontalScrollable
          scrollContainerRef={scrollContainerRef}
          shouldShowArrows={isMdAndUp}
        >
          <div
            id="section-selectors-list"
            className={classes.sectionSelectorsList}
            ref={scrollContainerRef}
          >
            {sections.map((section) => (
              <a
                href={`#section-${section.id}`}
                key={`section-link-${section.id}`}
                id={`section-link-${section.id}`}
                className={classNames(
                  classes.sectionItem,
                  section.id === activeSection?.id && classes.sectionItemActive,
                )}
                style={{
                  backgroundColor: section.bgColor,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(section.id, isMdAndUp ? 60 : undefined);
                }}
              >
                <p>{section.title}</p>
                {section.picture && (
                  <img
                    src={getImageFromAPI(section.picture)}
                    className={classes.sectionItemImage}
                    width={48}
                    height={48}
                    alt=""
                  />
                )}
              </a>
            ))}
          </div>
        </HorizontalScrollable>
      </div>
    </div>
  );
};

export default FixedSectionScroller;
