import React from "react";
import NavbarDesktop from "@base/components/Navbar/NavbarDesktop";
import NavbarMobile from "@base/components/Navbar/NavbarMobile";
import useWindowSize from "@base/hooks/useWindowSize";

const Navbar = () => {
  const { isMdAndUp } = useWindowSize();

  if (isMdAndUp) return <NavbarDesktop />;

  return <NavbarMobile />;
};

export default Navbar;
