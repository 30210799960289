import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

import Icon from "./Icon";

const useStyles = createUseStyles(({ color, spacing }) => ({
  container: {
    display: "flex",
    gap: spacing.xs,
    color: color.defaultText,
  },
  iconContainer: {
    padding: [0, spacing.xs],
  },
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const IconWithText: React.FC<{
  iconUrl: string;
  onClick?: () => void;
  interactiveElementId?: string;
  className?: string;
  children?: React.ReactNode;
}> = ({ iconUrl, onClick, interactiveElementId, className, children }) => {
  const classes = useStyles();

  return (
    <div className={classNames([classes.container, className])}>
      <div className={classes.iconContainer}>
        <Icon url={iconUrl} stroke="currentColor" height={24} width={24} />
      </div>
      <div>
        {onClick ? (
          <span
            onClick={onClick}
            className={classes.clickable}
            tabIndex={0}
            onKeyDown={(e) => {
              e.preventDefault();
              if (["Enter", " "].includes(e.key)) {
                onClick();
              }
            }}
            id={interactiveElementId}
          >
            {children}
          </span>
        ) : (
          <span>{children}</span>
        )}
      </div>
    </div>
  );
};

export default IconWithText;
