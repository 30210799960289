import { createUseStyles } from "react-jss";

const useNavbarStyles = createUseStyles(({ color }) => ({
  showNavbar: {
    top: "0px !important",
    transition: "300ms ease",
  },
  icon: {
    display: "flex",
    cursor: "pointer",
    width: 40,
    height: 40,
  },
  closeIcon: {
    borderRadius: "50%",
    background: "rgba(255, 255, 255, 0.5)",
    justifyContent: "center",
    padding: 10,
    marginLeft: "auto",
  },
  navbarBackground: {
    backgroundColor: color.topNavBarBg,
  },
  cartPageNavbar: {
    position: "relative",
  },
}));

export default useNavbarStyles;
