import React from "react";
import { createUseStyles } from "react-jss";
import { getBrandTheme } from "@theme";
import classNames from "classnames";

const useStyles = createUseStyles(
  ({ borderRadius, color, config, spacing }) => ({
    boxList: {
      display: "flex",
      flexDirection: "column",
      width: `calc(100vw - ${spacing.x5l}px)`,
      left: spacing.xxl,
      gap: config.boxGap,
      backgroundColor: color.boxListBg,
      borderTopLeftRadius: borderRadius.x3l,
      borderTopRightRadius: borderRadius.x3l,
    },
  }),
);

type Props = {
  onClick?: () => void;
  hasFooter?: boolean;
  id?: string;
  className?: string;
  children?: React.ReactNode;
};

const BoxList: React.FC<Props> = ({
  children,
  onClick,
  hasFooter = true,
  id = "boxlist",
  className,
}) => {
  const classes = useStyles();
  const { config } = getBrandTheme();

  return (
    <div
      id={id}
      className={classNames([classes.boxList, className])}
      onClick={onClick}
      style={{
        paddingBottom: hasFooter ? config.footerHeight : undefined,
        position: hasFooter ? "absolute" : "relative",
      }}
    >
      {children}
    </div>
  );
};

export default BoxList;
