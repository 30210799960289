import { createUseStyles } from "react-jss";

export const useSharedStyles = createUseStyles(({ spacing, font }) => ({
  menuItemSubMenu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: spacing.s,
    fontSize: font.size.s,
    fontWeight: font.weight.m,
    cursor: "pointer",
  },
}));
