const getDateFromUTC = (date: string | Date) => {
  const dateObject = new Date(date);
  return new Date(
    Date.UTC(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate(),
      dateObject.getHours(),
      dateObject.getMinutes(),
      dateObject.getSeconds(),
      dateObject.getMilliseconds(),
    ),
  );
};

const getUnixCurrentDate = () => Date.now();

const getUnixDateFromUTC = (date: string | Date) =>
  getDateFromUTC(date).getTime();

export { getDateFromUTC, getUnixCurrentDate, getUnixDateFromUTC };
