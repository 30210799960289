import React, { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ECheckoutOptionType } from "@api/interfaces/cartLayouts";
import { IPaymentStatus } from "@api/interfaces/paymentLayouts";
import { isPresent } from "@apl-digital/utils";
import Loader from "@base/components/Global/Loader";
import useWindowSize from "@base/hooks/useWindowSize";
import { NavigationPath, OrderID } from "@constants/navigation";
import { useAppDispatch, useAppSelector } from "@store";
import { paymentCallbackCompleted, resetPayment } from "@store/payment";
import {
  resetShoppingCart,
  selectCurrentTransactionId,
  unbundleShoppingCart,
} from "@store/shoppingCart";

const validStatuses: string[] = [
  IPaymentStatus.SUCCESS,
  IPaymentStatus.CANCELLED,
  IPaymentStatus.ERROR,
] as const;

const isPaymentStatusValid = (status?: string): boolean => {
  return isPresent(status) && !validStatuses.includes(status);
};

const PaymentLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { orderID, paymentStatus } = useParams();
  const { isMdAndUp } = useWindowSize();

  const transactionId = useAppSelector(selectCurrentTransactionId);

  useEffect(() => {
    if (!isPresent(orderID)) {
      console.error("No order id found");
      dispatch(resetShoppingCart());
      dispatch(resetPayment());
      navigate(NavigationPath.Home);
      return;
    }

    if (isPaymentStatusValid(paymentStatus)) {
      console.error("Invalid payment status", paymentStatus);

      navigate(
        generatePath(NavigationPath.OrderStatus, {
          [OrderID]: orderID,
        }),
      );
      return;
    }

    if (paymentStatus === IPaymentStatus.SUCCESS) {
      dispatch(
        paymentCallbackCompleted({
          type: "success",
          barcodeWithoutChecksum: orderID,
          checkoutOptionType: ECheckoutOptionType.PayNow,
        }),
      );

      navigate(
        generatePath(NavigationPath.OrderStatus, {
          [OrderID]: orderID,
        }),
      );
      return;
    }

    if (paymentStatus === IPaymentStatus.CANCELLED) {
      dispatch(
        paymentCallbackCompleted({
          type: "cancelled",
          barcodeWithoutChecksum: orderID,
          checkoutOptionType: ECheckoutOptionType.PayNow,
        }),
      );

      if (isPresent(transactionId)) {
        dispatch(unbundleShoppingCart({ transactionId }));
      }

      if (isMdAndUp) {
        navigate(NavigationPath.Home);
      } else {
        navigate(NavigationPath.Cart);
      }

      return;
    }

    if (paymentStatus === IPaymentStatus.ERROR) {
      dispatch(
        paymentCallbackCompleted({
          type: "error",
          barcodeWithoutChecksum: orderID,
          checkoutOptionType: ECheckoutOptionType.PayNow,
        }),
      );

      if (isPresent(transactionId)) {
        dispatch(unbundleShoppingCart({ transactionId }));
      }

      if (isMdAndUp) {
        navigate(NavigationPath.Home);
      } else {
        navigate(NavigationPath.Cart);
      }

      return;
    }
  }, []);

  return <Loader isFullScreen />;
};

export default PaymentLandingPage;
