import { DefaultTheme } from "react-jss";

import { commonLightTheme } from "../common.light";

export const urbanKitchenLightTheme: DefaultTheme = {
  ...commonLightTheme,
  blobStorage: {
    ...commonLightTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/light/logo/logo-urban-kitchen.svg",
  },
  config: {
    ...commonLightTheme.config,
    brandLogoWidth: 117,
    brandLogoHeight: 45,
    sectionsColors: "#FFEFDC;#FBF2D2;#F0F6DF;#EAF7F9;#FFE1F3;#FFECEC",
  },
  color: {
    ...commonLightTheme.color,
    productRowFeaturedBg: "#C84771",
    productRowFeaturedText: "#FFFFFF",
  },
} as const;
