import React from "react";
import { createUseStyles } from "react-jss";
import { Modal } from "@base/components/Global/Modal";
import { scrollToSection } from "@store/ProductsProvider/helper";

import MenuSearchDesktop from "./MenuSearchDesktop";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: 700,
    width: 750,
  },
});

const SearchModalDesktopWrapper: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const classes = useStyles();

  const handleClose = (id?: number) => {
    onClose();

    if (id) {
      setTimeout(() => scrollToSection(id), 100);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} showCloseButton={false}>
      <div className={classes.container}>
        <MenuSearchDesktop closeModal={handleClose} />
      </div>
    </Modal>
  );
};

export default SearchModalDesktopWrapper;
