import { useEffect, useRef } from "react";

type RegularCallback = () => void;
type AsyncCallback = () => Promise<void>;
type Callback = RegularCallback | AsyncCallback;

export default function useInterval(
  callback: Callback,
  delay: number,
  enabled = true,
) {
  const savedCallback = useRef<Callback>(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id: NodeJS.Timeout;

    if (enabled && delay !== null) {
      id = setInterval(tick, delay);
    }

    return () => clearInterval(id);
  }, [delay, enabled]);
}
