import { isPresent } from "@apl-digital/utils";
import { createSelector } from "@reduxjs/toolkit";
import { isStatus } from "@store/utils";
import { endOfToday, isBefore } from "date-fns";

import {
  mapOrderDataToOrderDataWithGrouping,
  mapOrderStatusResponseToOrderData,
} from "./mappers";
import { selectOrders } from "./slice";
import { sortOrdersByCreatedDateOrSelected } from "./utils";

export const selectOrderData = createSelector([selectOrders], (orders) =>
  orders.map((order) =>
    isStatus(order, ["stale", "stalePending", "succeeded", "orderMoved"])
      ? { ...order, state: mapOrderStatusResponseToOrderData(order.state) }
      : order,
  ),
);

const selectActiveOrders = createSelector([selectOrderData], (orders) =>
  orders.filter(
    (order) =>
      (isStatus(order, ["stale", "stalePending", "succeeded"]) &&
        !order.state.isComplete) ||
      isStatus(order, ["idle", "pending", "failedPending", "failed"]),
  ),
);

const selectInactiveOrders = createSelector([selectOrderData], (orders) =>
  orders.filter(
    (order) =>
      (isStatus(order, ["stale", "stalePending", "succeeded"]) &&
        order.state.isComplete) ||
      isStatus(order, ["notFound", "orderMoved"]),
  ),
);

export const selectActiveOrdersWithGrouping = createSelector(
  [
    selectActiveOrders,
    (state, selectedOrderId: string | undefined) => selectedOrderId,
  ],
  (orders, selectedOrderId) =>
    orders
      .map((order) =>
        isStatus(order, ["stale", "stalePending", "succeeded", "orderMoved"])
          ? {
              ...order,
              state: mapOrderDataToOrderDataWithGrouping(order.state),
            }
          : order,
      )
      .sort((a, b) =>
        sortOrdersByCreatedDateOrSelected(a.state, b.state, selectedOrderId),
      ),
);

export const selectInactiveOrdersWithGrouping = createSelector(
  [
    selectInactiveOrders,
    (state, selectedOrderId: string | undefined) => selectedOrderId,
  ],
  (orders, selectedOrderId) =>
    orders
      .map((order) =>
        isStatus(order, ["stale", "stalePending", "succeeded", "orderMoved"])
          ? {
              ...order,
              state: mapOrderDataToOrderDataWithGrouping(order.state),
            }
          : order,
      )
      .sort((a, b) =>
        sortOrdersByCreatedDateOrSelected(a.state, b.state, selectedOrderId),
      ),
);

export const selectOrderWithGroupingByBarcodeWithoutChecksum = createSelector(
  [
    selectOrderData,
    (state, barcodeWithoutChecksum: string | undefined) =>
      barcodeWithoutChecksum,
  ],
  (orders, barcodeWithoutChecksum) => {
    const existingOrder = orders.find(
      (order) => order.state.BarcodeWithoutChecksum === barcodeWithoutChecksum,
    );

    if (existingOrder) {
      return isStatus(existingOrder, [
        "stale",
        "stalePending",
        "succeeded",
        "orderMoved",
      ])
        ? {
            ...existingOrder,
            state: mapOrderDataToOrderDataWithGrouping(existingOrder.state),
          }
        : existingOrder;
    }

    return;
  },
);

export const selectActiveAndSelectedOrdersWithGrouping = createSelector(
  [
    selectActiveOrdersWithGrouping,
    (state, selectedOrderId: string | undefined) =>
      selectOrderWithGroupingByBarcodeWithoutChecksum(state, selectedOrderId),
  ],
  (activeOrders, selectedOrder) => {
    const orders = [...activeOrders];

    if (isPresent(selectedOrder)) {
      const selectedOrderIndex = orders.findIndex(
        (order) =>
          order.state.BarcodeWithoutChecksum ===
          selectedOrder.state.BarcodeWithoutChecksum,
      );

      if (selectedOrderIndex === -1) {
        orders.unshift(selectedOrder);
      } else {
        orders.splice(selectedOrderIndex, 1);
        orders.unshift(selectedOrder);
      }
    }

    return orders;
  },
);

export const selectOrdersForRefresh = createSelector([selectOrders], (orders) =>
  orders
    .filter(
      (order) =>
        isStatus(order, ["idle", "stale", "failed"]) &&
        !(isStatus(order, "failed") && order.retryCount > 3),
    )
    .map((order) => order.state.BarcodeWithoutChecksum),
);

export const selectActiveOrdersCount = createSelector(
  selectActiveOrders,
  (orders) => orders.length,
);

export const selectNoneExpiredOrders = createSelector(
  [selectOrders, selectActiveOrders, selectInactiveOrders],
  (orders, activeOrders, inactiveOrders) => {
    const noneExpiredBarCodes = inactiveOrders
      .filter(
        (order) =>
          (isStatus(order, ["stale", "stalePending", "succeeded"]) &&
            isPresent(order.state.dttmLastStatusChange) &&
            isBefore(
              new Date(order.state.dttmLastStatusChange),
              endOfToday(),
            )) ||
          isStatus(order, ["idle", "pending", "failedPending", "failed"]),
      )
      .concat(activeOrders)
      .map((order) => order.state.BarcodeWithoutChecksum);

    return orders.filter((order) =>
      noneExpiredBarCodes.includes(order.state.BarcodeWithoutChecksum),
    );
  },
);

export const selectIsOrderRegistered = createSelector(
  [
    selectOrders,
    (state, barcodeWithoutChecksum: string | undefined) =>
      barcodeWithoutChecksum,
  ],
  (orders, barcodeWithoutChecksum) =>
    orders.some(
      (order) => order.state.BarcodeWithoutChecksum === barcodeWithoutChecksum,
    ),
);
