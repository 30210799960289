import { put } from "@api";
import { IUserLayout, IUserLoginRequest } from "@api/interfaces/userLayouts";
import { ApiPath } from "@api/paths";
import { ApiRequest, BodyWrapper, RequestResponse } from "@api/types";

const personData: ApiRequest<
  RequestResponse<IUserLayout>,
  BodyWrapper<IUserLoginRequest>
> = async ({ ...options }) =>
  put({
    ...options,
    api: ApiPath.User.person,
    canFail: true,
  });

const UserAuth = {
  personData,
};

export default UserAuth;
