import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles(({ font, spacing, color }) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    gap: spacing.m,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flexDirection: "column",
    gap: spacing.s,
    alignItems: "center",
    alignSelf: "center",
  },
  label: {
    width: "fit-content",
    color: color.orderStatusCardQueueNumberLabelTextColor,
    fontSize: font.size.x3l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.xl,
  },
  value: {
    width: "fit-content",
    color: color.orderStatusCardQueueNumberValueTextColor,
    fontSize: font.size.x4l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.xxl,
    textWrap: "nowrap",
  },
}));

type OrderCardQueueNumberBlockProps = {
  orderQueueNumber: string;
  variant: "column" | "row";
};

export const OrderCardQueueNumberBlock: React.FC<
  OrderCardQueueNumberBlockProps
> = ({ orderQueueNumber, variant }) => {
  const classes = useStyles();
  const { t } = useTranslation("orderStatusPage");

  return (
    <div className={classNames(classes.container, classes[variant])}>
      <div className={classes.label}>
        {t("OrderCardQueueNumberBlock.orderQueueNumberLabel")}
      </div>
      <div className={classes.value}>{orderQueueNumber}</div>
    </div>
  );
};
