import React from "react";
import { createUseStyles } from "react-jss";
import { ReactSVG } from "react-svg";
import { isPresent } from "@apl-digital/utils";
import classNames from "classnames";
import { motion } from "framer-motion";

const useStyles = createUseStyles(({ color }) => ({
  container: {
    position: "relative",
    border: [2, "solid", color.toggleBorderColor],
    borderRadius: 100,
    width: 246,
    height: 136,
    boxSizing: "content-box",
    backgroundColor: color.toggleBackground,
  },
  singleOptionVariant: {
    width: 136,
  },
  options: {
    position: "absolute",
    inset: 12,
    display: "flex",
  },
  option: {
    flexBasis: "50%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: color.toggleOptionColor,
    userSelect: "none",
    transition: ["color", "150ms", "ease-out"],

    "& svg path": {
      stroke: "currentColor",
    },

    "@media (hover: hover)": {
      "&:not($optionSelected):hover": {
        color: color.toggleHoveredOptionColor,
      },
    },
  },
  optionSelected: {
    color: color.toggleSelectedOptionColor,
  },
  ring: {
    position: "absolute",
    width: 120,
    height: 120,
    backgroundColor: color.toggleSelectedOptionBackground,
    borderRadius: 60,

    top: 8,
    left: 8,

    transition:
      "left 250ms cubic-bezier(.51,.92,.24,1.15), transform 150ms cubic-bezier(.51,.92,.24,1.15)",
  },
  ringActive: {
    transform: "scale(0.98)",
  },
}));

export type ToggleOption<TValue> = {
  label: string;
  iconUrl: string;
  value: TValue;
  optionId: string;
};

type ToggleProps<TValue> = {
  value: TValue | null;
  options: ToggleOption<TValue>[];
  onChange: (value: TValue) => void;
};

const Toggle = <TValue,>({ value, options, onChange }: ToggleProps<TValue>) => {
  const classes = useStyles();

  if (options.length < 1) {
    return null;
  }

  return (
    <div
      className={classNames(
        classes.container,
        options.length === 1 && classes.singleOptionVariant,
      )}
    >
      <motion.div
        whileHover={classes.ringActive}
        className={classNames(isPresent(value) && classes.ring)}
        style={{
          left:
            value === options[0].value
              ? 8
              : value === options[1]?.value
                ? "calc(100% - 128px)"
                : 8,
        }}
      ></motion.div>
      <div className={classes.options}>
        {options.map((option, index) => (
          <div
            key={index}
            tabIndex={index}
            className={classNames(
              classes.option,
              value === option.value && classes.optionSelected,
            )}
            onClick={() => onChange(option.value)}
            id={option.optionId}
          >
            <ReactSVG src={option.iconUrl} />
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Toggle;
