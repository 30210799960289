import React from "react";
import { createUseStyles } from "react-jss";
import { ReactSVG } from "react-svg";

const useStyles = createUseStyles(({}) => ({
  "@keyframes pulse": {
    "0%": {
      opacity: 0,
      transform: "scale(0.4)",
    },
    "60%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(1)",
    },
  },
  svg: {
    "& ellipse": {
      transformOrigin: "50% 50%",
      animationName: "$pulse",
      animationDuration: "1.5s",
      animationIterationCount: "infinite",
      animationTimingFunction: "ease",
    },
  },
}));

export const StepperTicker: React.FC<{ url: string }> = ({ url }) => {
  const classes = useStyles();

  return <ReactSVG src={url} className={classes.svg} />;
};
