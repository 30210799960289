import React from "react";
import { useTranslation } from "react-i18next";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "@base/components/AppRouter";
import MetaTags from "@base/components/MetaTags";
import config from "@constants/config";
import { SalesPointContextProvider } from "@context/SalesPointProvider";
import { ErrorPage } from "@pages/ErrorPage";
import * as Sentry from "@sentry/react";

const ConfiguredApp: React.FC = () => {
  const { i18n } = useTranslation();

  const oidcSettings: AuthProviderProps = {
    authority: config.ssoConfig.authorityUrl,
    client_id: config.ssoConfig.clientId ?? "",
    redirect_uri: `${window.origin}/auth`,
    response_type: "code",
    scope: "openid profile",
    automaticSilentRenew: true,
    redirectMethod: "replace",
    acr_values: `culture:${i18n.language}`,
  };

  return (
    <SalesPointContextProvider>
      <BrowserRouter>
        <AuthProvider {...oidcSettings}>
          <AppRouter />
        </AuthProvider>
      </BrowserRouter>
    </SalesPointContextProvider>
  );
};

const App: React.FC = () => {
  const scope = Sentry.getCurrentScope();

  scope.setTag("apl.vendor", config.tenantConfig.key);
  scope.setTag("apl.location", config.tenantConfig.location);

  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <ErrorPage
          error={{ type: "admin", messageForAdmin: errorData.error }}
        />
      )}
    >
      <MetaTags />
      <ConfiguredApp />
    </Sentry.ErrorBoundary>
  );
};

export default App;
