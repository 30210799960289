import React from "react";
import Icon from "@base/components/Global/Icon";
import { getBlobStorage } from "@theme";

import { StepperTicker } from "./StepperTicker";

export type StepperIconProps = {
  status: "DONE" | "IN_PROGRESS" | "DEFAULT";
};

export const StepperIcon: React.FC<StepperIconProps> = ({ status }) => {
  const { statusReadyIcon, statusNotReadyIcon, statusInProgressIcon } =
    getBlobStorage();

  if (status === "DONE") {
    return <Icon url={statusReadyIcon} height={24} width={24} />;
  }
  if (status === "IN_PROGRESS") {
    return <StepperTicker url={statusInProgressIcon} />;
  }

  return <Icon url={statusNotReadyIcon} height={24} width={24} />;
};
