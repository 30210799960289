import React from "react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles({
  btn: {
    background: "transparent",
    border: "none",
    padding: 0,
    display: "flex",
    alignItems: "center",
    "&:disabled": {
      border: "none",
    },
    width: "100%",
    cursor: "pointer",
  },
});

type IconButtonProps = {
  id?: string;
  action?: () => void;
  children: React.ReactNode;
  override?: React.ReactNode;
};

const IconButton: React.FC<IconButtonProps> = ({
  id = "icon-btn",
  action,
  children,
  override,
}) => {
  const classes = useStyles();

  return (
    <div
      onClick={action}
      className={classnames([classes.btn, override])}
      id={id}
    >
      {children}
    </div>
  );
};

export default IconButton;
