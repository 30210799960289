import { InferType, number, object, string } from "yup";

export const UserLayoutSchema = object({
  ID: number().required(),
  FirstName: string().required(),
  LastName: string().required(),
  Email: string().nullable(),
  Phone: string().nullable(),
  LanguageID: number().required(),
  GUID: string().optional(),
});

export type IUserLayout = InferType<typeof UserLayoutSchema>;

export interface IUserCredentials {
  UserID: string;
  UserSecret?: string;
}

export interface IUserLoginRequest {
  AuthenticationMethod: number;
  Credentials: IUserCredentials;
  Application: number;
}
