import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

import { commonStyles } from "./commonStyles";

const useStyles = createUseStyles(({ color }) => ({
  ...commonStyles,
  line: {
    height: "14px",
    background: color.skeletonElementBg,
    borderRadius: 50,
    marginBottom: 8,
    position: "relative",
    overflow: "hidden",
    width: "100%",

    "&:after": {
      content: "''",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      position: "absolute",
      backgroundImage: color.skeletonElementShimmer,
      animation: "$shimmer 1.5s infinite",
      overflow: "hidden",
    },
  },
  lastLine: {
    width: "60%",
    "&:after": {
      right: "-66.66%",
    },
  },
}));

const BodyTextSkeleton: React.FC<{ nLines: number; className?: string }> = ({
  nLines = 4,
  className,
}) => {
  const classes = useStyles();
  return (
    <div className={className}>
      {[...Array(nLines)].map((i, idx, arr) => (
        <div
          key={idx}
          className={classNames(
            classes.line,
            idx === arr.length - 1 && classes.lastLine,
          )}
        ></div>
      ))}
    </div>
  );
};

export default BodyTextSkeleton;
