import React, { createContext, useContext, useEffect } from "react";
import { useDeviceSelectors, useMobileOrientation } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { isPresent } from "@apl-digital/utils";
import ConfirmationModal from "@base/components/Modals/ConfirmationModal";
import DeliveryMethodModal from "@base/components/Modals/DeliveryMethod/DeliveryMethodModal";
import SearchModalWrapper from "@base/components/Modals/MenuSearch/SearchModalWrapper";
import useEffectAsync from "@base/hooks/useEffectAsync";
import { NavigationPath } from "@constants/navigation";
import { useSalesPointContext } from "@context/SalesPointProvider";
import { useAppDispatch, useAppSelector } from "@store";
import {
  hideDeliveryMethodModal,
  hideProductSearchModal,
  setIsLandscape,
  setIsTouchDevice,
  showDeliveryMethodModal,
} from "@store/layout";
import {
  selectAvailableDiningOptions,
  selectIsKitchenOpen,
} from "@store/salesPoint/selectors";
import { initializeDiningCart, selectShoppingCart } from "@store/shoppingCart";
import { getTheatreResource } from "@store/theatreResource";
import { selectUserId } from "@store/user";
import { isStatus } from "@store/utils";

export type ConfirmationModalContent = {
  title: string;
  body: string;
  confirmationCallback: () => void;
};

type ModalContextResults = {
  showConfirmationModal: (modal: ConfirmationModalContent) => void;
  hideConfirmationModal: () => void;
};

const ModalContext = createContext<ModalContextResults>(
  {} as ModalContextResults,
);

export const useModalContext = () => useContext(ModalContext);

type ModalContainerProps = {
  children: React.ReactNode;
};

/**
 * @deprecated
 * This should get deprecated, since every modal should be rendered where its needed
 */
export const ModalContainer: React.FC<ModalContainerProps> = ({ children }) => {
  const [confirmationModal, setConfirmationModal] =
    React.useState<ConfirmationModalContent | null>(null);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLandscape } = useMobileOrientation();
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);

  const [{ configuration }] = useSalesPointContext();

  const shoppingCart = useAppSelector(selectShoppingCart);
  const userId = useAppSelector(selectUserId);
  const availableDiningOptions = useAppSelector(selectAvailableDiningOptions);

  const isProductSearchModalVisible = useAppSelector(
    (state) => state.layout.isProductSearchModalVisible,
  );
  const isDeliveryMethodModalVisible = useAppSelector(
    (state) => state.layout.isDeliveryMethodModalVisible,
  );

  const isKitchenOpen = useAppSelector(selectIsKitchenOpen);

  useEffectAsync(async () => {
    if (
      !isPresent(configuration) ||
      !isStatus(shoppingCart, ["idle", "failed"]) ||
      location.pathname !== NavigationPath.Home ||
      !isKitchenOpen
    ) {
      return;
    }

    if (
      availableDiningOptions.length === 1 &&
      !availableDiningOptions[0].IsPreorderEnabled
    ) {
      let theatreResourceId = 0;

      if (isPresent(configuration.DefaultTableBarcode)) {
        await dispatch(
          getTheatreResource({
            barcodeWithoutChecksum: configuration.DefaultTableBarcode,
          }),
        )
          .unwrap()
          .then((response) => (theatreResourceId = response.ID));
      }

      dispatch(
        initializeDiningCart({
          theatreResourceId: theatreResourceId,
          dttmRequestedDelivery: null,
          diningOptionId: availableDiningOptions[0].ID,
          customerPersonId: userId,
        }),
      );
    } else {
      dispatch(showDeliveryMethodModal());
    }
  }, [shoppingCart, configuration, location]);

  useEffect(() => {
    if (selectors.isMobile) {
      dispatch(setIsTouchDevice(true));
      dispatch(setIsLandscape(isLandscape));
    } else {
      dispatch(setIsTouchDevice(false));
      dispatch(setIsLandscape(false));
    }
  }, [isLandscape, selectors.isMobile]);

  return (
    <ModalContext.Provider
      value={{
        showConfirmationModal: (modal) => setConfirmationModal(modal),
        hideConfirmationModal: () => setConfirmationModal(null),
      }}
    >
      <ConfirmationModal
        isOpen={isPresent(confirmationModal)}
        onClose={() => setConfirmationModal(null)}
        modalObject={confirmationModal}
      />
      <SearchModalWrapper
        isOpen={isProductSearchModalVisible}
        onClose={() => dispatch(hideProductSearchModal())}
      />
      <DeliveryMethodModal
        isOpen={isDeliveryMethodModalVisible}
        onClose={() => dispatch(hideDeliveryMethodModal())}
      />
      {children}
    </ModalContext.Provider>
  );
};
