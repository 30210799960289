import React, { KeyboardEventHandler, MutableRefObject, useRef } from "react";
import { createUseStyles } from "react-jss";
import Icon from "@base/components/Global/Icon";
import { getBlobStorage, getBrandTheme } from "@theme";
import classnames from "classnames";
import classNames from "classnames";

const useStyles = createUseStyles(
  ({ color, spacing, font, borderRadius, opacity }) => ({
    container: {
      position: "relative",
      paddingTop: 5,
    },
    input: {
      width: "100%",
      height: 48,
      fontSize: font.size.m,
      lineHeight: font.lineHeight.s,
      padding: [spacing.l, spacing.x2l],
      outline: 0,
      border: `1px solid ${color.fieldBorder}`,
      color: color.fieldText,
      borderRadius: borderRadius.input,
      background: color.fieldBg,
      "&:focus": {
        border: `1px solid ${color.fieldBorderFocused}`,
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
      "&::placeholder": {
        color: color.fieldTextPlaceholder,
      },
    },
    inputError: {
      border: `1px solid ${color.fieldErrorBorder}`,
      "&:focus": {
        border: `1px solid ${color.fieldErrorBorder}`,
      },
    },
    hasPrefixIcon: {
      paddingLeft: 35,
    },
    hasValue: {
      paddingRight: 35,
    },
    prefixIconWrapper: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      height: "100%",
      padding: spacing.s,
      color: color.fieldIcon,
    },
    suffixIconWrapper: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      height: "100%",
      padding: spacing.s,
      top: 0,
      right: 0,
    },
    clearInputIconButtonWrapper: {
      position: "absolute",
      right: 0,
      top: 5,
      height: 48,
      display: "flex",
      alignItems: "center",
      padding: spacing.s,
    },
    clearInputButton: {
      background: color.fieldIconBg,
      border: 0,
      padding: 0,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      height: 24,
    },
    clearInputButtonIcon: {
      display: "flex",
      padding: spacing.xs,
      cursor: "pointer",
    },
    disabled: {
      opacity: opacity.disabled,
      cursor: "not-allowed",
    },
    errorMessage: {
      color: color.error,
      padding: [0, spacing.xxl],
      fontSize: font.size.xxs,
      lineHeight: "1.3em",
    },
    label: {
      position: "absolute",
      top: -5,
      left: 18,
      background: color.fieldBg,
      color: color.fieldLabelText,
      padding: [0, 5],
      zIndex: 1,
    },
    labelError: {
      color: color.error,
    },
  }),
);

const ClearInputButton = ({ action }: { action: () => void }) => {
  const classes = useStyles();
  const { closeIcon } = getBlobStorage();
  const { color } = getBrandTheme();

  return (
    <button type="button" onClick={action} className={classes.clearInputButton}>
      <div className={classes.clearInputButtonIcon}>
        <Icon url={closeIcon} width={16} height={16} stroke={color.fieldIcon} />
      </div>
    </button>
  );
};

type TextFieldProps = {
  setValue: (value: string) => void;
  value: string;
  type?: string;
  placeholder?: string;
  isDisabled?: boolean;
  autoFocus?: boolean;
  hasError?: boolean;
  hidePrefixIconOnValue?: boolean;
  prefixIcon?: React.ReactNode;
  showClearButton?: boolean;
  override?: React.ReactNode;
  label?: string;
  showSuffixIcon?: boolean;
  suffixIcon?: React.ReactNode;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  handleKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  id?: string;
  autoComplete?: string;
  width?: string;
  errorMessage?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  noPadding?: boolean;
};

const TextField: React.FC<TextFieldProps> = ({
  setValue,
  value,
  type = "text",
  placeholder = "",
  isDisabled = false,
  hasError = false,
  hidePrefixIconOnValue = true,
  prefixIcon = null,
  showClearButton = true,
  override,
  label,
  showSuffixIcon = false,
  suffixIcon = null,
  inputRef,
  handleKeyDown,
  id = "input",
  autoComplete,
  autoFocus = false,
  width = "initial",
  errorMessage,
  onBlur,
  noPadding = false,
}) => {
  const classes = useStyles();
  const prefixIconHidden = () =>
    !prefixIcon || (!!value && hidePrefixIconOnValue);
  const inputLocalRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classNames(!noPadding && classes.container)}
      style={{ width }}
    >
      {!prefixIconHidden() && (
        <div className={classes.prefixIconWrapper}>{prefixIcon}</div>
      )}
      {label ? (
        <span
          className={classnames(classes.label, hasError && classes.labelError)}
        >
          {label}
        </span>
      ) : null}
      <input
        ref={inputRef || inputLocalRef}
        placeholder={placeholder}
        name={autoComplete}
        autoComplete={autoComplete}
        type={type}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        value={value}
        autoFocus={autoFocus}
        onChange={(e) => setValue(e.target.value)}
        disabled={isDisabled}
        className={classnames([
          classes.input,
          !prefixIconHidden() && classes.hasPrefixIcon,
          value && classes.hasValue,
          hasError && classes.inputError,
          isDisabled && classes.disabled,
          override,
        ])}
        id={id}
      />
      {showClearButton && !isDisabled && value && (
        <div className={classes.clearInputIconButtonWrapper}>
          <ClearInputButton
            action={() => {
              setValue("");
              inputLocalRef?.current?.focus();
              inputRef?.current?.focus();
            }}
          />
        </div>
      )}
      {showSuffixIcon && (
        <div className={classes.suffixIconWrapper}>{suffixIcon}</div>
      )}
      {errorMessage && (
        <div className={classes.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default TextField;
