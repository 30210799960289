import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import Icon from "@base/components/Global/Icon";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(
  ({ color, borderRadius, spacing, font, zIndex }) => ({
    outerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: color.modalOverlay,
      zIndex: zIndex.actionTopModal,
    },
    container: {
      background: color.productAddSuccessBg,
      borderRadius: borderRadius.x3l,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: spacing.xxl,
      padding: [spacing.x5l, spacing.x4l],
    },
    msg: {
      fontSize: font.size.x2l,
      lineHeight: font.lineHeight.l,
      fontWeight: font.weight.s,
      color: color.productAddSuccessText,
    },
  }),
);

type Props = {
  hideModal: (value: boolean) => void;
  multiple: boolean;
  isEdit: boolean;
};

const ItemAddedSuccess: React.FC<Props> = ({ hideModal, multiple, isEdit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };
  const { successGrayIcon } = getBlobStorage();

  return (
    <div
      className={classes.outerContainer}
      onClick={() => {
        hideModal(false);
        navigateBack();
      }}
    >
      <div className={classes.container} onClick={(e) => e.stopPropagation()}>
        <div>
          <Icon width={100} height={100} url={successGrayIcon} />
        </div>
        <div className={classes.msg}>
          {multiple
            ? isEdit
              ? t("cart_modal_update_success_multi")
              : t("cart_modal_success_multi")
            : isEdit
              ? t("cart_modal_update_success_single")
              : t("cart_modal_success_single")}
        </div>
      </div>
    </div>
  );
};

export default ItemAddedSuccess;
