import { commonDarkTheme } from "@theme/themes/common.dark";
import { DefaultTheme } from "react-jss";

export const forumcinemasDarkTheme: DefaultTheme = {
  ...commonDarkTheme,
  blobStorage: {
    ...commonDarkTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/dark/logo/logo-forumcinemas.svg",
  },
  config: {
    ...commonDarkTheme.config,
    brandLogoWidth: 117,
    brandLogoHeight: 45,
    sectionsColors: "#393426",
  },
  color: {
    ...commonDarkTheme.color,
    productRowFeaturedBg: "#71420A",
  },
} as const;
