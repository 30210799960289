import React from "react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

import { MiddleGroupItem } from "./types";

const useStyles = createUseStyles(({ color, spacing }) => ({
  middleGroupItem: {
    height: 40,
    marginLeft: spacing.m,
    paddingLeft: spacing.x5l,

    display: "flex",
    alignItems: "center",

    color: color.orderStatusPageLineText,
    borderLeft: "1px solid",
  },
  lastMiddleGroupItem: {
    borderLeft: "none",
  },
}));

type StepperMiddleGroupItemProps = {
  item: MiddleGroupItem;
  isLastUpperGroup: boolean;
  isLastMiddleGroup: boolean;
  isLastMiddleGroupItem: boolean;
};

export const StepperMiddleGroupItem: React.FC<StepperMiddleGroupItemProps> = ({
  item,
  isLastUpperGroup,
  isLastMiddleGroup,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classnames([
        classes.middleGroupItem,
        isLastUpperGroup && isLastMiddleGroup && classes.lastMiddleGroupItem,
      ])}
    >
      {item.label}
    </div>
  );
};
