import React from "react";
import useWindowSize from "@base/hooks/useWindowSize";

import SearchModalDesktopWrapper from "./SearchModalDesktopWrapper";
import SearchModalMobileWrapper from "./SearchModalMobileWrapper";

const SearchModalWrapper: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const { isMdAndUp } = useWindowSize();

  if (isMdAndUp) {
    return <SearchModalDesktopWrapper {...props} />;
  } else {
    return <SearchModalMobileWrapper {...props} />;
  }
};

export default SearchModalWrapper;
