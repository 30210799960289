import React from "react";
import ReactDOM from "react-dom";

export type ModalRootProps = {
  children?: React.ReactNode;
};

export const ModalRoot: React.FC<ModalRootProps> = ({ children }) => {
  const modalRoot = document.getElementById("modal-wrapper");

  if (!modalRoot) {
    throw new Error(
      "There should be a #modal-wrapper ancestor in the DOM in order to use <ModalRoot/>",
    );
  }

  return ReactDOM.createPortal(children, modalRoot);
};
