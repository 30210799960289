import { EShoppingCartOnHoldReason } from "@api/interfaces/cartLayouts";
import { IDiningOptionEnum } from "@api/interfaces/configurationLayouts";
import PaymentService from "@api/service/paymentService";
import { isPresent } from "@apl-digital/utils";
import config from "@constants/config";
import { useAppDispatch, useAppSelector } from "@store";
import {
  selectContactInfo,
  selectHasContactInfo,
  selectIsContactInfoBound,
} from "@store/contactInfo";
import {
  bindContactInfo,
  bundleShoppingCart,
  putOnHold,
  selectCanEditShoppingCart,
  selectCurrentBarcodeWithoutChecksum,
  selectCurrentTransactionId,
  selectShoppingCartDingingOption,
  unbundleShoppingCart,
  updateTheatreResource,
} from "@store/shoppingCart";
import {
  getTheatreResource,
  selectTheatreResource,
} from "@store/theatreResource";
import { selectIsTheatreResourceBound } from "@store/theatreResource/selectors";
import { isStatus } from "@store/utils";
import React, { useEffect } from "react";

type FlowErrorState =
  | "bindContactInfoError"
  | "theatreResourceError"
  | "bundleShoppingCartError"
  | "putOnHoldError";

type FlowState =
  | FlowErrorState
  | "idle"
  | "started"
  | "bindContactInfoCheckStarted"
  | "bindContactInfoOk"
  | "theatreResourceCheckStarted"
  | "theatreResourceNeeded"
  | "theatreResourceRefreshNeeded"
  | "theatreResourceOk"
  | "bundleShoppingCartStarted"
  | "bundleShoppingCartOk"
  | "putOnHoldStarted"
  | "putOnHoldOk"
  | "completed"
  | "error";

type OnErrorProps = {
  error: FlowErrorState;
};

type UsePayLaterCheckoutFlowProps = {
  onError?: ({ error }: OnErrorProps) => void;
};

export type UsePayLaterCheckoutFlowResults = {
  setFlowState: (state: FlowState) => void;
  resetFlow: () => void;
  startFlow: () => void;
  currentFlowState: FlowState;
  isComplete: boolean;
};

export const usePayLaterCheckoutFlow = ({
  onError,
}: UsePayLaterCheckoutFlowProps): UsePayLaterCheckoutFlowResults => {
  const dispatch = useAppDispatch();

  const transactionId = useAppSelector(selectCurrentTransactionId);
  const theatreResource = useAppSelector(selectTheatreResource);
  const isTheatreResourceBound = useAppSelector(selectIsTheatreResourceBound);
  const contactInfo = useAppSelector(selectContactInfo);
  const hasContactInfo = useAppSelector(selectHasContactInfo);
  const isContactInfoBound = useAppSelector(selectIsContactInfoBound);
  const canEditShoppingCart = useAppSelector(selectCanEditShoppingCart);
  const diningOptionId = useAppSelector(selectShoppingCartDingingOption);
  const barcodeWithoutChecksum = useAppSelector(
    selectCurrentBarcodeWithoutChecksum,
  );

  const [flowState, setFlowState] = React.useState<FlowState>("idle");

  const startFlow = () => {
    setFlowState("started");
  };

  const resetFlow = () => {
    setFlowState("idle");
  };

  useEffect(() => {
    if (flowState === "started") {
      setFlowState("bindContactInfoCheckStarted");
    } else if (flowState === "bindContactInfoOk") {
      console.log("diningOptionId", diningOptionId);
      if (diningOptionId === IDiningOptionEnum.DINE_IN) {
        setFlowState("theatreResourceCheckStarted");
      } else {
        setFlowState("theatreResourceOk");
      }
    } else if (flowState === "theatreResourceOk") {
      setFlowState("bundleShoppingCartStarted");
    } else if (flowState === "bundleShoppingCartOk") {
      setFlowState("putOnHoldStarted");
    } else if (flowState === "putOnHoldOk") {
      setFlowState("completed");
    }
  }, [flowState]);

  useEffect(() => {
    if (flowState === "bindContactInfoError") {
      onError?.({ error: flowState });
    } else if (flowState === "theatreResourceError") {
      if (isPresent(transactionId)) {
        dispatch(unbundleShoppingCart({ transactionId })).then(() => {
          onError?.({ error: flowState });
        });
      } else {
        onError?.({ error: flowState });
      }
    } else if (flowState === "bundleShoppingCartError") {
      onError?.({ error: flowState });
    } else if (flowState === "putOnHoldError") {
      onError?.({ error: flowState });
    }
  }, [flowState]);

  useEffect(() => {
    if (flowState === "bindContactInfoCheckStarted") {
      if (!isPresent(transactionId) || !hasContactInfo) {
        setFlowState("bindContactInfoError");
      } else if (!canEditShoppingCart && isContactInfoBound) {
        setFlowState("bindContactInfoOk");
      } else if (hasContactInfo) {
        dispatch(
          bindContactInfo({
            transactionId,
            body: contactInfo,
          }),
        )
          .then(() => {
            setFlowState("bindContactInfoOk");
          })
          .catch(() => {
            setFlowState("bindContactInfoError");
          });
      }
    }
  }, [flowState, canEditShoppingCart, transactionId, contactInfo]);

  useEffect(() => {
    if (flowState === "theatreResourceCheckStarted") {
      if (!isPresent(transactionId)) {
        setFlowState("theatreResourceError");
      } else if (!canEditShoppingCart && isTheatreResourceBound) {
        setFlowState("theatreResourceOk");
      } else if (isStatus(theatreResource, "succeeded")) {
        dispatch(
          updateTheatreResource({
            transactionId,
            theatreResourceId: theatreResource.state.ID,
          }),
        )
          .then(() => {
            setFlowState("theatreResourceOk");
          })
          .catch(() => {
            setFlowState("theatreResourceError");
          });
      } else if (isStatus(theatreResource, ["idle", "failed"])) {
        setFlowState("theatreResourceNeeded");
      } else if (isStatus(theatreResource, "stale")) {
        dispatch(
          getTheatreResource({
            barcodeWithoutChecksum: theatreResource.state.Barcode,
          }),
        );
      }
    }
  }, [flowState, canEditShoppingCart, theatreResource]);

  useEffect(() => {
    if (flowState === "bundleShoppingCartStarted") {
      if (!canEditShoppingCart && isPresent(transactionId)) {
        setFlowState("bundleShoppingCartOk");
      } else if (!canEditShoppingCart || !isPresent(transactionId)) {
        setFlowState("bundleShoppingCartError");
      } else {
        dispatch(bundleShoppingCart({ transactionId }))
          .then(() => {
            setFlowState("bundleShoppingCartOk");
          })
          .catch(() => {
            setFlowState("bundleShoppingCartError");
          });
      }
    }
  }, [flowState, canEditShoppingCart, transactionId]);

  useEffect(() => {
    if (flowState === "putOnHoldStarted") {
      if (!isPresent(transactionId) || !isPresent(barcodeWithoutChecksum)) {
        setFlowState("putOnHoldError");
      } else {
        dispatch(
          putOnHold({
            transactionId,
            body: {
              ShoppingCartOnHoldReason: EShoppingCartOnHoldReason.OpenInvoice,
              Description: `${config.tenantConfig.key} ${config.tenantConfig.location} Order App ${new Date().toISOString()} pay later`,
              StatusMonitorUrl: PaymentService.generateStatusPageUrl(
                barcodeWithoutChecksum,
              ),
            },
          }),
        )
          .then(() => {
            setFlowState("putOnHoldOk");
          })
          .catch(() => {
            setFlowState("putOnHoldError");
          });
      }
    }
  }, [flowState, transactionId, barcodeWithoutChecksum]);

  return {
    setFlowState,
    resetFlow,
    startFlow,
    currentFlowState: flowState,
    isComplete: flowState === "completed",
  };
};
