import React, { useLayoutEffect } from "react";
import useWindowSize from "@base/hooks/useWindowSize";
import {
  clearSessionStorage,
  clearStorage,
  SessionStorageKey,
} from "@constants/storage";
import * as Sentry from "@sentry/react";

import ErrorPageDesktop from "./ErrorPageDesktop";
import ErrorPageMobile from "./ErrorPageMobile";
import { AppError } from "./types";

export const reloadWebsite = () => {
  clearStorage(undefined, { includeUnmanaged: true });
  clearSessionStorage([SessionStorageKey.SENTRY_REPLAY_SESSION], {
    mode: "keep",
    includeUnmanaged: true,
  });

  window.open("/", "_self");
};

type ErrorPageProps = {
  error?: AppError;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  const { isMdAndUp } = useWindowSize();

  Sentry.captureException(new Error("Showing error page", { cause: error }));

  useLayoutEffect(() => {
    const handle = setTimeout(() => {
      reloadWebsite();
    }, 3600000);

    return clearTimeout(handle);
  }, []);

  if (isMdAndUp) {
    return <ErrorPageDesktop error={error} />;
  } else {
    return <ErrorPageMobile error={error} />;
  }
};
