import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { OrderStatus } from "@api/interfaces/orderLayouts";
import { isPresent } from "@apl-digital/utils";
import Icon, { IconProps } from "@base/components/Global/Icon";
import { getBlobStorage, getBrandTheme } from "@theme";

type Variant =
  | OrderStatus.Cancelled
  | OrderStatus.Completed
  | OrderStatus.Refunded
  | "notFound"
  | "orderMoved";

const TranslationVariantMap = {
  [OrderStatus.Cancelled]: "cancelled",
  [OrderStatus.Completed]: "completed",
  [OrderStatus.Refunded]: "refunded",
  notFound: "notFound",
  orderMoved: "orderMoved",
} as const;

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: spacing.xl,
  },
  title: {
    fontSize: font.size.m,
    lineHeight: font.lineHeight.s,
    fontWeight: font.weight.m,
    color: color.orderStatusCardOrderCardCompletedBlockTitleTextColor,
  },
  subTitle: {
    fontSize: font.size.x2l,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.l,
    color: color.orderStatusCardOrderCardCompletedBlockSubtitleTextColor,
  },
  infoBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
}));

type OrderCardContentColumnProps = {
  variant: Variant;
  lastStatusChangeDateTime: string | null;
  showSubtitle?: boolean;
};

export const OrderCardCompletedBlock: React.FC<OrderCardContentColumnProps> = ({
  variant,
  lastStatusChangeDateTime,
  showSubtitle,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("orderStatusPage");

  const { color } = getBrandTheme();
  const { validFieldIcon, closeIcon, warningIcon } = getBlobStorage();

  const IconVariantMap: Record<Variant, IconProps> = {
    [OrderStatus.Cancelled]: {
      url: closeIcon,
      stroke: color.mediumGray,
      fill: color.mediumGray,
    },
    [OrderStatus.Completed]: {
      url: validFieldIcon,
      fill: color.greyCoal,
    },
    [OrderStatus.Refunded]: {
      url: closeIcon,
      stroke: color.mediumGray,
      fill: color.mediumGray,
    },
    notFound: { url: warningIcon, stroke: color.mediumGray },
    orderMoved: { url: warningIcon, stroke: color.mediumGray },
  } as const;

  return (
    <div className={classes.container}>
      <div className={classes.infoBlock}>
        <div className={classes.title}>
          {t(
            `OrderCardCompletedBlock.status.${TranslationVariantMap[variant]}.title`,
          )}
        </div>

        {(isPresent(showSubtitle) && showSubtitle) ||
        isPresent(lastStatusChangeDateTime) ? (
          <div className={classes.subTitle}>
            {t(
              `OrderCardCompletedBlock.status.${TranslationVariantMap[variant]}.subtitle`,
              isPresent(lastStatusChangeDateTime)
                ? {
                    orderCompletedAt: new Date(lastStatusChangeDateTime),
                    formatParams: {
                      orderCompletedAt: {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      },
                    },
                  }
                : undefined,
            )}
          </div>
        ) : null}
      </div>
      <div>
        <Icon {...IconVariantMap[variant]} height={24} width={24} />
      </div>
    </div>
  );
};
