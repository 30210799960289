import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import FixedSectionScroller from "@base/components/FixedSectionScroller/FixedSectionScroller";
import { getStorage, StorageKey } from "@constants/storage";
import { useProducts } from "@store/ProductsProvider";
import { ProductListItem } from "@store/ProductsProvider/types";

import SectionsList from "./SectionsList";

// TODO: move these constants somewhere more appropriate
export const SECTIONS_SELECTOR_HEIGHT = 76;
export const SECTION_TITLE_HEIGHT = 88;
export const CATEGORIES_SELECTOR_HEIGHT = 56;
export const ITEM_MARGIN_BOTTOM = 12;

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 54,
  },
  topContainer: {
    marginTop: spacing.x3l,
    position: "relative",
  },
}));

const ProductList: React.FC<{ showShadows?: boolean }> = ({
  showShadows = false,
}) => {
  const classes = useStyles();
  const sectionList = useRef<HTMLDivElement>(null);
  const [activeSection, setActiveSection] = useState<ProductListItem | null>(
    null,
  );
  const [{ sections }] = useProducts();

  useEffect(() => {
    const isModalOpen = getStorage(StorageKey.IS_MODAL_OPEN);
    if (
      activeSection === null &&
      sections &&
      sections.length > 0 &&
      !isModalOpen
    ) {
      setActiveSection(sections[0]);
    }
  }, [sections]);

  if (!sections) {
    return null;
  }

  return (
    <div ref={sectionList} className={classes.topContainer}>
      <FixedSectionScroller
        sectionListRef={sectionList}
        activeSection={activeSection}
        sections={sections}
      />
      <div className={classes.container}>
        <SectionsList
          sections={(sections || []) as ProductListItem[]}
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          showShadows={showShadows}
        />
      </div>
    </div>
  );
};

export default ProductList;
