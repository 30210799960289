import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ICartAddModifierChoice } from "@api/interfaces/cartLayouts";
import {
  IModifier,
  ProductModifierConstraints,
  ProductModifierDisplayMode,
  ProductModifierSelectionMode,
} from "@api/interfaces/productLayouts";
import Box from "@base/components/Box/Box";
import Icon from "@base/components/Global/Icon";
import useInterval from "@base/hooks/useInterval";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { useProducts } from "@store/ProductsProvider";
import { getBlobStorage, getBrandTheme } from "@theme";
import classnames from "classnames";

const useStyles = createUseStyles(({ spacing, font, color }) => ({
  extrasRow: {
    background: "transparent",
    padding: 0,
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    fontWeight: font.weight.m,
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    color: color.productPageDescriptionText,
    padding: [spacing.l, 0],
  },
  headerBlock: {
    display: "flex",
    flexDirection: "column",
  },
  price: {
    minWidth: 60,
    marginLeft: "auto",
  },
  header: {
    fontSize: 32,
    fontWeight: font.weight.s,
    lineHeight: "34px",
    color: color.productPageHeadingText,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: spacing.s,
  },
  itemInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
    alignItems: "center",
    padding: [spacing.s, spacing.l],
    position: "relative",
  },
  img: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  imgTitle: {
    fontSize: font.size.s,
    fontWeight: font.weight.m,
    lineHeight: font.lineHeight.xxs,
    color: color.productPageImageTitle,
    textAlign: "center",
  },
  imgBlock: {
    display: "flex",
    flexWrap: "wrap",
    gap: spacing.xxl,
    justifyContent: "center",
  },
  mandatoryOption: {
    fontWeight: font.weight.m,
    fontSize: font.size.s,
    lineHeight: font.lineHeight.xxs,
    color: color.productPageMandatoryOption,
    paddingTop: spacing.l,
  },
  error: {
    color: color.error,
  },
  checkedIcon: {
    position: "absolute",
    top: -5,
    right: 10,
  },
  extrasBox: {
    gap: 24,
  },
}));

type ExtrasRowProps = {
  name: string;
  price: number;
  isAdded: boolean;
  isMandatory: boolean;
  img?: string;
  hasDefaultSelected?: boolean;
};

const ExtrasImgRow: React.FC<ExtrasRowProps> = ({
  name,
  price,
  isAdded,
  img,
  hasDefaultSelected = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("productPage");

  const { extraChoiceIcon } = getBlobStorage();
  const { opacity } = getBrandTheme();

  return (
    <div className={classes.imgContainer}>
      {isAdded && (
        <Icon
          url={extraChoiceIcon}
          width={24}
          height={24}
          className={classes.checkedIcon}
        />
      )}
      <div
        className={classes.img}
        style={{
          backgroundImage: `url(${getImageFromAPI(img || "")})`,
          opacity: hasDefaultSelected
            ? isAdded
              ? opacity.enabled
              : opacity.disabled
            : opacity.enabled,
        }}
      />
      <div className={classes.imgTitle}>
        <div>{name}</div>
        {price && price > 0 ? (
          <div className={classes.price}>
            {t("ExtrasImgRow.price", {
              price,
              formatParams: {
                price: { currency: "EUR" },
              },
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const ExtrasRow: React.FC<ExtrasRowProps> = ({
  name,
  price,
  isAdded,
  isMandatory,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("productPage");

  const { extraChoiceIcon, checkedRadioSmIcon, emptyRadioSmIcon } =
    getBlobStorage();

  return (
    <div className={classes.row}>
      <div className={classes.icon}>
        {isAdded ? (
          isMandatory ? (
            <Icon url={checkedRadioSmIcon} height={24} width={24} />
          ) : (
            <Icon url={extraChoiceIcon} height={24} width={24} />
          )
        ) : (
          <Icon url={emptyRadioSmIcon} height={24} width={24} />
        )}
      </div>
      <div className={classes.itemInfoContainer}>
        <div>{name}</div>
      </div>
      {price && price > 0 ? (
        <div className={classes.price}>
          {t("ExtrasRow.price", {
            price,
            formatParams: {
              price: { currency: "EUR" },
            },
          })}
        </div>
      ) : null}
    </div>
  );
};

type AddItem = {
  idToAdd?: number;
  idToRemove?: number;
  price: number;
  choiceId?: number;
};

type ExtrasBlockProps = {
  modifier: IModifier;
  cartModifiers: ICartAddModifierChoice[];
  setCartModifiers: (value: ICartAddModifierChoice[]) => void;
  hasError?: boolean;
};

const ExtrasBlock: React.FC<ExtrasBlockProps> = ({
  modifier,
  cartModifiers,
  setCartModifiers,
  hasError = false,
}) => {
  const classes = useStyles();
  const [addedItems, setAddedItems] = useState<number[]>(
    cartModifiers.map((e) => e.ProductModifierChoiceID),
  );
  const [blockAddedItems, setBlockAddedItems] = useState<number[]>([]);
  const [, { getRawProductById }] = useProducts();
  const [previousSelection, setPreviousSelection] = useState<number>();
  const [hasDefaultSelected, setHasDefaultSelected] = useState(false);
  const [hasMaxSelectionChoiceError, setHasMaxSelectionChoiceError] =
    useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setAddedItems(cartModifiers.map((e) => e.ProductModifierChoiceID));
    setBlockAddedItems(
      cartModifiers
        .filter((m) =>
          modifier.Choices.find((c) => m.ProductModifierChoiceID === c.ID),
        )
        .map((e) => e.ProductModifierChoiceID),
    );
  }, [cartModifiers]);

  const isMandatory =
    modifier && modifier.Constraint == ProductModifierConstraints.MANDATORY;

  const updateCartModifiers = (item: AddItem): void => {
    let cartItems: ICartAddModifierChoice[] = cartModifiers;
    if (item.idToRemove) {
      cartItems = cartItems.filter(
        (e) => e.ProductModifierChoiceID != item.idToRemove,
      );
    }

    if (item.idToAdd) {
      const newChoice: ICartAddModifierChoice = {
        ProductModifierID: modifier.ID,
        ProductModifierChoiceID: item.idToAdd,
        Amount: 1,
        ProductId: modifier.Choices.find(
          (choice) => choice.ID === item.choiceId,
        )!.ProductID,
      };
      cartItems = [...cartItems, newChoice];
    }

    setCartModifiers(cartItems);
  };

  const rowActions = (
    choiceID: number,
    productPrice: number,
    isRowAdded: boolean,
  ) => {
    setHasMaxSelectionChoiceError(false);
    const isReachedLimit =
      modifier.SelectionMode == ProductModifierSelectionMode.MULTIPLE_CHOICE &&
      blockAddedItems.length >= modifier.SelectionMaxChoiceCount &&
      modifier.SelectionMaxChoiceCount != 0;
    if (
      modifier.SelectionMode == ProductModifierSelectionMode.MULTIPLE_CHOICE &&
      previousSelection
    ) {
      if (isRowAdded) {
        updateCartModifiers({
          idToRemove: choiceID,
          price: productPrice,
          choiceId: choiceID,
        });
      } else {
        if (isReachedLimit) {
          document.getElementById(`modifier-${modifier.ID}`)?.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
          setHasMaxSelectionChoiceError(true);
          return;
        }
        updateCartModifiers({
          idToAdd: choiceID,
          price: productPrice,
          choiceId: choiceID,
        });
      }
    } else {
      setHasDefaultSelected(true);
      if (isRowAdded && !isMandatory) {
        updateCartModifiers({
          idToRemove: choiceID,
          price: productPrice,
          choiceId: choiceID,
        });
      } else {
        updateCartModifiers({
          idToAdd: choiceID,
          idToRemove: previousSelection,
          price: productPrice,
          choiceId: choiceID,
        });
      }
    }

    setPreviousSelection(choiceID);
  };

  const isTextual = modifier.DisplayMode === ProductModifierDisplayMode.TEXTUAL;

  return (
    <>
      <Box
        id={`modifier-${modifier.ID}`}
        key={modifier.ID}
        classNames={classes.extrasBox}
      >
        <div className={classes.headerBlock}>
          <div className={classes.header}>
            {modifier.LocalName.concat(isMandatory ? "*" : "")}
          </div>
          {(isMandatory || modifier.SelectionMaxChoiceCount > 0) && (
            <div
              className={classnames([
                classes.mandatoryOption,
                { [classes.error]: hasError || hasMaxSelectionChoiceError },
              ])}
            >
              {modifier.SelectionMaxChoiceCount > 0 && !isMandatory
                ? t("product_extras_selection_max_choice_msg").replace(
                    "%limit%",
                    String(modifier.SelectionMaxChoiceCount || ""),
                  )
                : t("product_modifier_error")}
            </div>
          )}
        </div>
        <div className={isTextual ? undefined : classes.imgBlock}>
          {modifier.Choices.slice()
            .sort((a, b) => (a.SortOrder < b.SortOrder ? -1 : 1))
            .map((choice) => {
              const product = getRawProductById(choice.ProductID);
              // Products with ID = 0 are add-on products which don't really exist separately (milk for coffee)
              // If choice has a ProductID > 0 and no product is found then do not display product (out of stock)
              if (!product && choice.ProductID != 0) {
                return null;
              }
              const price: number = product?.GrossUnitPrice || 0;
              const [isRowAdded, setIsRowAdded] = useState<boolean>(
                cartModifiers
                  .map((m) => m.ProductModifierChoiceID)
                  .includes(choice.ID),
              );
              const [shouldShowConstraintMsg, setShouldShowConstraintMsg] =
                useState<boolean>(false);

              useInterval(
                () => setShouldShowConstraintMsg(false),
                2000,
                shouldShowConstraintMsg,
              );

              useEffect(() => {
                if (addedItems.length < modifier.Constraint) {
                  setShouldShowConstraintMsg(false);
                }
                if (addedItems.includes(choice.ID)) {
                  if (isMandatory) {
                    setHasDefaultSelected(true);
                    setPreviousSelection(choice.ID);
                  }
                  setIsRowAdded(true);
                } else {
                  setIsRowAdded(false);
                }
              }, [addedItems]);

              // Adds default item to cart
              useEffect(() => {
                if (isMandatory && choice.DefaultQuantity != 0) {
                  rowActions(choice.ID, price, isRowAdded);
                  setHasDefaultSelected(true);
                }
              }, []);

              if (isTextual) {
                return (
                  <div
                    className={classes.extrasRow}
                    onClick={() => rowActions(choice.ID, price, isRowAdded)}
                    id={choice.LocalName}
                    key={choice.ID}
                  >
                    <ExtrasRow
                      name={`${choice.LocalName || product?.LocalName} ${
                        choice.PublicDescription
                      }`}
                      price={price}
                      isAdded={isRowAdded}
                      isMandatory={isMandatory}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    key={choice.ID}
                    onClick={() => rowActions(choice.ID, price, isRowAdded)}
                  >
                    <ExtrasImgRow
                      name={`${choice.LocalName || product?.LocalName} ${
                        choice.PublicDescription
                      }`}
                      price={price}
                      isAdded={isRowAdded}
                      isMandatory={isMandatory}
                      hasDefaultSelected={hasDefaultSelected}
                      img={choice.MediaResources[0]?.ThumbnailLocation}
                    />
                  </div>
                );
              }
            })}
        </div>
      </Box>
    </>
  );
};

export default ExtrasBlock;
