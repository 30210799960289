import { windowBreakpoints } from "@constants/breakpoints";
import { useLayoutEffect, useState } from "react";

interface Size {
  width: number | undefined;
  height: number | undefined;
}

interface ReturnValue {
  windowSize: Size;
  isXs: boolean | undefined;
  isSm: boolean | undefined;
  isMd: boolean | undefined;
  isLg: boolean | undefined;
  isXl: boolean | undefined;
  isXxl: boolean | undefined;
  isXsAndUp: boolean | undefined;
  isSmAndUp: boolean | undefined;
  isMdAndUp: boolean | undefined;
  isLgAndUp: boolean | undefined;
  isXlAndUp: boolean | undefined;
  isXxlAndUp: boolean | undefined;
}

function useWindowSize(): ReturnValue {
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useLayoutEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const width = windowSize.width;

  const isXs = width
    ? width >= windowBreakpoints.xsMinWidth &&
      width < windowBreakpoints.smMinWidth
    : undefined;
  const isSm = width
    ? width >= windowBreakpoints.smMinWidth &&
      width < windowBreakpoints.mdMinWidth
    : undefined;
  const isMd = width
    ? width >= windowBreakpoints.mdMinWidth &&
      width < windowBreakpoints.lgMinWidth
    : undefined;
  const isLg = width
    ? width >= windowBreakpoints.lgMinWidth &&
      width < windowBreakpoints.xlMinWidth
    : undefined;
  const isXl = width
    ? width >= windowBreakpoints.xlMinWidth &&
      width < windowBreakpoints.xxlMinWidth
    : undefined;
  const isXxl = width ? width >= windowBreakpoints.xxlMinWidth : undefined;

  const isXsAndUp = width ? width > windowBreakpoints.xsMinWidth : undefined;
  const isSmAndUp = width ? width > windowBreakpoints.smMinWidth : undefined;
  const isMdAndUp = width ? width > windowBreakpoints.mdMinWidth : undefined;
  const isLgAndUp = width ? width > windowBreakpoints.lgMinWidth : undefined;
  const isXlAndUp = width ? width > windowBreakpoints.xlMinWidth : undefined;
  const isXxlAndUp = width ? width > windowBreakpoints.xxlMinWidth : undefined;

  return {
    windowSize,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isXxl,
    isXsAndUp,
    isSmAndUp,
    isMdAndUp,
    isLgAndUp,
    isXlAndUp,
    isXxlAndUp,
  };
}

export default useWindowSize;
