import config from "@constants/config";
import { TOptions } from "i18next";

import {
  FilterNamespaceKeys,
  UseSalesPointTranslationKeyResults,
  ValidNamespaceKeys,
} from "./useSalesPointTranslationKey.types";

const useSalesPointTranslationKey = (): UseSalesPointTranslationKeyResults => {
  const salesPointKey =
    `${config.tenantConfig.key}.${config.tenantConfig.location}` as const;

  const resolveKey = <
    TKey extends ValidNamespaceKeys<TOpt>,
    TOpt extends TOptions,
  >(
    key: TKey,
    _tOptions?: TOpt,
  ): FilterNamespaceKeys<TKey, TOpt>[] => {
    return [
      `${salesPointKey}.${String(key)}`,
      `default.${String(key)}`,
    ] as FilterNamespaceKeys<TKey, TOpt>[];
  };

  return {
    resolveKey,
  };
};

export default useSalesPointTranslationKey;
