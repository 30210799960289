import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useTimeoutFn } from "react-use";
import CartService from "@api/service/cartService";
import { isPresent } from "@apl-digital/utils";
import AmountButton from "@base/components/Cart/AmountBtn";
import Icon from "@base/components/Global/Icon";
import { getImageFromAPI } from "@base/utils/imageHelper";
import config from "@constants/config";
import DeleteItemsModal from "@pages/CartPage/components/DeleteItemsModal";
import { useAppDispatch, useAppSelector } from "@store";
import { useProducts } from "@store/ProductsProvider";
import {
  addProductToCart,
  removeLinesFromCart,
  selectCanEditShoppingCart,
  selectCurrentTransactionId,
  selectShoppingCartItemCount,
  UnifiedCartLine,
  UnifiedCartLineModifierChoice,
} from "@store/shoppingCart";
import { getProductMinRequiredAge } from "@store/shoppingCart/helper";
import { getBlobStorage, getBrandTheme } from "@theme";
import classnames from "classnames";

import { useProductDetailNavigation } from "../hooks";

import { CartItemModifiersBlock } from "./CartItemModifiersBlock";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: [spacing.l, 0],
    color: color.black,
    justifyContent: "center",
    gap: spacing.s,
    borderTop: `1px solid ${color.cartPageItemBlockBorder}`,
  },
  productMainInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: spacing.l,
  },
  productInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: spacing.xs,
    flexGrow: 1,
  },
  clickable: {
    cursor: "pointer",
  },
  itemInfo: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  infoName: {
    alignItems: "center",
    fontWeight: font.weight.m,
    fontSize: font.size.m,
    lineHeight: font.lineHeight.m,
    wordWrap: "break-word",
    color: color.cartPageRowTitle,
  },
  infoPrice: {
    alignItems: "center",
    fontWeight: font.weight.m,
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    color: color.cartPageRowPrice,
  },
  amount: {
    display: "flex",
    position: "absolute",
    top: 0,
    right: -10,
    color: color.white,
    backgroundColor: color.black,
    borderRadius: "50%",
    width: 24,
    height: 24,
    justifyContent: "center",
    alignItems: "center",
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
  },
  img: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "50%",
    height: 48,
    width: 48,
    position: "relative",
  },
  extraInfoRow: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.m,
    alignItems: "center",
  },
  ageRestriction: {
    color: color.white,
    backgroundColor: color.warningBg,
    padding: [0, spacing.s],
    fontSize: font.size.xxs,
    borderRadius: 12,
  },
}));

type CartItemBlockProps = {
  item: UnifiedCartLine;
};

const CartItemBlock: React.FC<CartItemBlockProps> = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation("cartPage");
  const [currentAmount, setCurrentAmount] = useState<number>(item.amount);
  const [isActive, setIsActive] = useState(false);

  const canEdit = useAppSelector(selectCanEditShoppingCart);
  const transactionId = useAppSelector(selectCurrentTransactionId);
  const shoppingCartTotalItemCount = useAppSelector(
    selectShoppingCartItemCount,
  );

  const dispatch = useAppDispatch();
  const [, { getRawProductById }] = useProducts();
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const { commentIcon } = getBlobStorage();
  const { navigateToProductDetailPage } = useProductDetailNavigation();
  const [_, cancel, reset] = useTimeoutFn(() => {
    setIsActive(false);
  }, 4000);

  const { color } = getBrandTheme();

  const rawProduct = getRawProductById(item.productId);

  const addItem = (amount: number) => {
    if (!isPresent(transactionId) || amount < 0) {
      return;
    }

    const request = CartService.buildAddItemRequest({
      productId: item.productId,
      amount: amount,
      modifiers: item.modifiers
        .filter(
          (modifier): modifier is UnifiedCartLineModifierChoice =>
            modifier.type === "choice",
        )
        .map((modifier) => ({
          ProductModifierID: modifier.modifierId,
          ProductModifierChoiceID: modifier.modifierChoiceId,
          Amount: modifier.amount,
          ProductId: item.productId,
        })),
      noteToKitchen: item.noteToKitchen,
      variableProductID: item.variableProductId,
    });

    dispatch(addProductToCart({ transactionId, body: request }));
  };

  const removeItem = (amount: number) => {
    if (!isPresent(transactionId) || amount < 0) {
      return;
    }

    const cartLineIdsToRemove = item.mergedCartLineIds.slice(
      item.mergedCartLineIds.length - amount,
    );

    dispatch(
      removeLinesFromCart({
        transactionId,
        cartLineIds: cartLineIdsToRemove,
      }),
    );
  };

  useEffect(() => {
    setCurrentAmount(item.amount);
  }, [item.amount]);

  useEffect(() => {
    if (currentAmount > item.amount) {
      addItem(currentAmount - item.amount);
    } else if (currentAmount < item.amount) {
      removeItem(item.amount - currentAmount);
    }
  }, [currentAmount]);

  const onProductClick = (): void => {
    if (item.isPackagingItem) {
      return;
    }

    cancel();
    setIsActive(false);
    navigateToProductDetailPage(item);
  };

  const minRequiredAge = isPresent(rawProduct)
    ? getProductMinRequiredAge(rawProduct)
    : 0;

  const shouldShowAgeRestriction = minRequiredAge > 0;

  return (
    <div className={classes.container}>
      {canEdit && (
        <DeleteItemsModal
          isOpen={shouldShowModal}
          header={t("CartItemBlock.deleteItemsModal.title")}
          message={t("CartItemBlock.deleteItemsModal.message")}
          showModal={setShouldShowModal}
          cartLineIds={[
            ...item.mergedCartLineIds,
            ...item.relatedChildCartLineIds,
          ]}
        />
      )}
      <div className={classes.itemInfo}>
        <div className={classes.productMainInfo}>
          <div
            className={classnames(
              "modify-item-btn", // for Google Analytics
              classes.productInfo,
              !item.isPackagingItem && classes.clickable,
            )}
            onClick={onProductClick}
          >
            <div className={classes.infoName}>{item.name}</div>
            <div className={classes.extraInfoRow}>
              <span className={classes.infoPrice}>
                {t("CartItemBlock.linePrice", {
                  linePrice: item.totalGrossSum,
                  formatParams: {
                    linePrice: { currency: "EUR" },
                  },
                })}
              </span>
              {shouldShowAgeRestriction && (
                <span className={classes.ageRestriction}>
                  {minRequiredAge}+
                </span>
              )}
              {item.noteToKitchen && (
                <Icon
                  url={commentIcon}
                  height={16}
                  width={16}
                  stroke={color.defaultText}
                />
              )}
            </div>
          </div>
          <div
            className={classnames(!item.isPackagingItem && classes.clickable)}
            onClick={(e) => {
              e.stopPropagation();
              if (canEdit && !item.isPackagingItem) {
                setIsActive(true);
                reset();
              }
            }}
          >
            {isActive && (
              <div style={{ marginRight: -10 }}>
                <AmountButton
                  currentAmount={currentAmount}
                  canIncrement={(increase) =>
                    config.appConfig.singleItemMaxQuantity >
                      item.amount + increase &&
                    config.appConfig.cartItemMaxQuantity >
                      shoppingCartTotalItemCount + increase
                  }
                  canDecrement={(amount) => amount > 0}
                  onAmountChange={setCurrentAmount}
                  onTrashAction={() => setShouldShowModal(true)}
                  isOpen={isActive}
                  isDisabled={
                    currentAmount === 0 &&
                    (config.appConfig.singleItemMaxQuantity <= item.amount ||
                      config.appConfig.cartItemMaxQuantity <=
                        shoppingCartTotalItemCount)
                  }
                  isReversed
                />
              </div>
            )}
            {!isActive && (
              <div
                className={classes.img}
                style={{
                  backgroundImage: `url(${getImageFromAPI(
                    rawProduct?.MediaResources[0]?.Location || "",
                  )})`,
                }}
              >
                <div className={classes.amount}>{item.amount}</div>
              </div>
            )}
          </div>
        </div>
        <CartItemModifiersBlock item={item} />
      </div>
    </div>
  );
};

export default CartItemBlock;
