import { FormatDistanceFn } from "date-fns";
import { useTranslation } from "react-i18next";

type UseFormatDistanceResults = {
  formatDistance: FormatDistanceFn;
};

export const useFormatDistance = (): UseFormatDistanceResults => {
  const { t } = useTranslation("datetime");

  const formatDistance: FormatDistanceFn = (token, count, options) => {
    let result;

    if (token === "halfAMinute") {
      result = t(`formatDistance.${token}`, { count });
    } else if (count === 1) {
      result = t(`formatDistance.${token}_one`, { count });
    } else {
      result = t(`formatDistance.${token}_other`, { count });
    }

    if (options?.addSuffix) {
      if (options.comparison && options.comparison > 0) {
        return t("inFuture", { distance: result });
      } else {
        return t("inPast", { distance: result });
      }
    }

    return result;
  };

  return {
    formatDistance,
  };
};
