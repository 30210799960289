import React from "react";
import { isSafari } from "react-device-detect";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  appleTouchIconSizes,
  appleTouchStartupImageSizes,
  faviconSizes,
} from "@base/components/MetaTags/constants";
import insertGTM from "@base/utils/googleTagManagerHelper";
import config from "@constants/config";
import { getBrandTheme } from "@theme";

const tenant = config.tenantConfig.key;
const faviconPath = config.assetConfig.assets.faviconUrl;
const manifestPath = config.assetConfig.assets.manifestUrl;

const MetaTags: React.FC = () => {
  const { t, i18n } = useTranslation("common");
  const theme = getBrandTheme();
  // @ts-ignore
  const brandTitle: string = t(`page.title.${tenant}`);
  // web standards use - instead of underscore
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang
  const currentLanguage = i18n.language.replace("_", "-");

  insertGTM();

  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{brandTitle}</title>
        <link rel="manifest" href={`${manifestPath}/manifest.json`} />
        <meta name="application-name" content={brandTitle} />
        <meta
          name="msapplication-config"
          content={`${manifestPath}/browserconfig.xml`}
        />
        <link
          rel="yandex-tableau-widget"
          href={`${manifestPath}/yandex-browser-manifest.json`}
        />
        <link rel="shortcut icon" href={`${faviconPath}/favicon.ico`} />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content={theme.color.primary} />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="228x228"
          href={`${faviconPath}/coast-228x228.png`}
        />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta
          name="msapplication-TileImage"
          content={`${faviconPath}/mstile-144x144.png`}
        />
      </Helmet>
      {isSafari && (
        <Helmet>
          <meta name="apple-mobile-web-app-title" content={brandTitle} />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          {appleTouchIconSizes.map((sizes) => (
            <link
              key={sizes}
              rel="apple-touch-icon"
              sizes={`${sizes}`}
              href={`${faviconPath}/apple-touch-icon-${sizes}.png`}
            />
          ))}
          {appleTouchStartupImageSizes.map(({ media, sizes }) => (
            <link
              key={sizes}
              rel="apple-touch-startup-image"
              media={media}
              href={`${faviconPath}/apple-touch-startup-image-${sizes}.png`}
            />
          ))}
        </Helmet>
      )}
      {!isSafari && (
        <Helmet>
          {faviconSizes.map((sizes) => (
            <link
              key={sizes}
              rel="icon"
              type="image/png"
              sizes={sizes}
              href={`${faviconPath}/favicon-${sizes}.png`}
            />
          ))}
        </Helmet>
      )}
    </HelmetProvider>
  );
};

export default MetaTags;
