import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Modal } from "@base/components/Global/Modal";

import { TheatreResourceModalContent } from "./TheatreResourceModalContent";
import { TheatreResourceScannerModal } from "./TheatreResourceScannerModal";

const useStyles = createUseStyles(({ color, spacing, font, borderRadius }) => ({
  container: {
    top: "calc(100vh - 90%)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    background: color.modalQrTableNrBg,
    padding: [44, spacing.x4l, 22, spacing.x4l],
    borderRadius: borderRadius.x3l,
    fontSize: font.size.xs,
    color: color.modalQrText,
    maxWidth: 343,
    gap: spacing.x4l,
  },
}));

type TheatreResourceModalProps = {
  isOpen: boolean;
  onAdded?: (value: string) => void;
  onClose: () => void;
};

export const TheatreResourceModal: React.FC<TheatreResourceModalProps> = ({
  isOpen,
  onAdded,
  onClose,
}) => {
  const classes = useStyles();

  const [shouldShowScanner, setShouldShowScanner] = useState<boolean>(false);

  const switchToScanner = () => {
    setShouldShowScanner(true);
  };

  const switchToManual = () => {
    setShouldShowScanner(false);
  };

  if (shouldShowScanner) {
    return (
      <TheatreResourceScannerModal
        isOpen={isOpen}
        onAdded={onAdded}
        onClose={onClose}
        switchToManual={switchToManual}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      forceToForeground
      allowCloseOnOutsideClick
    >
      <div id="qr-modal-container" className={classes.container}>
        <TheatreResourceModalContent
          onAdded={onAdded}
          onClose={onClose}
          switchToScanner={switchToScanner}
        />
      </div>
    </Modal>
  );
};
