import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import {
  EmblaCarouselType,
  EmblaEventType,
  EmblaPluginType,
} from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import Fade from "embla-carousel-fade";
import useEmblaCarousel from "embla-carousel-react";

import { DotButton, useDotButton } from "./DotButton";
import { LazyLoadImage } from "./Image";

type StyleProps = {
  autoplayInterval?: number;
  styleVariant?: "default" | "rounded";
};

const useStyles = createUseStyles(({ color }) => ({
  carousel: {
    height: "100%",
  },
  viewport: {
    height: "100%",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    touchAction: "pan-y pinch-zoom",
    marginLeft: "calc(1rem * -1)",
    height: "100%",
  },
  dots: {
    transform: "translateY(-150%)",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  },
  dot: {
    "-webkit-tap-highlight-color": "rgba(49, 49, 49, 0.5)",
    "-webkit-appearance": "none",
    appearance: "none",
    touchAction: "manipulation",
    cursor: "pointer",
    border: 0,
    padding: 0,
    margin: 0,
    width: "0.5rem",
    height: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: ({ styleVariant }: StyleProps) =>
      styleVariant === "rounded" ? "50%" : undefined,
    transition: "width 0.25s",
    backgroundColor: color.imageCarouselDotColor,
  },
  "@keyframes l1": {
    "100%": { backgroundSize: "100%" },
  },
  dotSelected: {
    width: "1rem",
    borderRadius: ({ styleVariant }: StyleProps) =>
      styleVariant === "rounded" ? "50px" : undefined,
    backgroundColor: color.imageCarouselDotSelectedColor,
  },
  dotSelectedAnimate: {
    background: `linear-gradient(${color.imageCarouselDotSelectedProgressColor} 0 0) 0/0% no-repeat ${color.imageCarouselDotSelectedColor}`,
    animation: ({ autoplayInterval }: StyleProps) =>
      `$l1 ${autoplayInterval}ms infinite linear`,
  },
}));

const TWEEN_FACTOR_BASE = 0.2;

export type CarouselImage = {
  img: string;
  placeholderImg: string;
};

type ImageCarouselProps = StyleProps & {
  mode?: "fade" | "slide" | "parallax";
  images: CarouselImage[];
  hasDots?: boolean;
  className?: string;
};

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
  styleVariant = "default",
  mode = "slide",
  images,
  autoplayInterval = 1000,
  hasDots = false,
  className,
}) => {
  const classes = useStyles({ styleVariant, autoplayInterval });

  const emblaPlugins: EmblaPluginType[] = [
    Autoplay({ playOnInit: true, delay: autoplayInterval }),
  ];

  if (mode === "fade") {
    emblaPlugins.push(Fade());
  }

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      watchDrag: images.length > 1,
    },
    emblaPlugins,
  );
  const [slidesInView, setSlidesInView] = useState<number[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const tweenFactor = useRef(0);
  const tweenNodes = useRef<HTMLElement[]>([]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const updateSlidesInView = useCallback((eemblaApi: EmblaCarouselType) => {
    setSlidesInView((previousSlidesInView) => {
      if (previousSlidesInView.length === eemblaApi.slideNodes().length) {
        eemblaApi.off("slidesInView", updateSlidesInView);
      }
      const inView = eemblaApi
        .slidesInView()
        .filter((index) => !previousSlidesInView.includes(index));
      return previousSlidesInView.concat(inView);
    });
  }, []);

  const setTweenNodes = useCallback((eemblaApi: EmblaCarouselType): void => {
    tweenNodes.current = eemblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector("#parallax_layer") as HTMLElement;
    });
  }, []);

  const setTweenFactor = useCallback((eemblaApi: EmblaCarouselType) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * eemblaApi.scrollSnapList().length;
  }, []);

  const tweenParallax = useCallback(
    (eemblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
      const engine = eemblaApi.internalEngine();
      const scrollProgress = eemblaApi.scrollProgress();
      const currentSlidesInView = eemblaApi.slidesInView();
      const isScrollEvent = eventName === "scroll";

      eemblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
        let diffToTarget = scrollSnap - scrollProgress;
        const slidesInSnap = engine.slideRegistry[snapIndex];

        slidesInSnap.forEach((slideIndex) => {
          if (isScrollEvent && !currentSlidesInView.includes(slideIndex))
            return;

          if (engine.options.loop) {
            engine.slideLooper.loopPoints.forEach((loopItem) => {
              const target = loopItem.target();

              if (slideIndex === loopItem.index && target !== 0) {
                const sign = Math.sign(target);

                if (sign === -1) {
                  diffToTarget = scrollSnap - (1 + scrollProgress);
                }
                if (sign === 1) {
                  diffToTarget = scrollSnap + (1 - scrollProgress);
                }
              }
            });
          }

          const translate = diffToTarget * (-1 * tweenFactor.current) * 100;
          const tweenNode = tweenNodes.current[slideIndex];
          tweenNode.style.transform = `translateX(${translate}%)`;
        });
      });
    },
    [],
  );

  useEffect(() => {
    if (!emblaApi) return;

    updateSlidesInView(emblaApi);

    if (mode === "parallax") {
      setTweenNodes(emblaApi);
      setTweenFactor(emblaApi);
      tweenParallax(emblaApi);

      emblaApi
        .on("reInit", setTweenNodes)
        .on("reInit", setTweenFactor)
        .on("reInit", tweenParallax)
        .on("scroll", tweenParallax)
        .on("slideFocus", tweenParallax);
    }

    const autoplay = emblaApi.plugins()?.autoplay;
    setIsPlaying(autoplay?.isPlaying());
    emblaApi
      .on("autoplay:play", () => setIsPlaying(true))
      .on("autoplay:stop", () => setIsPlaying(false))
      .on("reInit", () => setIsPlaying(autoplay?.isPlaying()));

    emblaApi
      .on("slidesInView", updateSlidesInView)
      .on("reInit", updateSlidesInView);
  }, [emblaApi, updateSlidesInView, tweenParallax, mode]);

  return (
    <div className={classNames(classes.carousel)}>
      <div className={classes.viewport} ref={emblaRef}>
        <div className={classNames(classes.container, className)}>
          {images.map((image, index) => (
            <LazyLoadImage
              key={index}
              imgSrc={image.img}
              placeholderImgSrc={image.placeholderImg}
              shouldLoad={
                slidesInView.indexOf(index - 1) > -1 ||
                slidesInView.indexOf(index) > -1
              }
              isParallax={mode === "parallax"}
              styleVariant={styleVariant}
            />
          ))}
        </div>
      </div>
      {hasDots && images.length > 1 && (
        <div className={classes.dots}>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={classNames(
                classes.dot,
                index === selectedIndex && classes.dotSelected,
                index === selectedIndex &&
                  isPlaying &&
                  classes.dotSelectedAnimate,
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};
