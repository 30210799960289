import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useTimeoutFn } from "react-use";
import { isPresent } from "@apl-digital/utils";
import Icon from "@base/components/Global/Icon";
import { getBlobStorage } from "@theme";
import QRScanner from "qr-scanner";
import QrScanner from "qr-scanner";

import Box from "../../Box/Box";
import BoxList from "../../Box/BoxList";
import Footer from "../../Global/Footer";
import IconButton from "../../Global/IconButton";

const useStyles = createUseStyles(({ font, spacing, color, zIndex }) => ({
  modalContainer: {
    zIndex: zIndex.actionTopModal,
    position: "relative",
  },
  outerContainer: {
    background: color.modalQrScannerOuterContainerBg,
  },
  manualLink: {
    textDecoration: "underline",
    fontWeight: font.weight.l,
    cursor: "pointer",
    textAlign: "center",
    color: color.modalQRScannerLink,
  },
  qrCodePreview: {
    background: color.modalQrScannerPreviewBg,
    position: "relative",
    height: "50%",

    "& video": {
      objectFit: "cover",
    },
  },
  closeModalBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10,
    width: 42,
    height: 42,
    margin: [spacing.m, spacing.m],
    "& svg": {
      width: 42,
      height: 42,
      background: color.modalQrScannerCloseBtnBg,
      borderRadius: "100%",
      opacity: 0.5,
    },
  },
  qrPreviewContent: {
    height: "100%",
    "& h1": {
      textAlign: "center",
      lineHeight: font.lineHeight.xxl,
      fontWeight: font.weight.s,
      fontSize: font.size.x4l,
      color: color.modalQrScannerPreviewContentText,
      margin: 0,
    },
    "& p": {
      color: color.modalQrScannerPreviewContentPText,
      fontSize: font.size.s,
      textAlign: "center",
      marginTop: spacing.xxl,
    },
  },
  qrPreviewBody: {
    top: "40%",
    backgroundColor: "transparent",
    height: "100%",
  },
  scanErrorMessage: {
    color: color.error,
    textAlign: "center",
    alignSelf: "center",
  },
  footer: {
    justifyContent: "center",
    height: "15%",
    borderTopRightRadius: "24px",
    borderTopLeftRadius: "24px",
    "& p": {
      color: color.white,
      fontSize: font.size.m,
      textAlign: "center",
    },
  },
  cameraSwitchIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    width: 42,
    height: 42,
    margin: [spacing.m, spacing.m],
    "& svg": {
      width: 42,
      height: 42,
      background: color.modalQrScannerCloseBtnBg,
      padding: spacing.s,
      borderRadius: "100%",
      opacity: 0.5,
    },
  },
  cameraNotReadyImg: {
    width: "100%",
    height: "80%",
    marginTop: 42,
    filter: "brightness(1) invert(1)",
  },
}));

type ValidationSuccess = {
  isValid: true;
  value: string;
};

type ValidationFailure = {
  isValid: false;
  message: string;
};

export type QRCodeScannerModalContentProps = {
  onScanned: (value: string) => void;
  onValidate?: (
    value: string,
  ) => Promise<ValidationSuccess | ValidationFailure>;
  onClose: () => void;
  switchToManual?: () => void;
};

const QRCodeScannerModalContent = ({
  onScanned,
  onValidate,
  onClose,
  switchToManual,
}: QRCodeScannerModalContentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);

  const [scanner, setScanner] = useState<QRScanner | null>(null);
  const [scannerStatus, setScannerStatus] = useState<
    "idle" | "pending" | "loaded"
  >("idle");
  const [hasCamera, setHasCamera] = useState<boolean>(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [scanError, setScanError] = useState<string | null>(null);
  const [facingMode, setFacingMode] =
    useState<QRScanner.FacingMode>("environment");

  const [reset] = useTimeoutFn(() => {
    if (isPresent(scanError)) {
      setScanError(null);
    }
  }, 3000);

  const setError = (error: string) => {
    setScanError(error);
    reset();
  };

  const parseResult = async (
    scanResult: QRScanner.ScanResult,
  ): Promise<void> => {
    try {
      const scanData = scanResult.data;

      const result = await onValidate?.(scanData);

      if (isPresent(result) && !result.isValid) {
        setError(result.message);
        return;
      }

      onClose();
      onScanned(result?.value ?? scanData);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(t("qr_scanner_errorMsg_failedToScan"));
      }
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    let newScanner: QrScanner | null = null;

    const startScanner = async () => {
      // check if browser supports cameras
      if (
        !("mediaDevices" in navigator) ||
        !("getUserMedia" in navigator.mediaDevices)
      ) {
        return;
      }

      const qrCameras = await QRScanner.listCameras(true);
      const hasScannerCamera = await QRScanner.hasCamera();

      if (
        !hasScannerCamera ||
        qrCameras.length === 0 ||
        qrCameras[0].id === ""
      ) {
        console.error("No camera found");
        return;
      }

      setHasCamera(true);

      if (!isPresent(videoRef.current)) {
        console.error("No video element found");
        return;
      }

      if (!isSubscribed) {
        return;
      }

      newScanner = new QRScanner(
        videoRef.current,
        async (result: QRScanner.ScanResult) => parseResult(result),
        {
          maxScansPerSecond: 1,
          highlightScanRegion: true,
          preferredCamera: facingMode,
        },
      );

      if (isSubscribed) {
        await newScanner.start();
      }

      if (isSubscribed) {
        setScanner(newScanner);
      }
    };

    setScannerStatus("pending");

    startScanner()
      .catch((error) => {
        console.error("Failed to start scanner", error);
      })
      .finally(() => {
        setScannerStatus("loaded");
      });

    return () => {
      isSubscribed = false;

      newScanner?.stop();
      newScanner?.destroy();
    };
  }, []);

  const changeCamera = async (): Promise<void> => {
    if (scanner) {
      if (facingMode == "environment") {
        setFacingMode("user");
        await scanner.setCamera("user");
      } else {
        setFacingMode("environment");
        await scanner.setCamera("environment");
      }
    }
  };

  const showManualModal = () => {
    switchToManual?.();
  };

  const onCloseModal = () => {
    onClose();
  };

  const { qrIcon, closeIcon, cameraSwitchIcon } = getBlobStorage();

  return (
    <>
      <div className={classes.qrCodePreview}>
        <IconButton action={onCloseModal}>
          <Icon
            url={closeIcon}
            className={classes.closeModalBtn}
            height={24}
            width={24}
          />
        </IconButton>
        {hasCamera && scannerStatus === "loaded" && (
          <IconButton action={changeCamera}>
            <Icon
              url={cameraSwitchIcon}
              className={classes.cameraSwitchIcon}
              height={24}
              width={24}
            />
          </IconButton>
        )}
        {!isVideoPlaying && (
          <img src={qrIcon} className={classes.cameraNotReadyImg} alt="" />
        )}

        <video
          ref={videoRef}
          width="100%"
          height="100%"
          onPlaying={() => setIsVideoPlaying(true)}
        />
      </div>
      <BoxList className={classes.qrPreviewBody}>
        <Box classNames={classes.qrPreviewContent}>
          <h1>{t("qr_scanner_scan_code_on_your_table_title")}</h1>
          <p>{t("qr_scanner_scan_code_on_your_table_msg")}</p>

          <div className={classes.manualLink} onClick={showManualModal}>
            {t("qr_scanner_helpMsg_enterManually")}
          </div>

          <strong className={classes.scanErrorMessage}>{scanError}</strong>
        </Box>
        {!hasCamera && scannerStatus !== "idle" && (
          <Footer override={classes.footer} isError>
            <p>{t("camera_permission_blocked_err_msg")}</p>
          </Footer>
        )}
      </BoxList>
    </>
  );
};

export default QRCodeScannerModalContent;
