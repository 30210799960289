import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useAuth } from "react-oidc-context";
import HorizontalScrollable from "@base/components/Global/HorizontalScrollable";
import Icon from "@base/components/Global/Icon";
import { getImageFromAPI } from "@base/utils/imageHelper";
import config from "@constants/config";
import { useProducts } from "@store/ProductsProvider";
import { scrollToSection } from "@store/ProductsProvider/helper";
import { ProductListItem } from "@store/ProductsProvider/types";
import { getBlobStorage, getBrandTheme } from "@theme";
import classnames from "classnames";

const BLOCK_HEIGHT = 177;
const BLOCK_BIG_HEIGHT = 221;
const BLOCK_WIDTH = 163;

const useStyles = createUseStyles(({ color, spacing, font, borderRadius }) => ({
  menuBlockItem: {
    height: BLOCK_HEIGHT,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: [spacing.xxl, spacing.x3l],
    borderRadius: borderRadius.l,
    minWidth: BLOCK_WIDTH,
    cursor: "pointer",
  },
  menuBlockItemBig: {
    height: BLOCK_BIG_HEIGHT,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 60,
    gap: spacing.xxl,
  },
  desktopContainer: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.xxl,
  },
  desktopBlocksContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    gap: spacing.xxl,
    overflowY: "hidden",
    overflowX: "auto",
    overflow: "-moz-scrollbars-none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      width: "0 !important",
      height: "0 !important",
    },
    scrollbarWidth: "none",
    scrollBehavior: "smooth",
  },
  searchBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    alignItems: "center",
    justifyContent: "center",
    color: color.menuBlockSearch,
    minWidth: BLOCK_WIDTH,
    height: BLOCK_HEIGHT,
    borderRadius: borderRadius.l,
    background: color.menuBlockSearchBg,
    cursor: "pointer",
    fontSize: font.size.m,
    lineHeight: font.lineHeight.s,
    textTransform: "uppercase",
  },
  blockContainer: {
    display: "flex",
    gap: spacing.xxl,
    justifyContent: "center",
  },
  title: {
    fontSize: font.size.xl,
    fontWeight: font.weight.m,
    lineHeight: font.lineHeight.m,
    color: color.menuBlockTitle,
  },
  header: {
    fontWeight: font.weight.s,
    fontSize: font.size.x4l,
    lineHeight: font.lineHeight.xxl,
    padding: [0, spacing.s, spacing.xl, spacing.xl],
    color: color.menuBlockTitle,
  },
  picture: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: 110,
    height: 110,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderTopLeftRadius: borderRadius.l,
    borderBottomRightRadius: borderRadius.l,
  },
  menuBlockCol: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxl,
    width: "100%",
    cursor: "pointer",
  },
}));

type MenuBlockItemProps = {
  item?: ProductListItem;
  isBig: boolean;
};

const MenuBlockItem: React.FC<MenuBlockItemProps> = ({ item, isBig }) => {
  const classes = useStyles();

  if (!item) return null;

  return (
    <div
      className={classnames([
        classes.menuBlockItem,
        isBig && classes.menuBlockItemBig,
      ])}
      style={{
        backgroundColor: item.bgColor,
      }}
      onClick={() => scrollToSection(item.id)}
    >
      <span className={classes.title}>{item.title}</span>
      <div
        className={classes.picture}
        style={{
          backgroundImage: `url(${getImageFromAPI(item.picture)})`,
        }}
      />
    </div>
  );
};

const isBig = (total: number, colLen: number, idx: number): boolean => {
  if (total === 2) return true;
  if (colLen < 5) {
    if (colLen % 3 === 0 && idx % 3 === 0) return true;
    if (colLen % 2 === 0 && idx % 2 == 0) return true;
  }
  if (colLen === 5 && (idx % 3 === 0 || idx % 5 === 0)) return true;
  if ((colLen === 6 || colLen === 9) && idx % 3 === 0) return true;
  if (colLen === 7) {
    if (idx <= 3 && idx % 3 === 0) return true;
    if (idx > 3 && (idx - 1) % 2 === 0) return true;
  }
  if (colLen === 8) {
    if ((idx <= 6 && idx % 3 === 0) || idx === 8) return true;
  }
  if (colLen === 10) {
    if (idx <= 6 && idx % 3 === 0) return true;
    if (idx > 6 && idx % 2 === 0) return true;
  }

  return false;
};

type MenuBlockMobileProps = {
  sections: ProductListItem[];
};

type MenuBlockDesktopProps = {
  sections: ProductListItem[];
  onOpenSearch: () => void;
};

const MenuBlockMobile: React.FC<MenuBlockMobileProps> = ({ sections }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  if (!sections || config.featureFlags.shouldHideMenuBlock) return null;

  const isOdd = sections.length % 2 !== 0;
  const total = sections.length;
  const left = sections.filter((s, idx) => idx % 2 === 0);
  const right = sections.filter((s, idx) => idx % 2 !== 0);

  return (
    <div
      className={classes.container}
      style={{
        paddingTop:
          isAuthenticated || !config.featureFlags.isLoginEnabled ? 32 : 60,
      }}
    >
      <div className={classes.header} id="menu-block-start">
        {t("menu_title")}
      </div>
      <div className={classes.blockContainer}>
        <div className={classes.menuBlockCol}>
          {left
            .slice(0, isOdd ? left.length - 1 : left.length)
            .map((item, idx) => {
              return (
                <MenuBlockItem
                  key={idx}
                  item={item}
                  isBig={isBig(
                    total,
                    isOdd ? left.length - 1 : left.length,
                    idx,
                  )}
                />
              );
            })}
        </div>
        <div className={classes.menuBlockCol}>
          {right.map((item, idx) => {
            return (
              <MenuBlockItem
                key={idx}
                item={item}
                isBig={isBig(total, right.length, idx + 1)}
              />
            );
          })}
        </div>
      </div>
      {isOdd && (
        <div>
          <MenuBlockItem item={left[left.length - 1]} isBig={false} />
        </div>
      )}
    </div>
  );
};

const MenuBlockDesktop: React.FC<MenuBlockDesktopProps> = ({
  sections,
  onOpenSearch,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const carouselRef = useRef<HTMLDivElement>(null);
  const { magnifyingGlassIcon } = getBlobStorage();
  const { color } = getBrandTheme();

  return (
    <div className={classes.desktopContainer}>
      <div id="categories-list-menu-btn">
        <div className={classes.searchBlock} onClick={onOpenSearch}>
          <Icon
            url={magnifyingGlassIcon}
            stroke={color.menuBlockSearchIcon}
            height={24}
            width={24}
          />
          <div>{t("menu_title")}</div>
        </div>
      </div>
      <HorizontalScrollable scrollContainerRef={carouselRef}>
        <div ref={carouselRef} className={classes.desktopBlocksContainer}>
          {sections.map((item, key) => (
            <MenuBlockItem isBig={false} item={item} key={key} />
          ))}
        </div>
      </HorizontalScrollable>
    </div>
  );
};

const MenuBlockDesktopWrapper: React.FC<{ onOpenSearch: () => void }> = ({
  onOpenSearch,
}) => {
  const [{ sections }] = useProducts();

  if (config.featureFlags.shouldHideMenuBlock) return null;

  if (!sections) {
    return <div style={{ height: BLOCK_HEIGHT }}></div>;
  }

  return <MenuBlockDesktop sections={sections} onOpenSearch={onOpenSearch} />;
};

const MenuBlockMobileWrapper: React.FC = () => {
  const [{ sections }] = useProducts();

  if (!sections || config.featureFlags.shouldHideMenuBlock) return null;

  return <MenuBlockMobile sections={sections} />;
};

export { MenuBlockDesktopWrapper, MenuBlockMobileWrapper };
