import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useAuth } from "react-oidc-context";
import Button from "@base/components/Global/Button";
import Icon from "@base/components/Global/Icon";
import { useUser } from "@context/UserProvider";
import { getBlobStorage } from "@theme";
import classnames from "classnames";

const useStyles = createUseStyles(({ spacing, borderRadius, font, color }) => ({
  mainContainer: {
    minHeight: 122,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    borderRadius: "24px",
    background: color.signInBackground,
    overflow: "hidden",
    cursor: "pointer",
    "& h3": {
      fontSize: font.size.m,
      fontWeight: font.weight.m,
      padding: 0,
      margin: 0,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "end",
    padding: [spacing.xxl, spacing.xxl],
  },
  smallContainer: {
    height: 68,
    padding: spacing.xl,
    borderRadius: borderRadius.l,
    marginTop: spacing.xxl,
  },
  icon: {
    right: 0,
    overflow: "hidden",
    position: "absolute",
    height: "100%",
    top: "12.12%",
    left: "73%",
    bottom: "29.68%",
    width: 127,
    backgroundRepeat: "no-repeat",
    borderBottomRightRadius: 20,
  },
  signIn: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  signInSmall: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.s,
    alignItems: "center",
  },
  benefitsMsg: {
    fontSize: font.size.xl,
    lineHeight: font.lineHeight.m,
  },
  benefitsMsgSmall: {
    fontSize: font.size.m,
    lineHeight: "18px",
  },
  outlineButton: {
    border: `1px solid ${color.black}`,
    background: color.burgerMenuLoginButtonBg,
    color: color.burgerMenuLoginButtonText,
    gap: spacing.xs,
    width: "50%",
  },
  loginButton: {
    color: color.white,
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    height: 38,
    width: "auto",
    padding: [spacing.xxl, spacing.xxl],
    marginTop: spacing.s,
    background: color.black,
    borderRadius: borderRadius.button,
    textAlign: "center",
    display: "flex",
    alignSelf: "start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "clip",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  accountLogInLoyaltyCardContainer: {
    flex: ".5",
  },
  accountLogInLoyaltyCardIcon: {
    right: -32,
    top: 15,
    position: "absolute",
  },
  menuItemLanguageExtra: {
    color: color.greyCoal,
  },
}));

type Props = {
  buttonId?: string;
};

const SignInBlock: React.FC<Props> = ({ buttonId = "sign-in-block" }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rotatedLoyaltyClubCardIcon } = getBlobStorage();
  const { login } = useUser();
  const { isLoading } = useAuth();

  return (
    <div>
      <div className={classes.mainContainer} onClick={login} id={buttonId}>
        <div style={{ flex: 1 }} className={classes.container}>
          <h3>{t("burgermenu_main_club_benefits")}</h3>
          <Button
            className={classnames([classes.outlineButton, classes.loginButton])}
            isLoading={isLoading}
            action={login}
          >
            {t("burgermenu_main_login")}
          </Button>
        </div>
        <div className={classes.accountLogInLoyaltyCardContainer}>
          <Icon
            url={rotatedLoyaltyClubCardIcon}
            className={classes.accountLogInLoyaltyCardIcon}
            height={139}
            width={151}
          />
        </div>
      </div>
    </div>
  );
};

export default SignInBlock;
