import { useMemo } from "react";
import React from "react";
import { Trans } from "react-i18next";

import { useRichTextTranslation } from "./useRichTextTranslation";

type RichListTranslationProps = {
  getTranslation: () => string[];
};

export const RichListTranslation = ({
  getTranslation,
}: RichListTranslationProps) => {
  const transProps = useRichTextTranslation();

  const translationLines = useMemo(() => {
    const translation = getTranslation();

    if (!Array.isArray(translation)) {
      return [translation];
    }

    return translation;
  }, [getTranslation]);

  return (
    <>
      {translationLines.map((line, idx) => (
        <Trans {...transProps} key={idx}>
          {line}
        </Trans>
      ))}
    </>
  );
};
