import useWindowSize from "@base/hooks/useWindowSize";
import { SECTIONS_SELECTOR_HEIGHT } from "@pages/HomePage/components/ProductList";
import { useScrollContext, Values } from "@store/ScrollProvider";
import { getBrandTheme } from "@theme";
import { deepEqual } from "fast-equals";
import { Location } from "history";
import React, { useEffect } from "react";

const useWindowScroll = (
  location: Location,
  scrollPositionRef: React.MutableRefObject<number>,
  isProductPage: boolean | undefined,
  getTopLen: () => number,
  setRefScrollPosition: (top: number) => void,
): Values => {
  const theme = getBrandTheme();
  const [{ values }, { setIsScrolledAboveFold, setValues }] =
    useScrollContext();
  const { isMdAndUp } = useWindowSize();

  useEffect(() => {
    const rootComponent = document.getElementById("root");

    const onWindowScrolled = () => {
      const { top = 0 } = rootComponent?.getBoundingClientRect() || {};

      const topLen = getTopLen();

      const hasScrolledToAboveFold = top ? top > topLen : true;
      setIsScrolledAboveFold(hasScrolledToAboveFold);

      const sectionContainer = document.getElementById("section-selectors-bar");
      const isScrollingUpward = top ? scrollPositionRef.current < top : false;

      const isStick = sectionContainer
        ? sectionContainer.classList.value.includes("show")
        : false;

      setIsScrolledAboveFold(hasScrolledToAboveFold);

      const shouldShowNavbar = Boolean(
        isScrollingUpward || hasScrolledToAboveFold || isProductPage,
      );

      let sectionLineTop: number;

      if (shouldShowNavbar) {
        if (hasScrolledToAboveFold) {
          sectionLineTop = 36;
        } else {
          sectionLineTop = 37 + theme.config.mobileNavbarHeight;
        }
      } else {
        sectionLineTop = 36;
      }

      const newValues = {
        sectionContainerTop: shouldShowNavbar
          ? isMdAndUp
            ? theme.config.desktopNavbarHeight
            : theme.config.mobileNavbarHeight
          : 0,
        fixedSubItemTop: shouldShowNavbar
          ? SECTIONS_SELECTOR_HEIGHT +
            (isMdAndUp
              ? theme.config.desktopNavbarHeight
              : theme.config.mobileNavbarHeight)
          : SECTIONS_SELECTOR_HEIGHT,
        sectionLineTop,
        shouldShowNavbar: shouldShowNavbar,
        transition: isStick,
      };

      setValues((prevValue) => {
        if (deepEqual(prevValue, newValues)) {
          return prevValue;
        } else {
          return newValues;
        }
      });

      setRefScrollPosition(top);
    };

    window.addEventListener("scroll", onWindowScrolled);

    return () => {
      window.removeEventListener("scroll", onWindowScrolled);
    };
  }, [location]);

  return values;
};

export default useWindowScroll;
