import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "@constants/misc";
import {
  isFieldMandatory,
  isFieldVisible,
} from "@context/SalesPointProvider/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { selectContactInfo } from "@store/contactInfo";
import { useAppSelector } from "@store/hooks";
import { SalesPointState, selectConfiguration } from "@store/salesPoint";
import * as Yup from "yup";

type ValidationContext = Array<SalesPointState["configuration"]>;

type ContactDetailsFormProviderProps = {
  children?: React.ReactNode;
};

const ContactDetailsFormProvider: React.FC<ContactDetailsFormProviderProps> = ({
  children,
}) => {
  const contactInfo = useAppSelector(selectContactInfo);
  const configuration = useAppSelector(selectConfiguration);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .nullable()
      .when("$configuration", ([conf]: ValidationContext, schema) => {
        if (isFieldMandatory("name", conf))
          return schema.required(t("form_error_message_required_field"));
        return schema;
      }),
    phone: Yup.string()
      .required()
      .nullable()
      .when("$configuration", ([conf]: ValidationContext, schema) => {
        if (isFieldVisible("phone", conf)) {
          return schema.matches(PHONE_NUMBER_REGEX, {
            message: t("contact_details_enter_valid_phone_number"),
            excludeEmptyString: true,
          });
        }
        return schema;
      })
      .when("$configuration", ([conf]: ValidationContext, schema) => {
        if (isFieldMandatory("phone", conf))
          return schema.required(t("form_error_message_required_field"));
        return schema;
      }),
    email: Yup.string()
      .required()
      .nullable()
      .when("$configuration", ([conf]: ValidationContext, schema) => {
        if (isFieldVisible("email", conf)) {
          return schema.matches(EMAIL_REGEX, {
            message: t("contact_details_enter_valid_email"),
            excludeEmptyString: true,
          });
        }
        return schema;
      })
      .when("$configuration", ([conf]: ValidationContext, schema) => {
        if (isFieldMandatory("email", conf))
          return schema.required(t("form_error_message_required_field"));
        return schema;
      }),
  });

  type validation = Yup.InferType<typeof validationSchema>;

  const form = useForm<validation>({
    values: {
      name: contactInfo.Name || null,
      phone: contactInfo.Phone || null,
      email: contactInfo.Email || null,
    },
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    context: { configuration },
  });

  return <FormProvider {...form}>{children}</FormProvider>;
};

export default ContactDetailsFormProvider;
