import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "@api/interfaces/orderLayouts";
import { isPresent } from "@apl-digital/utils";
import Button from "@base/components/Global/Button";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { NavigationPath } from "@constants/navigation";
import { useAppDispatch } from "@store";
import { removeOrder } from "@store/orders";
import { OrderDataWithGrouping } from "@store/orders/types";

import { OrderCardCompletedBlock } from "./OrderCardCompletedBlock";
import { OrderCardExtraInfoBlock } from "./OrderCardExtraInfoBlock";
import { OrderCardPreOrderBlock } from "./OrderCardPreOrderBlock";
import { OrderCardQueueNumberBlock } from "./OrderCardQueueNumberBlock";
import StatusBlock from "./StatusBlock";

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  statusHeader: {
    fontSize: font.size.m,
    lineHeight: "1.4em",
    margin: [spacing.l, 0],
    color: color.infoTextColor,
  },
  statusBlockContainer: {
    marginTop: spacing.xxl,
  },
  rootContainer: {
    height: "100%",

    display: "flex",
    flexDirection: "column",
    gap: spacing.x5l,
    justifyContent: "space-between",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  actionRow: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
  },
}));

type OrderCardContentColumnProps = {
  order: OrderDataWithGrouping;
  variant: "desktop" | "mobile";
  isMovedOrder: boolean;
  isSelected: boolean;
};

export const OrderCardContentColumn: React.FC<OrderCardContentColumnProps> = ({
  order,
  variant,
  isMovedOrder,
  isSelected,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("orderStatusPage");
  const { resolveKey } = useSalesPointTranslationKey();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={classes.rootContainer}>
      <div className={classes.infoContainer}>
        {isMovedOrder ? (
          <OrderCardCompletedBlock
            variant="orderMoved"
            lastStatusChangeDateTime={order.dttmLastStatusChange}
            showSubtitle
          />
        ) : order.isComplete &&
          (order.Status === OrderStatus.Completed ||
            order.Status === OrderStatus.Refunded ||
            order.Status === OrderStatus.Cancelled) ? (
          <OrderCardCompletedBlock
            variant={order.Status}
            lastStatusChangeDateTime={order.dttmLastStatusChange}
          />
        ) : (
          <>
            <OrderCardQueueNumberBlock
              orderQueueNumber={order.QueueNumber}
              variant="row"
            />

            {isPresent(order.dttmRequestedDelivery) ? (
              <OrderCardPreOrderBlock
                requestedDeliveryDateTime={order.dttmRequestedDelivery}
                diningOptionId={order.DiningOption.ID}
                variant="row"
              />
            ) : (
              <div className={classes.statusHeader} id="order-table-number">
                {t(resolveKey("orderHelpMessage"), { ns: "salespoint" })}
              </div>
            )}
          </>
        )}

        <div className={classes.statusBlockContainer}>
          <StatusBlock order={order} />
        </div>

        <OrderCardExtraInfoBlock
          transactionId={order.TransactionID}
          totalGrossSum={order.TotalGrossSum}
          variant="row"
        />
      </div>

      {isMovedOrder && !isSelected ? (
        <div className={classes.actionRow}>
          <Button
            action={() => dispatch(removeOrder(order.BarcodeWithoutChecksum))}
            fitContent
          >
            {t("OrderCardContentColumn.removeOrderButtonText")}
          </Button>
        </div>
      ) : (
        variant === "desktop" && (
          <div className={classes.actionRow}>
            <Button
              action={() => navigate(NavigationPath.Home)}
              fitContent
              id="order-more-btn"
            >
              {t("OrderCardContentColumn.orderMoreButton")}
            </Button>
          </div>
        )
      )}
    </div>
  );
};
