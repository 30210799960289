import config from "@constants/config";
import { DefaultTheme } from "react-jss";

import {
  apollocinemaTheme,
  apolloFoodTruckTheme,
  blenderTheme,
  cityExpressTheme,
  finnkinoTheme,
  forumcinemasTheme,
  kfcTheme,
  mySushiTheme,
  olearysTheme,
  urbanKitchenTheme,
  vapianoTheme,
} from "./themes";
import {
  ThemeCountry,
  ThemeCountryMap,
  ThemeKey,
  ThemeVariant,
  ThemeVariantMap,
} from "./types";

const selectThemeVariant = <T>(
  theme: ThemeVariantMap<T>,
  color: ThemeVariant,
): T => {
  const themeColor = theme[color];

  if (!themeColor) {
    throw Error(`Theme color ${color} not found for theme ${theme}`);
  }

  return themeColor;
};

const selectThemeCountry = <T>(
  theme: ThemeCountryMap<T>,
  country: ThemeCountry,
): T => {
  const themeCountry = theme[country];

  if (!themeCountry) {
    throw Error(`Theme country ${country} not found for theme ${theme}`);
  }

  return themeCountry;
};

const selectTheme = <T extends DefaultTheme>(
  theme:
    | ThemeCountryMap<T>
    | ThemeVariantMap<T>
    | ThemeCountryMap<ThemeVariantMap<T>>,
  country?: ThemeCountry,
  color?: ThemeVariant,
): DefaultTheme => {
  if (country && color) {
    const themeColors = selectThemeCountry(
      theme as ThemeCountryMap<ThemeVariantMap<T>>,
      country,
    );

    return selectThemeVariant(themeColors, color);
  }

  if (country) {
    return selectThemeCountry(theme as ThemeCountryMap<T>, country);
  }

  if (color) {
    return selectThemeVariant(theme as ThemeVariantMap<T>, color);
  }

  throw Error(
    `Theme ${theme} not found for country ${country} and color ${color}`,
  );
};

const applyThemeMode = (color: ThemeVariant): void => {
  if (color === "DARK") {
    document.body.style.backgroundColor = "#101010";
  } else {
    document.body.style.backgroundColor = "#FFFFFF";
  }
};

type ThemeConfigsType = {
  [key in ThemeKey]: (themeVariant: ThemeVariant) => DefaultTheme;
};

const ThemeConfigs: ThemeConfigsType = {
  VAPIANO: (color) => selectThemeVariant(vapianoTheme, color),
  OLEARYS: (color) => selectThemeVariant(olearysTheme, color),
  BLENDER: (color) => selectThemeVariant(blenderTheme, color),
  MYSUSHI: (color) => selectThemeVariant(mySushiTheme, color),
  APOLLO_CINEMA_EE: (color) => selectTheme(apollocinemaTheme, "EE", color),
  APOLLO_CINEMA_LT: (color) => selectTheme(apollocinemaTheme, "LT", color),
  FORUM_CINEMAS_LT: (color) => selectThemeVariant(forumcinemasTheme, color),
  KFC: (color) => selectThemeVariant(kfcTheme, color),
  FINNKINO: (color) => selectThemeVariant(finnkinoTheme, color),
  URBAN_KITCHEN: (color) => selectThemeVariant(urbanKitchenTheme, color),
  CITY_EXPRESS: (color) => selectThemeVariant(cityExpressTheme, color),
  APOLLO_FOOD_TRUCK: (color) => selectThemeVariant(apolloFoodTruckTheme, color),
} as const;

export const getBrandTheme = (): DefaultTheme => {
  const theme = config.themeConfig.key;
  const defaultThemeVariant = config.themeConfig.defaultVariant;

  applyThemeMode(defaultThemeVariant);
  const themeConfig = ThemeConfigs[theme];

  if (!themeConfig) throw Error(`ThemeConfig not found for brand ${theme}`);

  return themeConfig(defaultThemeVariant);
};

export const getBlobStorage = () => {
  const { blobStorage } = getBrandTheme();
  return blobStorage;
};
