import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Banner, { BannerVariant } from "@base/components/Global/Banner";
import IconWithText from "@base/components/Global/IconWithText";
import { Modal } from "@base/components/Global/Modal";
import { RichListTranslation } from "@base/components/Global/RichTextTranslation";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { useWhiteLabelImage } from "@base/hooks/useWhiteLabelImage";
import useWindowSize from "@base/hooks/useWindowSize";
import LocationModalContent from "@pages/HomePage/components/LocationModalContent";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(({ color }) => ({
  location: {
    color: color.orderStatusPageLocationText,
    alignSelf: "flex-end",
  },
}));

const OngoingOrdersBanner = () => {
  const { isMd } = useWindowSize();
  const { t } = useTranslation(["salespoint", "orderStatusPage"]);
  const { resolveKey } = useSalesPointTranslationKey();

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const { statusBannerImage, locationIcon } = getBlobStorage();
  const getWhiteLabeledImage = useWhiteLabelImage();
  const classes = useStyles();

  const imgSrc = getWhiteLabeledImage(statusBannerImage);

  return (
    <>
      <Modal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      >
        <LocationModalContent />
      </Modal>
      <Banner
        title={t("orderStatusPage:OngoingOrdersBanner.title")}
        subtitle={t("orderStatusPage:OngoingOrdersBanner.subtitle")}
        imgSrc={imgSrc}
        column={isMd}
        variant={BannerVariant.SUCCESS}
      >
        <IconWithText
          iconUrl={locationIcon}
          onClick={() => setIsContactModalOpen(true)}
          interactiveElementId="order-status-page-header-contact-modal-open-btn"
          className={classes.location}
        >
          <RichListTranslation
            getTranslation={() =>
              t(resolveKey("addressLines", { returnObjects: true }), {
                returnObjects: true,
              })
            }
          />
        </IconWithText>
      </Banner>
    </>
  );
};

export default OngoingOrdersBanner;
