import { ICartContactInfo } from "@api/interfaces/cartLayouts";
import { isDefined } from "@apl-digital/utils";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/slices";

import { selectUserData } from "./slice";

export const selectUser = (state: RootState) => state.user;

export const selectUserContactInfo = createSelector(
  selectUserData,
  (userData): ICartContactInfo => {
    const names = [userData?.FirstName, userData?.LastName].filter(isDefined);

    return {
      Name: names.length > 0 ? names.join(" ") : null,
      Email: userData?.Email ?? null,
      Phone: userData?.Phone ?? null,
    };
  },
);
