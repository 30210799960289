import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Icon from "@base/components/Global/Icon";
import { getBlobStorage, getBrandTheme } from "@theme";
import classnames from "classnames";

import { useHighestMinRequiredAge } from "../hooks";

const useStyles = createUseStyles(({ spacing, font, color }) => ({
  warning: {
    display: "flex",
    gap: spacing.s,
    justifyContent: "center",
    fontSize: font.size.s,
    lineHeight: font.lineHeight.s,
    fontWeight: font.weight.m,
  },
  verified: {
    color: color.black,
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
  confirmText: {
    color: color.cartPageConfirmBlockText,
  },
}));

type VerifyAgeBlockProps = {
  isChecked?: boolean;
  onClick: () => void;
  hasError?: boolean;
};

export const VerifyAgeBlock: React.FC<VerifyAgeBlockProps> = ({
  onClick,
  isChecked = false,
  hasError = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const minRequiredAge = useHighestMinRequiredAge();

  const { warningUnchecked, warningChecked } = getBlobStorage();
  const { color } = getBrandTheme();

  return (
    <div
      className={classnames([classes.warning, isChecked && classes.verified])}
      id="confirm-age-bar"
      onClick={onClick}
    >
      <div className={classes.icon} id="confirm-age-btn">
        {isChecked ? (
          <Icon url={warningChecked} height={24} width={24} />
        ) : (
          <Icon
            url={warningUnchecked}
            stroke={hasError ? color.white : undefined}
            fill={hasError ? color.warningBg : undefined}
            height={24}
            width={24}
          />
        )}
      </div>
      <div className={classes.confirmText}>
        {t("cart_checkout_confirmAgeMsg").replace(
          "%MinRequiredAge%",
          String(minRequiredAge),
        )}
      </div>
    </div>
  );
};
