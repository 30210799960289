import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ICartLayout } from "@api/interfaces/cartLayouts";
import { IDiningOptionEnum } from "@api/interfaces/configurationLayouts";
import Box from "@base/components/Box/Box";
import Loader from "@base/components/Global/Loader";
import ContactDetailsForm from "@forms/contactDetails/ContactDetailsForm";
import CartItemsBlock, {
  CartItemsBlockVariant,
} from "@pages/CartPage/components/CartItemsBlock";
import { CheckoutOptions } from "@pages/CartPage/components/CheckoutOptions";
import { ResourceInfoBlock } from "@pages/CartPage/components/ResourceInfoBlock";
import TotalBlock, {
  TotalBlockVariant,
} from "@pages/CartPage/components/TotalBlock";
import { VerifyAgeBlock } from "@pages/CartPage/components/VerifyAgeBlock";
import { useHighestMinRequiredAge } from "@pages/CartPage/hooks";
import { useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import { selectShouldShowPaymentErrorForCurrentCart } from "@store/payment/selectors";
import { useScrollContext } from "@store/ScrollProvider";
import {
  selectShoppingCart,
  selectShoppingCartTotal,
  selectShoppingCartTotalItemCount,
} from "@store/shoppingCart";
import { isStatus } from "@store/utils";

import { SECTIONS_SELECTOR_HEIGHT } from "./ProductList";

type StyleProps = {
  isNavbarVisible: boolean;
};

const useStyles = createUseStyles(
  ({ color, config: themeConfig, spacing, borderRadius }) => ({
    blockContainer: {
      color: color.black,
      padding: spacing.xxl,
    },
    cartContainer: {
      maxHeight: ({ isNavbarVisible }: StyleProps) =>
        isNavbarVisible
          ? `calc(100vh - ${SECTIONS_SELECTOR_HEIGHT + 2 * spacing.xxl}px - ${
              themeConfig.desktopNavbarHeight
            }px)`
          : `calc(100vh - ${SECTIONS_SELECTOR_HEIGHT + 2 * spacing.xxl}px)`,
      position: "sticky",
      top: ({ isNavbarVisible }: StyleProps) =>
        isNavbarVisible
          ? SECTIONS_SELECTOR_HEIGHT + spacing.xxl + 54
          : SECTIONS_SELECTOR_HEIGHT + spacing.xxl,
      margin: [spacing.xxl, 0],
      padding: 0,
      borderRadius: borderRadius.l,
      boxShadow: color.boxShadowGlobal,
      transitionDuration: "300ms",
      transitionTimingFunction: "ease",
      transitionProperty: ["top", "max-height"],
    },
    smallContainer: {
      overflowY: "auto",
      padding: spacing.xxl,
    },

    checkboxContainer: {
      marginBottom: spacing.xl,
      cursor: "default",

      // Checkbox. These styles belong to a separate checkbox field component
      display: "flex",
      alignItems: "center",
      gap: spacing.s,
    },
    unverified: {
      color: color.warningBg,
    },

    takeOutContainerNote: {
      color: color.infoTextColor,
      margin: [spacing.l, spacing.x4l],
    },
    card: {
      margin: [spacing.xxl, 0],
      backgroundColor: color.boxBg,
      borderRadius: borderRadius.l,
      boxShadow: color.boxShadowGlobal,
      padding: spacing.xxl,
    },
    confirmText: {
      color: color.cartPageConfirmBlockText,
    },

    verifyAgeBlock: {
      paddingTop: spacing.xl,
    },
  }),
);

type ShoppingCartCardProps = {
  shoppingCart: ICartLayout;
  isShoppingCartLoaded: boolean;
};

const ShoppingCartCardContent: React.FC<ShoppingCartCardProps> = ({
  shoppingCart,
  isShoppingCartLoaded,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const cartRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [{ values }] = useScrollContext();
  const classes = useStyles({ isNavbarVisible: !!values.shouldShowNavbar });
  const shoppingCartItemCount = useAppSelector(
    selectShoppingCartTotalItemCount,
  );
  const shouldShowPaymentError = useAppSelector(
    selectShouldShowPaymentErrorForCurrentCart,
  );
  const shoppingCartTotal = useAppSelector(selectShoppingCartTotal);
  const minRequiredAge = useHighestMinRequiredAge();

  const [isAgeVerified, setIsAgeVerified] = useState(false);

  const {
    formState: { isValid },
  } = useFormContext();

  useEffect(() => {
    if (!shouldShowPaymentError) return;

    const timeout = setTimeout(() => {
      if (!cartRef.current) return;
      window.scrollTo({
        top: cartRef.current.offsetTop - SECTIONS_SELECTOR_HEIGHT,
        behavior: "smooth",
      });
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, [shouldShowPaymentError]);

  const shouldVerifyAge = minRequiredAge > 0;

  const isCheckoutButtonDisabled =
    (shouldVerifyAge && !isAgeVerified) ||
    !isValid ||
    !shoppingCartItemCount ||
    !isShoppingCartLoaded;

  return (
    <>
      <div className={classes.card}>
        <ResourceInfoBlock />
      </div>

      <Box classNames={classes.cartContainer} ref={cartRef}>
        <div className={classes.smallContainer} ref={scrollContainerRef}>
          <CartItemsBlock variant={CartItemsBlockVariant.SIDEBAR} />
          {shoppingCart.Defaults.DiningOptionID ===
            IDiningOptionEnum.TAKE_OUT && (
            <div className={classes.takeOutContainerNote}>
              {t("orders_takeaway_extra_info")}
            </div>
          )}
          <TotalBlock
            total={shoppingCart.Sums}
            variant={TotalBlockVariant.SIDEBAR}
          />
          <ContactDetailsForm />
          {shouldVerifyAge && (
            <div className={classes.verifyAgeBlock}>
              <VerifyAgeBlock
                isChecked={isAgeVerified}
                onClick={() => setIsAgeVerified((prevState) => !prevState)}
              />
            </div>
          )}
        </div>

        <CheckoutOptions
          isDisabled={isCheckoutButtonDisabled}
          totalSum={shoppingCartTotal}
          variant="desktop"
          scrollContainerRef={scrollContainerRef}
        />
      </Box>
    </>
  );
};

export const ShoppingCartCard = () => {
  const shoppingCart = useAppSelector(selectShoppingCart);

  if (isStatus(shoppingCart, "pending")) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!hasData(shoppingCart)) {
    return null;
  }

  return (
    <ShoppingCartCardContent
      shoppingCart={shoppingCart.state}
      isShoppingCartLoaded={isStatus(shoppingCart, "succeeded")}
    />
  );
};
