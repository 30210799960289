import React from "react";
import { useTranslation } from "react-i18next";

type CartItemModifierBlockProps = {
  name: string;
  description?: string;
  amount: number;
  grossSum: number;
};

export const CartItemModifierBlock: React.FC<CartItemModifierBlockProps> = ({
  name,
  description = "",
  amount,
  grossSum,
}) => {
  const { t } = useTranslation("cartPage");

  return (
    <li>
      {grossSum > 0
        ? t("CartItemBlock.modifierLine.withPrice", {
            name: name,
            description: description,
            singlePrice: grossSum,
            amount: amount,
            totalPrice: grossSum * amount,
            formatParams: {
              totalPrice: { currency: "EUR" },
              singlePrice: { currency: "EUR" },
            },
          })
        : t("CartItemBlock.modifierLine.withoutPrice", {
            name: name,
            description: description,
            amount: amount,
          })}
    </li>
  );
};
