export enum IDiningOptionEnum {
  DINE_IN = 2,
  TAKE_OUT = 3,
}

export type GetAvailableDatesRequest = {
  diningOption: IDiningOptionEnum;
};

export interface IAvailableDiningDate {
  dtDate: string;
}

export interface IAvailableDiningTime {
  TheatreResourceID: number;
  dttmDelivery: string | null;
}

export type GetAvailableTimesRequest = {
  diningOption: IDiningOptionEnum;
  date: string;
};

export type DiningOption = {
  ID: IDiningOptionEnum;
  Name: string;
  LocalName: string;
  DisplayName: string;
  IsEnabled: boolean;
  IsPreorderEnabled: boolean;
};

type LocaleMap = Record<string, string[]>;

export type AssetCategories =
  | "home_page_desktop_banner"
  | "home_page_mobile_banner"
  | "location_page_business_image"
  | "status_page_mobile_banner";
type CategoryMap = Record<AssetCategories, LocaleMap>;

type ColorVariants = "DARK" | "LIGHT";
type AssetsMap = Record<ColorVariants, CategoryMap>;

export interface ISalesPointConfiguration {
  IsKitchenOpen: boolean;
  ProductSalesLayoutId: number;
  RequiredFormFields?: string[];
  VisibleFormFields?: string[];
  DefaultTableBarcode?: string;
  DiningOptions: DiningOption[];
  KitchenOrderMonitorListWindowInMinutes: number;
  assets: AssetsMap;
}
