import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import LazyLoad from "react-lazyload";
import Icon from "@base/components/Global/Icon";
import TextField from "@base/components/Global/TextField";
import ProductItem from "@pages/HomePage/components/ProductItem";
import { ITEM_MARGIN_BOTTOM } from "@pages/HomePage/components/ProductList";
import SearchCategoriesList from "@pages/HomePage/components/SearchCategoriesList";
import { useProducts } from "@store/ProductsProvider";
import { ProductListItem } from "@store/ProductsProvider/types";
import { getBlobStorage } from "@theme";

const SEARCH_MINIMUM_CHARACTERS = 2;

const useStyles = createUseStyles(({ spacing, font }) => ({
  container: {
    overflowY: "auto",
  },
  searchListResults: {
    paddingLeft: spacing.xxl,
    paddingRight: spacing.xxl,
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    marginBottom: spacing.x5l,
  },
  fixedField: {
    padding: spacing.l,
    width: "100%",
    zIndex: 99,
  },
  noItems: {
    fontSize: font.size.xs,
    lineHeight: font.lineHeight.xs,
    textAlign: "center",
    paddingTop: spacing.xxl,
    paddingBottom: spacing.xxl,
  },
  footerCompensator: {
    marginBottom: 104 + ITEM_MARGIN_BOTTOM,
  },
}));

type MenuSearchMobileProps = {
  closeModal: (id?: number) => void;
};

const MenuSearchMobile: React.FC<MenuSearchMobileProps> = ({ closeModal }) => {
  const searchRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [, { getAllProductsInSections }] = useProducts();
  const [products, setProducts] = useState<ProductListItem[]>();

  useEffect(() => {
    setProducts(getAllProductsInSections());
  }, []);

  const productTitles = useMemo(
    () => products?.map((product) => product.title.toLowerCase()) || [],
    [products],
  );

  const optimisedSearchString =
    search.length >= SEARCH_MINIMUM_CHARACTERS ? search.toLowerCase() : "";

  const foundItemIndices =
    optimisedSearchString.length && productTitles.length
      ? productTitles.reduce((acc, curr, idx) => {
          if (curr.includes(optimisedSearchString)) {
            return acc.concat(idx);
          } else {
            return acc;
          }
        }, [] as number[])
      : ([] as number[]);

  const foundItems = products
    ? foundItemIndices.reduce((acc, curr) => {
        return acc.concat(products[curr]);
      }, [] as ProductListItem[])
    : [];

  const onSearchInputChange = (value: string) => {
    setSearch(value);
    if (searchRef.current && searchRef.current.parentElement) {
      searchRef.current.parentElement.scrollTo({ top: 0 });
    }
  };

  const { magnifyingGlassIcon } = getBlobStorage();

  return (
    <div ref={searchRef} className={classes.container}>
      <div className={classes.fixedField} id="search-bar">
        <TextField
          autoComplete="off"
          setValue={onSearchInputChange}
          value={search}
          placeholder={t("search_modal_placeholder")}
          suffixIcon={<Icon url={magnifyingGlassIcon} height={24} width={24} />}
          showSuffixIcon={!search}
          id="search-input"
        />
      </div>
      <div className={classes.searchListResults}>
        {foundItems.map((item) => (
          <LazyLoad
            once
            offset={200}
            key={`lazy-search-product-item-category-${item.id}`}
          >
            <ProductItem
              item={item}
              key={`search-product-${item.id}`}
              onClick={closeModal}
              shouldForceLazyLoad
            />
          </LazyLoad>
        ))}
        {optimisedSearchString.length > 0 && foundItems.length === 0 && (
          <div className={classes.noItems}>{t("search_modal_noResults")}</div>
        )}
        {optimisedSearchString.length === 0 && (
          <div className={classes.footerCompensator}>
            <SearchCategoriesList closeModal={closeModal} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuSearchMobile;
