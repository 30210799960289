export const ProductID = "productID";
export const QRCodeParameter = "qrCode";
export const PaymentStatus = "paymentStatus";
export const OrderID = "orderID";

export const dismissiblePaths: string[] = [
  "product/",
  "scan",
  "scan/manual",
  "/info",
  "/cart",
];

export const NavigationPath = {
  Home: "/",
  // used in navbar
  ProductBase: "/product",
  Product: `/product/:${ProductID}`,
  Cart: "/cart",
  OrderBase: "/order",
  OrderStatus: `/order/:${OrderID}`,
  OrderPaymentCallback: `/order/:${OrderID}/payment/:${PaymentStatus}`,
  QR: `/table/:${QRCodeParameter}`,
  Payment: `/payment/status/:${PaymentStatus}`,
  AuthCallback: "/auth",
  Location: "/location",
};

export enum ProductParams {
  EDIT = "edit",
  COMMENT = "comment",
  AMOUNT = "amount",
  MODIFIERS = "modifiers",
  LINE_ID = "line-id",
  VARIABLE_ID = "variable-id",
}
