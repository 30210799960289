import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "@base/components/Global/Button";
import Icon from "@base/components/Global/Icon";
import { getBlobStorage, getBrandTheme } from "@theme";

const useStyles = createUseStyles({
  uppercaseText: {
    textTransform: "uppercase",
  },
});

type Props = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const FooterMenuButton: React.FC<Props> = ({ isOpen, open, close }) => {
  const classes = useStyles();
  const { closeIcon } = getBlobStorage();
  const { t } = useTranslation();
  const { color } = getBrandTheme();

  if (isOpen) {
    return (
      <Button
        action={close}
        id="menu-btn"
        isMenu
        className={classes.uppercaseText}
        children={
          <Icon
            url={closeIcon}
            stroke={color.footerMenuBtnStroke}
            width={32}
            height={32}
          />
        }
      />
    );
  }
  return (
    <Button
      action={open}
      id="home-footer-menu-btn"
      isMenu
      className={classes.uppercaseText}
    >
      {t("menu_title")}
    </Button>
  );
};

export default FooterMenuButton;
