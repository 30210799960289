import { DefaultTheme } from "react-jss";

import { commonLightTheme } from "../common.light";

export const vapianoLightTheme: DefaultTheme = {
  ...commonLightTheme,
  blobStorage: {
    ...commonLightTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/light/logo/logo-vapiano.svg",
    burgerMenuLinks: [
      "https://assets.markus.live/00-generic-app/icons/logo/ApolloClub24.svg",
    ],
  },
  config: {
    ...commonLightTheme.config,
    brandLogoWidth: 104,
    brandLogoHeight: 40,
    sectionsColors: "#FFEFDC;#E8F4F9;#F6F0FF;#FFECEC;#F2F4E0",
  },
  color: {
    ...commonLightTheme.color,
    productRowFeaturedBg: "#3CCC65",
  },
} as const;
