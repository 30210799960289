import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { ImageCarousel } from "@base/components/Global/ImageCarousel";
import { useRichTextTranslation } from "@base/components/Global/RichTextTranslation/useRichTextTranslation";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { getImageFromAPI } from "@base/utils/imageHelper";
import config from "@constants/config";
import { NavigationPath } from "@constants/navigation";
import { useAppSelector } from "@store";
import { selectAssetCategoryUrls } from "@store/salesPoint";

const useStyles = createUseStyles(
  ({ color, font, spacing, borderRadius, mediaQuery }) => ({
    locationBoxContainer: {
      width: "100%",
      background: color.locationBoxBg,
      borderRadius: borderRadius.x3l,
      overflow: "hidden",
      display: "flex",
      [mediaQuery.md]: {
        height: 294,
      },
      [mediaQuery.lg]: {
        height: 372,
      },
      [mediaQuery.xl]: {
        height: 452,
      },
    },
    locationInfo: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      paddingRight: spacing.x5l,
      [mediaQuery.md]: {
        width: "43%",
        marginLeft: 54,
      },
      [mediaQuery.lg]: {
        width: "38%",
        marginLeft: 60,
      },
      [mediaQuery.xl]: {
        width: "43%",
        marginLeft: 88,
      },
    },
    locationTitle: {
      display: "-webkit-box",
      fontWeight: font.weight.s,
      color: color.locationBoxHeader,
      [mediaQuery.md]: {
        boxOrient: "vertical",
        overflow: "hidden",
        lineClamp: 2,
        fontSize: font.size.x3l,
        lineHeight: "40px",
      },
      [mediaQuery.lg]: {
        fontSize: font.size.x5l,
        lineHeight: "62px",
        lineClamp: "unset",
      },
    },
    additionalText: {
      marginTop: spacing.x5l,
      fontWeight: font.weight.m,
      alignItems: "center",
      color: color.locationBoxText,
      display: "-webkit-box",
      lineClamp: 3,
      boxOrient: "vertical",
      overflow: "hidden",
      [mediaQuery.md]: {
        lineClamp: 3,
        fontSize: font.size.s,
        lineHeight: font.lineHeight.s,
      },
      [mediaQuery.lg]: {
        lineClamp: 4,
        fontSize: font.size.l,
        lineHeight: font.lineHeight.m,
      },
      [mediaQuery.xl]: {
        lineClamp: 7,
      },
    },
    navigation: {
      marginTop: spacing.x5l,
      cursor: "pointer",
      color: color.locationBoxNavigation,
      "& a": {
        textDecoration: "none",
      },
      [mediaQuery.md]: {
        fontSize: font.size.s,
      },
      [mediaQuery.md]: {
        fontSize: font.size.l,
      },
    },
    coverImage: {
      borderRadius: borderRadius.x3l,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    wrapper: {
      marginTop: 54,
    },
    imageWrapper: {
      borderRadius: borderRadius.x3l,
      overflow: "hidden",
      [mediaQuery.md]: {
        width: "57%",
      },
      [mediaQuery.lg]: {
        width: "62%",
      },
      [mediaQuery.xl]: {
        width: "57%",
      },
    },
  }),
);

const LocationBox: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { resolveKey } = useSalesPointTranslationKey();
  const { t } = useTranslation(["salespoint", "translations"]);
  const transProps = useRichTextTranslation();
  const images = useAppSelector((state) =>
    selectAssetCategoryUrls(state, "home_page_desktop_banner"),
  );

  const navigateToLocation = () => {
    navigate(NavigationPath.Location);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.locationBoxContainer}>
        <div className={classes.locationInfo}>
          <div className={classes.locationTitle}>{t(resolveKey("name"))}</div>
          <div className={classes.additionalText}>
            <Trans
              {...transProps}
              i18nKey={resolveKey("description")}
              ns="salespoint"
            />
          </div>
          <div className={classes.navigation} onClick={navigateToLocation}>
            {t("translations:global_btn_moreInfo")}
          </div>
        </div>
        <div className={classes.imageWrapper}>
          <ImageCarousel
            styleVariant="rounded"
            mode={config.appConfig.carousel.homePageDesktopBanner.mode}
            autoplayInterval={
              config.appConfig.carousel.homePageDesktopBanner.autoplayInterval
            }
            images={images.map((image) => ({
              img: getImageFromAPI(image, 800, 490),
              placeholderImg: getImageFromAPI(image, 80, 49),
            }))}
            hasDots
          />
        </div>
      </div>
    </div>
  );
};
export default LocationBox;
