import React from "react";
import { createUseStyles } from "react-jss";
import { IProductSubItemProduct } from "@api/interfaces/productLayouts";
import Icon from "@base/components/Global/Icon";
import { useProducts } from "@store/ProductsProvider";
import { ProductListItem } from "@store/ProductsProvider/types";
import { getBlobStorage } from "@theme";
import classnames from "classnames";

const useStyles = createUseStyles(({ spacing, borderRadius, font, color }) => ({
  container: {
    display: "flex",
    width: "calc(100% + 32px)",
    marginLeft: -spacing.xxl,
    flexDirection: "row",
    marginTop: spacing.x5l,
    border: `1px solid ${color.productPageShapeBlockBorder}`,
    borderRadius: borderRadius.x3l,
    padding: [spacing.s, spacing.xxl],
    fontSize: font.size.m,
    lineHeight: font.lineHeight.xs,
    fontWeight: font.weight.m,
    justifyContent: "space-between",
    alignItems: "center",
    color: color.productPageShapeBlockLabel,
    "& >:first-child:not($active)": {
      paddingLeft: spacing.l,
    },
    "& >:last-child:not($active)": {
      paddingRight: spacing.l,
    },
  },
  active: {
    backgroundColor: color.productPageShapeBlockActiveBg,
    color: color.productPageShapeBlockActiveText,
    borderRadius: borderRadius.x3l,
    padding: [spacing.xxl, spacing.x5l],
    margin: -20,
    transition: "all 0.3s ease",
    position: "relative",
  },
  activeTitle: {
    padding: [spacing.xs, 0],
  },
  price: {
    fontSize: font.size.xxs,
    lineHeight: font.lineHeight.x3s,
    textAlign: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkedIcon: {
    position: "absolute",
    top: -5,
    right: -5,
  },
  clickable: {
    cursor: "pointer",
  },
}));

type Props = {
  items: IProductSubItemProduct[];
  selectedItem: ProductListItem;
  setSelectedItem: (value: ProductListItem) => void;
};

const ProductShapeBlock: React.FC<Props> = ({
  items,
  selectedItem,
  setSelectedItem,
}) => {
  const classes = useStyles();
  const [, { getProductById }] = useProducts();
  const { checkedWhiteBgSmIcon } = getBlobStorage();

  return (
    <div className={classes.container}>
      {items.map((item) => {
        const rawItem = getProductById(item.ID);
        if (!rawItem) return null;
        const isActive = selectedItem.id === rawItem.id;
        const price: number =
          (rawItem.grossPrice || 0) - (selectedItem.grossPrice || 0);
        return (
          <div
            className={classnames(classes.clickable, [
              isActive ? classes.active : undefined,
            ])}
            onClick={() => !isActive && setSelectedItem(rawItem)}
            key={rawItem.id}
          >
            {isActive && (
              <Icon
                url={checkedWhiteBgSmIcon}
                width={24}
                height={24}
                className={classes.checkedIcon}
              />
            )}
            <div className={classes.item}>
              <span className={isActive ? classes.activeTitle : undefined}>
                {rawItem.shortTitle}
              </span>
              {!isActive && (
                <span className={classes.price}>
                  {price >= 0 ? "+" : "-"}
                  {Math.abs(price).toFixed(2)}
                  {" €"}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ProductShapeBlock;
