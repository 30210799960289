import React from "react";
import { useTranslation } from "react-i18next";
import Banner, { BannerVariant } from "@base/components/Global/Banner";
import { useWhiteLabelImage } from "@base/hooks/useWhiteLabelImage";
import { getBlobStorage } from "@theme";

const PreviousOrdersBanner = () => {
  const { statusBannerImage } = getBlobStorage();
  const getWhiteLabeledImage = useWhiteLabelImage();
  const { t } = useTranslation("orderStatusPage");

  const imgSrc = getWhiteLabeledImage(statusBannerImage);

  return (
    <Banner
      title={t("PreviousOrdersBanner.title")}
      subtitle={t("PreviousOrdersBanner.subtitle")}
      imgSrc={imgSrc}
      variant={BannerVariant.ERROR}
    />
  );
};

export default PreviousOrdersBanner;
