import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation, useNavigate } from "react-router-dom";
import BurgerMenu from "@base/components/BurgerMenu/BurgerMenu";
import Icon from "@base/components/Global/Icon";
import LanguageDropdown from "@base/components/LanguageDropdown/LanguageDropdown";
import useDismissiblePath from "@base/components/Navbar/hooks/useDismissiblePath";
import useNavbarStyles from "@base/components/Navbar/hooks/useNavbarStyles";
import useUpdateSectionStyles from "@base/components/Navbar/hooks/useUpdateSectionStyles";
import useWindowScroll from "@base/components/Navbar/hooks/useWindowScroll";
import { getBrandLogo } from "@base/utils/getBrandLogo";
import { ABOVE_FOLD_HEIGHT } from "@constants/misc";
import { NavigationPath } from "@constants/navigation";
import { useAppSelector } from "@store";
import { selectActiveOrdersCount } from "@store/orders";
import { useScrollContext } from "@store/ScrollProvider";
import { getBlobStorage, getBrandTheme } from "@theme";
import classNames from "classnames";

import DiningOptionButton from "./DiningOptionButton";
import { useNavbarContext } from "./NavbarContext";

const mobileNavbarStyles = createUseStyles(
  ({ spacing, font, color, zIndex, config: themeConfig }) => ({
    navbarMobile: {
      color: color.topNavBarText,
      paddingTop: 4,
      paddingBottom: 4,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      padding: [0, spacing.xxl],
      position: "fixed",
      width: "100%",
      top: -themeConfig.mobileNavbarHeight,
      left: 0,
      zIndex: zIndex.bottomMenu,
      transition: "300ms ease",
    },
    iPhoneNavbar: {
      top: -100,
      position: "fixed",
      height: 100,
      width: "100vw",
      backgroundColor: color.black,
      zIndex: 999999,
    },
    tableInfoBlock: {
      display: "flex",
      gap: spacing.xxl,
      marginLeft: "auto",
    },
    tableInfoBlockWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    productTitle: {
      color: color.topNavBarText,
      fontWeight: font.weight.s,
      fontSize: font.size.l,
      lineHeight: font.lineHeight.m,
      marginLeft: "auto",
    },
    justify: {
      justifyContent: "space-between",
    },
    navbarOrder: {
      marginLeft: "auto",
    },
  }),
);
const NavbarMobile: React.FC = () => {
  const sharedClasses = useNavbarStyles();
  const classes = mobileNavbarStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname || null;
  const [{ isScrolledAboveFold }] = useScrollContext();
  const { customTitle } = useNavbarContext();

  const isDismissible = useDismissiblePath();

  const activeOrderCount = useAppSelector(selectActiveOrdersCount);
  const [hasSidebar, setSidebar] = useState(false);
  const scrollPositionRef = React.useRef(0);

  const setRefScrollPosition = (value: number) => {
    scrollPositionRef.current = value;
  };

  const showSidebar = () => setSidebar(!hasSidebar);

  const isProductPage = pathname?.includes(NavigationPath.ProductBase);
  const isHomePage = pathname === NavigationPath.Home;
  const isCartPage = pathname === NavigationPath.Cart;

  const shouldShowBrandLogo =
    isHomePage || pathname === NavigationPath.Location;

  const { color } = getBrandTheme();

  const getTopLen = () => {
    if (isHomePage) return -ABOVE_FOLD_HEIGHT;
    const calculatedScreenWidth = screen.width - (30 * screen.width) / 100;
    return -calculatedScreenWidth;
  };

  const values = useWindowScroll(
    location,
    scrollPositionRef,
    isProductPage,
    getTopLen,
    setRefScrollPosition,
  );
  useUpdateSectionStyles(values);

  const navigateBack = () => {
    if (isCartPage) {
      navigate(`${NavigationPath.Home}#menu-block-start`);
    } else {
      navigate(-1);
    }
  };

  const { burgerIcon, burgerActiveIcon, closeIcon } = getBlobStorage();

  const onBrandLogoClick = () => {
    if (isHomePage) {
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    } else {
      navigateBack();
    }
  };

  return (
    <>
      <div>
        <div className={classes.iPhoneNavbar} />
        <div
          className={classNames([
            classes.navbarMobile,
            values.shouldShowNavbar && sharedClasses.showNavbar,
            (!isScrolledAboveFold ||
              (isProductPage && !isScrolledAboveFold) ||
              (isHomePage && !isScrolledAboveFold)) &&
              sharedClasses.navbarBackground,
            !isProductPage && classes.justify,
            isCartPage && sharedClasses.cartPageNavbar,
          ])}
        >
          {shouldShowBrandLogo && (
            <div
              onClick={onBrandLogoClick}
              style={{ cursor: "pointer", display: "flex" }}
              id="navbar-logo-btn"
            >
              {getBrandLogo()}
            </div>
          )}
          {isProductPage && !isScrolledAboveFold && (
            <span className={classes.productTitle}>{customTitle}</span>
          )}
          <>
            {isDismissible ? (
              <span
                role="button"
                onClick={navigateBack}
                className={classNames([
                  sharedClasses.icon,
                  sharedClasses.closeIcon,
                ])}
              >
                <Icon
                  height={32}
                  width={32}
                  url={closeIcon}
                  stroke={color.black}
                />
              </span>
            ) : (
              <div
                className={classNames([
                  classes.tableInfoBlockWrapper,
                  !shouldShowBrandLogo && classes.navbarOrder,
                ])}
              >
                <div className={classes.tableInfoBlock}>
                  <DiningOptionButton />
                  <span
                    role="button"
                    onClick={showSidebar}
                    className={sharedClasses.icon}
                    id="burger-menu-open-btn"
                  >
                    {activeOrderCount > 0 ? (
                      <Icon height={32} width={32} url={burgerActiveIcon} />
                    ) : (
                      <Icon height={32} width={32} url={burgerIcon} />
                    )}
                  </span>
                </div>
                {isScrolledAboveFold && <LanguageDropdown contrastMode />}
              </div>
            )}
          </>
        </div>
        <BurgerMenu showSidebar={showSidebar} hasSidebar={hasSidebar} />
      </div>
    </>
  );
};

export default NavbarMobile;
