import React, { RefObject, useLayoutEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ReactSVG } from "react-svg";
import { getBlobStorage } from "@theme";
import classNames from "classnames";

type StyleProps = {
  buttonHeight: number;
};

const useStyles = createUseStyles(({ color }) => ({
  container: {
    overflow: "hidden",
    position: "relative",
    flexGrow: 1,
  },
  arrowButtonContainer: {
    position: "absolute",
    width: 24, // (buttonHeight / 2) if we want a perfect half-circle
    bottom: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
  },

  arrowButton: {
    zIndex: 1,
    background: color.horizontalScrollableButtonBg,
    height: ({ buttonHeight }: StyleProps) => buttonHeight,
    display: "flex",
    alignItems: "center",
    width: 24, // (buttonHeight / 2) if we want a perfect half-circle
    position: "absolute",

    "& svg": {
      transform: "scale(1)",
    },
    "@media (hover: hover)": {
      cursor: "pointer",
      "&:hover svg": {
        transform: "scale(1.2)",
      },
    },
  },
  leftArrowButtonContainer: {
    left: 0,
    "& $arrowButton": {
      borderTopRightRadius: ({ buttonHeight }: StyleProps) => buttonHeight / 2,
      borderBottomRightRadius: ({ buttonHeight }: StyleProps) =>
        buttonHeight / 2,
      justifyContent: "flex-end",
    },
  },
  rightArrowButtonContainer: {
    right: 0,
    "& $arrowButton": {
      borderTopLeftRadius: ({ buttonHeight }: StyleProps) => buttonHeight / 2,
      borderBottomLeftRadius: ({ buttonHeight }: StyleProps) =>
        buttonHeight / 2,
      justifyContent: "flex-start",
    },
  },
  svgContainer: {
    "& div ": {
      display: "flex",
    },
    "& svg": {
      width: 30,
      height: 30,
    },
  },
}));

type HorizontalScrollableProps = {
  scrollContainerRef: RefObject<HTMLDivElement>;
  buttonHeight?: number;
  shouldShowArrows?: boolean;
  children?: React.ReactNode;
};

const HorizontalScrollable: React.FC<HorizontalScrollableProps> = ({
  children,
  scrollContainerRef,
  buttonHeight = 48,
  shouldShowArrows = true,
}) => {
  const classes = useStyles({ buttonHeight });
  const { caretLeftThinIcon, caretRightThinIcon } = getBlobStorage();
  const [isLeftArrowVisible, setIsLeftArrowVisible] =
    useState(shouldShowArrows);
  const [isRightArrowVisible, setIsRightArrowVisible] =
    useState(shouldShowArrows);

  useLayoutEffect(() => {
    if (!shouldShowArrows) return;

    const onScroll = () => {
      const el = scrollContainerRef.current;

      if (!el) return;

      setIsLeftArrowVisible(el.scrollLeft > 0);
      setIsRightArrowVisible(
        Math.abs(el.scrollWidth - (el.offsetWidth + el.scrollLeft)) > 4,
      );
    };

    onScroll();

    scrollContainerRef.current?.addEventListener("scroll", onScroll);

    return () => {
      scrollContainerRef.current?.removeEventListener("scroll", onScroll);
    };
  }, [children]);

  const scrollContainerLeft = () => {
    const el = scrollContainerRef.current;
    if (!el) return;
    el.scrollTo({ left: el.scrollLeft - 500, behavior: "smooth" });
  };

  const scrollContainerRight = () => {
    const el = scrollContainerRef.current;
    if (!el) return;
    el.scrollTo({ left: el.scrollLeft + 500, behavior: "smooth" });
  };

  return (
    <div className={classes.container}>
      {isLeftArrowVisible && (
        <div
          className={classNames(
            classes.arrowButtonContainer,
            classes.leftArrowButtonContainer,
          )}
        >
          <div className={classes.arrowButton} onClick={scrollContainerLeft}>
            <ReactSVG
              className={classes.svgContainer}
              src={caretLeftThinIcon}
            />
          </div>
        </div>
      )}
      {children}
      {isRightArrowVisible && (
        <div
          className={classNames(
            classes.arrowButtonContainer,
            classes.rightArrowButtonContainer,
          )}
        >
          <div className={classes.arrowButton} onClick={scrollContainerRight}>
            <ReactSVG
              className={classes.svgContainer}
              src={caretRightThinIcon}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HorizontalScrollable;
