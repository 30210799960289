import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ReactSVG } from "react-svg";
import Button from "@base/components/Global/Button";
import { getBlobStorage } from "@theme";

import { reloadWebsite } from "./ErrorPage";
import { AppError } from "./types";
import { useError } from "./useError";

const useStyles = createUseStyles(({ font, spacing, color }) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: font.weight.s,
    fontSize: font.size.x4l,
    margin: [2 * spacing.x4l, 0, spacing.xl],
    color: color.errorPageTitle,
  },
  text: {
    fontSize: font.size.s,
    textAlign: "center",
    maxWidth: "70%",
    margin: [spacing.x4l, 0],
    color: color.errorPageText,
  },
  footnote: {
    fontSize: font.size.xs,
    textAlign: "center",
    maxWidth: "70%",
    margin: [spacing.x4l, 0],
    color: color.errorPageText,
    opacity: 0.5,
  },
  svg: {
    maxWidth: 600,
    marginTop: -100,
  },
}));

type ErrorPageDesktopProps = {
  error?: AppError;
};

const ErrorPageDesktop: React.FC<ErrorPageDesktopProps> = ({ error }) => {
  const classes = useStyles();
  const { t } = useTranslation("errorPage");
  const { errorPageBg } = getBlobStorage();
  const { title, message, footnote } = useError(error);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <ReactSVG className={classes.svg} src={errorPageBg} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 className={classes.title}>{title}</h1>
          <p className={classes.text}>{message}</p>
          {footnote && <p className={classes.footnote}>{footnote}</p>}
          <Button action={reloadWebsite} fitContent>
            {t("ErrorPageDesktop.goBackButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPageDesktop;
