import config from "@constants/config";

type WhiteLabelImageResult = (imgURL: string) => string;

export const useWhiteLabelImage = (): WhiteLabelImageResult => {
  return (imgURL: string): string => {
    const newUrl = imgURL
      .replaceAll("%tenantKey%", config.tenantConfig.key)
      .replaceAll("%tenantLocation%", config.tenantConfig.location);

    return newUrl;
  };
};
