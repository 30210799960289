import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ISum } from "@api/interfaces/cartLayouts";
import classNames from "classnames";

const useStyles = createUseStyles(({ color, spacing, font }) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: [spacing.xxl, spacing.xxl],
    color: color.cartPageTotalBlock,
    marginTop: spacing.xl,

    "&$sidebarVariant": {
      padding: 0,
      margin: [spacing.x3l, 0],
    },
  },
  sidebarVariant: {},
  label: {
    color: color.defaultText,
    fontWeight: font.weight.s,
    fontSize: 32,
    lineHeight: "36px",

    "$sidebarVariant &": {
      fontSize: font.size.x2l,
    },
  },
  value: {
    fontSize: 44,
    fontWeight: font.weight.s,
    lineHeight: "46px",

    "$sidebarVariant &": {
      fontSize: 32,
    },
  },
}));

enum TotalBlockVariant {
  PAGE = "PAGE",
  SIDEBAR = "SIDEBAR",
}

type TotalBlockProps = {
  variant: TotalBlockVariant;
  total?: ISum;
};

const TotalBlock: React.FC<TotalBlockProps> = ({ total, variant }) => {
  const classes = useStyles();
  const { t } = useTranslation("cartPage");

  if (!total) return null;

  return (
    <div
      className={classNames(classes.container, {
        [classes.sidebarVariant]: variant === TotalBlockVariant.SIDEBAR,
      })}
    >
      <div className={classes.label}>{t("TotalBlock.label")}</div>
      <div id="cart-total-price" className={classes.value}>
        {t("TotalBlock.value", {
          grossSum: total.LineGrossSum,
          formatParams: {
            grossSum: { currency: "EUR" },
          },
        })}
      </div>
    </div>
  );
};

export default TotalBlock;
export { TotalBlockVariant };
