import React from "react";
import { createUseStyles } from "react-jss";
import {
  ECheckoutOptionType,
  ICheckoutOption,
} from "@api/interfaces/cartLayouts";
import { useAppSelector } from "@store";
import { selectShouldShowPaymentErrorForCurrentCart } from "@store/payment/selectors";
import { selectCheckoutOptions } from "@store/shoppingCart";
import classNames from "classnames";

import { CheckoutOption } from "./CheckoutOption";
import { PayLaterCheckoutOptionProps } from "./PayLaterCheckoutOption";
import { PayNowCheckoutOptionProps } from "./PayNowCheckoutOption";

const useStyles = createUseStyles(({ spacing, color, borderRadius }) => ({
  container: {
    display: "flex",
    flexDirection: "column",

    gap: spacing.x2l,
    width: "100%",
  },
  failedPaymentNoticeContainer: {
    background: color.white,
    backgroundColor: color.warningBg,
    borderTopRightRadius: borderRadius.l,
    borderTopLeftRadius: borderRadius.l,
  },
  desktop: {
    padding: spacing.xl,
  },
}));

const sortCheckoutOptions = (a: ICheckoutOption) => {
  if (a.CheckoutOptionType === ECheckoutOptionType.PayNow) {
    return -1;
  }

  return 1;
};

type CheckoutOptionsProps = PayLaterCheckoutOptionProps &
  PayNowCheckoutOptionProps;

export const CheckoutOptions: React.FC<CheckoutOptionsProps> = (props) => {
  const classes = useStyles();
  const shouldShowPaymentError = useAppSelector(
    selectShouldShowPaymentErrorForCurrentCart,
  );

  const checkoutOptions = useAppSelector(selectCheckoutOptions);

  if (!checkoutOptions) {
    return null;
  }

  return (
    <div
      className={classNames(
        classes.container,
        props.variant === "desktop" && classes.desktop,
        shouldShowPaymentError && classes.failedPaymentNoticeContainer,
      )}
    >
      {checkoutOptions.toSorted(sortCheckoutOptions).map((option, index) => (
        <CheckoutOption {...props} {...option} key={index} />
      ))}
    </div>
  );
};
