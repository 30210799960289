import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ font, scrollbar }) => ({
  "@global @font-face": [...font.faceFaces],
  "@global body": {
    fontFamily: font.defaultFontStyles.fontFamily,
    fontStyle: font.defaultFontStyles.fontStyle,
    fontWeight: font.defaultFontStyles.fontWeight,
    fontSize: font.defaultFontStyles.fontSize,

    wordBreak: "break-word",
    wordWrap: "break-word",

    lineHeight: "20px",

    color: "#000",

    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
  },
  "@global *": {
    boxSizing: "border-box",
  },
  "@global *::-webkit-scrollbar": {
    height: scrollbar.height,
    width: scrollbar.width,
    borderRadius: scrollbar.borderRadius,
    backgroundColor: scrollbar.backgroundColor,
  },
  "@global *::-webkit-scrollbar-thumb": {
    borderRadius: scrollbar.thumb.borderRadius,
    backgroundColor: scrollbar.thumb.backgroundColor,
  },

  "@global *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: scrollbar.thumb.hover.backgroundColor,
  },
}));

type GlobalStyleProviderProps = {
  children: React.ReactNode;
};

export const GlobalStyleProvider: React.FC<GlobalStyleProviderProps> = ({
  children,
}) => {
  useStyles();

  return <>{children}</>;
};
