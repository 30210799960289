import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import LazyLoad from "react-lazyload";
import Icon from "@base/components/Global/Icon";
import TextField from "@base/components/Global/TextField";
import ProductItem from "@pages/HomePage/components/ProductItem";
import SearchCategoriesList from "@pages/HomePage/components/SearchCategoriesList";
import { useProducts } from "@store/ProductsProvider";
import { ProductListItem } from "@store/ProductsProvider/types";
import { getBlobStorage, getBrandTheme } from "@theme";

const SEARCH_MINIMUM_CHARACTERS = 2;

const useStyles = createUseStyles(({ borderRadius, spacing, font }) => ({
  searchListResults: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    overflowY: "auto",
    margin: [0, spacing.l, spacing.l, spacing.l],
    borderRadius: borderRadius.s,
    padding: spacing.l,
  },
  headerContainer: {
    padding: [spacing.x4l, spacing.x4l, 0, spacing.x4l],
    marginBottom: spacing.xxl,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  field: {
    width: "calc(100% - 50px)",
  },
  noItems: {
    fontSize: font.size.xs,
    lineHeight: font.lineHeight.xs,
    textAlign: "center",
    paddingTop: spacing.xxl,
    paddingBottom: spacing.xxl,
  },
  closeButton: {
    borderRadius: 1000,
    background: "rgba(255, 255, 255, 0.5)",
    justifyContent: "center",
    cursor: "pointer",
    display: "flex",

    "@media (hover: hover)": {
      "&:hover": {
        background: "rgba(190, 190, 190, 0.5)",
      },
    },
  },
}));

type MenuSearchDesktopProps = {
  closeModal: (id?: number) => void;
};

const MenuSearchDesktop: React.FC<MenuSearchDesktopProps> = ({
  closeModal,
}) => {
  const searchRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [, { getAllProductsInSections }] = useProducts();
  const [products, setProducts] = useState<ProductListItem[]>();
  const { closeIcon } = getBlobStorage();
  const { color } = getBrandTheme();

  useEffect(() => {
    setProducts(getAllProductsInSections());
  }, []);

  const productTitles = useMemo(
    () => products?.map((product) => product.title.toLowerCase()) || [],
    [products],
  );

  const optimisedSearchString =
    search.length >= SEARCH_MINIMUM_CHARACTERS ? search.toLowerCase() : "";

  const foundItemIndices =
    optimisedSearchString.length && productTitles.length
      ? productTitles.reduce((acc, curr, idx) => {
          if (curr.includes(optimisedSearchString)) {
            return acc.concat(idx);
          } else {
            return acc;
          }
        }, [] as number[])
      : ([] as number[]);

  const foundItems = products
    ? foundItemIndices.reduce((acc, curr) => {
        return acc.concat(products[curr]);
      }, [] as ProductListItem[])
    : [];

  const onSearchInputChange = (value: string) => {
    setSearch(value);
    if (searchRef.current && searchRef.current) {
      searchRef.current.scrollTo({ top: 0 });
    }
  };

  const { magnifyingGlassIcon } = getBlobStorage();

  return (
    <>
      <div className={classes.headerContainer} id="search-bar">
        <div className={classes.field}>
          <TextField
            autoComplete="off"
            setValue={onSearchInputChange}
            value={search}
            placeholder={t("search_modal_placeholder")}
            suffixIcon={
              <Icon url={magnifyingGlassIcon} height={24} width={24} />
            }
            showSuffixIcon={!search}
            id="search-input"
            autoFocus={true}
          />
        </div>
        <span
          role="button"
          className={classes.closeButton}
          onClick={() => closeModal()}
        >
          <Icon height={32} width={32} url={closeIcon} stroke={color.black} />
        </span>
      </div>

      <div className={classes.searchListResults} ref={searchRef}>
        {foundItems.map((item) => (
          <LazyLoad
            once
            offset={200}
            key={`lazy-search-product-item-category-${item.id}`}
          >
            <ProductItem
              item={item}
              key={`search-product-${item.id}`}
              onClick={closeModal}
              shouldForceLazyLoad
            />
          </LazyLoad>
        ))}
        {optimisedSearchString.length > 0 && foundItems.length === 0 && (
          <div className={classes.noItems}>{t("search_modal_noResults")}</div>
        )}
        {optimisedSearchString.length === 0 && (
          <SearchCategoriesList closeModal={closeModal} wrap />
        )}
      </div>
    </>
  );
};

export default MenuSearchDesktop;
