import React from "react";
import {
  ECheckoutOptionType,
  ICheckoutOption,
} from "@api/interfaces/cartLayouts";

import {
  PayLaterCheckoutOption,
  PayLaterCheckoutOptionProps,
} from "./PayLaterCheckoutOption";
import {
  PayNowCheckoutOption,
  PayNowCheckoutOptionProps,
} from "./PayNowCheckoutOption";

export type CheckoutOptionProps = PayLaterCheckoutOptionProps &
  PayNowCheckoutOptionProps &
  ICheckoutOption;

export const CheckoutOption: React.FC<CheckoutOptionProps> = (props) => {
  const { CheckoutOptionType } = props;

  if (props.IsEnabled === false) return null;

  switch (CheckoutOptionType) {
    case ECheckoutOptionType.PayNow:
      return <PayNowCheckoutOption {...props} />;

    case ECheckoutOptionType.PayLater:
      return <PayLaterCheckoutOption {...props} />;
  }

  return null;
};
