import { Configuration } from "@api/controller/configuration";
import {
  createAsyncStatusMachineThunk,
  getCancelWhenAlreadyLoadingCondition,
} from "@store/apiRequestStatusMachine";

import { selectConfiguration } from "./slice";

export const loadSalesPointConfiguration = createAsyncStatusMachineThunk(
  "salesPoint/loadSalesPointConfiguration",
  Configuration.getSalesPointConfiguration,
  selectConfiguration,
  [getCancelWhenAlreadyLoadingCondition()],
);
