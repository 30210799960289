import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import ProductItem from "@pages/HomePage/components/ProductItem";
import { ProductListItem } from "@store/ProductsProvider/types";
import { getBlobStorage } from "@theme";

import Icon from "../Global/Icon";

const scrollOffset = 300;

const useStyles = createUseStyles(({ spacing }) => ({
  carouselContainer: {
    position: "relative",
  },
  carouselScrollForwardBtn: {
    position: "absolute",
    right: -30,
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
  carouselScrollBackBtn: {
    position: "absolute",
    left: -30,
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
  carousel: {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    gap: spacing.l,
  },
  inActiveArrow: {
    "& svg": {
      opacity: 0.3,
    },
  },
}));

type ProductsCarouselProps = {
  products: ProductListItem[] | undefined;
};

const ProductsCarousel: React.FC<ProductsCarouselProps> = ({ products }) => {
  const classes = useStyles();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [shouldShowLeftArrow, setShouldShowLeftArrow] = useState(false);
  const [shouldShowRightArrow, setShouldShowRightArrow] = useState(false);
  const { caretRightThinIcon, caretLeftThinIcon } = getBlobStorage();
  const hasHorizontalScrollbar: boolean | null =
    carouselRef.current &&
    carouselRef?.current?.scrollWidth > carouselRef?.current?.clientWidth;

  const onCarouselScrollForward = () => {
    if (carouselRef.current) carouselRef.current.scrollLeft += scrollOffset;
  };
  const onCarouselScrollBack = () => {
    if (carouselRef.current) carouselRef.current.scrollLeft -= scrollOffset;
  };

  useEffect(() => {
    const container = carouselRef?.current;
    setShouldShowLeftArrow(container?.scrollLeft !== 0);
    if (products) setShouldShowRightArrow(products?.length > 0);

    const handleScroll = () => {
      setShouldShowLeftArrow(container?.scrollLeft !== 0);
      if (container) {
        setShouldShowRightArrow(
          container.scrollWidth > container.clientWidth &&
            container.scrollLeft <
              container.scrollWidth - container.clientWidth,
        );
      }
    };
    carouselRef?.current?.addEventListener("scroll", handleScroll);
    return () =>
      carouselRef?.current?.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className={classes.carouselContainer}>
      {hasHorizontalScrollbar && (
        <>
          <div
            className={classes.carouselScrollForwardBtn}
            onClick={onCarouselScrollForward}
          >
            <Icon
              url={caretRightThinIcon}
              width={32}
              height={32}
              className={!shouldShowRightArrow ? classes.inActiveArrow : ""}
            />
          </div>
          <div
            className={classes.carouselScrollBackBtn}
            onClick={onCarouselScrollBack}
          >
            <Icon
              url={caretLeftThinIcon}
              width={32}
              height={32}
              className={!shouldShowLeftArrow ? classes.inActiveArrow : ""}
            />
          </div>
        </>
      )}

      <div className={classes.carousel} ref={carouselRef}>
        {products &&
          products.map((product: ProductListItem) => {
            return (
              <ProductItem
                key={`product-cross_sell-${product.id}`}
                item={product}
                carouselMode
              />
            );
          })}
      </div>
    </div>
  );
};

export default ProductsCarousel;
