import { InitializeDiningCartRequest } from "./interfaces/cartLayouts";
import {
  GetAvailableDatesRequest,
  GetAvailableTimesRequest,
} from "./interfaces/configurationLayouts";
import { GetProductLayoutsRequest } from "./interfaces/productLayouts";

const ApiPath = {
  Product: {
    getProductLayouts: ({
      productLayoutID,
      customerPersonId = 0,
      diningOptionId = 1,
    }: GetProductLayoutsRequest): string => {
      const params = new URLSearchParams({
        diningOptionId: diningOptionId.toString(),
      });

      return `/products/${productLayoutID}/customer/${customerPersonId}?${params.toString()}`;
    },
  },
  Cart: {
    initializeCart: (customerPersonId: number = 0): string => {
      const params = new URLSearchParams({
        customerPersonID: customerPersonId.toString(),
      });

      return `/cart/initialize?${params.toString()}`;
    },
    initializeDiningCart: ({
      theatreResourceId,
      dttmRequestedDelivery,
      diningOptionId,
      customerPersonId = 0,
    }: InitializeDiningCartRequest): string => {
      const params = new URLSearchParams({
        customerPersonID: customerPersonId.toString(),
        TheatreResourceID: theatreResourceId.toString(),
        DiningOptionID: diningOptionId.toString(),
      });

      if (dttmRequestedDelivery) {
        params.append("dttmRequestedDelivery", dttmRequestedDelivery);
      }

      return `/cart/initializeDiningOptionCart?${params.toString()}`;
    },
    updateCustomerCart: (transactionId: number): string =>
      `/cart/${transactionId}/customer`,
    addToCart: (transactionId: number): string => `/cart/${transactionId}/add`,
    getShoppingCart: (transactionId: number): string =>
      `/cart/${transactionId}`,
    removeProductFromCart: (productId: number, transactionId: number): string =>
      `/cart/${transactionId}/remove/${productId}`,
    removeAllProductsFromCart: (transactionId: number): string =>
      `/cart/${transactionId}/remove`,
    removeLinesFromCart: (
      cartLineIds: number[],
      transactionId: number,
    ): string => {
      const params = new URLSearchParams({
        $filter: `ID in (${cartLineIds.join(",")})`,
      });

      return `/cart/${transactionId}/remove?${params.toString()}`;
    },
    bundle: (transactionId: number): string => `/cart/${transactionId}/bundle`,
    unbundle: (transactionId: number): string =>
      `/cart/${transactionId}/unbundle`,
    theatreResource: (
      theatreResourceId: number,
      transactionId: number,
    ): string => {
      const params = new URLSearchParams({
        theatreresourceid: theatreResourceId.toString(),
      });

      return `/cart/${transactionId}/location?${params.toString()}`;
    },
    removeCustomerFromCart: (transactionId: number): string =>
      `/cart/${transactionId}/customer`,
    bindContactInfo: (transactionId: number): string =>
      `/cart/${transactionId}/contact`,
    updateLanguage: (transactionId: number): string =>
      `/cart/${transactionId}/language`,
    putOnHold: (transactionId: number): string =>
      `/cart/${transactionId}/putonhold`,
  },
  Configuration: {
    getSalesPointConfiguration: "/salespoint",
    availableDeliveryDates: ({
      diningOption,
    }: GetAvailableDatesRequest): string =>
      `/salespoint/availableDeliveryDates/${diningOption}`,
    availableDeliveryTimes: ({
      diningOption,
      date,
    }: GetAvailableTimesRequest): string =>
      `/salespoint/availableDeliveryTimes/${diningOption}/${date}`,
  },
  User: {
    person: "/persons",
  },
  TheatreResource: {
    getByBarcode: (tableNr: string): string => `/location/${tableNr}`,
  },
  Order: {
    status: (barcodeWithoutChecksum: string[]): string =>
      `/transactions/orderstatussummary?transactionBarcodes=${barcodeWithoutChecksum.join(",")}`,
  },
};

const PaymentPath = {
  initialize: (barcode: string) => `/payment/${barcode}`,
  status: (guid: string): string => `/remoteshoppingcart/${guid}/status`,
};

export { ApiPath, PaymentPath };
