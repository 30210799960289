import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  IAvailableDiningDate,
  IAvailableDiningTime,
  IDiningOptionEnum,
} from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import useEffectAsync from "@base/hooks/useEffectAsync";
import { useSalesPointContext } from "@context/SalesPointProvider";
import { getBlobStorage } from "@theme";
import { format, isToday, isTomorrow } from "date-fns";

import SelectNative from "../Modals/DeliveryMethod/SelectNative";

const ASAP_OPTION_VALUE_AS_STRING = "ASAP";

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
  },
}));

const formatTime = (dateString: string) => {
  return format(new Date(dateString), "HH:mm");
};

const PreorderTimeSelection: React.FC<{
  selectedTimeObject: IAvailableDiningTime | null;
  setSelectedTimeObject: React.Dispatch<
    React.SetStateAction<IAvailableDiningTime | null>
  >;
  setIsInLoadingState?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDiningOption: IDiningOptionEnum;
  datesRefetchTrigger?: number | boolean;
  onSelectTime?: (timeObject: IAvailableDiningTime) => void;
  showFieldLabels?: boolean;
  dateSelectCallback?: () => void;
  allowUndefinedTimeValue?: boolean;
}> = ({
  selectedTimeObject,
  setSelectedTimeObject,
  setIsInLoadingState,
  selectedDiningOption,
  datesRefetchTrigger,
  onSelectTime = () => {},
  showFieldLabels = false,
  dateSelectCallback = () => {},
  allowUndefinedTimeValue = false,
}) => {
  const classes = useStyles();
  const [availableDates, setAvailableDates] = useState<IAvailableDiningDate[]>(
    [],
  );
  const [selectedDate, setSelectedDate] = useState<string>();
  const [availableTimes, setAvailableTimes] = useState<IAvailableDiningTime[]>(
    [],
  );
  const { t } = useTranslation();
  const [, { getAvailableDates, getAvailableTimes }] = useSalesPointContext();
  const { schedulingIcon, timeIcon } = getBlobStorage();
  const [isLoadingDates, setIsLoadingDates] = useState(false);
  const [isLoadingTimes, setIsLoadingTimes] = useState(false);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isToday(date)) return t("dining_options_relative_date_today");
    if (isTomorrow(date)) return t("dining_options_relative_date_tomorrow");

    return format(date, "dd.MM.yyyy");
  };

  const handleSelectTime = (val: string) => {
    let timeObject;

    if (val === ASAP_OPTION_VALUE_AS_STRING) {
      timeObject = availableTimes.find((i) => i.dttmDelivery === null);
    } else {
      timeObject = availableTimes.find((i) => i.dttmDelivery === val);
    }

    if (!timeObject) {
      throw new Error("Selected time object not found among options");
    }

    setSelectedTimeObject(timeObject);
    onSelectTime(timeObject);
  };

  useEffectAsync(async () => {
    setSelectedDate(undefined);
    setSelectedTimeObject(null);
    setIsLoadingDates(true);
    const result = await getAvailableDates(selectedDiningOption);
    if (isPresent(result) && result.length > 0) {
      setAvailableDates(result);
      setSelectedDate(result[0].dtDate);
    }
    setIsLoadingTimes(true);
    setIsLoadingDates(false);
  }, [selectedDiningOption, datesRefetchTrigger]);

  useEffectAsync(async () => {
    if (!selectedDate) {
      setIsLoadingTimes(false);
      return;
    }
    setAvailableTimes([]);
    setIsLoadingTimes(true);
    const result = await getAvailableTimes(selectedDiningOption, {
      dtDate: selectedDate,
    });
    if (isPresent(result) && result.length > 0) {
      setAvailableTimes(result);

      if (!allowUndefinedTimeValue) {
        setSelectedTimeObject(result[0]);
      }
    }
    setIsLoadingTimes(false);
  }, [selectedDate]);

  useEffect(() => {
    if (isLoadingDates || isLoadingTimes) {
      setIsInLoadingState?.(true);
    } else {
      setIsInLoadingState?.(false);
    }

    return () => {
      setIsInLoadingState?.(false);
    };
  }, [isLoadingDates, isLoadingTimes]);

  return (
    <div className={classes.container}>
      <SelectNative
        label={showFieldLabels ? t("form_field_label_date") : undefined}
        options={availableDates
          .sort((a, b) => +new Date(a.dtDate) - +new Date(b.dtDate))
          .map((i) => ({
            label: formatDate(i.dtDate),
            value: i.dtDate,
          }))}
        value={selectedDate}
        width="100%"
        onSelect={(value) => {
          setSelectedDate(value);
          dateSelectCallback();
        }}
        suffixIconUrl={schedulingIcon}
        isLoading={isLoadingDates}
      />

      <SelectNative
        allowUndefinedValue={allowUndefinedTimeValue}
        label={showFieldLabels ? t("form_field_label_time") : undefined}
        options={
          selectedDate
            ? availableTimes.map((i) => ({
                label:
                  (i.dttmDelivery && formatTime(i.dttmDelivery)) ??
                  t("dining_options_relative_date_asap"),
                value: i.dttmDelivery ?? ASAP_OPTION_VALUE_AS_STRING,
              }))
            : []
        }
        value={
          selectedTimeObject?.dttmDelivery === null
            ? ASAP_OPTION_VALUE_AS_STRING
            : undefined
        }
        width="100%"
        onSelect={handleSelectTime}
        suffixIconUrl={timeIcon}
        isLoading={isLoadingTimes || isLoadingDates}
      />
    </div>
  );
};

export default PreorderTimeSelection;
