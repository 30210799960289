import { IUserLayout } from "@api/interfaces/userLayouts";
import { getStorage, StorageKey } from "@constants/storage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { logInWithID, logInWithUsernameAndPassword } from "./actions";
import { UserState } from "./type";

const getInitialState = (loadFromLocalStorage: boolean): UserState => {
  return {
    userData: loadFromLocalStorage ? getStorage(StorageKey.USER) : null,
  };
};

export const userSlice = createSlice({
  name: "user",
  initialState: getInitialState(true),
  reducers: {
    setUser: (state, { payload }: PayloadAction<IUserLayout>) => {
      state.userData = payload;
    },
    resetUser: (state) => {
      state.userData = getInitialState(false).userData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logInWithID.fulfilled, (state, { payload }) => {
      state.userData = payload;
    });

    builder.addCase(
      logInWithUsernameAndPassword.fulfilled,
      (state, { payload }) => {
        state.userData = payload;
      },
    );
  },
  selectors: {
    selectUserGuid: (state) => state.userData?.GUID,
    selectUserId: (state) => state.userData?.ID,
    selectUserData: (state) => state.userData,
    selectHasUserData: (state) => state.userData !== null,
  },
});

export const { setUser, resetUser } = userSlice.actions;

export const {
  selectUserGuid,
  selectUserId,
  selectHasUserData,
  selectUserData,
} = userSlice.selectors;

export default userSlice.reducer;
