import React from "react";
import useWindowSize from "@base/hooks/useWindowSize";

import DesktopHomePage from "../DesktopHomePage";
import MobileHomePage from "../MobileHomePage";

const HomePageWrapper: React.FC = () => {
  const { isMdAndUp } = useWindowSize();

  if (isMdAndUp) {
    return <DesktopHomePage />;
  }

  return <MobileHomePage />;
};

export default HomePageWrapper;
