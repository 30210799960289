import { deepEqual } from "fast-equals";
import { useEffect, useState } from "react";

export const useTemporaryState = <T>(initialState: T, timeout: number) => {
  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    if (deepEqual(state, initialState)) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setState(initialState);
    }, timeout);

    return () => clearTimeout(timeoutId);
  }, [state, initialState, timeout]);

  return [state, setState] as const;
};
