import { MutableRefObject, useEffect } from "react";

const useOnClickOutside = (
  ref: MutableRefObject<any>, // eslint-disable-line
  handler: (event: MouseEvent | TouchEvent) => void,
) => {
  useEffect(() => {
    // eslint-disable-next-line
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mouseup", listener);
    document.addEventListener("touchsend", listener);
    return () => {
      document.removeEventListener("mouseup", listener);
      document.removeEventListener("touchend", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
