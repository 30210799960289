import { ResponseFailed, ResponseSucceeded } from "@api/types";

const ERROR_STATUSES = [400, 401, 403, 404, 500];
const API_RETRY_TIMEOUT = 500;

function getGenericAPIError(
  reason: string,
  request: ResponseFailed["request"],
): ResponseFailed<undefined>;
function getGenericAPIError(
  reason: string,
  request: ResponseFailed["request"],
  response: Response,
): ResponseFailed<undefined>;
function getGenericAPIError<T>(
  reason: string,
  request: ResponseFailed["request"],
  response: Response,
  responseData: T,
): ResponseFailed<T>;
function getGenericAPIError<T>(
  reason: string,
  request: ResponseFailed["request"],
  response?: Response,
  responseData?: T,
): ResponseFailed<T | undefined> {
  return {
    request: request,
    response: responseData as T,
    responseStatus: response?.status,
    isResponseOk: false,
    reason,
  };
}

const getResponse = <T>(
  responseData: T,
  response: Response,
): ResponseSucceeded<T> => ({
  response: responseData as T,
  responseStatus: response.status,
  isResponseOk: true,
});

export { API_RETRY_TIMEOUT, ERROR_STATUSES, getGenericAPIError, getResponse };
