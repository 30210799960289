export interface IProductCategory {
  ID: number;
  Name: string;
}

export interface IProductTax {
  ID: number;
  Name: string;
  Rate: number;
  AccountingCode: string;
  FiscalCode: number;
}

export interface IProductSubItemProduct {
  ID: number;
  SortOrder: number;
  IsDefault: boolean;
}

export interface IMediaResource {
  ID: number;
  Name: string;
  Location: string;
  ThumbnailLocation: string;
  ProductModifierID: number;
  ProductModifierChoiceID: number;
}

export interface IModifierChoice {
  ID: number;
  Name: string;
  ProductID: number;
  SortOrder: number;
  MaxQuantity: number;
  LocalName: string;
  KitchenCode: string;
  RemovedItemKitchenCode: string;
  RemovedItemLocalName: string;
  DefaultQuantity: number;
  PublicDescription: string;
  MediaResources: IMediaResource[];
}

export interface IModifier {
  ID: number;
  Name: string;
  Constraint: ProductModifierConstraints;
  SortOrder: number;
  Choices: IModifierChoice[];
  LocalName: string;
  KitchenCodePrefix: string;
  SelectionMode: ProductModifierSelectionMode;
  SelectionMaxChoiceCount: number;
  DisplayMode: ProductModifierDisplayMode;
}

export interface IModifierSet {
  ID: number;
  Name: string;
  Modifiers: IModifier[];
}

export interface IProductAlcoholContent {
  ID?: number;
  Name?: string;
  MinRequiredAge?: number;
}

export interface IProductSalesMessageCategory {
  ID?: number;
  Name: string;
  MinRequiredAge?: number;
  Text: string;
}

export enum ExternalIdentifier {
  FEATURED = "FEATURED",
  DISABLED = "DISABLED",
  CROSS_SELLING = "CROSS_SELLING",
}

export interface IKeyword {
  ExternalIdentifier: string;
}

export interface IProduct {
  ID: number;
  Column?: number;
  Name: string;
  LocalName: string;
  PublicDescription: string;
  Type: number;
  Category?: IProductCategory;
  NetUnitPrice: number;
  GrossUnitPrice: number;
  IsProductEnabledForSelectedDiningOption: boolean;
  Tax: IProductTax;
  SubItemProducts: IProductSubItemProduct[];
  MediaResources: IMediaResource[];
  ModifierSet: IModifierSet;
  Keywords: IKeyword[];
  AlcoholContent?: IProductAlcoholContent;
  VariationDisplayName: string;
  SalesMessageCategory?: IProductSalesMessageCategory;
}

export interface ISectionItem {
  ID: number;
  ProductID: number;
  Column: number;
}

export interface ISection {
  ID: number;
  Name: string;
  LocalName: string;
  ProductLayoutID: number;
  RowCount: number;
  SortOrder: number;
  Items: ISectionItem[];
  MediaResources: IMediaResource[];
}

export interface IProductLayoutResponse {
  ID: number;
  Name: string;
  Products: IProduct[];
  Sections: ISection[];
  created?: number; // local usage
  userId?: number; // local usage
}

export enum ProductModifierConstraints {
  UNKNOWN = 0,
  MANDATORY = 1,
  OPTIONAL = 2,
}

export enum ProductModifierSelectionMode {
  UNKNOWN = 0,
  SINGLE_CHOICE = 1,
  MULTIPLE_CHOICE = 2,
}

export enum ProductModifierDisplayMode {
  GRAPHICAL = 1,
  TEXTUAL = 2,
}

export type GetProductLayoutsRequest = {
  productLayoutID: number;
  customerPersonId?: number;
  diningOptionId?: number;
};
