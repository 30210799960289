import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "@base/components/Global/Button";
import { Modal } from "@base/components/Global/Modal";
import { ConfirmationModalContent } from "@context/ModalContainer";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  container: {
    padding: [50, spacing.x5l, spacing.x5l, spacing.x5l],
    width: 370,
  },
  title: {
    fontSize: font.size.x2l,
    fontWeight: font.weight.s,
    color: color.defaultText,
    lineHeight: font.lineHeight.l,
  },
  bodyText: {
    margin: [spacing.x5l, 0],
    fontSize: font.size.m,
    color: color.defaultText,
    lineHeight: font.lineHeight.m,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
  },
}));

const ConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  modalObject: ConfirmationModalContent | null;
}> = ({ isOpen, onClose, modalObject }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!modalObject) return null;

  const { title, body, confirmationCallback } = modalObject;

  const onConfirm = async () => {
    try {
      await confirmationCallback();
      onClose();
    } catch (e) {}
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} forceToForeground>
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        <p className={classes.bodyText}>{body}</p>
        <div className={classes.buttonsContainer}>
          <Button isTransparent action={onClose}>
            {t("global_btn_cancel")}
          </Button>
          <Button action={onConfirm}>{t("global_btn_confirm")}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
