import { request } from "@api";
import {
  GetAvailableDatesRequest,
  GetAvailableTimesRequest,
  IAvailableDiningDate,
  IAvailableDiningTime,
  ISalesPointConfiguration,
} from "@api/interfaces/configurationLayouts";
import { ApiPath } from "@api/paths";
import { ApiRequest, RequestResponse } from "@api/types";

const getSalesPointConfiguration: ApiRequest<
  RequestResponse<ISalesPointConfiguration>,
  void
> = async () =>
  request({
    api: ApiPath.Configuration.getSalesPointConfiguration,
  });

const getAvailableDates: ApiRequest<
  RequestResponse<IAvailableDiningDate[]>,
  GetAvailableDatesRequest
> = async ({ diningOption, ...options }) =>
  request({
    ...options,
    api: ApiPath.Configuration.availableDeliveryDates({ diningOption }),
  });

const getAvailableTimes: ApiRequest<
  RequestResponse<IAvailableDiningTime[]>,
  GetAvailableTimesRequest
> = async ({ diningOption, date, ...options }) =>
  request({
    ...options,
    api: ApiPath.Configuration.availableDeliveryTimes({ diningOption, date }),
  });

export const Configuration = {
  getSalesPointConfiguration,
  getAvailableDates,
  getAvailableTimes,
};
