import { configureStore, ThunkAction, UnknownAction } from "@reduxjs/toolkit";

import listenerMiddleware from "./listenerMiddleware";
import { sentryReduxEnhancer } from "./sentryEnhancer";
import { rootReducer, RootState } from "./slices";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(listenerMiddleware),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export type Store = typeof store;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  UnknownAction
>;
