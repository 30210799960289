import { isPresent } from "@apl-digital/utils";
import {
  NavigationPath,
  ProductID,
  ProductParams,
} from "@constants/navigation";
import {
  UnifiedCartLine,
  UnifiedCartLineModifierChoice,
} from "@store/shoppingCart";
import { generatePath, useNavigate } from "react-router-dom";

const buildProductURL = (cartItem: UnifiedCartLine): string => {
  const url = generatePath(NavigationPath.Product, {
    [ProductID]: isPresent(cartItem.variableProductId)
      ? String(cartItem.variableProductId)
      : String(cartItem.productId),
  });

  const params = new URLSearchParams();

  params.set(ProductParams.EDIT, "true");
  params.set(ProductParams.AMOUNT, String(cartItem.amount));
  params.set(
    ProductParams.LINE_ID,
    [...cartItem.mergedCartLineIds, ...cartItem.relatedChildCartLineIds].join(
      ",",
    ),
  );

  if (isPresent(cartItem.noteToKitchen)) {
    params.set(ProductParams.COMMENT, cartItem.noteToKitchen);
  }

  if (cartItem.modifiers.length > 0) {
    params.set(
      ProductParams.MODIFIERS,
      cartItem.modifiers
        .filter(
          (modifier): modifier is UnifiedCartLineModifierChoice =>
            modifier.type === "choice",
        )
        .map((modifier) => modifier.modifierChoiceId)
        .join(","),
    );
  }

  if (isPresent(cartItem.variableProductId)) {
    params.set(ProductParams.VARIABLE_ID, String(cartItem.productId));
  }

  return `${url}?${params.toString()}`;
};

type UseProductDetailNavigationResults = {
  navigateToProductDetailPage: (cartItem: UnifiedCartLine) => void;
};

export const useProductDetailNavigation =
  (): UseProductDetailNavigationResults => {
    const navigate = useNavigate();

    return {
      navigateToProductDetailPage: (cartItem: UnifiedCartLine) =>
        navigate(buildProductURL(cartItem)),
    };
  };
