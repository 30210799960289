import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useAuth } from "react-oidc-context";
import Button from "@base/components/Global/Button";
import Icon from "@base/components/Global/Icon";
import { useUser } from "@context/UserProvider";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(({ font, color }) => ({
  accountLogInContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    borderRadius: "24px",
    background: color.signInBlockBg,
    overflow: "hidden",
    margin: "1rem",
    paddingLeft: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "& h3": {
      fontSize: font.size.m,
      fontWeight: font.weight.m,
      color: color.burgerMenuAccountLogIn,
    },
  },
  loginButton: {
    background: color.burgerMenuBtnBg,
    color: color.burgerMenuBtnText,
    height: "auto",
  },
  accountLogInLoyaltyCardContainer: {
    flex: "0.8",
  },
  accountLogInLoyaltyCardIcon: {
    width: 151,
    height: 139,
    right: "-2rem",
    bottom: "-2rem",
    position: "absolute",
  },
  accountLoginContent: {
    flex: 1,
    justifyContent: "end",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
  },
}));

type UserLoginProps = {
  onClick?: () => void;
};

export const UserLogin: React.FC<UserLoginProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { login } = useUser();
  const { isLoading } = useAuth();

  const onLoginClick = () => {
    login();
  };

  const { rotatedLoyaltyClubCardIcon } = getBlobStorage();

  return (
    <div
      className={classes.accountLogInContainer}
      id="burger-menu-login-btn-container"
    >
      <div className={classes.accountLoginContent}>
        <h3>{t("burgermenu_main_club_benefits")}</h3>
        <Button
          action={onLoginClick}
          className={classes.loginButton}
          id="login-btn"
          isLoading={isLoading}
        >
          {t("burgermenu_main_login")}
        </Button>
      </div>
      <div className={classes.accountLogInLoyaltyCardContainer}>
        <Icon
          url={rotatedLoyaltyClubCardIcon}
          className={classes.accountLogInLoyaltyCardIcon}
          height="139px"
          width="151px"
        />
      </div>
    </div>
  );
};
