import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import Button from "@base/components/Global/Button";
import Footer from "@base/components/Global/Footer";
import Icon from "@base/components/Global/Icon";
import KitchenClosedFooterContent from "@base/components/Global/KitchenClosedFooterContent";
import { NavigationPath } from "@constants/navigation";
import { useSalesPointContext } from "@context/SalesPointProvider";
import { useAppSelector } from "@store";
import {
  selectShoppingCartTotal,
  selectShoppingCartTotalItemCount,
} from "@store/shoppingCart";
import { getBlobStorage, getBrandTheme } from "@theme";

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  footerButtons: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.xxl,
    width: "100%",
    justifyContent: "space-between",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  kitchenClosedMsg: {
    color: color.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: font.size.s,
    lineHeight: font.lineHeight.xxs,
    fontWeight: font.weight.m,
  },
  button: {
    justifyContent: "space-around",
  },
}));

type Props = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

const HomePageFooter: React.FC<Props> = ({ isOpen, openModal, closeModal }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { cartIcon, closeIcon } = getBlobStorage();
  const { t } = useTranslation("homePage");
  const shoppingCartItemCount = useAppSelector(
    selectShoppingCartTotalItemCount,
  );
  const shoppingCartTotal = useAppSelector(selectShoppingCartTotal);
  const [{ configuration }] = useSalesPointContext();
  const { color } = getBrandTheme();

  const isButtonDisabled = shoppingCartItemCount == 0;

  return (
    <Footer isError={!configuration.IsKitchenOpen}>
      {configuration.IsKitchenOpen && (
        <span className={classes.footerButtons}>
          <Button
            action={() => {
              closeModal();
              navigate(NavigationPath.Cart);
            }}
            isDisabled={isButtonDisabled}
            id="home-footer-cart-btn"
            className={classes.button}
          >
            <Icon
              url={cartIcon}
              fill={
                isButtonDisabled
                  ? color.footerCartIconInactive
                  : color.footerCartIconActive
              }
              height={24}
              width={24}
            />
            <div id="total-footer-items">
              {t("HomePageFooter.cartButton.cartItemCount", {
                count: shoppingCartItemCount,
              })}
            </div>
            <div id="total-footer-price">
              {t("HomePageFooter.cartButton.cartTotalPrice", {
                cartTotal: shoppingCartTotal,
                formatParams: {
                  cartTotal: { currency: "EUR" },
                },
              })}
            </div>
          </Button>
          {isOpen && (
            <Button
              action={closeModal}
              id="menu-btn"
              isMenu
              className={classes.uppercaseText}
              children={
                <Icon
                  url={closeIcon}
                  stroke={color.searchMenuCloseIcon}
                  width={32}
                  height={32}
                />
              }
            />
          )}
          {!isOpen && (
            <Button
              action={openModal}
              id="menu-btn"
              isMenu
              className={classes.uppercaseText}
            >
              {t("HomePageFooter.menuButtonText")}
            </Button>
          )}
        </span>
      )}
      {!configuration.IsKitchenOpen && (
        <KitchenClosedFooterContent
          isOpen={isOpen}
          open={openModal}
          close={closeModal}
        />
      )}
    </Footer>
  );
};

export default HomePageFooter;
