import React, { forwardRef, PropsWithChildren, RefObject } from "react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles(({ spacing, color, borderRadius }) => ({
  box: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    borderRadius: borderRadius.x3l,
    backgroundColor: color.boxBg,
    boxShadow: color.boxShadowTop,
    padding: [spacing.x5l, spacing.x4l],
  },
}));

type Props = {
  classNames?: string;
  ref?: RefObject<HTMLDivElement>;
  id?: string;
};

const Box = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, classNames, id }, ref) => {
    const classes = useStyles();

    return (
      <div className={classnames([classes.box, classNames])} ref={ref} id={id}>
        {children}
      </div>
    );
  },
);

export default Box;
