import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { parseNumber, strict } from "@apl-digital/utils";
import config from "@constants/config";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE,
  normalizeDepth: 10,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: true,
    }),
    Sentry.extraErrorDataIntegration({
      captureErrorCause: true,
    }),
  ],
  tracePropagationTargets: ["localhost", config.apiConfig.orderAppProxy?.url],
  profilesSampleRate: strict(
    parseNumber(process.env.SENTRY_PROFILES_SAMPLE_RATE),
  ),

  // Performance Monitoring
  tracesSampleRate: strict(parseNumber(process.env.SENTRY_TRACE_SAMPLE_RATE)),

  // Session Replay
  replaysSessionSampleRate: strict(
    parseNumber(process.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  ),
  replaysOnErrorSampleRate: strict(
    parseNumber(process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
  ),
});
