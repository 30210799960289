import React from "react";
import { createUseStyles } from "react-jss";
import { getBrandTheme } from "@theme";
import classnames from "classnames";

import { useSharedStyles } from "./shared.style";
import { StepperMiddleGroupItem } from "./StepperMiddleGroupItem";
import { MiddleGroup } from "./types";

const useStyles = createUseStyles(({ color, spacing }) => ({
  middleGroup: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: 40,
    color: color.orderStatusPageLineText,
    marginLeft: spacing.m,
    paddingLeft: spacing.x3l,
    "&:before": {
      content: '""', // eslint-disable-line
      display: "block",
      position: "absolute",
      width: 8,
      height: 8,
      borderRadius: "50%",
      background: color.orderStatusPageLineText,
      top: "50%",
      left: 1,
      transform: "translate(-55%, -50%)",
    },
  },
}));

type StepperMiddleGroupProps = {
  group: MiddleGroup;
  isLastUpperGroup: boolean;
  isLastMiddleGroup: boolean;
};

export const StepperMiddleGroup: React.FC<StepperMiddleGroupProps> = ({
  group,
  isLastUpperGroup,
  isLastMiddleGroup,
}) => {
  const classes = useStyles();

  const { color } = getBrandTheme();
  const sharedClasses = useSharedStyles({
    linkLineColor: color.orderStatusPageLineText,
  });

  return (
    <>
      <div
        className={classnames([
          classes.middleGroup,
          isLastUpperGroup && isLastMiddleGroup
            ? sharedClasses.linkLineTop
            : sharedClasses.linkLineBoth,
        ])}
      >
        {group.label}
      </div>

      {group.items.map((item, index, array) => (
        <StepperMiddleGroupItem
          key={item.id}
          item={item}
          isLastUpperGroup={isLastUpperGroup}
          isLastMiddleGroup={isLastMiddleGroup}
          isLastMiddleGroupItem={index === array.length - 1}
        />
      ))}
    </>
  );
};
