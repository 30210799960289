import { isPresent } from "@apl-digital/utils";
import { clearStorage, setStorage, StorageKey } from "@constants/storage";
import { AppStartListening } from "@store/listenerMiddleware";
import { deepEqual } from "fast-equals";

export const addUserListeneres = (startListening: AppStartListening) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      !deepEqual(previousState.user.userData, currentState.user.userData),

    effect: (_action, { getState }) => {
      const userData = getState().user.userData;

      if (isPresent(userData)) {
        setStorage(StorageKey.USER, userData);
      } else {
        clearStorage(StorageKey.USER);
      }
    },
  });
};
