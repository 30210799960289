import { ERROR_STATUSES, getGenericAPIError, getResponse } from "@api/helpers";
import type {
  FetchApiOptions,
  FetchRequestOptions,
  FetchWithErrorsOptions,
  RequestResponse,
} from "@api/types";
import { ContentType, RequestHeader, RequestMethod } from "@api/types";
import { isPresent } from "@apl-digital/utils";
import config from "@constants/config";
import * as Sentry from "@sentry/react";
import i18n from "i18next";

const fetchApi = async <TSuccess, TError>({
  signal,
  // web standards use - instead of underscore
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang
  language = i18n.language.replace("_", "-"),
  ...options
}: FetchApiOptions): RequestResponse<TSuccess, TError> => {
  const { method, api, shouldParseResponse, body, headers } = options;

  const isFormData = body instanceof FormData;

  const apiPath = `${config.apiConfig.orderAppProxy?.url}${api}`;

  const requestHeaders = new Headers({
    ...headers,
    [RequestHeader.TENANT_KEY]: window.location.hostname
      .split(".")
      .reverse()
      .join("."),
  });

  if (isPresent(language)) {
    requestHeaders.append(RequestHeader.ACCEPTED_LANGUAGE, language);
  }

  if (!isFormData) {
    requestHeaders.append(
      RequestHeader.CONTENT_TYPE,
      ContentType.APPLICATION_JSON,
    );
  }

  try {
    const response = await fetch(apiPath, {
      signal,
      method,
      headers: requestHeaders,
      ...([RequestMethod.POST, RequestMethod.PUT].includes(method) && {
        body: isFormData ? (body as FormData) : JSON.stringify(body),
      }),
    });

    let responseData = null;
    if (ERROR_STATUSES.includes(response.status)) {
      return getGenericAPIError(
        "Error response status",
        { ...options, language },
        response,
      );
    }

    if (shouldParseResponse) {
      try {
        responseData = await response.json();
      } catch (e) {
        return getGenericAPIError(
          "Error parsing response",
          { ...options, language },
          response,
        );
      }
    }

    return getResponse<TSuccess>(responseData, response);
  } catch (error) {
    return getGenericAPIError("Error fetching data", { ...options, language });
  }
};

const fetchWithErrors = async <TSuccess, TError>({
  shouldParseResponse = true,
  canFail = false,
  ...options
}: FetchWithErrorsOptions): RequestResponse<TSuccess, TError> => {
  let signal = options.signal;
  let timeoutId: NodeJS.Timeout | undefined;

  if (!isPresent(options.signal)) {
    const abortController = new AbortController();

    timeoutId = setTimeout(() => {
      Sentry.captureException(
        new Error(
          `Request has exceeded the timeout of ${config.apiConfig.orderAppProxy.timeout}ms, aborting request`,
          {
            cause: {
              ...options,
            },
          },
        ),
      );
      abortController.abort();
    }, config.apiConfig.orderAppProxy.timeout);

    signal = abortController.signal;
  }

  const response = await fetchApi<TSuccess, TError>({
    ...options,
    signal,
    shouldParseResponse,
  });

  clearTimeout(timeoutId);

  if (canFail || response.isResponseOk) {
    return response;
  }

  return response;
};

export const request = async <TSuccess, TError = void>(
  options: FetchRequestOptions,
): RequestResponse<TSuccess, TError> =>
  fetchWithErrors({ ...options, method: RequestMethod.GET });

export const post = async <TSuccess, TError = void>(
  options: FetchRequestOptions,
): RequestResponse<TSuccess, TError> =>
  fetchWithErrors({
    ...options,
    method: RequestMethod.POST,
  });

export const put = async <TSuccess, TError = void>(
  options: FetchRequestOptions,
): RequestResponse<TSuccess, TError> =>
  fetchWithErrors({
    ...options,
    method: RequestMethod.PUT,
  });

export const deleteRequest = async <TSuccess, TError = void>(
  options: FetchRequestOptions,
): RequestResponse<TSuccess, TError> =>
  fetchWithErrors({
    ...options,
    method: RequestMethod.DELETE,
  });
