import { ShoppingCart } from "@api/controller/shoppingCart";
import { createAsyncFetchThunk } from "@store/utils";

export const removeCustomerFromCart = createAsyncFetchThunk(
  "shoppingCart/removeCustomerFromCart",
  ShoppingCart.removeCustomerFromCart,
);

export const bindContactInfo = createAsyncFetchThunk(
  "shoppingCart/bindContactInfo",
  ShoppingCart.bindContactInfo,
);

// TheatreResource is an abstract term and on most cases refers to tableNumber.
// On Preorders, this allocates kitchen availability.
export const updateTheatreResource = createAsyncFetchThunk(
  "shoppingCart/updateTheatreResource",
  ShoppingCart.updateTheatreResource,
);

export const updateCustomerCart = createAsyncFetchThunk(
  "shoppingCart/updateCustomerCart",
  ShoppingCart.updateCustomerCart,
);

export const initializeDiningCart = createAsyncFetchThunk(
  "shoppingCart/initializeDiningCart",
  ShoppingCart.initializeDiningCart,
);

export const bundleShoppingCart = createAsyncFetchThunk(
  "shoppingCart/bundleShoppingCart",
  ShoppingCart.bundle,
);

export const unbundleShoppingCart = createAsyncFetchThunk(
  "shoppingCart/unbundleShoppingCart",
  ShoppingCart.unbundle,
);

export const addProductToCart = createAsyncFetchThunk(
  "shoppingCart/addProductToCart",
  ShoppingCart.addProductToCart,
);

export const refreshCart = createAsyncFetchThunk(
  "shoppingCart/refreshCart",
  ShoppingCart.getShoppingCartByBarcode,
);

export const removeLinesFromCart = createAsyncFetchThunk(
  "shoppingCart/removeLinesFromCart",
  ShoppingCart.removeLinesFromCart,
);

export const removeAllProductsFromCart = createAsyncFetchThunk(
  "shoppingCart/removeAllProductsFromCart",
  ShoppingCart.removeAllProductsFromCart,
);

export const removeProductFromCart = createAsyncFetchThunk(
  "shoppingCart/removeProductFromCart",
  ShoppingCart.removeProductFromCart,
);

export const updateShoppingCartLanguage = createAsyncFetchThunk(
  "shoppingCart/updateShoppingCartLanguage",
  ShoppingCart.updateLanguage,
);

export const putOnHold = createAsyncFetchThunk(
  "shoppingCart/putOnHold",
  ShoppingCart.putOnHold,
);
