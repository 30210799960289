import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { OrderStatus } from "@api/interfaces/orderLayouts";
import { isPresent } from "@apl-digital/utils";
import Icon from "@base/components/Global/Icon";
import {
  MiddleGroup,
  Stepper,
  UpperGroup,
} from "@base/components/Global/Stepper";
import config from "@constants/config";
import { OrderDataGroup, OrderDataWithGrouping } from "@store/orders";
import { getBlobStorage } from "@theme";
import classNames from "classnames";

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  statusBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
  },
  statusProgressBlock: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxl,
    marginTop: spacing.xxl,
    width: "100%",
  },
  statusProgressBlockKDS: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  lineText: {
    display: "-webkit-box",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: spacing.xxl,
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: font.size.s,
    color: color.orderStatusPageLineText,
  },
  lineTextPadding: {
    paddingLeft: spacing.xl,
  },
  pickupLocationText: {
    fontSize: font.size.m,
    display: "flex",
    flexDirection: "row",
    gap: spacing.s,
    color: color.orderStatusPagePickUpText,
  },
}));

type StatusBlockProps = {
  order: OrderDataWithGrouping;
};

const StatusBlock: React.FC<StatusBlockProps> = ({ order }) => {
  const classes = useStyles();
  const { t } = useTranslation("orderStatusPage");

  const { locationIcon } = getBlobStorage();

  const canShowDeliveredItems =
    config.kdsConfig.isEnabled && config.kdsConfig.shouldShowDeliveredItems;

  const mapOrderDataGroupToMiddleGroup = (
    orderDataGroups: Array<OrderDataGroup>,
  ): Array<MiddleGroup> => {
    return orderDataGroups.flatMap((group) => {
      if (!isPresent(group.ReadyForDeliveryAtChannel)) {
        return group.lines.map((line) => ({
          id: line.ID,
          label: <div className={classes.lineText}>{line.Name}</div>,
          items: [],
        }));
      }

      return {
        id: group.ReadyForDeliveryAtChannel.ID,
        label: (
          <div className={classes.pickupLocationText}>
            <Icon
              url={locationIcon}
              width={20}
              height={20}
              stroke="white"
              fill="#C83C42"
            />
            {group.ReadyForDeliveryAtChannel.LocalName}
          </div>
        ),
        items: group.lines.map((line) => ({
          id: line.ID,
          label: (
            <div
              className={classNames(classes.lineText, classes.lineTextPadding)}
            >
              {line.Name}
            </div>
          ),
        })),
      };
    });
  };

  const itemsPending: MiddleGroup[] = useMemo(
    () => mapOrderDataGroupToMiddleGroup(order.groups.pending),
    [order.groups.pending],
  );

  const itemsInProgress: MiddleGroup[] = useMemo(
    () => mapOrderDataGroupToMiddleGroup(order.groups.inProgress),
    [order.groups.inProgress],
  );

  const itemsReady: MiddleGroup[] = useMemo(
    () => mapOrderDataGroupToMiddleGroup(order.groups.ready),
    [order.groups.ready],
  );

  const itemsDelivered: MiddleGroup[] = useMemo(
    () => mapOrderDataGroupToMiddleGroup(order.groups.delivered),
    [order.groups.delivered],
  );

  const itemsAll: MiddleGroup[] = useMemo(
    () => mapOrderDataGroupToMiddleGroup(order.groups.all),
    [order.groups.all],
  );

  const stepperGroups: UpperGroup[] = [];

  if (order.Status === OrderStatus.Cancelled) {
    stepperGroups.push({
      id: "cancelled",
      label: t("StatusBlock.stepperStatus.cancelled"),
      middleGroups: [],
      isDone: order.Status === OrderStatus.Cancelled,
      timeStamp: isPresent(order.dttmLastStatusChange)
        ? new Date(order.dttmLastStatusChange)
        : undefined,
    });
  } else if (order.Status === OrderStatus.Refunded) {
    stepperGroups.push({
      id: "refunded",
      label: t("StatusBlock.stepperStatus.refunded"),
      middleGroups: [],
      isDone: order.Status === OrderStatus.Refunded,
      timeStamp: isPresent(order.dttmLastStatusChange)
        ? new Date(order.dttmLastStatusChange)
        : undefined,
    });
  } else if (config.kdsConfig.isEnabled) {
    stepperGroups.push({
      id: "pending",
      label: t("StatusBlock.stepperStatus.pending"),
      middleGroups: itemsPending,
      isDone: true,
      isActive: order.isPending,
      timeStamp: new Date(order.dttmCreated),
    });

    stepperGroups.push({
      id: "inProgress",
      label: t("StatusBlock.stepperStatus.inProgress"),
      middleGroups: itemsInProgress,
      isDone: itemsPending.length === 0,
      isActive: itemsInProgress.length > 0,
    });

    if (config.featureFlags.showReadyForPickupStatus) {
      const isDone =
        itemsReady.length === 0 &&
        itemsPending.length === 0 &&
        itemsInProgress.length === 0;

      stepperGroups.push({
        id: "readyForPickup",
        label: t("StatusBlock.stepperStatus.readyForPickup"),
        middleGroups: itemsReady,
        isDone,
        isActive: itemsReady.length > 0,
        timeStamp:
          isPresent(order.dttmReadyForDelivery) && isDone
            ? new Date(order.dttmReadyForDelivery)
            : undefined,
      });
    }

    if (canShowDeliveredItems) {
      stepperGroups.push({
        id: "pickedUp",
        label: t("StatusBlock.stepperStatus.pickedUp"),
        middleGroups: itemsDelivered,
        isDone:
          itemsReady.length === 0 &&
          itemsPending.length === 0 &&
          itemsInProgress.length === 0,
        isActive: itemsDelivered.length > 0 && itemsReady.length !== 0,
      });
    }
  } else {
    stepperGroups.push({
      id: "inProgress-all",
      label: t("StatusBlock.stepperStatus.inProgress"),
      middleGroups: itemsAll,
      isDone: true,
      isActive: true,
    });
  }

  return <Stepper groups={stepperGroups} />;
};

export default StatusBlock;
