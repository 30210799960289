import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  DiningOption,
  IAvailableDiningTime,
  IDiningOptionEnum,
} from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import PreorderTimeSelection from "@base/components/Cart/PreorderTimeSelection";
import Button from "@base/components/Global/Button";
import Icon from "@base/components/Global/Icon";
import BodyTextSkeleton from "@base/components/Global/Skeleton/BodyTextSkeleton";
import TextField from "@base/components/Global/TextField";
import LanguageDropdown from "@base/components/LanguageDropdown/LanguageDropdown";
import { useModalContext } from "@context/ModalContainer";
import { useAppDispatch, useAppSelector } from "@store";
import { hasData } from "@store/apiRequestStatusMachine";
import {
  selectAvailableDiningOptions,
  selectDefaultTableBarcode,
} from "@store/salesPoint";
import {
  initializeDiningCart,
  selectCurrentDiningOption,
  selectShoppingCart,
} from "@store/shoppingCart";
import {
  getTheatreResource,
  selectTheatreResource,
} from "@store/theatreResource";
import { selectUserId } from "@store/user";
import { getBlobStorage, getBrandTheme } from "@theme";

import { TheatreResourceScannerModal } from "../TheatreResourceModal/TheatreResourceScannerModal";

import Toggle, { ToggleOption } from "./Toggle";

type InfoText = {
  asap: string;
  preorder: string;
};

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  container: {
    width: 350,
    padding: [spacing.x5l, spacing.x5l + spacing.x3l],
    overflowY: "auto",
  },
  title: {
    fontSize: font.size.x2l,
    fontWeight: font.weight.s,
    color: color.defaultText,
    margin: [spacing.x2l, 0],
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: spacing.l,
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxl,
    marginBottom: spacing.l,
  },
  infoText: {
    color: color.infoTextColor,
    margin: [spacing.x3l, 0],
  },
  qrFormContainer: {
    display: "flex",
    margin: [spacing.x4l, 0],
    gap: spacing.m,
    alignContent: "center",
  },
  scanBtn: {
    cursor: "pointer",
    background: color.qrScannerButtonBackground,
    border: `1px solid ${color.fieldBorder}`,
    boxShadow: 0,
    borderRadius: 100,
    width: 50,
    height: 50,
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  enterQrCodeClickable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    color: color.defaultText,
    gap: 8,
    cursor: "pointer",
  },
  conflictErrorMessage: {
    color: color.error,
    margin: [spacing.s, 0],
  },
  language: {
    position: "absolute",
    top: 12,
    left: 4,
  },
  form: {
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
  },
}));

type DeliveryMethodModalContentProps = {
  onCreateCart: () => void;
};

export const DeliveryMethodModalContent: React.FC<
  DeliveryMethodModalContentProps
> = ({ onCreateCart }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const shoppingCart = useAppSelector(selectShoppingCart);
  const tableInfo = useAppSelector(selectTheatreResource);
  const availableDiningOptions = useAppSelector(selectAvailableDiningOptions);
  const currentDiningOption = useAppSelector(selectCurrentDiningOption);
  const [selectedDiningOption, setSelectedDiningOption] =
    useState<DiningOption | null>(currentDiningOption);
  const userId = useAppSelector(selectUserId);
  const isTouchDevice = useAppSelector((state) => state.layout.isTouchDevice);
  const { showConfirmationModal } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimeObject, setSelectedTimeObject] =
    useState<IAvailableDiningTime | null>(null);
  const [isTableNumberFormVisible, setIsTableNumberFormVisible] =
    useState<boolean>();
  const defaultTableBarcode = useAppSelector(selectDefaultTableBarcode);
  const [tableCodeValue, setTableCodeValue] = useState<string>("");
  const { t } = useTranslation();
  const { diningOptionIconDineIn, diningOptionIconTakeaway, scanQrIcon } =
    getBlobStorage();
  const [isTableCodeError, setIsTableCodeError] = useState(false);
  const { color } = getBrandTheme();
  const [deliveryDatesRefetchTrigger, setDeliveryDatesRefetchTrigger] =
    useState<number>(0);
  const [shouldShowTimeSlotConflictError, setShouldShowTimeSlotConflictError] =
    useState(false);
  const [shouldShowScanner, setShouldShowScanner] = useState(false);

  const diningOptionMap: Record<
    IDiningOptionEnum,
    Omit<ToggleOption<DiningOption>, "value">
  > = {
    [IDiningOptionEnum.DINE_IN]: {
      label: t("dining_options_title_dine_in"),
      iconUrl: diningOptionIconDineIn,
      optionId: "dine-in-option-btn",
    },
    [IDiningOptionEnum.TAKE_OUT]: {
      label: t("dining_options_title_take_out"),
      iconUrl: diningOptionIconTakeaway,
      optionId: "take-out-option-btn",
    },
  };

  const options = availableDiningOptions.map(
    (option): ToggleOption<DiningOption> => ({
      ...diningOptionMap[option.ID],
      value: option,
    }),
  );

  const showTableNumberForm = () => {
    if (hasData(tableInfo)) {
      setTableCodeValue(tableInfo.state.Barcode);
    }

    setIsTableNumberFormVisible(true);
  };

  useEffect(() => {
    if (!hasData(tableInfo) && isPresent(defaultTableBarcode)) {
      dispatch(
        getTheatreResource({
          barcodeWithoutChecksum: defaultTableBarcode,
        }),
      );
    }
  }, [tableInfo, defaultTableBarcode]);

  useEffect(() => {
    setIsTableNumberFormVisible(false);
    setTableCodeValue("");
    setSelectedTimeObject(null);
  }, [selectedDiningOption]);

  const createCart = () => {
    if (!isPresent(selectedDiningOption)) {
      throw Error("Dining option ID is not present");
    }

    let theatreResourceId = 0;

    if (isPresent(selectedTimeObject?.TheatreResourceID)) {
      theatreResourceId = selectedTimeObject?.TheatreResourceID;
    } else if (hasData(tableInfo)) {
      theatreResourceId = tableInfo.state.ID;
    }

    try {
      dispatch(
        initializeDiningCart({
          theatreResourceId: theatreResourceId,
          dttmRequestedDelivery: selectedTimeObject?.dttmDelivery ?? null,
          diningOptionId: selectedDiningOption.ID,
          customerPersonId: userId,
        }),
      );
      onCreateCart();
    } catch (e) {
      setDeliveryDatesRefetchTrigger((prevState) => prevState + 1);
      setShouldShowTimeSlotConflictError(true);
    }
  };

  const applyTableInfo = () => {
    dispatch(
      getTheatreResource({
        barcodeWithoutChecksum: tableCodeValue,
      }),
    )
      .unwrap()
      .then(() => {
        setIsTableNumberFormVisible(false);
        setTableCodeValue("");
        setIsTableCodeError(false);

        createCart();
      })
      .catch(() => {
        setIsTableCodeError(true);
      });
  };

  const onSubmit = () => {
    setShouldShowTimeSlotConflictError(false);
    if (tableCodeValue.length > 0) {
      applyTableInfo();
    } else {
      createCart();
    }
  };

  const handleToggleChange = (newDiningOption: DiningOption) => {
    setShouldShowTimeSlotConflictError(false);

    if (!shoppingCart?.state?.Lines?.length) {
      setSelectedDiningOption(newDiningOption);
      return;
    }

    showConfirmationModal({
      title: t("delivery_option_change_confirmation_modal_title"),
      body: t("delivery_option_change_confirmation_modal_body"),
      confirmationCallback: () => setSelectedDiningOption(newDiningOption),
    });
  };

  const infoTextMap: Record<IDiningOptionEnum, InfoText> = {
    [IDiningOptionEnum.DINE_IN]: {
      asap: t("dining_options_info_text_dine_in_asap_message"),
      preorder: t("dining_options_info_text_dine_in_preorder_message"),
    },
    [IDiningOptionEnum.TAKE_OUT]: {
      asap: t("dining_options_info_text_take_out_asap_message"),
      preorder: t("dining_options_info_text_take_out_preorder_message"),
    },
  };

  return (
    <>
      <div className={classes.container}>
        <TheatreResourceScannerModal
          onAdded={setTableCodeValue}
          isOpen={shouldShowScanner}
          onClose={() => setShouldShowScanner(false)}
          switchToManual={() => setShouldShowScanner(false)} // The manual modal is in the background and should be visible when we close this scanner modal
        />

        <LanguageDropdown
          className={classes.language}
          shouldOpenFirst={!hasData(shoppingCart)}
        />

        <div className={classes.toggleContainer}>
          <Toggle
            value={selectedDiningOption}
            onChange={handleToggleChange}
            options={options}
          />
        </div>

        <div className={classes.form}>
          {selectedDiningOption && selectedDiningOption.IsPreorderEnabled && (
            <>
              <h3 className={classes.title}>
                {selectedDiningOption.ID === IDiningOptionEnum.DINE_IN
                  ? t("dining_options_title_dine_in")
                  : t("dining_options_title_take_out")}
                :
              </h3>
              <div className={classes.fieldsContainer}>
                <PreorderTimeSelection
                  datesRefetchTrigger={deliveryDatesRefetchTrigger}
                  setIsInLoadingState={setIsLoading}
                  selectedTimeObject={selectedTimeObject}
                  dateSelectCallback={() =>
                    setShouldShowTimeSlotConflictError(false)
                  }
                  setSelectedTimeObject={setSelectedTimeObject}
                  selectedDiningOption={selectedDiningOption.ID}
                />
              </div>
            </>
          )}
          {selectedDiningOption?.ID === IDiningOptionEnum.DINE_IN &&
            (isTableNumberFormVisible ? (
              <>
                <div className={classes.qrFormContainer}>
                  <TextField
                    width="100%"
                    autoComplete="off"
                    setValue={setTableCodeValue}
                    value={tableCodeValue}
                    hidePrefixIconOnValue={false}
                    showClearButton={false}
                    placeholder={t("dining_options_insert_table_number")}
                    autoFocus
                    hasError={isTableCodeError}
                    id="qr-modal-input"
                    noPadding
                  />
                  {isTouchDevice && (
                    <button
                      className={classes.scanBtn}
                      onClick={() => setShouldShowScanner(true)}
                    >
                      <Icon
                        height={20}
                        width={20}
                        url={scanQrIcon}
                        stroke={color.black}
                        wrapper="svg"
                      />
                    </button>
                  )}
                </div>
                <p className={classes.infoText}>
                  {t("dining_options_info_text_table_code")}
                </p>
              </>
            ) : (
              <>
                {hasData(tableInfo) && (
                  <div
                    className={classes.enterQrCodeClickable}
                    role="button"
                    tabIndex={0}
                    onClick={showTableNumberForm}
                    id="change-table-number-btn"
                  >
                    <Icon
                      height={20}
                      width={20}
                      url={scanQrIcon}
                      stroke={color.defaultText}
                    />
                    {t("dining_options_insert_table_number")}{" "}
                    {tableInfo.state.Barcode
                      ? `(${tableInfo.state.Barcode})`
                      : ""}
                  </div>
                )}
              </>
            ))}

          {selectedDiningOption !== null &&
            (isLoading ? (
              <BodyTextSkeleton nLines={3} className={classes.infoText} />
            ) : (
              <p className={classes.infoText}>
                {isPresent(selectedTimeObject?.dttmDelivery)
                  ? infoTextMap[selectedDiningOption.ID].preorder
                  : infoTextMap[selectedDiningOption.ID].asap}
              </p>
            ))}

          {shouldShowTimeSlotConflictError && (
            <div className={classes.conflictErrorMessage}>
              {t("dining_options_time_conflict_error_message")}
            </div>
          )}

          <Button
            action={onSubmit}
            id="delivery-method-continue-btn"
            isDisabled={isLoading}
          >
            {t("global_btn_continue")}
          </Button>
        </div>
      </div>
    </>
  );
};
