import {
  clearSessionStorage,
  SessionStorageKey,
  setSessionStorage,
} from "@constants/storage";
import { isAnyOf } from "@reduxjs/toolkit";
import { AppStartListening } from "@store/listenerMiddleware";
import {
  loadSalesPointConfiguration,
  selectConfiguration,
} from "@store/salesPoint";
import { resetShoppingCartSlice } from "@store/shoppingCart";
import { isStatus } from "@store/utils";
import { deepEqual } from "fast-equals";

import { resetTheatreResource, selectTheatreResource } from "./slice";

export const addTheatreResourceListeneres = (
  startListening: AppStartListening,
) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      !deepEqual(
        selectTheatreResource(previousState),
        selectTheatreResource(currentState),
      ),
    effect: (_action, { getState }) => {
      const tableInfo = selectTheatreResource(getState());

      if (isStatus(tableInfo, ["succeeded"])) {
        setSessionStorage(SessionStorageKey.THEATER_RESOURCE, tableInfo.state);
      } else {
        clearSessionStorage(SessionStorageKey.THEATER_RESOURCE);
      }
    },
  });

  startListening({
    matcher: isAnyOf(resetShoppingCartSlice),
    effect: (_action, { dispatch }) => {
      dispatch(resetTheatreResource());
    },
  });

  // Load default theatre resource when the configuration is loaded
  startListening({
    actionCreator: loadSalesPointConfiguration.fulfilled,
    effect: (_action, { getState }) => {
      const configuration = selectConfiguration(getState());

      // The actionCreator ensures that the configuration is succeeded
      if (!isStatus(configuration, ["succeeded"])) {
        return;
      }

      const existingTable = selectTheatreResource(getState());

      if (!isStatus(existingTable, ["idle", "failed"])) {
        return;
      }
    },
  });
};
