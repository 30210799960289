import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isPresent } from "@apl-digital/utils";
import { useProducts } from "@store/ProductsProvider";
import { ProductListItem } from "@store/ProductsProvider/types";
import { IdleState, State } from "@store/types";

type InitialCurrentProductState = { productId?: string };

type CurrentProductState =
  | IdleState<InitialCurrentProductState>
  | State<"loaded", ProductListItem>
  | State<"notFound", InitialCurrentProductState>;

type ProductPageContextResults = {
  currentProduct: CurrentProductState;
};

const ProductPageContext = createContext<ProductPageContextResults>(
  {} as ProductPageContextResults,
);

export const useProductPageContext = () => useContext(ProductPageContext);

type ProductPageContextProviderProps = {
  children?: React.ReactNode;
};

export const ProductPageContextProvider: React.FC<
  ProductPageContextProviderProps
> = ({ children }) => {
  const { productID } = useParams();

  const [currentProduct, setCurrentProduct] = useState<CurrentProductState>({
    status: "idle",
    state: { productId: productID },
  });

  const [{ rawProducts }, { getProductById }] = useProducts();

  useEffect(() => {
    if (
      isPresent(productID) &&
      isPresent(rawProducts) &&
      rawProducts.length > 0
    ) {
      const foundProduct = getProductById(Number(productID));

      if (isPresent(foundProduct)) {
        setCurrentProduct({ status: "loaded", state: foundProduct });
      } else {
        setCurrentProduct({
          status: "notFound",
          state: { productId: productID },
        });
      }
    }
  }, [productID, rawProducts]);

  return (
    <ProductPageContext.Provider
      value={{
        currentProduct,
      }}
    >
      {children}
    </ProductPageContext.Provider>
  );
};
