import React, { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { createUseStyles } from "react-jss";
import { getStorage, StorageKey } from "@constants/storage";
import { ProductListItem } from "@store/ProductsProvider/types";
import classnames from "classnames";

import ItemList from "./ItemList";
import {
  CATEGORIES_SELECTOR_HEIGHT,
  ITEM_MARGIN_BOTTOM,
  SECTIONS_SELECTOR_HEIGHT,
} from "./ProductList";

const useStyles = createUseStyles(({ color, spacing, font, mediaQuery }) => ({
  category: {
    paddingTop: spacing.x5l,
    paddingBottom: spacing.xxl,
    paddingLeft: spacing.xxl,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.xl,
    color: color.subCategoriesHeadingText,
    fontSize: font.size.x3l,
  },
  productListContainer: {
    marginBottom: 0,
    display: "flex",
    flexWrap: "wrap",
    gap: spacing.xxl,

    [mediaQuery.lg]: {
      marginRight: -spacing.xxl,
    },
  },
  featuredItemWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
  },
}));

type CategoryProps = {
  sectionId: number;
  category: ProductListItem;
  activeCategoryRef: MutableRefObject<ProductListItem | null>;
  isScrollActiveRef: MutableRefObject<boolean>;
  setActiveCategory?: (item: ProductListItem) => void;
  showShadows?: boolean;
};

const CategoryItem: React.FC<CategoryProps> = ({
  sectionId,
  category,
  activeCategoryRef,
  isScrollActiveRef,
  setActiveCategory,
  showShadows = false,
}) => {
  const classes = useStyles();
  const categoriesList = useRef<HTMLDivElement>(null);
  const sortCategoryItems = (items: ProductListItem[] | undefined) => {
    return items?.sort((a, b) =>
      a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1,
    );
  };
  const sortedCategoryItems = useMemo(() => {
    return sortCategoryItems(category?.items?.slice());
  }, [category?.items]);

  useEffect(() => {
    const onScroll = () => {
      const isModalOpen = getStorage(StorageKey.IS_MODAL_OPEN);
      if (categoriesList.current && !isModalOpen) {
        const categoriesListRect =
          categoriesList.current.getBoundingClientRect();

        const isCategoryVisible =
          categoriesListRect.top <=
          SECTIONS_SELECTOR_HEIGHT + CATEGORIES_SELECTOR_HEIGHT;

        const hasLeftCategory =
          -categoriesListRect.top >=
          categoriesListRect.height -
            SECTIONS_SELECTOR_HEIGHT +
            ITEM_MARGIN_BOTTOM;

        const isSameCategory = activeCategoryRef.current
          ? activeCategoryRef.current?.id === category.id
          : false;

        if (
          isCategoryVisible &&
          setActiveCategory &&
          !hasLeftCategory &&
          !isSameCategory &&
          isScrollActiveRef?.current
        ) {
          setActiveCategory(category);
        }
      }
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div ref={categoriesList}>
      <div
        id={`category-${sectionId}-${category.id}`}
        className={classnames(classes.category)}
      >
        {category.title}
      </div>
      <div className={classes.productListContainer}>
        <ItemList
          items={sortedCategoryItems}
          keyGenerator={(itemId) => `product-category-${itemId}`}
          showShadows={showShadows}
        />
      </div>
    </div>
  );
};

type ItemsListType = {
  sectionId: number;
  categories: ProductListItem[] | undefined;
  activeCategoryRef: MutableRefObject<ProductListItem | null>;
  isScrollActiveRef: MutableRefObject<boolean>;
  setActiveCategory?: (item: ProductListItem) => void;
  showShadows?: boolean;
};

const CategoriesList: React.FC<ItemsListType> = ({
  sectionId,
  categories,
  activeCategoryRef,
  isScrollActiveRef,
  setActiveCategory,
  showShadows = false,
}) => (
  <>
    {categories?.map((category) => (
      <CategoryItem
        key={`category-${sectionId}-${category.uniqueId}`}
        sectionId={sectionId}
        activeCategoryRef={activeCategoryRef}
        isScrollActiveRef={isScrollActiveRef}
        category={category}
        setActiveCategory={setActiveCategory}
        showShadows={showShadows}
      />
    ))}
  </>
);

export default CategoriesList;
