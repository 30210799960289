import * as React from "react";

import { CopyableField } from "./components/CopyableField";

export type UseRichTextTranslationResults = {
  components?:
    | readonly React.ReactElement[]
    | { readonly [tagName: string]: React.ReactElement };
};

export const useRichTextTranslation = (): UseRichTextTranslationResults => {
  return {
    components: {
      italic: <i />,
      bold: <strong />,
      copy: <CopyableField />,
    },
  };
};
