import React from "react";
import { createUseStyles } from "react-jss";
import { formatTime } from "@base/utils/formatting";
import { getBrandTheme } from "@theme";
import classnames from "classnames";

import { useSharedStyles } from "./shared.style";
import { StepperIcon, StepperIconProps } from "./StepperIcon";
import { StepperMiddleGroup } from "./StepperMiddleGroup";
import { UpperGroup } from "./types";

const useStyles = createUseStyles(({ color, spacing, font }) => ({
  statusRow: {
    display: "flex",
    position: "relative",
    height: 40,
    paddingLeft: spacing.x3l,
    flexDirection: "row",
    alignItems: "center",
    fontSize: font.size.xs,
    fontWeight: font.weight.l,
    marginLeft: spacing.m,
  },
  statusRowIcon: {
    position: "absolute",
    width: 24,
    height: 24,
    top: "50%",
    left: 0,
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
  time: {
    fontSize: font.size.l,
    fontWeight: font.weight.m,
    color: color.orderStatusCardStatusRowTimeTextColor,
    textWrap: "nowrap",
  },
  statusRowText: {
    lineHeight: font.lineHeight.m,
    fontSize: font.size.l,
    fontWeight: font.weight.m,
    color: color.orderStatusPageRowText,
  },
  statusRowLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: spacing.x5l,
    flexGrow: 1,
  },
}));

type StepperUpperGroupProps = {
  group: UpperGroup;
  isFirstGroup: boolean;
  isMiddleGroup: boolean;
  isLastGroup: boolean;
};

export const StepperUpperGroup: React.FC<StepperUpperGroupProps> = ({
  group,
  isFirstGroup,
  isMiddleGroup,
  isLastGroup,
}) => {
  const classes = useStyles();

  const { color } = getBrandTheme();
  const sharedClasses = useSharedStyles({
    linkLineColor: color.orderStatusPageLineText,
  });

  let stepperIconStatus: StepperIconProps["status"] = "DEFAULT";

  if (group.isActive) {
    stepperIconStatus = "IN_PROGRESS";
  } else if (group.isDone) {
    stepperIconStatus = "DONE";
  }

  return (
    <div>
      <div
        className={classnames([
          classes.statusRow,
          isFirstGroup && !isLastGroup && sharedClasses.linkLineBottom,
          isMiddleGroup && sharedClasses.linkLineBoth,
          isLastGroup &&
            !isFirstGroup &&
            (group.middleGroups.length > 0
              ? sharedClasses.linkLineBoth
              : sharedClasses.linkLineTop),
        ])}
      >
        <div className={classes.statusRowIcon}>
          <StepperIcon status={stepperIconStatus} />
        </div>
        <div className={classes.statusRowLabel}>
          <div className={classes.statusRowText}>{group.label}</div>
          {group.isDone && group.timeStamp && (
            <div className={classes.time}>{formatTime(group.timeStamp)}</div>
          )}
        </div>
      </div>
      {group.middleGroups.map((middleGroup, index, array) => (
        <StepperMiddleGroup
          key={middleGroup.id}
          group={middleGroup}
          isLastUpperGroup={isLastGroup}
          isLastMiddleGroup={index === array.length - 1}
        />
      ))}
    </div>
  );
};
