import {
  clearZodStorage,
  setZodStorage,
  ZodLocalStorageKey,
} from "@constants/storage";
import { isAnyOf } from "@reduxjs/toolkit";
import { AppStartListening } from "@store/listenerMiddleware";
import { resetShoppingCart, resetShoppingCartSlice } from "@store/shoppingCart";
import { isStatus } from "@store/utils";

import {
  paymentCallbackCompleted,
  resetPayment,
  selectPaymentCallback,
} from "./slice";

export const addPaymentListeners = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(resetShoppingCartSlice, resetShoppingCart),
    effect: (_action, { dispatch }) => {
      dispatch(resetPayment());
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      selectPaymentCallback(currentState) !==
      selectPaymentCallback(previousState),
    effect: (_action, { getState }) => {
      const previousPayment = selectPaymentCallback(getState());

      if (!isStatus(previousPayment, "idle")) {
        setZodStorage(ZodLocalStorageKey.PAYMENT_CALLBACK, previousPayment);
      } else {
        clearZodStorage(ZodLocalStorageKey.PAYMENT_CALLBACK);
      }
    },
  });

  startListening({
    actionCreator: paymentCallbackCompleted,
    effect: ({ payload }, { dispatch }) => {
      if (payload.type === "success") {
        dispatch(resetShoppingCart());
      }
    },
  });
};
