import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

import { ImageParallaxWrapper } from "./ImageParallaxWrapper";
import { ProgressiveImage } from "./ProgressiveImage";

type StyleProps = {
  styleVariant?: "default" | "rounded";
};

const useStyles = createUseStyles(({ borderRadius }) => ({
  slide: {
    transform: "translate3d(0, 0, 0)",
    flex: "0 0 100%",
    minWidth: 0,
    paddingLeft: "1rem",
    position: "relative",
    overflow: "hidden",
  },
  slideImage: {
    borderRadius: ({ styleVariant }: StyleProps) =>
      styleVariant === "rounded" ? borderRadius.x3l : undefined,
    display: "block",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
}));

type LazyLoadImageProps = StyleProps & {
  imgSrc: string;
  placeholderImgSrc: string;
  shouldLoad: boolean;
  isParallax?: boolean;
};

export const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
  imgSrc,
  placeholderImgSrc,
  shouldLoad,
  isParallax = false,
  styleVariant,
}) => {
  const classes = useStyles({ styleVariant });

  return (
    <div className={classes.slide}>
      <ImageParallaxWrapper isEnabled={isParallax} styleVariant={styleVariant}>
        {({ parallaxImageClassName }) => (
          <>
            {shouldLoad && (
              <ProgressiveImage
                className={classNames(
                  parallaxImageClassName,
                  classes.slideImage,
                )}
                lowResSrc={placeholderImgSrc}
                highResSrc={imgSrc}
                styleVariant={styleVariant}
              />
            )}
          </>
        )}
      </ImageParallaxWrapper>
    </div>
  );
};
