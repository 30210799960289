import React, { Children } from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles(
  ({ borderRadius, mediaQuery, color, spacing, font }) => ({
    container: {
      borderRadius: borderRadius.x3l,
      display: "flex",
      padding: spacing.x4l,
      gap: spacing.x3l,
    },
    SUCCESS: {
      background: color.bannerSuccessBackground,
      color: color.bannerSuccessTextColor,
    },
    ERROR: {
      background: color.bannerErrorBackground,
      color: color.bannerErrorTextColor,
    },
    columnVariant: {
      flexDirection: "column",
    },
    column: {
      flex: "1",
      display: "flex",
      alignItems: "center",
    },
    textColumn: {
      flexDirection: "column",
      alignItems: "initial",
    },
    imageColumn: {
      flexGrow: 0,
      display: "none",
      [mediaQuery.lg]: {
        display: "block",
      },
    },

    title: {
      fontWeight: font.weight.s,
      fontSize: font.size.x4l,
      lineHeight: font.lineHeight.xxl,
      margin: [spacing.m, 0],
    },
    subtitle: {
      fontWeight: font.weight.m,
      lineHeight: font.lineHeight.m,
      fontSize: font.size.m,
      display: "block",
    },
    image: {
      objectFit: "cover",
      width: 150,
      height: 150,
      display: "none",
      [mediaQuery.lg]: {
        display: "block",
      },
      borderRadius: borderRadius.xl,
    },
  }),
);

enum BannerVariant {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

type BannerProps = {
  title: string;
  subtitle: string;
  variant: BannerVariant;
  imgSrc?: string;
  column?: boolean;
  children?: React.ReactNode;
};

const Banner: React.FC<BannerProps> = ({
  title,
  subtitle,
  variant,
  imgSrc,
  column = false,
  children,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.container, classes[variant], [
        column && classes.columnVariant,
      ])}
    >
      {imgSrc && (
        <div className={classNames(classes.column, classes.imageColumn)}>
          <img className={classes.image} src={imgSrc} />
        </div>
      )}
      <div className={classNames(classes.column, classes.textColumn)}>
        <h2 className={classes.title}>{title}</h2>
        <span className={classes.subtitle}>{subtitle}</span>
      </div>
      <div className={classes.column}>{children}</div>
    </div>
  );
};

export { BannerVariant };
export default Banner;
