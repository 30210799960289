import authLandingPage from "@assets/locales/dev/authLandingPage.json";
import cartPage from "@assets/locales/dev/cartPage.json";
import common from "@assets/locales/dev/common.json";
import datetime from "@assets/locales/dev/datetime.json";
import errorPage from "@assets/locales/dev/errorPage.json";
import global from "@assets/locales/dev/global.json";
import homePage from "@assets/locales/dev/homePage.json";
import orderStatusPage from "@assets/locales/dev/orderStatusPage.json";
import productPage from "@assets/locales/dev/productPage.json";
import salespoint from "@assets/locales/dev/salespoint.json";
import translations from "@assets/locales/dev/translations.json";
import config from "@constants/config";
import { getStorage, setStorage, StorageKey } from "@constants/storage";
import i18n from "i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export const defaultNS = "translations" as const;

export const resources = {
  dev: {
    translations,
    common,
    global,
    datetime,
    orderStatusPage,
    errorPage,
    cartPage,
    productPage,
    homePage,
    authLandingPage,
    salespoint,
  },
} as const;

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${config.assetConfig.translations.url}/{{lng}}/{{ns}}.json`,
      crossDomain: true,
      allowMultiLoading: true,
    },
    ns: Object.keys(resources.dev),
    defaultNS,
    lng: getStorage(StorageKey.LANGUAGE, config.appConfig.defaultLanguage),
    fallbackLng: "en",
    returnEmptyString: process.env.NODE_ENV !== "development",
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
      defaultTransParent: "div",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "p", "hr"],
      transWrapTextNodes: "",
      transEmptyNodeValue: "",
    },
    partialBundledLanguages: true,
    resources: process.env.NODE_ENV === "development" ? resources : undefined,
  });

const setLanguage = async (value: string) => {
  const currentLanguage = i18next.language;
  if (currentLanguage == value) return;
  await i18next.changeLanguage(value);
  setStorage(StorageKey.LANGUAGE, value);
};

export default i18n;
export { setLanguage };
